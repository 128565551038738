import {
  postResumeApi,
  getResumeMeApi,
  postResumeExpectedJobApi,
  getResumesByIdApi,
} from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class ResumeStore {
  myResume = {};

  constructor() {
    makeAutoObservable(this);
  }

  setMyResume = (param) => {
    this.myResume = param;
  };

  postResume = async (data) => {
    return postResumeApi(data);
  };

  postResumeExpectedJob = async (data) => {
    await postResumeExpectedJobApi(data)
      .then((res) => {
        console.log(res.data.data, 'postresumeExpectedJob');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getResumeMe = async () => {
    await getResumeMeApi()
      .then((res) => {
        console.log(res.data.data);
        this.setMyResume(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getResumesById = async () => {
    await getResumesByIdApi()
      .then((res) => {
        console.log(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const resumeStore = new ResumeStore();

export default resumeStore;
