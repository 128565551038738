import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import { formatPayType } from 'components/utils/useful-function';

const LocalJobCard = ({
  company,
  title,
  address,
  pay,
  payType,
  useSecurePayment,
  visaAvailable,
}) => {
  return (
    <Wrap>
      <EngAddress>{address}</EngAddress>
      <TagAllWrap>
        <TagWrap>{useSecurePayment && <Tag color="purple">#Pay Guaranteed</Tag>}</TagWrap>
        <TagWrap>{visaAvailable && <Tag color="green">#VISA</Tag>}</TagWrap>
      </TagAllWrap>
      <CompanyName>{company}</CompanyName>
      <PayWrap>
        <PayBadge>{formatPayType(payType)}</PayBadge>
        <Pay>{pay.toLocaleString()}₩</Pay>
      </PayWrap>
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 330px;
  height: 204px;
  border: 1px solid ${theme.colors.border};
  border-radius: 5px;
  padding: 24px 22px;
  position: relative;
  @media ${theme.mobile} {
    width: 252px;
    height: 152px;
    margin: 0 0 10px 0;
    padding: 10px;
  }
`;

const TagAllWrap = styled.div`
  height: 55px;
`;

const CompanyName = styled.p`
  font-family: 'nexonMedium';
  color: #9493a4;
  font-size: 16px;
  letter-spacing: -0.6px;
  margin: 10px 0 5px 0;

  @media ${theme.mobile} {
    font-size: 12px;
  }
`;

const EngAddress = styled.p`
  font-family: 'nexonMedium';
  color: #434343;
  font-size: 15px;
  letter-spacing: -0.5px;
  margin: 0 0 12px 0;

  @media ${theme.mobile} {
    font-size: 10px;
  }
`;

const PayWrap = styled.div`
  display: flex;
`;

const PayBadge = styled.div`
  font-family: 'nexonMedium';
  color: #ff419f;
  font-size: 17px;
  letter-spacing: -0.5px;
  border: 2px solid #ff419f;
  padding: 2px 7px;
  border-radius: 19px;
  @media ${theme.mobile} {
    font-size: 13px;
  }
`;

const Pay = styled.p`
  font-family: 'nexonBold';
  color: #1c1c1c;
  font-size: 23px;
  letter-spacing: -0.7px;
  margin: 0 0 0 6px;
  @media ${theme.mobile} {
    font-size: 17px;
    margin: 2px 0 0 6px;
  }
`;

const TagWrap = styled.div`
  margin: 0 0 2px 0;
`;

const Tag = styled.div`
  font-family: 'nexonMedium';
  padding: 5px;
  background-color: #f1f3f6;
  border-radius: 14px;
  font-size: 12.8px;
  letter-spacing: -0.6px;
  display: inline-block;

  ${(props) =>
    props.color === 'purple' &&
    css`
      color: #712cef;
    `}

  ${(props) =>
    props.color === 'green' &&
    css`
      color: #00bf87;
    `}
`;

export default LocalJobCard;
