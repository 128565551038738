import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import MainNav from 'components/templates/MainNav';
import { observer } from 'mobx-react';
import stores from 'stores';
import Checkbox from 'components/atoms/Checkbox';

const AnnouncementType = observer(() => {
  const [checkAnnoucementType, setCheckAnnouncementType] = useState('');

  const handleCheckAnnouncementType = (value) => {
    setCheckAnnouncementType(value);
  };
  return (
    <PageLayout>
      <MainNav />
      <ContentWrap>
        <Title>공고 유형 선택</Title>
        <BorderWrap>
          <Label>전국 공고</Label>
          <CheckboxFlexWrap>
            <LeftSection>
              <Checkbox
                key="announcementType"
                defaultValue={true}
                type="radio"
                name="announcementType"
                handleClick={() => handleCheckAnnouncementType('PREMIUM')}
                isChecked={checkAnnoucementType === 'PREMIUM'}
              >
                <CheckboxText isChecked={checkAnnoucementType === 'PREMIUM'}>
                  프리미엄 잡 Premium Jobs
                </CheckboxText>
              </Checkbox>
              <Desc>
                전국 모든 사용자들에게 노출되는 광고로 코라이프(KO-LIFE) 메인 최상단에 위치합니다.
                <br />
                가장 높은 광고 효과를 자랑하며, 지원률 가장 높습니다.
              </Desc>
              <ImageText>※ 예시 이미지</ImageText>
            </LeftSection>
            <RightSection></RightSection>
          </CheckboxFlexWrap>
          <CheckboxFlexWrap>
            <LeftSection>
              <Checkbox
                key="announcementType"
                defaultValue={true}
                type="radio"
                name="announcementType"
                handleClick={() => handleCheckAnnouncementType('GRAND')}
                isChecked={checkAnnoucementType === 'GRAND'}
              >
                <CheckboxText isChecked={checkAnnoucementType === 'GRAND'}>
                  그랜드 잡 Grand Jobs
                </CheckboxText>
              </Checkbox>
              <Desc>
                전국 모든 사용자들에게 노출되는 광고로 코라이프(KO-LIFE) 메인 중간에 위치합니다.
                <br />
                프리미업 잡 다음으로 광고 효과가 높습니다.
              </Desc>
              <ImageText>※ 예시 이미지</ImageText>
            </LeftSection>
            <RightSection></RightSection>
          </CheckboxFlexWrap>
          <Label>지역 공고</Label>
          <CheckboxFlexWrap>
            <LeftSection>
              <Checkbox
                key="announcementType"
                defaultValue={true}
                type="radio"
                name="announcementType"
                handleClick={() => handleCheckAnnouncementType('LOCAL')}
                isChecked={checkAnnoucementType === 'LOCAL'}
              >
                <CheckboxText isChecked={checkAnnoucementType === 'LOCAL'}>
                  로컬 잡 Local Jobs (유료)
                </CheckboxText>
              </Checkbox>
              <Desc>
                특정 지역 사용자들에게만 노출되는 광고입니다. 노출 범위는 시,군,구 단위로
                노출됩니다.
                <br />
                예시 1: 서울특별시 강남구 -&gt; 강남구 사용자에게만 노출됩니다.
              </Desc>
              <ImageText>※ 예시 이미지</ImageText>
            </LeftSection>
            <RightSection></RightSection>
          </CheckboxFlexWrap>
          <CheckboxFlexWrap>
            <LeftSection>
              <Checkbox
                key="announcementType"
                defaultValue={true}
                type="radio"
                name="announcementType"
                handleClick={() => handleCheckAnnouncementType('LIFE')}
                isChecked={checkAnnoucementType === 'LIFE'}
              >
                <CheckboxText isChecked={checkAnnoucementType === 'LIFE'}>
                  라이프 잡 Life Jobs (무료)
                </CheckboxText>
              </Checkbox>
              <Desc>
                라이프 잡(LIFE JOB)은 무료 광고입니다.
                <br />
                특정 지역 사용자들에게만 노출 시킬 수 있으며, 주 1회 등록가능합니다.
              </Desc>
              <ImageText>※ 예시 이미지</ImageText>
            </LeftSection>
            <RightSection></RightSection>
          </CheckboxFlexWrap>
        </BorderWrap>
        <Button>공고 등록하기</Button>
      </ContentWrap>
    </PageLayout>
  );
});

const ContentWrap = styled.div`
  width: 1100px;
  margin: 0 auto;
`;

const Title = styled.p`
  font-size: 17px;
  font-family: 'nexonBold';
  letter-spacing: -0.44px;
  color: #434343;
  margin: 25px 0 0 10px;
`;

const BorderWrap = styled.div`
  width: 100%;
  border: 1px solid #8d8a9b;
  border-radius: 10px;
  margin: 6px 0 0 0;
  padding: 24px 20px;
`;

const Label = styled.p`
  font-family: 'nexonMedium';
  color: #0c2120;
  font-size: 19px;
  letter-spacing: -0.48px;
  width: 100%;
  background-color: #eef1f5;
  padding: 7px 14px;
`;

const CheckboxFlexWrap = styled.div`
  display: flex;

  & + & {
    border-top: 1px solid #6e6a6b;
  }
`;

const LeftSection = styled.div`
  padding: 40px 15px;
`;

const RightSection = styled.div``;

const CheckboxText = styled.p`
  color: #3b393c;

  ${(props) =>
    props.isChecked === true &&
    css`
      color: ${theme.colors.primary};
    `}
`;

const Desc = styled.p`
  font-family: 'nexonRegular';
  color: #434343;
  font-size: 14px;
  letter-spacing: -0.36px;
  line-height: 26px;
  margin: 18px 0 0 35px;
`;

const ImageText = styled.p`
  font-family: 'nexonMedium';
  color: #434343;
  font-size: 11px;
  letter-spacing: -0.28px;
  margin: 37px 0 0 35px;
`;

const Button = styled.button`
  font-family: 'nexonMedium';
  width: 320px;
  height: 60px;
  background-color: ${theme.colors.primary};
  color: white;
  font-size: 20px;
  letter-spacing: -1.25px;
  margin: 120px auto;
  display: block;
`;

export default AnnouncementType;
