import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import MainNav from 'components/templates/MainNav';
import { BiCopyAlt, BiTimeFive } from 'react-icons/bi';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { observer } from 'mobx-react';
import stores from 'stores';
import { useParams } from 'react-router-dom';
import { formatPayType } from 'components/utils/useful-function';
import KakaoMap from 'components/modules/KakaoMap';
import { Mobile, Others } from 'styles/MediaQuery';

const RecruitmentDetail = observer(() => {
  const { jobStore } = stores;
  const { id } = useParams();

  useEffect(() => {
    jobStore.getJobsDetail(id);
  }, [jobStore, id]);

  const jobDetail = jobStore.jobsDetailData;

  const formatContact = (number) => {
    const contact = number?.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    return contact;
  };

  const handleCopyClipBoard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('주소가 복사되었습니다.');
    } catch (e) {
      alert('복사에 실패하였습니다');
    }
  };

  const handleApplications = (jobId) => {
    jobStore.postJobsApplications(jobId);
  };

  return (
    <PageLayout>
      <MainNav />
      <Wrap>
        <ContentWrap>
          <CreatedDate>2021.08.04 등록</CreatedDate>
          <Title>{jobDetail.title}</Title>
          <CompanyName>{jobDetail.companyName}</CompanyName>
          <ConditionFlexWrap>
            <ConditionWrap>
              <ConditionBox>
                <ConditionIcon>
                  <PayType>{formatPayType(jobDetail.payType)}</PayType>
                </ConditionIcon>
                <ConditionText>{jobDetail.payAmount?.toLocaleString()}￦</ConditionText>
              </ConditionBox>
              <ConditionBox>
                <ConditionIcon>
                  <CalendarIcon />
                </ConditionIcon>
                <ConditionText>{jobDetail.numberOfWorkDays} Day a week</ConditionText>
              </ConditionBox>
              <ConditionBox>
                <ConditionIcon>
                  <TimeIcon />
                </ConditionIcon>
                <ConditionText>{jobDetail.workingTimeSpan}</ConditionText>
              </ConditionBox>
            </ConditionWrap>
            <ButtonWrap>
              <Button>전화하기</Button>
              <Button onClick={() => handleApplications(jobDetail.id)}>지원하기</Button>
            </ButtonWrap>
          </ConditionFlexWrap>
        </ContentWrap>
        <BackgroundContentWrap>
          {jobDetail.useSecurePayment && (
            <TagExplanationBox>
              <KeywordBlue>#Pay Guaranteed</KeywordBlue>
              <KeywordTitle>코라이프가 인증한 안전한 기업입니다.</KeywordTitle>
              <KeywordDesc>
                임금 체불 발생시, 코라이프에서 급여를 받을 수 있도록 법률 서비스를 제공해드립니다.
              </KeywordDesc>
            </TagExplanationBox>
          )}
          {jobDetail.visaAvailable && (
            <TagExplanationBox>
              <KeywordGreen>#VISA</KeywordGreen>
              <KeywordTitle>외국인에게 비자를 제공할 수 있는 기업입니다.</KeywordTitle>
              <KeywordDesc>제공되는 비자는 E-7, E-9 취업비자입니다.</KeywordDesc>
            </TagExplanationBox>
          )}
        </BackgroundContentWrap>
        <ContentWrap>
          <WrapBox>
            <LabelBorder>키워드</LabelBorder>
            <KeywordTagWrap>
              {jobDetail.keywordList?.map((item) => (
                <Tag color="purple" key={item}>
                  #{item}
                </Tag>
              ))}
            </KeywordTagWrap>
          </WrapBox>
          <FlexWrap>
            <FlexWrapBox>
              <LabelBorder>모집 조건</LabelBorder>
              <TextConditionsWrap>
                <TextConditionsItem>
                  <TextConditionsLabel>모집인원</TextConditionsLabel>
                  <TextConditionsInfo>{jobDetail.numberOfPersons}명</TextConditionsInfo>
                </TextConditionsItem>
                <TextConditionsItem>
                  <TextConditionsLabel>모집성별</TextConditionsLabel>
                  <TextConditionsInfo>
                    {jobDetail.gender === 'NONE'
                      ? '성별 무관'
                      : jobDetail.gender === 'MAN'
                      ? '남성'
                      : jobDetail.gender === 'WOMAN'
                      ? '여성'
                      : ''}
                  </TextConditionsInfo>
                </TextConditionsItem>
                <TextConditionsItem>
                  <TextConditionsLabel>근로자 희망 국적</TextConditionsLabel>
                  <TextConditionsInfo>국적 무관</TextConditionsInfo>
                </TextConditionsItem>
              </TextConditionsWrap>
            </FlexWrapBox>
            <FlexWrapBox>
              <LabelBorder>근무 조건</LabelBorder>
              <TextConditionsWrap>
                <TextConditionsItem>
                  <TextConditionsLabel>급여</TextConditionsLabel>
                  <TextConditionsInfo>
                    <PaymentType>{formatPayType(jobDetail.payType)}</PaymentType>
                    {jobDetail.payAmount?.toLocaleString()} 원
                    <PayHelpText>*2022년 최저시급 : 9,160원</PayHelpText>
                  </TextConditionsInfo>
                </TextConditionsItem>
                <TextConditionsItem>
                  <TextConditionsLabel>근무기간</TextConditionsLabel>
                  <TextConditionsInfo>
                    {jobDetail.periodType === 'UNDER_WEEK'
                      ? '1주 이하'
                      : jobDetail.periodType === 'UNDER_MONTH'
                      ? '1개월 이하'
                      : jobDetail.periodType === 'UNDER_3MONTH'
                      ? '3개월 이하'
                      : jobDetail.periodType === 'UNDER_6MONTH'
                      ? '6개월 이하'
                      : jobDetail.periodType === 'UNDER_1YEAR'
                      ? '1년 이하'
                      : jobDetail.periodType === 'ABOVE_1YEAR'
                      ? '1년 이상'
                      : ''}
                  </TextConditionsInfo>
                </TextConditionsItem>
                <TextConditionsItem>
                  <TextConditionsLabel>근무요일</TextConditionsLabel>
                  <TextConditionsInfo>주 {jobDetail.numberOfWorkDays}일</TextConditionsInfo>
                </TextConditionsItem>
                <TextConditionsItem>
                  <TextConditionsLabel>근무시간</TextConditionsLabel>
                  <TextConditionsInfo>{jobDetail.workingTimeSpan}</TextConditionsInfo>
                </TextConditionsItem>
                {jobDetail.visaAvailable && (
                  <TextConditionsItem>
                    <TextConditionsLabel>비자</TextConditionsLabel>
                    <TextConditionsInfo>E-7</TextConditionsInfo>
                  </TextConditionsItem>
                )}
              </TextConditionsWrap>
            </FlexWrapBox>
          </FlexWrap>
        </ContentWrap>
      </Wrap>
      <Mobile>
        <Border></Border>
      </Mobile>
      <Wrap>
        <ContentWrap>
          <Label>상세 정보</Label>
          <InformationBox>
            {jobDetail.details}
            <TranslationWrap>
              <TranslationButton>Translation</TranslationButton>
            </TranslationWrap>
          </InformationBox>
        </ContentWrap>
        {jobDetail.imageUrl && (
          <Photo>
            <img src={jobDetail.imageUrl} alt="근무 이미지" />
          </Photo>
        )}
        <ContentWrap>
          <Label>근무 지역</Label>
          <AddressWrap>
            {`${jobDetail.address}\n${jobDetail.addressDetails}`}
            <CopyIcon
              onClick={() =>
                handleCopyClipBoard(`${jobDetail.address}\n${jobDetail.addressDetails}`)
              }
            />
          </AddressWrap>
        </ContentWrap>
        <Photo>
          <KakaoMap latitude={jobDetail.latitude} longitude={jobDetail.longitude} />
        </Photo>
        <ContentWrap>
          <Label>회사 정보</Label>
          <TextConditionsWrap>
            <TextConditionsItem>
              <TextConditionsLabel>업체명</TextConditionsLabel>
              <TextConditionsInfo>{jobDetail.companyName}</TextConditionsInfo>
            </TextConditionsItem>
            <TextConditionsItem>
              <TextConditionsLabel>연락처</TextConditionsLabel>
              <TextConditionsInfo>{formatContact(jobDetail.contact)}</TextConditionsInfo>
            </TextConditionsItem>
          </TextConditionsWrap>
        </ContentWrap>
        <Mobile>
          <Border></Border>
        </Mobile>
      </Wrap>
      <NoneBorderWrap>
        <FlexWrap>
          <Warning>
            ※ 구직이 아닌 광고 등의 목적으로 연락처를 이용할 경우 법적 처벌을 받을 수 있습니다.
          </Warning>
          <FlexButtonWrap>
            <FlexButton>전화하기</FlexButton>
            <FlexButton>지원하기</FlexButton>
          </FlexButtonWrap>
        </FlexWrap>
      </NoneBorderWrap>
    </PageLayout>
  );
});

const Wrap = styled.div`
  width: 1100px;
  margin: 60px auto 0;
  border: 1px solid ${theme.colors.border};
  border-radius: 10px;

  @media ${theme.mobile} {
    width: 100%;
    border: none;
    margin: 0;
  }
`;

const NoneBorderWrap = styled.div`
  width: 1100px;
  margin: 0 auto 0;

  @media ${theme.mobile} {
    width: 100%;
    margin: 0;
  }
`;

const ContentWrap = styled.div`
  padding: 35px 40px 50px;

  @media ${theme.mobile} {
    padding: 35px 30px 0;
  }
`;

const CreatedDate = styled.p`
  font-family: 'nexonMedium';
  color: #9493a4;
  font-size: 14px;
  letter-spacing: -0.7px;
`;

const Title = styled.p`
  font-family: 'nexonMedium';
  color: #424547;
  font-size: 29px;
  letter-spacing: -1.25px;
  margin: 34px 0 24px;

  @media ${theme.mobile} {
    font-size: 25px;
    margin: 15px 0;
  }
`;
const CompanyName = styled.p`
  font-family: 'nexonRegular';
  color: #313131;
  font-size: 19px;
  letter-spacing: -0.8px;

  @media ${theme.mobile} {
    font-family: 'nexonMedium';
    color: #80878e;
    font-size: 17px;
  }
`;

const ConditionFlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${theme.mobile} {
    display: block;
  }
`;

const ConditionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 50px 0 0 0;
  width: 550px;

  @media ${theme.mobile} {
    margin: 45px 20px 55px 20px;
  }
`;

const ConditionBox = styled.div`
  width: 140px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media ${theme.mobile} {
    width: 120px;
  }
`;

const ConditionIcon = styled.div`
  width: 100%;
  margin: 0 0 15px 0;
  height: 66px;
`;

const ConditionText = styled.p`
  font-family: 'nexonMedium';
  color: #424547;
  font-size: 17px;
  letter-spacing: -0.8px;
  width: 100%;
  text-align: center;
`;

const PayType = styled.div`
  width: 65px;
  height: 65px;
  border-radius: 35px;
  border: 2px solid #ff419f;
  font-family: 'nexonMedium';
  color: #ff419f;
  font-size: 16px;
  letter-spacing: -0.8px;
  text-align: center;
  padding: 21px 0 0 0;
  margin: 0 auto;
`;

const FlexButtonWrap = styled.div`
  display: flex;

  @media ${theme.mobile} {
    display: flex;
    width: 100%;
    padding: 0 20px;
    justify-content: space-between;
  }
`;

const ButtonWrap = styled.div`
  display: block;
  width: 225px;
  margin: 0 20px 0 0;

  @media ${theme.mobile} {
    display: flex;
    width: 100%;
    padding: 0 20px;
    justify-content: space-between;
  }
`;

const Button = styled.button`
  font-family: 'nexonMedium';
  width: 225px;
  height: 70px;
  background-color: transparent;
  border: 2px solid ${theme.colors.primary};
  color: #424547;
  font-size: 22px;
  letter-spacing: -1.13px;
  border-radius: 5px;

  & + & {
    margin: 22px 0 0 0;
  }

  @media ${theme.mobile} {
    & + & {
      margin: 0;
    }
  }
`;

const FlexButton = styled(Button)`
  & + & {
    margin: 0 0 0 22px;
  }
`;

const BackgroundContentWrap = styled.div`
  margin: 35px 0 0 0;
`;

const TagExplanationBox = styled.div`
  padding: 30px 35px;
  background-color: #eef1f5;
  display: flex;
  align-items: center;

  @media ${theme.mobile} {
    display: block;
  }
`;

const KeywordBlue = styled.p`
  font-family: 'nexonBold';
  font-size: 22px;
  letter-spacing: -0.8px;
  color: #712cef;
  width: 200px;

  @media ${theme.mobile} {
    font-size: 18px;
  }
`;

const KeywordGreen = styled(KeywordBlue)`
  color: #00bf87;
`;

const KeywordTitle = styled.p`
  font-family: 'nexonRegular';
  font-size: 18px;
  font-size: 14px;
  color: #434343;
  margin: 0 5px 0 0;

  @media ${theme.mobile} {
    margin: 13px 0;
    font-size: 18px;
  }
`;
const KeywordDesc = styled(KeywordTitle)`
  font-size: 14px;
  letter-spacing: -0.7px;
`;

const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 90px 0 60px 0;

  @media ${theme.mobile} {
    display: block;
  }
`;

const WrapBox = styled.div`
  @media ${theme.mobile} {
    width: 100%;
    & + & {
      margin: 70px 0 0 0;
    }
  }
`;

const FlexWrapBox = styled(WrapBox)`
  width: 470px;
`;

const LabelBorder = styled.p`
  font-family: 'nexonMedium';
  font-size: 20px;
  color: #313131;
  letter-spacing: -1px;
  padding: 13px 0;
  border-bottom: 1px solid ${theme.colors.purpleGray};
`;

const KeywordTagWrap = styled.div`
  margin: 20px 0 0 0;
`;

const Tag = styled.div`
  font-family: 'nexonMedium';
  padding: 7px;
  background-color: #ffffff;
  display: inline-block;
  border-radius: 14px;
  font-size: 16px;
  letter-spacing: -0.7px;

  ${(props) =>
    props.color === 'purple' &&
    css`
      color: #712cef;
    `}

  ${(props) =>
    props.color === 'green' &&
    css`
      color: #00bf87;
    `}

    & + & {
    margin-left: 5px;
  }

  @media ${theme.mobile} {
    background-color: #f1f3f6;
  }
`;

const TextConditionsWrap = styled.div`
  padding: 20px 5px 0 5px;
`;

const TextConditionsItem = styled.div`
  display: flex;

  & + & {
    margin: 26px 0 0 0;
  }
`;

const TextConditionsLabel = styled.p`
  font-family: 'nexonMedium';
  font-size: 18px;
  color: #80878e;
  letter-spacing: -0.8px;
  width: 150px;
`;

const TextConditionsInfo = styled.p`
  font-family: 'nexonMedium';
  font-size: 18px;
  color: #313131;
  letter-spacing: -0.8px;
`;

const PaymentType = styled.span`
  font-family: 'nexonMedium';
  font-size: 15px;
  color: #ff419f;
  letter-spacing: -0.7px;
  border: 1px solid #ff419f;
  padding: 3px 8px;
  border-radius: 15px;
  margin: 0 8px 0 0;
`;

const PayHelpText = styled.span`
  display: block;
  font-family: 'nexonRegular';
  font-size: 15px;
  color: #9495a3;
  letter-spacing: -0.7px;
  margin: 10px 0 0 0;
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.purpleGray};
  margin: 35px 0 0 0;
`;

const Label = styled.p`
  font-family: 'nexonMedium';
  font-size: 20px;
  color: #313131;
  letter-spacing: -1px;
`;

const InformationBox = styled.div`
  border: 1.5px solid ${theme.colors.purpleGray};
  border-radius: 8px;
  margin: 18px 0 0 0;
  padding: 17px 22px 90px;
  font-family: 'nexonRegular';
  font-size: 20px;
  color: #313131;
  letter-spacing: -1px;
  position: relative;
  line-height: 34px;
`;

const TranslationWrap = styled.div`
  border-top: 1px solid ${theme.colors.purpleGray};
  position: absolute;
  bottom: 0;
  width: 90%;
`;

const TranslationButton = styled.button`
  font-size: 15px;
  color: ${theme.colors.purpleGray};
  font-family: 'nexonMedium';
  letter-spacing: -0.7px;
  background-color: transparent;
  border: 1px solid ${theme.colors.purpleGray};
  border-radius: 20px;
  padding: 10px 13px;
  margin: 16px 0;
`;

const Photo = styled.div`
  width: 100%;
  height: 400px;
  margin: 35px 0 0 0;
  padding: 0 40px;

  @media ${theme.mobile} {
    padding: 0;
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const AddressWrap = styled.div`
  font-family: 'nexonMedium';
  font-size: 17px;
  color: #80878e;
  letter-spacing: -0.8px;
  margin: 22px 0 16px 0;
  display: flex;
`;

const CopyIcon = styled(BiCopyAlt)`
  margin: 0 0 0 5px;
  color: ${theme.colors.primary};
  cursor: pointer;
`;

const Warning = styled.div`
  font-family: 'nexonMedium';
  font-size: 14px;
  color: #985971;
  letter-spacing: -0.7px;
  text-align: center;
  margin: 0 0 44px 0;
`;

const CalendarIcon = styled(FaRegCalendarAlt)`
  font-size: 50px;
  padding: 10px 0 0 50px;

  @media ${theme.mobile} {
    padding: 10px 0 0 35px;
  }
`;

const TimeIcon = styled(BiTimeFive)`
  font-size: 50px;
  padding: 12px 0 0 43px;

  @media ${theme.mobile} {
    padding: 10px 0 0 35px;
  }
`;

export default RecruitmentDetail;
