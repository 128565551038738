import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';

const ResumeInput = ({ label, name, value, placeholder, type, ...rest }) => {
  return (
    <InputWrap>
      <Label>{label}</Label>
      <Input name={name} value={value} type={type} placeholder={placeholder} {...rest} />
    </InputWrap>
  );
};

export const AnnouncementInput = ({ label, name, value, placeholder, type, ...rest }) => {
  return (
    <InputWrap>
      <SmallLabel>{label}</SmallLabel>
      <Input name={name} value={value} type={type} placeholder={placeholder} {...rest} />
    </InputWrap>
  );
};

const InputWrap = styled.div`
  display: flex;
  @media ${theme.mobile} {
    display: block;
  }
`;

const Label = styled.p`
  font-family: 'nexonMedium';
  font-size: 20px;
  letter-spacing: -1px;
  color: #1f2422;
  width: 100px;
  line-height: 44px;
  @media ${theme.mobile} {
    margin: 0 0 10px 0;
    line-height: 20px;
    color: #80878e;
  }
`;
const Input = styled.input`
  width: 500px;
  border: none;
  border-bottom: 1px solid ${theme.colors.borderLight};
  font-family: 'nexonRegular';
  font-size: 17px;
  padding: 8px 0;

  &::placeholder {
    color: ${theme.colors.borderLight};
  }

  @media ${theme.mobile} {
    width: 100%;
    font-size: 21px;
  }
`;

const SmallLabel = styled(Label)`
  font-size: 17px;
  width: 150px;
`;

export default ResumeInput;
