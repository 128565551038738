import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { routes } from 'route/routes';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId="719063081930-3fgcvfbsq1edm0p1n62datkbthu44jom.apps.googleusercontent.com">
        <Routes>
          {routes.map((route) => (
            <Route path={route.path} element={route.element} key={`route_${route.path}`} />
          ))}
        </Routes>
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
}

export default App;
