import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import LabeldInput from 'components/atoms/LabeledInput';
import { observer } from 'mobx-react';
import stores from 'stores';

const SignUp = observer(() => {
  const { userStore } = stores;

  const [inputs, setInputs] = useState({ name: '', email: '', password: '', passwordCheck: '' });

  const { name, email, password, passwordCheck } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const [role, setRole] = useState('');

  const handleClickRole = (tab) => {
    setRole(tab);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      alert('이메일을 입력해주세요');
      return;
    }
    if (!password) {
      alert('비밀번호를 입력해주세요');
      return;
    }
    if (password !== passwordCheck) {
      alert('비밀번호가 일치하는지 확인해주세요');
      return;
    }
    if (!role) {
      alert('회원 유형을 선택해주세요');
      return;
    }
    if (!name) {
      alert('성함을 입력해주세요');
      return;
    }
    const data = {
      authType: 'EMAIL',
      email: email,
      name: name,
      password: password,
      role: role,
      thirdPartyAccessToken: '',
      thirdPartyUserId: '',
    };
    console.log(data);
    userStore.signUpUsers(data);
  };

  return (
    <PageLayout>
      <ContentWrap>
        <Title>회원가입하기</Title>
        <Form onSubmit={handleSubmit}>
          <RoleTabWrap>
            <RoleTab onClick={() => handleClickRole('EMPLOYEE')} active={role === 'EMPLOYEE'}>
              구직자 회원
            </RoleTab>
            <RoleTab onClick={() => handleClickRole('EMPLOYER')} active={role === 'EMPLOYER'}>
              기업 회원
            </RoleTab>
          </RoleTabWrap>
          <LabeldInput
            label="성명"
            name="name"
            value={name}
            placeholder="성함을 입력해주세요."
            onChange={onChange}
          />
          <LabeldInput
            label="이메일 입력"
            name="email"
            value={email}
            placeholder="사용하시는 이메일을 입력해주세요."
            onChange={onChange}
          />
          <LabeldInput
            label="비밀번호"
            name="password"
            value={password}
            placeholder="비밀번호 (8 ~ 16자 이상)"
            onChange={onChange}
            type="password"
          />
          <LabeldInput
            label="비밀번호 확인"
            name="passwordCheck"
            value={passwordCheck}
            placeholder="비밀번호 (8 ~ 16자 이상)"
            onChange={onChange}
            type="password"
          />
          <Button type="submit">가입하기</Button>
        </Form>
      </ContentWrap>
    </PageLayout>
  );
});

const ContentWrap = styled.div`
  padding: 0 30px;
`;

const Title = styled.div`
  font-size: 35px;
  font-family: 'nexonBold';
  text-align: center;
  margin: 140px 0 65px 0;
  @media ${theme.mobile} {
    font-size: 25px;
    margin: 60px 0 65px 0;
  }
`;

const Form = styled.form`
  border: 1px solid ${theme.colors.border};
  width: 900px;
  margin: 0 auto;
  padding: 90px 80px 100px;
  border-radius: 10px;
  @media ${theme.mobile} {
    border: 0;
    width: 100%;
    padding: 0;
  }
`;

const Button = styled.button`
  font-family: 'nexonMedium';
  border: none;
  width: 372px;
  height: 68px;
  background-color: ${theme.colors.primary};
  margin: 120px auto 0;
  display: block;
  color: white;
  font-size: 22px;
  @media ${theme.mobile} {
    margin: 80px auto 0;
    width: 250px;
    height: 58px;
  }
`;

const RoleTabWrap = styled.div`
  display: flex;
  margin: 0 0 53px 0;
  @media ${theme.mobile} {
    & + & {
      margin: 0 0 40px 0;
    }
  }
`;

const RoleTab = styled.div`
  font-family: 'nexonMedium';
  width: 50%;
  height: 54px;
  border: 1px solid ${theme.colors.borderLight};
  background-color: transparent;
  color: #80878e;
  font-size: 20px;
  text-align: center;
  padding: 15px 0 0 0;

  & + & {
    margin-left: -1px;
  }

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonBold';
      border: 1px solid ${theme.colors.primary};
      z-index: 1;
      color: ${theme.colors.primary};
    `}
`;

export default SignUp;
