import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import MainNav from 'components/templates/MainNav';
import { AnnouncementInput } from 'components/modules/ResumeInput';
import Checkbox from 'components/atoms/Checkbox';
import { observer } from 'mobx-react';
import stores from 'stores';
import SelectOptions from 'components/atoms/SelectOptions';
import DaumPostcode from 'react-daum-postcode';
import { BiSearch } from 'react-icons/bi';

const jobCategory = [
  { text: '공장', value: 'FACTORY' },
  { text: '농장', value: 'FARM' },
  { text: '식당', value: 'RESTAURANT' },
  { text: '건설', value: 'CONSTRUCTION' },
  { text: '번역', value: 'TRANSLATION' },
  { text: '사무직', value: 'OFFICE' },
];

const payTypeOptions = [
  { value: 'HOURLY', text: '시급' },
  { value: 'DAILY', text: '일급' },
  { value: 'WEEKLY', text: '주급' },
  { value: 'MONTHLY', text: '월급' },
  { value: 'YEARLY', text: '연봉' },
];

const timeOption = [
  { value: 'MORNING', text: '오전' },
  { value: 'AFTERNOON', text: '오후' },
];

const hourOption = [
  { value: '01:00', text: '01:00' },
  { value: '02:00', text: '02:00' },
  { value: '03:00', text: '03:00' },
  { value: '04:00', text: '04:00' },
  { value: '05:00', text: '05:00' },
  { value: '06:00', text: '06:00' },
  { value: '07:00', text: '07:00' },
  { value: '08:00', text: '08:00' },
  { value: '09:00', text: '09:00' },
  { value: '10:00', text: '10:00' },
  { value: '11:00', text: '11:00' },
  { value: '12:00', text: '12:00' },
];

const JobPeriodTypeOptions = [
  {
    text: '일주일 이하',
    value: 'UNDER_WEEK',
  },
  {
    text: '한 달 이하',
    value: 'UNDER_MONTH',
  },
  {
    text: '3개월 이하',
    value: 'UNDER_3MONTH',
  },
  {
    text: '6개월 이하',
    value: 'UNDER_6MONTH',
  },
  {
    text: '1년 미만',
    value: 'UNDER_1YEAR',
  },
  {
    text: '1년 이상',
    value: 'ABOVE_1YEAR',
  },
];

const workDayOptions = [
  {
    text: '주 1일',
  },
  {
    text: '주 2일',
  },
  {
    text: '주 3일',
  },
  {
    text: '주 4일',
  },
  {
    text: '주 5일',
  },
  {
    text: '주 6일',
  },
  {
    text: '주 7일',
  },
];

const ProposalEmployment = observer(() => {
  const [inputs, setInputs] = useState({
    title: '',
    companyName: '',
    cellphone: '',
    numOfPerson: 0,
    payAmount: 0,
    address: '',
    detailAddress: '',
    detailInformation: '',
    businessName: '',
    idNumFront: '',
    idNumBack: '',
    representative: '',
  });

  const {
    title,
    companyName,
    cellphone,
    numOfPerson,
    payAmount,
    address,
    detailAddress,
    detailInformation,
    businessName,
    idNumFront,
    idNumBack,
    representative,
  } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const [sectors, setSectors] = useState('');

  const handleClickSectors = (tab) => {
    setSectors(tab);
  };

  const [sectorEtc, setSectorEtc] = useState(false);

  const handleSectorEtc = () => {
    setSectorEtc(!sectorEtc);
    setSectors('');
  };

  const [payType, setPayType] = useState({ value: '', text: '선택' });

  const [showPostCode, setShowPostCode] = useState(false);

  const handleAddressComplete = (data) => {
    setInputs({
      ...inputs,
      address: data.address,
    });
    setShowPostCode(false);
  };

  const postCodeStyle = {
    display: 'block',
    position: 'fixed',
    top: 'calc(50% - 200px)',
    left: 'calc(50% - 350px)',
    width: '700px',
    height: '400px',
    zIndex: 100,
    border: '2px solid #bcbcbc',
  };

  const [startTime, setStartTime] = useState({ value: 'AFTERNOON', text: '오후' });
  const [startHour, setStartHour] = useState({ value: '01:00', text: '01:00' });
  const [endTime, setEndTime] = useState({ value: 'MORNING', text: '오전' });
  const [endHour, setEndHour] = useState({ value: '01:00', text: '01:00' });
  const [jobPeriodType, setJobPeriodType] = useState({ value: '', text: '선택' });
  const [workDay, setWorkDay] = useState({ text: '선택' });

  return (
    <PageLayout>
      <MainNav />
      <ContentWrap>
        <Title>채용제안 보내기</Title>
        <BorderWrap>
          <SectionWrap>
            <InputItemWrap>
              <AnnouncementInput
                label="공고 제목"
                name="title"
                value={title}
                placeholder="오늘의 맛집"
                onChange={onChange}
              />
            </InputItemWrap>
            <InputItemWrap>
              <AnnouncementInput
                label="업체명"
                name="companyName"
                value={companyName}
                placeholder="아웃랜더코트립"
                onChange={onChange}
              />
            </InputItemWrap>
            <InputItemWrap>
              <AnnouncementInput
                label="연락처"
                name="cellphone"
                value={cellphone}
                placeholder="010-5123-1234"
                onChange={onChange}
              />
            </InputItemWrap>
            <InputItemWrap>
              <InputFlexWrap>
                <Label>모집 분야</Label>
                <SectorsWrap>
                  <SectorsTabWrap>
                    {jobCategory.map((item) => (
                      <SectorsTab
                        onClick={() => handleClickSectors(item.value)}
                        active={sectors === item.value}
                        key={item.value}
                      >
                        {item.text}
                      </SectorsTab>
                    ))}
                  </SectorsTabWrap>
                  <CheckboxFlexWrap>
                    <Checkbox
                      key="sectorEtc"
                      defaultValue={true}
                      type="checkbox"
                      name="sectorEtc"
                      handleClick={handleSectorEtc}
                      isChecked={sectorEtc === true}
                    >
                      기타 :
                    </Checkbox>
                    <CheckboxInput />
                  </CheckboxFlexWrap>
                </SectorsWrap>
              </InputFlexWrap>
            </InputItemWrap>
          </SectionWrap>
          <DivideBorder></DivideBorder>
          <SectionWrap>
            <FlexWrap>
              <HalfSection>
                <InputItemWrap>
                  <LabelFlexWrap>
                    <FlexLabel>급여</FlexLabel>
                    <LabelAdd>
                      *2021년 최저시급은 <span>9,160원</span> 입니다.
                    </LabelAdd>
                  </LabelFlexWrap>
                  <PayTypeFlexWrap>
                    <SelectOptionWrap>
                      <SelectOptions
                        value={payType}
                        onSelect={(val) => setPayType(val)}
                        options={payTypeOptions}
                        style={{ height: '40px' }}
                        line={true}
                      />
                    </SelectOptionWrap>
                    <SmallInputWrap>
                      <SmallInput
                        name="payAmount"
                        value={payAmount}
                        placeholder="0"
                        onChange={onChange}
                      />
                      원
                    </SmallInputWrap>
                  </PayTypeFlexWrap>
                </InputItemWrap>
                <InputItemWrap>
                  <Label>근무 장소</Label>
                  <AddressInputWrap>
                    <AddressInputBtnWrap>
                      <AddressInput
                        placeholder="주소"
                        defaultValue={inputs.address}
                        onClick={() => setShowPostCode(!showPostCode)}
                      />
                      <SearchIcon />
                    </AddressInputBtnWrap>
                    <DetailAddressInput
                      name="detailAddress"
                      value={detailAddress}
                      placeholder="상세주소를 입력해주세요"
                      onChange={onChange}
                    />
                  </AddressInputWrap>
                  {showPostCode ? (
                    <PopupBackground onClick={() => setShowPostCode(false)}>
                      <DaumPostcode onComplete={handleAddressComplete} style={postCodeStyle} />
                    </PopupBackground>
                  ) : (
                    <></>
                  )}
                </InputItemWrap>
              </HalfSection>
              <HalfSection>
                <InputItemWrap>
                  <Label>근무 시간</Label>
                  <TimeWrap>
                    <StartTime>
                      <TimeSelectWrap>
                        <SelectOptions
                          value={startTime}
                          onSelect={(val) => setStartTime(val)}
                          options={timeOption}
                          style={{ height: '40px' }}
                          line={true}
                        />
                      </TimeSelectWrap>
                      <HourSelectWrap>
                        <SelectOptions
                          value={startHour}
                          onSelect={(val) => setStartHour(val)}
                          options={hourOption}
                          style={{ height: '40px' }}
                          line={true}
                        />
                      </HourSelectWrap>
                    </StartTime>
                    <InputSubText>~</InputSubText>
                    <EndTime>
                      <TimeSelectWrap>
                        <SelectOptions
                          value={endTime}
                          onSelect={(val) => setEndTime(val)}
                          options={timeOption}
                          style={{ height: '40px' }}
                          line={true}
                        />
                      </TimeSelectWrap>
                      <HourSelectWrap>
                        <SelectOptions
                          value={endHour}
                          onSelect={(val) => setEndHour(val)}
                          options={hourOption}
                          style={{ height: '40px' }}
                          line={true}
                        />
                      </HourSelectWrap>
                    </EndTime>
                  </TimeWrap>
                </InputItemWrap>
                <InputItemWrap>
                  <InputFlexWrap>
                    <InputFlexItem>
                      <Label>근무 기간</Label>
                      <JobPeriodSelectWrap>
                        <SelectOptions
                          value={jobPeriodType}
                          onSelect={(val) => setJobPeriodType(val)}
                          options={JobPeriodTypeOptions}
                          style={{ height: '40px' }}
                          line={true}
                        />
                      </JobPeriodSelectWrap>
                    </InputFlexItem>
                    <InputFlexItem>
                      <Label>근무 요일</Label>
                      <JobPeriodSelectWrap>
                        <SelectOptions
                          value={workDay}
                          onSelect={(val) => setWorkDay(val)}
                          options={workDayOptions}
                          style={{ height: '40px' }}
                          line={true}
                        />
                      </JobPeriodSelectWrap>
                    </InputFlexItem>
                  </InputFlexWrap>
                </InputItemWrap>
              </HalfSection>
            </FlexWrap>
          </SectionWrap>
          <DivideBorder></DivideBorder>
          <SectionWrap>
            <InputItemWrap>
              <Label>상세 정보</Label>
              <TextArea
                name="detailInformation"
                value={detailInformation}
                placeholder="상세정보를 적어주세요"
                onChange={onChange}
              />
            </InputItemWrap>
          </SectionWrap>
        </BorderWrap>
        <Button>채용제안 보내기</Button>
      </ContentWrap>
    </PageLayout>
  );
});

const ContentWrap = styled.div`
  width: 1100px;
  margin: 0 auto;
`;

const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
`;

const Title = styled.p`
  font-size: 26px;
  font-family: 'nexonBold';
  letter-spacing: -0.65px;
  color: #434343;
  margin: 60px 0 5px 10px;
`;

const BorderWrap = styled.div`
  width: 100%;
  border: 1px solid #8d8a9b;
  border-radius: 10px;
`;

const SectionWrap = styled.div`
  padding: 31px 37px 0;
`;

const InputItemWrap = styled.div`
  margin: 0 0 70px 0;
  position: relative;
`;

const InputFlexWrap = styled.div`
  display: flex;
`;

const Label = styled.p`
  font-family: 'nexonMedium';
  color: #80878e;
  font-size: 17px;
  letter-spacing: -1px;
  color: #1f2422;
  width: 150px;
`;

const SectorsWrap = styled.div``;

const SectorsTabWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 32px 0;
  width: 385px;
  border: none;
  padding: 0;
`;

const SelectBox = styled.div`
  border: 1px solid ${theme.colors.borderLight};
  color: #6d6f82;
  font-family: 'nexonMedium';
  font-size: 15px;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
`;

const SectorsTab = styled(SelectBox)`
  position: relative;
  width: 50%;
  height: 40px;

  &:nth-child(2n) {
    margin-left: -1px;
  }

  &:nth-child(n + 1) {
    margin-top: -1px;
  }

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonMedium';
      border: 1px solid ${theme.colors.primary};
      z-index: 1;
      color: ${theme.colors.primary};
    `}
`;

const CheckboxFlexWrap = styled.div`
  display: flex;
`;

const CheckboxInput = styled.input`
  width: 300px;
  border: none;
  border-bottom: 1px solid ${theme.colors.borderLight};
  font-family: 'nexonRegular';
  font-size: 15px;
  padding: 6px 0;
  margin: 0 0 0 10px;

  &::placeholder {
    color: #d0d2e8;
  }
`;

const DivideBorder = styled.div`
  height: 14px;
  width: 100%;
  background-color: #eef1f5;
`;

const LabelFlexWrap = styled.div`
  display: flex;
`;

const FlexLabel = styled(Label)`
  width: auto;
`;

const LabelAdd = styled(Label)`
  font-size: 14px;
  width: auto;
  margin: 0 0 0 25px;

  & > span {
    color: ${theme.colors.primary};
  }
`;

const PayTypeFlexWrap = styled.div`
  display: flex;
  margin: 20px 0 0 0;
`;

const SelectOptionWrap = styled.div`
  width: 80px;
  margin: 0 28px 0 0;
`;

const SmallInputWrap = styled.div`
  display: flex;
  font-size: 19px;
  color: #434343;
  letter-spacing: -0.43px;
  font-family: 'nexonMedium';
  align-items: center;
`;

const SmallInput = styled.input`
  width: 180px;
  border: none;
  border-bottom: 1px solid ${theme.colors.borderLight};
  font-family: 'nexonRegular';
  font-size: 19px;
  margin: 0 8px 0 0;
  height: 40px;

  &::placeholder {
    color: #d0d2e8;
  }
`;

const AddressInputWrap = styled.div`
  margin: 14px 0 0 0;
  width: 440px;
`;

const AddressInput = styled.input`
  border: 1px solid #d0d2e8;
  width: 440px;
  height: 54px;
  padding: 0 0 0 17px;
  font-size: 17px;
  font-family: 'nexonRegular';
  cursor: pointer;
`;

const AddressInputBtnWrap = styled.div`
  position: relative;
`;

const DetailAddressInput = styled.input`
  border: 1px solid #d0d2e8;
  width: 440px;
  height: 54px;
  padding: 0 0 0 17px;
  font-size: 17px;
  font-family: 'nexonRegular';
  margin: 7px 0 0 0;
`;

const PopupBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
`;

const SearchIcon = styled(BiSearch)`
  position: absolute;
  color: #313131;
  font-size: 26px;
  right: 8px;
  top: 14px;
`;

const HalfSection = styled.div``;

const TimeWrap = styled.div`
  margin: 20px 0 0 0;
  display: flex;
`;

const StartTime = styled.div`
  display: flex;
  margin: 0 20px 0 0;
`;

const EndTime = styled.div`
  display: flex;
  margin: 0 0 0 20px;
`;

const SelectWrap = styled.div`
  width: 80px;
  position: relative;
`;

const TimeSelectWrap = styled(SelectWrap)`
  width: 70px;
`;

const HourSelectWrap = styled(SelectWrap)`
  width: 90px;
  margin: 0 0 0 17px;
`;

const InputSubText = styled.p`
  font-size: 22px;
  color: ${theme.colors.bodyText};
  margin: 7px 0 0 0;
`;

const InputFlexItem = styled.div`
  & + & {
    margin: 0 0 0 60px;
  }
`;

const JobPeriodSelectWrap = styled.div`
  width: 160px;
  margin: 20px 0 0 0;
`;

const TextArea = styled.textarea`
  font-family: 'nexonRegular';
  border: 1px solid #d0d2e8;
  height: 320px;
  width: 100%;
  font-size: 17px;
  padding: 23px;
  box-sizing: border-box;
  resize: none;
  letter-spacing: -1px;
  margin: 20px 0 0 0;

  &::placeholder {
    color: #d5d5e4;
  }

  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  font-family: 'nexonMedium';
  width: 320px;
  height: 60px;
  background-color: ${theme.colors.primary};
  color: white;
  font-size: 20px;
  letter-spacing: -1.25px;
  margin: 120px auto;
  display: block;
`;
export default ProposalEmployment;
