import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import { observer } from 'mobx-react';
import stores from 'stores';

const SignInEmployer = observer(() => {
  const { userStore, certificationStore } = stores;
  const [inputs, setInputs] = useState({
    id: '',
    password: '',
    cellphone: '',
    certificationNumber: '',
  });

  const { id, password, cellphone, certificationNumber } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const data = {
      authType: 'EMAIL',
      email: id,
      name: '',
      password: password,
      role: 'EMPLOYER',
      thirdPartyAccessToken: '',
      thirdPartyUserId: '',
    };
    console.log(data);
    userStore.signInUsers(data);
  };

  const handleSendCertificationCode = () => {
    certificationStore.postCertificationsCode({ cellphone: cellphone });
  };

  const handleCheckCertificationCode = () => {
    certificationStore.postCertifications({
      cellphone: cellphone,
      code: certificationNumber,
      onlyForCertification: false,
    });
  };

  return (
    <PageLayout>
      <ContentWrap>
        <Title>기업 회원 로그인</Title>
        <LoginBoxWrap>
          <LoginBox>
            <SmallTitle>아이디/비밀번호로 로그인 하기</SmallTitle>
            <TopButtonWrap>
              <TextButton>회원가입하기</TextButton>
            </TopButtonWrap>
            <LoginInputWrap>
              <LeftInputBox>
                <Input name="id" value={id} placeholder="아이디" onChange={onChange} />
                <Input
                  name="password"
                  value={password}
                  placeholder="비밀번호"
                  onChange={onChange}
                />
              </LeftInputBox>
              <LoginButton onClick={handleLogin}>로그인</LoginButton>
            </LoginInputWrap>
            <BottomButtonWrap>
              <TextButton>로그인 상태 유지</TextButton>
              <TextButton>비밀번호 찾기</TextButton>
            </BottomButtonWrap>
          </LoginBox>
          <ColumnBorder></ColumnBorder>
          <LoginBox>
            <SmallTitle>휴대폰 번호로 로그인 하기</SmallTitle>
            <CellphoneLoginInputWrap>
              <CellphoneInputWrap>
                <CellphoneInput
                  name="cellphone"
                  value={cellphone}
                  placeholder="휴대폰 번호"
                  onChange={onChange}
                />
                <CellphoneInputButton onClick={handleSendCertificationCode}>
                  인증번호 발송
                </CellphoneInputButton>
              </CellphoneInputWrap>
              <CellphoneInputWrap>
                <CellphoneInput
                  name="certificationNumber"
                  value={certificationNumber}
                  placeholder="인증번호"
                  onChange={onChange}
                />
                <CellphoneInputButton onClick={handleCheckCertificationCode}>
                  확인
                </CellphoneInputButton>
              </CellphoneInputWrap>
            </CellphoneLoginInputWrap>
          </LoginBox>
        </LoginBoxWrap>
      </ContentWrap>
    </PageLayout>
  );
});

const ContentWrap = styled.div`
  width: 1080px;
  margin: 140px auto 0;
`;
const Title = styled.p`
  font-size: 28px;
  font-family: 'nexonBold';
  margin: 0 0 0 10px;
`;

const LoginBoxWrap = styled.div`
  border: 1px solid ${theme.colors.border};
  border-radius: 10px;
  display: flex;
  margin: 28px 0 0 0;
`;

const LoginBox = styled.div`
  width: 420px;
  margin: 60px 60px 120px;
`;

const SmallTitle = styled.p`
  font-family: 'nexonMedium';
  font-size: 20px;
  color: #242d24;
  letter-spacing: -0.54px;
`;

const TopButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 70px 0 10px 0;
`;

const TextButton = styled.button`
  font-family: 'nexonRegular';
  font-size: 15px;
  background: transparent;
  padding: 0;
`;

const LoginInputWrap = styled.div`
  display: flex;
`;

const LeftInputBox = styled.div`
  width: calc(100% - 128px);
`;

const Input = styled.input`
  width: 100%;
  height: 56px;
  border: 1px solid ${theme.colors.borderLight};
  font-size: 17px;
  padding: 0 0 0 22px;
  font-family: 'nexonRegular';

  &::placeholder {
    color: ${theme.colors.borderLight};
  }

  & + & {
    border-top: none;
  }
`;

const LoginButton = styled.button`
  width: 128px;
  height: 112px;
  background-color: ${theme.colors.primary};
  color: white;
  font-size: 18px;
`;

const BottomButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0 0;
`;

const CellphoneLoginInputWrap = styled.div`
  margin: 85px 0 0 0;
`;

const CellphoneInputWrap = styled.div`
  display: flex;
  justify-content: space-between;

  & + & {
    margin: 15px 0 0 0;
  }
`;

const CellphoneInput = styled.input`
  width: 260px;
  height: 62px;
  border: 1px solid ${theme.colors.borderLight};
  font-size: 17px;
  padding: 0 0 0 22px;
  font-family: 'nexonRegular';

  &::placeholder {
    color: ${theme.colors.borderLight};
  }
`;

const CellphoneInputButton = styled.button`
  width: 150px;
  background-color: ${theme.colors.primary};
  height: 62px;
  color: white;
  font-size: 17px;
  font-family: 'nexonMedium';
`;

const ColumnBorder = styled.div`
  width: 1px;
  height: 420px;
  margin: 10px 0 0 0;
  background-color: ${theme.colors.border};
`;

export default SignInEmployer;
