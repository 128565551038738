import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import MainNav from 'components/templates/MainNav';
import { observer } from 'mobx-react';
import stores from 'stores';
import { BsFillPersonFill } from 'react-icons/bs';

const ShowResume = observer(() => {
  return (
    <PageLayout>
      <MainNav />
      <ContentWrap>
        <Title>이력서 보기</Title>
        <BorderWrap>
          <SectionWrap>
            <FlexWrap>
              <InfoWrap>
                <Photo>
                  <PersonIcon />
                </Photo>
                <TextInfo>
                  <InfoBox>
                    <InfoLabel>이름</InfoLabel>
                    <InfoCotent>Teja totsapon</InfoCotent>
                  </InfoBox>
                  <InfoBox>
                    <InfoLabel>국적</InfoLabel>
                    <InfoCotent>태국</InfoCotent>
                  </InfoBox>
                  <InfoBox>
                    <InfoLabel>성별</InfoLabel>
                    <InfoCotent>남자</InfoCotent>
                  </InfoBox>
                  <InfoBox>
                    <InfoLabel>생년월일</InfoLabel>
                    <InfoCotent>1986 . 03 . 24</InfoCotent>
                  </InfoBox>
                  <InfoBox>
                    <InfoLabel>연락처</InfoLabel>
                    <InfoCotent>010-5555-8888</InfoCotent>
                  </InfoBox>
                </TextInfo>
              </InfoWrap>
              <ButtonWrap>
                <Button>전화하기</Button>
                <Button>문자하기</Button>
              </ButtonWrap>
            </FlexWrap>
          </SectionWrap>
          <DivideBorder></DivideBorder>
          <SectionWrap>
            <FlexWrap>
              <FlexItem>
                <Label>이력사항</Label>
                <CareerWrap>
                  <CareerItem>
                    <CareerItemLabel>한국어 능력</CareerItemLabel>
                    <CareerItemContent>대학교 재학중</CareerItemContent>
                  </CareerItem>
                  <CareerItem>
                    <CareerItemLabel>경력 업무</CareerItemLabel>
                    <CareerItemContent>식당 홀서빙</CareerItemContent>
                  </CareerItem>
                  <CareerItem>
                    <CareerItemLabel>거주 유형</CareerItemLabel>
                    <CareerItemContent>유학생</CareerItemContent>
                  </CareerItem>
                  <CareerItem>
                    <CareerItemLabel>비자</CareerItemLabel>
                    <CareerItemContent>D - 2</CareerItemContent>
                  </CareerItem>
                </CareerWrap>
              </FlexItem>
              <FlexItem>
                <DetailBox>
                  <Label>희망 근무지역</Label>
                  <RegionBox>서울 종로구</RegionBox>
                </DetailBox>
                <DetailBox>
                  <Label>경력</Label>
                  <RegionBox>식당 6개월 이상, 공장 1년 이상</RegionBox>
                </DetailBox>
              </FlexItem>
            </FlexWrap>
            <IntroductionPart>
              <Label>자기소개</Label>
              <TranslateButton>번역</TranslateButton>
              <IntroductionBox>
                <BeforeTranslate>我會努力工作.</BeforeTranslate>
                <AfterTranslate>성실히 근무하겠습니다.</AfterTranslate>
              </IntroductionBox>
              <TextLength>12/500자</TextLength>
            </IntroductionPart>
          </SectionWrap>
        </BorderWrap>
        <NoticeText>
          ※구인/구직 목적 이외에 다른 목적으로 이용시 이력서 삭제 혹은 비공개 조치가 될 수 있습니다.
        </NoticeText>
      </ContentWrap>
    </PageLayout>
  );
});

const ContentWrap = styled.div`
  width: 1100px;
  margin: 0 auto;
`;
const Title = styled.p`
  font-size: 26px;
  font-family: 'nexonBold';
  letter-spacing: -0.65px;
  color: #434343;
  margin: 60px 0 5px 10px;
`;

const BorderWrap = styled.div`
  width: 100%;
  border: 1px solid #8d8a9b;
  border-radius: 10px;
`;

const SectionWrap = styled.div`
  padding: 31px 37px;
`;

const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoWrap = styled.div`
  display: flex;
  align-items: center;
`;

const Photo = styled.div`
  width: 180px;
  height: 214px;
  background-color: #e6e4f2;
  border: 1px solid #e6e4f2;
  margin: 0 34px 0 0;
`;

const TextInfo = styled.div``;

const InfoBox = styled.div`
  display: flex;
  & + & {
    margin: 25px 0 0 0;
  }
`;

const InfoLabel = styled.p`
  font-family: 'nexonMedium';
  font-size: 19px;
  letter-spacing: -1px;
  color: #1f2422;
  width: 100px;
`;
const InfoCotent = styled(InfoLabel)`
  font-family: 'nexonRegular';
  width: auto;
`;

const PersonIcon = styled(BsFillPersonFill)`
  font-size: 180px;
  color: white;
  padding: 20px 0 0 0;
`;

const ButtonWrap = styled.div`
  width: 158px;
  margin: 10px 0 0 0;
`;

const Button = styled.button`
  font-family: 'nexonMedium';
  background-color: transparent;
  border: 2px solid ${theme.colors.primary};
  border-radius: 10px;
  width: 157px;
  height: 76px;
  color: #424547;
  font-size: 22px;
  letter-spacing: -0.56px;

  & + & {
    margin: 15px 0 0 0;
  }
`;

const DivideBorder = styled.div`
  height: 14px;
  width: 100%;
  background-color: #eef1f5;
`;

const FlexItem = styled.div``;

const Label = styled.p`
  font-family: 'nexonMedium';
  color: #424547;
  font-size: 19px;
  letter-spacing: -0.48px;
`;

const CareerWrap = styled.div`
  border: 1px solid #d0d2e8;
  width: 409px;
  margin: 10px 0 0 0;
`;

const CareerItem = styled.div`
  display: flex;

  & + & {
    border-top: 1px solid #d0d2e8;
  }
`;

const CareerItemLabel = styled.p`
  background-color: #e6e4f2;
  width: 128px;
  font-family: 'nexonRegular';
  color: #434343;
  font-size: 16px;
  letter-spacing: -0.41px;
  height: 54px;
  padding: 18px;
`;

const CareerItemContent = styled.p`
  font-family: 'nexonRegular';
  color: #595959;
  font-size: 16px;
  letter-spacing: -0.41px;
  height: 54px;
  padding: 18px;
`;

const DetailBox = styled.div`
  & + & {
    margin: 29px 0 0 0;
  }
`;

const RegionBox = styled.div`
  width: 410px;
  height: 54px;
  border: 1px solid #d0d2e8;
  margin: 10px 0 0 0;
  font-family: 'nexonRegular';
  color: #434343;
  font-size: 16px;
  letter-spacing: -0.41px;
  padding: 18px;
`;

const IntroductionPart = styled.div`
  margin: 52px 0 20px 0;
  position: relative;
`;

const IntroductionBox = styled.div`
  border: 1px solid #d0d2e8;
  margin: 14px 0 0 0;
  padding: 0 20px;
`;

const BeforeTranslate = styled.div`
  font-family: 'nexonRegular';
  color: #434343;
  font-size: 17px;
  letter-spacing: -0.43px;
  padding: 25px 0;
  border-bottom: 1px solid #a2a5d2;
`;

const AfterTranslate = styled.div`
  font-family: 'nexonRegular';
  color: #434343;
  font-size: 17px;
  letter-spacing: -0.43px;
  padding: 25px 0;
`;

const TranslateButton = styled.button`
  background-color: transparent;
  border: 2px solid ${theme.colors.primary};
  color: ${theme.colors.primary};
  font-size: 17px;
  font-family: 'nexonMedium';
  letter-spacing: -0.44px;
  border-radius: 20px;
  padding: 3px 10px;
  position: absolute;
  right: 0;
  top: 0;
`;

const TextLength = styled.p`
  color: #424547;
  font-size: 15px;
  font-family: 'nexonRegular';
  letter-spacing: -0.39px;
  position: absolute;
  right: 0;
  bottom: -24px;
`;

const NoticeText = styled.p`
  color: #b85565;
  font-size: 15px;
  font-family: 'nexonRegular';
  letter-spacing: -0.39px;
  margin: 13px 0 0 10px;
`;

export default ShowResume;
