import {
  getEmployeeJobsApi,
  getJobsDetailApi,
  postJobsApplicationsApi,
  getUsersApplicationsApi,
  getMainJobsApi,
  postEmployerJobsApi,
} from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class JobStore {
  localJobData = [];
  lifeJobData = [];
  premiunJobData = [];
  grandJobData = [];
  totalLifeJob = 0;
  jobsDetailData = {};
  applicationsData = [];
  totalUserApplication = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setLocalJobData = (param) => {
    this.localJobData = param;
  };

  setLifeJobData = (param) => {
    this.lifeJobData = param;
  };

  setPremiumJobData = (param) => {
    this.premiunJobData = param;
  };

  setGrandJobData = (param) => {
    this.grandJobData = param;
  };

  setTotalLifeJob = (param) => {
    this.totalLifeJob = param;
  };

  setJobsDetailData = (param) => {
    this.jobsDetailData = param;
  };

  setApplicationsData = (param) => {
    this.applicationsData = param;
  };

  setTotalUserApplication = (param) => {
    this.totalUserApplication = param;
  };

  getEmployeeLocalJobs = async (
    productType,
    jobCategories,
    keywordType,
    langCode,
    pageNumber,
    pageSize,
    regionIds,
  ) => {
    await getEmployeeJobsApi(
      productType,
      jobCategories,
      keywordType,
      langCode,
      pageNumber,
      pageSize,
      regionIds,
    )
      .then((res) => {
        console.log(res.data, 'local job');
        this.setLocalJobData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getEmployeeLifeJobs = async (
    productType,
    jobCategories,
    keywordType,
    langCode,
    pageNumber,
    pageSize,
    regionIds,
  ) => {
    await getEmployeeJobsApi(
      productType,
      jobCategories,
      keywordType,
      langCode,
      pageNumber,
      pageSize,
      regionIds,
    )
      .then((res) => {
        console.log(res.data, 'life job');
        this.setLifeJobData(res.data.data);
        this.setTotalLifeJob(res.data.totalCount);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getEmployeePremiumJobs = async (
    productType,
    jobCategories,
    keywordType,
    langCode,
    pageNumber,
    pageSize,
    regionIds,
  ) => {
    await getEmployeeJobsApi(
      productType,
      jobCategories,
      keywordType,
      langCode,
      pageNumber,
      pageSize,
      regionIds,
    )
      .then((res) => {
        console.log(res.data, 'premium job');
        this.setPremiumJobData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getEmployeeGrandJobs = async (
    productType,
    jobCategories,
    keywordType,
    langCode,
    pageNumber,
    pageSize,
    regionIds,
  ) => {
    await getEmployeeJobsApi(
      productType,
      jobCategories,
      keywordType,
      langCode,
      pageNumber,
      pageSize,
      regionIds,
    )
      .then((res) => {
        console.log(res.data, 'grand job');
        this.setGrandJobData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getJobsDetail = async (id) => {
    await getJobsDetailApi(id)
      .then((res) => {
        console.log(res.data.data);
        this.setJobsDetailData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postJobsApplications = async (jobId) => {
    await postJobsApplicationsApi(jobId)
      .then((res) => {
        console.log(res);
        this.setTotalUserApplication(res.data.totalCount);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getUsersApplications = async (langCode, pageNumber, pageSize) => {
    await getUsersApplicationsApi(langCode, pageNumber, pageSize)
      .then((res) => {
        console.log(res);
        this.setApplicationsData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getMainJobs = async (langCode) => {
    await getMainJobsApi(langCode)
      .then((res) => {
        console.log(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postEmployerJobs = async (data) => {
    await postEmployerJobsApi(data)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const jobStore = new JobStore();

export default jobStore;
