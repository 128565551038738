import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import Checkbox from 'components/atoms/Checkbox';
import ResumeInput from 'components/modules/ResumeInput';
import { BsFillPersonFill } from 'react-icons/bs';
import { observer } from 'mobx-react';
import stores from 'stores';
import { toJS } from 'mobx';
import PopUp from 'components/atoms/PopUp';
import { formatPhoneNumber } from 'components/utils/useful-function';
import { Mobile, Others } from 'styles/MediaQuery';
import MyPageLayout from 'components/templates/MypageLayout';

const expectedJobCategory = [
  { text: '공장', value: 'FACTORY' },
  { text: '농장', value: 'FARM' },
  { text: '식당', value: 'RESTAURANT' },
  { text: '건설', value: 'CONSTRUCTION' },
  { text: '번역', value: 'TRANSLATION' },
  { text: '사무직', value: 'OFFICE' },
];

const payTypeArray = [
  { text: '시급', value: 'HOURLY' },
  { text: '일급', value: 'DAILY' },
  { text: '주급', value: 'WEEKLY' },
  { text: '월급', value: 'MONTHLY' },
];

const koreanSkillArray = [
  {
    text: 'very bad',
    value: 'VERY_BAD',
  },
  {
    text: 'bad',
    value: 'BAD',
  },
  {
    text: 'normal',
    value: 'NORMAL',
  },
  {
    text: 'good',
    value: 'GOOD',
  },
  {
    text: 'very good',
    value: 'VERY_GOOD',
  },
];

const careerArray = [
  {
    text: 'Factory',
    value: 'FACTORY',
  },
  {
    text: 'Farm',
    value: 'FARM',
  },
  {
    text: 'Restaurant',
    value: 'RESTAURANT',
  },
  {
    text: 'Construction',
    value: 'CONSTRUCTION',
  },
  {
    text: 'Translation',
    value: 'TRANSLATION',
  },
  {
    text: 'etc',
    value: 'ETC',
  },
];

const JobPeriodType = [
  {
    text: '일주일 이하',
    value: 'UNDER_WEEK',
  },
  {
    text: '한 달 이하',
    value: 'UNDER_MONTH',
  },
  {
    text: '3개월 이하',
    value: 'UNDER_3MONTH',
  },
  {
    text: '6개월 이하',
    value: 'UNDER_6MONTH',
  },
  {
    text: '1년 미만',
    value: 'UNDER_1YEAR',
  },
  {
    text: '1년 이상',
    value: 'ABOVE_1YEAR',
  },
];

const workType = [
  {
    koreanText: '유학생',
    engText: 'foreign student',
    value: 'FOREIGN_STUDENT',
  },
  {
    koreanText: '근로자',
    engText: 'foreign worker',
    value: 'FOREIGN_WORKER',
  },
  {
    koreanText: '구직준비',
    engText: 'job seeker',
    value: 'JOB_SEEKER',
  },
  {
    koreanText: '이주민',
    engText: 'immigrant',
    value: 'IMMIGRANT',
  },
  {
    koreanText: '기타',
    engText: 'etc',
    value: 'ETC',
  },
];

const ModifyResume = observer(() => {
  const { fileStore, resumeStore, certificationStore, countryStore, regionsStore } = stores;

  useEffect(() => {
    countryStore.getCountries();
  }, [countryStore]);

  useEffect(() => {
    regionsStore.getRegions();
  }, [regionsStore]);

  useEffect(() => {
    resumeStore.getResumeMe();
  }, [resumeStore]);

  const myResume = resumeStore.myResume;

  const regionData = regionsStore.regionData.slice(0, 17);
  const regionDataByParentId = regionsStore.regionDataByParentId;

  const countryData = toJS(countryStore.countryData);

  const [tab, setTab] = useState('RESUME_FIRST');

  const [sectors, setSectors] = useState('');

  const handleClickSectors = (tab) => {
    setSectors(tab);
  };

  const [noSectors, setNoSectors] = useState(false);

  const handleNoSectors = () => {
    setNoSectors(!noSectors);
    setSectors('');
  };

  useEffect(() => {
    if (sectors !== '') {
      setNoSectors(false);
    }
  }, [sectors]);

  //   useEffect(() => {
  //     if (noSectors === true) {
  //       setSectors('ETC');
  //     }
  //   }, [noSectors]);

  const [payType, setPayType] = useState('');

  const handleClickPayType = (tab) => {
    setPayType(tab);
  };

  const [noPayAmount, setNoPayAmount] = useState(false);

  const handleNoPayAmount = (e) => {
    setNoPayAmount(!noPayAmount);
  };

  useEffect(() => {
    if (noPayAmount === true) {
      setPayInputs({
        lowest: '',
        highest: '',
      });
    }
  }, [noPayAmount]);

  const [payInputs, setPayInputs] = useState({
    lowest: '',
    highest: '',
  });

  const { lowest, highest } = payInputs;

  const onChangePayInput = (e) => {
    const { name, value } = e.target;
    let payFormatted = `${parseInt(value.replace(/\D/g, '')).toLocaleString()}`;
    if (isNaN(parseInt(value))) {
      setPayInputs({
        ...payInputs,
        [name]: '',
      });
    } else {
      setPayInputs({
        ...payInputs,
        [name]: payFormatted,
      });
      setNoPayAmount(false);
    }
  };

  const [inputs, setInputs] = useState({
    name: '',
    birth: '',
    cellphone: '',
    certificationNumber: '',
    careerExplanation: '',
    introduction: '',
    countryEtc: '',
  });

  const {
    name,
    birth,
    cellphone,
    certificationNumber,
    careerExplanation,
    introduction,
    countryEtc,
  } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onChangeBirth = (e) => {
    const { value, name } = e.target;
    // console.log(value, name);
    let birthFormatted = '';
    let onlyNumber = value.replaceAll('-', '');
    if (onlyNumber.length > 6) {
      birthFormatted = `${onlyNumber.substring(0, 4)}-${onlyNumber.substring(
        4,
        6,
      )}-${onlyNumber.substring(6, 8)}`;
    } else if (onlyNumber.length > 4) {
      birthFormatted = `${onlyNumber.substring(0, 4)}-${onlyNumber.substring(4, 6)}`;
    } else {
      birthFormatted = onlyNumber.substring(0, 4);
    }
    setInputs({
      ...inputs,
      [name]: birthFormatted,
    });
  };

  const [image, setImage] = useState('');
  const fileInput = useRef(null);
  const [profileImgUrl, setProfileImageUrl] = useState('');

  const handleChangeImage = (e) => {
    const uploadImage = e.target.files[0];
    if (uploadImage) {
      const formData = new FormData();
      formData.append('file', uploadImage);
      formData.append('type', 'resume');
      fileStore
        .updateFile(formData)
        .then((res) => {
          setProfileImageUrl(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
      setImage(uploadImage);
    } else {
      //업로드 취소할 시
      setImage('');
      return;
    }
    //화면에 프로필 사진 표시
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const [gender, setGender] = useState('NONE');

  const handleClickGender = (tab) => {
    setGender(tab);
  };

  const [koreanSkill, setKoreanSkill] = useState('');

  const handleClickKoreanSkill = (tab) => {
    setKoreanSkill(tab);
  };

  const [residenceType, setResidenceType] = useState('');

  const handleClickResidenceType = (tab) => {
    setResidenceType(tab);
  };

  const handleTabResumeSecond = () => {
    setTab('RESUME_SECOND');
  };

  const handleSendCertificationCode = () => {
    certificationStore.postCertificationsCode({ cellphone: cellphone });
  };

  const handleCheckCertificationCode = () => {
    certificationStore.postCertifications({
      cellphone: cellphone,
      code: certificationNumber,
      onlyForCertification: true,
    });
  };

  const [countryPopupVisible, setCountryPopupVisible] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCountryName, setSelectedCountryName] = useState('');

  const handleCheckCountryPopup = () => {
    if (!selectedCountry) {
      return;
    }
    setCountryPopupVisible(!countryPopupVisible);
  };

  const handleSelectCountry = (countryIsoCode, langCode, nameInOrigin) => {
    setSelectedCountry(countryIsoCode);
    setSelectedCountryName(nameInOrigin);
    localStorage.setItem('countryIsoCode', countryIsoCode);
    localStorage.setItem('languageCode', langCode);
  };

  const handleCloseCountryPopup = () => {
    setCountryPopupVisible(false);
    setSelectedCountry('');
    setSelectedCountryName('');
  };

  const [career, setCareer] = useState('');

  const handleClickCareer = (value) => {
    setCareer(value);
    setJobCategoryPeriodPopupVisible(true);
  };

  const [jobPeriod, setJobPeriod] = useState('');

  const [jobCategoryPeriodPopupVisible, setJobCategoryPeriodPopupVisible] = useState(false);

  const handleSelectJobCategoryPeriod = (value) => {
    setJobPeriod(value);
  };

  const [jobPeriodTypeObject, setJobPeriodTypeObject] = useState({});

  const handleCheckPeriodPopup = () => {
    if (!jobPeriod) {
      return;
    }
    setJobPeriodTypeObject({ ...jobPeriodTypeObject, [career]: jobPeriod });
    setJobPeriod('');
    setCareer('');
    setJobCategoryPeriodPopupVisible(false);
  };

  const handleCancelPeriodPopup = () => {
    setJobPeriod('');
    setCareer('');
    setJobCategoryPeriodPopupVisible(false);
  };

  const [regionsPopupVisible, setRegionsPopupVisible] = useState(false);

  const [selectedSido, setSelectedSido] = useState(0);
  const [selectedSidoCode, setSelectedSidoCode] = useState(0);
  const [selectedSidoName, setSelectedSidoName] = useState('');

  const handleSelectSido = (id, name, code) => {
    setSelectedSido(id);
    setSelectedSidoName(name);
    setSelectedSidoCode(code);
    setSelectGungu('');
    setSelectGunguName('');
    regionsStore.getRegionsByParentId(id);
  };

  const [selectGungu, setSelectGungu] = useState('');
  const [selectGunguName, setSelectGunguName] = useState('');

  const handleSelectGungu = (id, name) => {
    setSelectGungu(id);
    setSelectGunguName(name);
  };

  const handleCheckRegionPopup = () => {
    if (!selectGungu) {
      return;
    }
    setRegionsPopupVisible(false);
  };

  const handleCancelRegionPopup = () => {
    setSelectedSido('');
    setSelectGungu('');
    setRegionsPopupVisible(false);
  };

  useEffect(() => {
    setInputs({
      name: myResume?.name,
      birth: myResume?.birthDate,
      cellphone: myResume?.cellphone,
      careerExplanation: myResume?.details,
      introduction: myResume?.introduction,
    });
    setSelectedCountryName(myResume?.country?.nameInOrigin);
    setGender(myResume?.gender);
    setKoreanSkill(myResume?.koreanCapability);
    setJobPeriodTypeObject(myResume?.jobCategoryPeriodTypeMap);
    setResidenceType(myResume?.workType);
    setSelectedSidoName(myResume?.expectedRegion?.sidoInSummary);
    setSelectedSido(myResume?.expectedRegion?.parent?.id);
    setSelectGungu(myResume?.expectedRegion?.id);
    setSelectGunguName(myResume?.expectedRegion?.sigungu);
    setSectors(myResume?.expectedJobCategory);
    setNoSectors(myResume?.noSelectExpectedJobCategory);
    setPayType(myResume?.expectedPayType);
    setPayInputs({
      lowest: myResume?.expectedMinPayAmount?.toLocaleString(),
      highest: myResume?.expectedMaxPayAmount?.toLocaleString(),
    });
    setNoPayAmount(myResume?.noSelectExpectedPayAmount);
  }, [
    myResume?.name,
    myResume?.country?.nameInOrigin,
    myResume?.gender,
    myResume?.birthDate,
    myResume?.cellphone,
    myResume?.koreanCapability,
    myResume?.jobCategoryPeriodTypeMap,
    myResume?.details,
    myResume?.workType,
    myResume?.introduction,
    myResume?.expectedRegion?.sidoInSummary,
    myResume?.expectedRegion?.parent?.id,
    myResume?.expectedRegion?.id,
    myResume?.expectedRegion?.sigungu,
    myResume?.expectedJobCategory,
    myResume?.noSelectExpectedJobCategory,
    myResume?.expectedPayType,
    myResume?.expectedMinPayAmount,
    myResume?.expectedMaxPayAmount,
    myResume?.noSelectExpectedPayAmount,
  ]);

  const handleSubmitResume = () => {
    const data = {
      birthDate: birth,
      cellphone: cellphone,
      countryEtcText: countryEtc,
      countyIsoCode: selectedCountry,
      details: careerExplanation,
      expectedJobCategory: sectors,
      expectedMaxPayAmount: parseFloat(lowest.replace(/\D/g, '')),
      expectedMinPayAmount: parseFloat(highest.replace(/\D/g, '')),
      expectedPayType: payType,
      expectedRegionId: parseInt(selectGungu),
      gender: gender,
      introduction: introduction,
      jobCategoryPeriodTypeMap: jobPeriodTypeObject,
      jobId: null, //job id
      koreanCapability: koreanSkill,
      name: name,
      noSelectExpectedJobCategory: noSectors,
      noSelectExpectedPayAmount: noPayAmount,
      noSelectExpectedRegion: null,
      profileImageData: profileImgUrl,
      resumeId: 0, //resumeId?
      workType: residenceType,
    };
    console.log(data);
    const expectedData = {
      expectedRegionId: parseInt(selectGungu),
      expectedJobCategory: sectors,
      noSelectExpectedJobCategory: noSectors,
      expectedMaxPayAmount: parseFloat(lowest.replace(/\D/g, '')),
      expectedMinPayAmount: parseFloat(highest.replace(/\D/g, '')),
      expectedPayType: payType,
      noSelectExpectedPayAmount: noPayAmount,
    };
    resumeStore
      .postResume(data)
      .then((res) => {
        resumeStore.postResumeExpectedJob(expectedData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <PageLayout>
      <Mobile>
        {tab === 'RESUME_SECOND' ? (
          <ContentWrap>
            <Title>희망직무</Title>
            <InputItemWrap>
              <Label>지역</Label>
              <SelectBox onClick={() => setRegionsPopupVisible(true)}>
                {selectGunguName
                  ? `${selectedSidoName}/${selectGunguName}`
                  : selectedSidoName
                  ? selectedSidoName
                  : '선택'}
              </SelectBox>
            </InputItemWrap>
            <InputItemWrap>
              <Label>업종</Label>
              <SectorsWrap>
                {expectedJobCategory.map((item) => (
                  <SectorsTab
                    onClick={() => handleClickSectors(item.value)}
                    active={sectors === item.value}
                    key={item.value}
                  >
                    {item.text}
                  </SectorsTab>
                ))}
              </SectorsWrap>
              <Checkbox
                key="업종선택"
                defaultValue={true}
                type="checkbox"
                name="sectors"
                handleClick={handleNoSectors}
                isChecked={noSectors === true}
              >
                업종 선택 안함
              </Checkbox>
            </InputItemWrap>
            <InputItemWrap>
              <Label>급여</Label>
              <PayTypeWrap>
                {payTypeArray.map((item) => (
                  <PayTypeTab
                    onClick={() => handleClickPayType(item.value)}
                    active={payType === item.value}
                    key={item.value}
                  >
                    {item.text}
                  </PayTypeTab>
                ))}
              </PayTypeWrap>
              <PayAmountInputWrap>
                <PayAmountInput
                  name="lowest"
                  value={lowest}
                  type="text"
                  onChange={onChangePayInput}
                />{' '}
                <WaveSign>~</WaveSign>
                <PayAmountInput
                  name="highest"
                  value={highest}
                  type="text"
                  onChange={onChangePayInput}
                />
              </PayAmountInputWrap>
            </InputItemWrap>
            <Checkbox
              key="급여선택"
              defaultValue={true}
              type="checkbox"
              name="payAmount"
              handleClick={handleNoPayAmount}
              isChecked={noPayAmount === true}
            >
              급여 선택 안함
            </Checkbox>
            <Button type="button" onClick={handleSubmitResume}>
              저장하기
            </Button>
          </ContentWrap>
        ) : (
          <>
            <ContentWrap>
              <Photo>
                {image ? (
                  <Img
                    src={image}
                    onClick={() => {
                      fileInput.current.click();
                    }}
                  />
                ) : (
                  <ImgIcon
                    onClick={() => {
                      fileInput.current.click();
                    }}
                  />
                )}
                <ImgInput
                  type="file"
                  accept="image/jpg,image/png,image/jpeg"
                  onChange={handleChangeImage}
                  ref={fileInput}
                />
              </Photo>
              <InputItemWrap>
                <ResumeInput
                  label="이름"
                  name="name"
                  value={name}
                  placeholder="홍길동"
                  onChange={onChange}
                />
              </InputItemWrap>
              <InputItemWrap>
                <Label>국적</Label>
                <NationalWrap>
                  <NationalButton
                    active={selectedCountryName?.length > 0}
                    onClick={() => setCountryPopupVisible(true)}
                  >
                    {selectedCountryName ? selectedCountryName : '선택'}
                  </NationalButton>
                  {selectedCountryName === 'ETC' && (
                    <>
                      <NationalIcon>:</NationalIcon>
                      <NationalInput name="countryEtc" value={countryEtc} onChange={onChange} />
                    </>
                  )}
                </NationalWrap>
              </InputItemWrap>
              <InputItemWrap>
                <Label>성별</Label>
                <GenderTabWrap>
                  <GenderTab onClick={() => handleClickGender('MAN')} active={gender === 'MAN'}>
                    남자
                  </GenderTab>
                  <GenderTab onClick={() => handleClickGender('WOMAN')} active={gender === 'WOMAN'}>
                    여자
                  </GenderTab>
                </GenderTabWrap>
              </InputItemWrap>
              <InputItemWrap>
                <ResumeInput
                  label="생년월일"
                  name="birth"
                  value={birth}
                  placeholder="1900 . 00 . 00"
                  onChange={onChangeBirth}
                />
              </InputItemWrap>
              <InputItemWrap>
                <ResumeInput
                  label="연락처"
                  name="cellphone"
                  value={cellphone}
                  placeholder="000-0000-0000"
                  onChange={onChange}
                />
              </InputItemWrap>
            </ContentWrap>
            <DivideBorder></DivideBorder>
            <ContentWrap>
              <ContentTitle>
                이력사항 <span>*선택 입력 사항입니다.</span>
              </ContentTitle>
              <InputItemWrap>
                <Label>한국어 능력</Label>
                <KoreanSkillWrap>
                  {koreanSkillArray.map((item) => (
                    <KoreanSkillTab
                      onClick={() => handleClickKoreanSkill(item.value)}
                      active={koreanSkill === item.value}
                      key={item.value}
                    >
                      {item.text}
                    </KoreanSkillTab>
                  ))}
                </KoreanSkillWrap>
              </InputItemWrap>
              <InputItemWrap>
                <Label>경력</Label>
                <CareerWrap>
                  {careerArray.map((item) => (
                    <CareerTabBox
                      active={jobPeriodTypeObject && item.value in jobPeriodTypeObject}
                      key={item.value}
                    >
                      <CareerTab
                        onClick={() => handleClickCareer(item.value)}
                        active={jobPeriodTypeObject && item.value in jobPeriodTypeObject}
                      >
                        <CareerIcon></CareerIcon>
                        {item.text}
                      </CareerTab>
                      {jobPeriodTypeObject && item.value in jobPeriodTypeObject ? (
                        <JobPeriodText>
                          {jobPeriodTypeObject[item.value] === 'UNDER_WEEK'
                            ? '1week↓'
                            : jobPeriodTypeObject[item.value] === 'UNDER_MONTH'
                            ? '1month↓'
                            : jobPeriodTypeObject[item.value] === 'UNDER_3MONTH'
                            ? '3month↓'
                            : jobPeriodTypeObject[item.value] === 'UNDER_6MONTH'
                            ? '6month↓'
                            : jobPeriodTypeObject[item.value] === 'UNDER_1YEAR'
                            ? '1year↓'
                            : jobPeriodTypeObject[item.value] === 'ABOVE_1YEAR'
                            ? '1year↑'
                            : ''}
                        </JobPeriodText>
                      ) : (
                        <></>
                      )}
                    </CareerTabBox>
                  ))}
                </CareerWrap>
              </InputItemWrap>
              <InputItemWrap>
                <ResumeInput
                  label="경력 업무"
                  name="careerExplanation"
                  value={careerExplanation}
                  placeholder="경력에 대해 작성해주세요."
                  onChange={onChange}
                />
                <HelpIcon>?</HelpIcon>
                <TextLength>0/30</TextLength>
              </InputItemWrap>
              <InputItemWrap>
                <Label>거주 유형</Label>
                <ResidenceTypeWrap>
                  {workType.map((item) => (
                    <ResidenceTypeTab
                      onClick={() => handleClickResidenceType(item.value)}
                      active={residenceType === item.value}
                      key={item.value}
                    >
                      {item.koreanText}
                      <span>{item.engText}</span>
                    </ResidenceTypeTab>
                  ))}
                </ResidenceTypeWrap>
              </InputItemWrap>
              <InputItemWrap>
                <TextAreaLabel>자기소개</TextAreaLabel>
                <TextArea
                  name="introduction"
                  value={introduction}
                  placeholder="자기소개를 적어주세요"
                  onChange={onChange}
                />
                <HelpIcon>?</HelpIcon>
                <TextLength>0/500</TextLength>
              </InputItemWrap>
              <Button onClick={handleTabResumeSecond}>다음</Button>
            </ContentWrap>
          </>
        )}
      </Mobile>
      <Others>
        <MyPageLayout>
          <PCTitle>이력서 수정</PCTitle>
          <ModifyResumeWrap>
            <PCContentWrap>
              <PCPhotoWrap>
                <PCLabel>증명사진</PCLabel>
                <PCPhoto active={image !== ''}>
                  {image ? (
                    <Img
                      src={image}
                      onClick={() => {
                        fileInput.current.click();
                      }}
                    />
                  ) : (
                    <PCImgIcon
                      onClick={() => {
                        fileInput.current.click();
                      }}
                    />
                  )}
                  <ImgInput
                    type="file"
                    accept="image/jpg,image/png,image/jpeg"
                    onChange={handleChangeImage}
                    ref={fileInput}
                  />
                </PCPhoto>
              </PCPhotoWrap>
              <InputItemWrap>
                <ResumeInput
                  label="이름"
                  name="name"
                  value={name}
                  placeholder="홍길동"
                  onChange={onChange}
                />
              </InputItemWrap>
              <InputItemWrap>
                <InputFlexWrap>
                  <PCLabel>국적</PCLabel>
                  <NationalWrap>
                    <NationalButton
                      active={selectedCountryName?.length > 0}
                      onClick={() => setCountryPopupVisible(true)}
                    >
                      {selectedCountryName ? selectedCountryName : '선택'}
                    </NationalButton>
                    {selectedCountryName === 'ETC' && (
                      <>
                        <NationalIcon>:</NationalIcon>
                        <NationalInput name="countryEtc" value={countryEtc} onChange={onChange} />
                      </>
                    )}
                  </NationalWrap>
                </InputFlexWrap>
              </InputItemWrap>
              <InputItemWrap>
                <InputFlexWrap>
                  <PCLabel>성별</PCLabel>
                  <GenderTabWrap>
                    <GenderTab onClick={() => handleClickGender('MAN')} active={gender === 'MAN'}>
                      남자
                    </GenderTab>
                    <GenderTab
                      onClick={() => handleClickGender('WOMAN')}
                      active={gender === 'WOMAN'}
                    >
                      여자
                    </GenderTab>
                  </GenderTabWrap>
                </InputFlexWrap>
              </InputItemWrap>
              <InputItemWrap>
                <ResumeInput
                  label="생년월일"
                  name="birth"
                  value={birth}
                  placeholder="1900 . 00 . 00"
                  onChange={onChangeBirth}
                />
              </InputItemWrap>
              <InputItemWrap>
                <ResumeInput
                  label="연락처"
                  name="cellphone"
                  value={cellphone}
                  placeholder="000-0000-0000"
                  onChange={onChange}
                />
              </InputItemWrap>
              <ContentTitle>이력사항</ContentTitle>
              <InputItemWrap>
                <InputFlexWrap>
                  <PCLabel>한국어 능력</PCLabel>
                  <KoreanSkillWrap>
                    {koreanSkillArray.map((item) => (
                      <KoreanSkillTab
                        onClick={() => handleClickKoreanSkill(item.value)}
                        active={koreanSkill === item.value}
                        key={item.value}
                      >
                        {item.text}
                      </KoreanSkillTab>
                    ))}
                  </KoreanSkillWrap>
                </InputFlexWrap>
              </InputItemWrap>
              <InputItemWrap>
                <InputFlexWrap>
                  <PCLabel>경력</PCLabel>
                  <CareerWrap>
                    {careerArray.map((item) => (
                      <CareerTabBox
                        active={jobPeriodTypeObject && item.value in jobPeriodTypeObject}
                        key={item.value}
                      >
                        <CareerTab
                          onClick={() => handleClickCareer(item.value)}
                          active={jobPeriodTypeObject && item.value in jobPeriodTypeObject}
                        >
                          {item.text}
                        </CareerTab>
                        {jobPeriodTypeObject && item.value in jobPeriodTypeObject ? (
                          <JobPeriodText>
                            {jobPeriodTypeObject[item.value] === 'UNDER_WEEK'
                              ? '1week↓'
                              : jobPeriodTypeObject[item.value] === 'UNDER_MONTH'
                              ? '1month↓'
                              : jobPeriodTypeObject[item.value] === 'UNDER_3MONTH'
                              ? '3month↓'
                              : jobPeriodTypeObject[item.value] === 'UNDER_6MONTH'
                              ? '6month↓'
                              : jobPeriodTypeObject[item.value] === 'UNDER_1YEAR'
                              ? '1year↓'
                              : jobPeriodTypeObject[item.value] === 'ABOVE_1YEAR'
                              ? '1year↑'
                              : ''}
                          </JobPeriodText>
                        ) : (
                          <></>
                        )}
                      </CareerTabBox>
                    ))}
                  </CareerWrap>
                </InputFlexWrap>
              </InputItemWrap>
              <InputItemWrap>
                <InputFlexWrap>
                  <PCLabel>거주 유형</PCLabel>
                  <ResidenceTypeWrap>
                    {workType.map((item) => (
                      <ResidenceTypeTab
                        onClick={() => handleClickResidenceType(item.value)}
                        active={residenceType === item.value}
                        key={item.value}
                      >
                        {item.koreanText}
                      </ResidenceTypeTab>
                    ))}
                  </ResidenceTypeWrap>
                </InputFlexWrap>
              </InputItemWrap>
              <InputItemWrap>
                <InputFlexWrap>
                  <PCLabel>경력 업무</PCLabel>
                  <CareerInput
                    name="careerExplanation"
                    value={careerExplanation}
                    placeholder="경력에 대해 작성해주세요."
                    onChange={onChange}
                  />
                  <TextLength>0/30</TextLength>
                </InputFlexWrap>
              </InputItemWrap>
              <TextAreaWrap>
                <InputItemWrap>
                  <TextAreaLabel>자기소개</TextAreaLabel>
                  <TextArea
                    name="introduction"
                    value={introduction}
                    placeholder="자기소개를 적어주세요"
                    onChange={onChange}
                  />
                  <Mobile>
                    <HelpIcon>?</HelpIcon>
                  </Mobile>
                  <TextLength>0/500</TextLength>
                </InputItemWrap>
              </TextAreaWrap>
            </PCContentWrap>
          </ModifyResumeWrap>
          <PCButton type="button" onClick={handleSubmitResume}>
            이력서 수정
          </PCButton>
        </MyPageLayout>
      </Others>
      <PopUp visible={countryPopupVisible}>
        <PopUpTitle>국적 선택</PopUpTitle>
        <PopUpTab>
          {countryData.map((item) => (
            <PopUpLanguageItemWrap
              onClick={() =>
                handleSelectCountry(item.countryIsoCode, item.langCode, item.nameInOrigin)
              }
              key={item.countryIsoCode}
            >
              <PopUpLanguageItem tab={selectedCountry === item.countryIsoCode}>
                <Flag>
                  <img src={item.flagImgUrl} alt="flag" />
                </Flag>
                <FlagText langCode={item.langCode}>{item.nameInOrigin}</FlagText>
              </PopUpLanguageItem>
            </PopUpLanguageItemWrap>
          ))}
        </PopUpTab>
        <PopupButtonWrap>
          <PopUpButtonGray onClick={handleCloseCountryPopup}>취소</PopUpButtonGray>
          <PopUpButton onClick={handleCheckCountryPopup}>확인</PopUpButton>
        </PopupButtonWrap>
      </PopUp>
      <PopUp visible={jobCategoryPeriodPopupVisible}>
        <PeriodPopupTabWrap>
          {JobPeriodType.map((item) => (
            <PeriodPopupTab
              onClick={() => handleSelectJobCategoryPeriod(item.value)}
              key={item.value}
              active={jobPeriod === item.value}
            >
              {item.text}
            </PeriodPopupTab>
          ))}
        </PeriodPopupTabWrap>
        <PopupButtonWrap>
          <PopUpButtonGray onClick={handleCancelPeriodPopup}>취소</PopUpButtonGray>
          <PopUpButton onClick={handleCheckPeriodPopup} type="button">
            확인
          </PopUpButton>
        </PopupButtonWrap>
      </PopUp>
      <PopUp visible={regionsPopupVisible}>
        <RegionPopupWrap>
          <RegionPopupTop>
            <RegionPopupTopBox>시/도</RegionPopupTopBox>
            <RegionPopupTopBox>시/군/구</RegionPopupTopBox>
          </RegionPopupTop>
          <RegionPopupMiddle>
            <CityWrap>
              {regionData.map((item) => (
                <CityItem
                  key={item.id}
                  onClick={() => handleSelectSido(item.id, item.sidoInSummary, item.sigunguCode)}
                  active={selectedSido === item.id}
                >
                  {item.sidoInSummary}
                  <CityEng>{item.sidoInEnglish}</CityEng>
                </CityItem>
              ))}
            </CityWrap>
            <CityWrap>
              {regionDataByParentId.map((item) => (
                <CityItem
                  key={item.id}
                  onClick={() => handleSelectGungu(item.id, item.sigungu)}
                  active={selectGungu === item.id}
                >
                  {item.sigungu}
                  <CityEng>{item.sigunguInEnglish}</CityEng>
                </CityItem>
              ))}
            </CityWrap>
          </RegionPopupMiddle>
        </RegionPopupWrap>
        <PopupButtonWrap>
          <PopUpButtonGray onClick={handleCancelRegionPopup} type="button">
            취소
          </PopUpButtonGray>
          <PopUpButton onClick={handleCheckRegionPopup} type="button">
            확인
          </PopUpButton>
        </PopupButtonWrap>
      </PopUp>
    </PageLayout>
  );
});

const ContentWrap = styled.div`
  padding: 40px 30px;
`;

const Title = styled.p`
  font-size: 22px;
  color: #313131;
  letter-spacing: -1.13px;
  font-family: 'nexonMedium';
  margin: 0 0 56px 0;
`;

const InputItemWrap = styled.div`
  margin: 10px 0 70px 0;
  position: relative;
`;

const Label = styled.p`
  font-family: 'nexonMedium';
  color: #80878e;
  font-size: 20px;
  margin: 10px 0 15px 0;
`;

const SelectBox = styled.div`
  width: 50%;
  height: 53px;
  border: 1px solid ${theme.colors.borderLight};
  color: #80878e;
  font-family: 'nexonMedium';
  font-size: 20px;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
`;

const SectorsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 32px 0;
`;

const SectorsTab = styled(SelectBox)`
  position: relative;

  &:nth-child(2n) {
    margin-left: -1px;
  }

  &:nth-child(n + 1) {
    margin-top: -1px;
  }

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonMedium';
      border: 1px solid ${theme.colors.primary};
      z-index: 1;
      color: ${theme.colors.primary};
    `}
`;

const PayTypeWrap = styled.div`
  display: flex;
`;

const PayTypeTab = styled.div`
  width: 25%;
  height: 53px;
  border: 1px solid ${theme.colors.borderLight};
  color: #80878e;
  font-family: 'nexonMedium';
  font-size: 20px;
  text-align: center;
  padding: 15px 0;
  position: relative;
  cursor: pointer;

  & + & {
    margin-left: -1px;
  }

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonMedium';
      border: 1px solid ${theme.colors.primary};
      z-index: 1;
      color: ${theme.colors.primary};
    `}
`;

const PayAmountInputWrap = styled.div`
  font-family: 'nexonRegular';
  display: flex;
  justify-content: space-between;
  font-size: 21px;
  margin: 55px 0 50px 0;
`;

const PayAmountInput = styled.input`
  border: none;
  border-bottom: 1px solid ${theme.colors.borderLight};
  padding: 6px 3px;
  width: 205px;
  font-size: 21px;
  font-family: 'nexonRegular';
  color: #313131;
`;

const Button = styled.button`
  font-family: 'nexonBold';
  width: 100%;
  height: 67px;
  background-color: ${theme.colors.primary};
  color: white;
  font-size: 25px;
  letter-spacing: -1.25px;
  margin: 63px 0 0 0;
`;

const Photo = styled.div`
  width: 170px;
  height: 170px;
  border-radius: 100px;
  background-color: #e6e4f2;
  margin: 35px auto 35px;
  overflow: hidden;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;

const ImgIcon = styled(BsFillPersonFill)`
  font-size: 130px;
  color: white;
  padding: 20px;
  cursor: pointer;
`;

const ImgInput = styled.input`
  display: none;
`;

const NationalWrap = styled.div`
  display: flex;
`;

const NationalButton = styled.button`
  font-family: 'nexonMedium';
  width: 224px;
  height: 54px;
  border: 1px solid ${theme.colors.borderLight};
  background-color: transparent;
  color: #80878e;
  font-size: 20px;

  ${(props) =>
    props.active === true &&
    css`
      border: 1.5px solid ${theme.colors.primary};
    `}
`;

const NationalIcon = styled.p`
  font-family: 'nexonRegular';
  font-size: 25px;
  line-height: 36px;
  padding: 7px 0 0 0;
  margin: 0 10px;
`;

const NationalInput = styled.input`
  width: 224px;
  border: none;
  border-bottom: 1px solid ${theme.colors.borderLight};
  height: 51px;
  font-family: 'nexonRegular';
  font-size: 21px;
  line-height: 34px;
  color: #313131;
`;

const GenderTabWrap = styled.div`
  display: flex;
`;

const GenderTab = styled.button`
  font-family: 'nexonMedium';
  width: 224px;
  height: 54px;
  border: 1px solid ${theme.colors.borderLight};
  background-color: transparent;
  color: #80878e;
  font-size: 20px;

  & + & {
    margin-left: -1px;
  }

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonBold';
      border: 1px solid ${theme.colors.primary};
      z-index: 1;
      color: ${theme.colors.primary};
    `}
`;

const CertificationWrap = styled.div`
  display: flex;
`;

const CertificationInput = styled.input`
  font-family: 'nexonRegular';
  width: 326px;
  height: 44px;
  border: 1px solid ${theme.colors.borderLight};
  padding: 0 0 0 15px;
  font-size: 22px;
`;

const CertificationButton = styled.button`
  font-family: 'nexonMedium';
  width: 147px;
  height: 43px;
  background-color: #d4cae3;
  border-radius: 30px;
  color: white;
  font-size: 20px;
  margin: 0 0 0 17px;

  ${(props) =>
    props.active === true &&
    css`
      background-color: ${theme.colors.primary};
    `}
`;

const CertificationButtonPostion = styled(CertificationButton)`
  position: absolute;
  right: 10px;
  bottom: 5px;
`;

const DivideBorder = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${theme.colors.purpleGray};
  margin: 72px 0 46px 0;
`;

const ContentTitle = styled.p`
  font-family: 'nexonMedium';
  font-size: 20px;
  margin: 110px 0 65px 0;

  & > span {
    font-family: 'nexonRegular';
    font-size: 15px;
    color: #9495a3;
    margin: 0 0 0 15px;
  }

  @media ${theme.mobile} {
    margin: 0 0 65px 0;
  }
`;

const KoreanSkillWrap = styled.div`
  display: flex;
  width: 620px;

  @media ${theme.mobile} {
    width: 100%;
  }
`;

const KoreanSkillTab = styled.div`
  font-family: 'nexonRegular';
  cursor: pointer;
  width: 20%;
  height: 61px;
  border: 1px solid ${theme.colors.borderLight};
  font-size: 18px;
  text-align: center;
  color: ${theme.colors.borderLight};
  padding: 20px 0;

  & + & {
    margin-left: -1px;
  }

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonMedium';
      border: 1px solid ${theme.colors.primary};
      z-index: 1;
      color: ${theme.colors.primary};
    `}
`;

const CareerWrap = styled.div`
  display: flex;
  width: 620px;

  @media ${theme.mobile} {
    width: 100%;
  }
`;

const CareerTabBox = styled.div`
  width: 16.666%;
  border: 1px solid ${theme.colors.borderLight};
  height: 66px;
  & + & {
    margin-left: -1px;
  }
  ${(props) =>
    props.active === true &&
    css`
      z-index: 1;
    `}

  @media ${theme.mobile} {
    height: 85px;
  }
`;

const CareerTab = styled.div`
  font-family: 'nexonRegular';
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  color: #595959;
  margin: 25px 0 0 0;

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonMedium';
      border: 1px solid ${theme.colors.primary};
      color: ${theme.colors.primary};
    `}

  @media ${theme.mobile} {
    color: ${theme.colors.borderLight};
    margin: 0;
  }
`;

const CareerIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: #eee;
  margin: 13px 22px 8px 22px;
`;

const JobPeriodText = styled.div`
  font-size: 17px;
  text-align: center;
  margin: 7px 0 0 0;
`;

const HelpIcon = styled.div`
  width: 19px;
  height: 19px;
  background-color: ${theme.colors.primary};
  border-radius: 10px;
  color: white;
  font-family: 'nexonBold';
  font-size: 16px;
  padding: 1px 5px;
  position: absolute;
  top: 0;
  right: 0;
`;

const TextLength = styled.p`
  text-align: right;
  color: #838392;
  font-size: 17px;
  font-family: 'nexonRegular';
  margin: 6px 0 0 0;

  position: absolute;
  right: 10px;
  bottom: -30px;
`;

const ResidenceTypeWrap = styled.div`
  position: relative;
  display: flex;
  width: 620px;

  @media ${theme.mobile} {
    display: block;
    width: 100%;
  }
`;

const ResidenceTypeTab = styled.div`
  position: relative;
  width: 20%;
  height: 66px;
  border: 1px solid ${theme.colors.borderLight};
  text-align: center;
  font-size: 12px;
  color: #595959;
  font-family: 'nexonMedium';
  padding: 25px 0 0 0;
  cursor: pointer;
  & + & {
    margin-left: -1px;
  }

  & > span {
    font-size: 17px;
    font-family: 'nexonRegular';
    margin: 0 0 0 18px;
  }

  ${(props) =>
    props.active === true &&
    css`
      border: 1px solid ${theme.colors.primary};
      z-index: 2;
      color: ${theme.colors.primary};
    `}

  @media ${theme.mobile} {
    width: 100%;
    height: 53px;
    font-size: 20px;
    color: #80878e;
    padding: 16px 0 0 0;
    & + & {
      margin-top: -1px;
      margin-left: 0px;
    }
  }
`;

const TextAreaLabel = styled.p`
  font-family: 'nexonMedium';
  font-size: 20px;
  margin: 0 0 24px 0;
`;
const TextArea = styled.textarea`
  font-family: 'nexonRegular';
  border: 1px solid #d0d2e8;
  height: 320px;
  width: 100%;
  font-size: 17px;
  padding: 23px;
  box-sizing: border-box;
  resize: none;
  letter-spacing: -1px;

  &::placeholder {
    color: #d5d5e4;
  }

  &:focus {
    outline: none;
  }

  @media ${theme.mobile} {
    font-size: 20px;
    height: 198px;
    padding: 15px;
  }
`;

const WaveSign = styled.p`
  padding: 5px 0 0 0;
`;

const PopUpTitle = styled.p`
  text-align: center;
  font-family: 'nexonRegular';
  font-size: 25px;
  margin: 40px 0 0 0;
`;

const PopUpTab = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 20px 0 0 0;
  height: 420px;
  overflow: scroll;
`;

const PopUpLanguageItemWrap = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const PopUpLanguageItem = styled.div`
  text-align: center;
  width: 80px;
  height: 100px;
  padding: 13px 0 0 0;

  ${(props) =>
    props.tab === true &&
    css`
      background-color: rgba(7, 0, 133, 0.2);
      border-radius: 5px;
    `}
`;

const Flag = styled.div`
  width: 30px;
  height: 25px;
  margin: 0 auto 25px;

  & > img {
    width: 30px;
    object-fit: cover;
  }
`;

const PopupButtonWrap = styled.div`
  display: flex;
  position: absolute;
  bottom: 0px;
  width: 100%;
`;

const PopUpButton = styled.button`
  width: 50%;
  height: 60px;
  border-top: 1px solid ${theme.colors.borderLight};
  color: ${theme.colors.primary};
  background-color: transparent;
  font-size: 20px;
  display: block;

  & + & {
    border-left: 1px solid ${theme.colors.borderLight};
  }
`;

const FlagText = styled.p`
  font-size: 15px;
  line-height: 20px;
  font-family: 'nexonRegular';
  ${(props) =>
    props.langCode === 'th' &&
    css`
      font-family: 'PromptR';
    `}
  ${(props) =>
    props.langCode === 'vi' &&
    css`
      font-family: 'ArchivoR';
    `}
  ${(props) =>
    ['ru', 'mn', 'km'].includes(props.langCode) &&
    css`
      font-family: 'NotoSansR';
    `}
  ${(props) =>
    ['zh-TW', 'zh-CN'].includes(props.langCode) &&
    css`
      font-family: 'NotoSansCJKTCR';
    `}
`;

const PeriodPopupTab = styled.div`
  font-size: 20px;
  font-family: 'nexonRegular';
  text-align: center;
  padding: 22px 0;
  margin: 0 40px;
  cursor: pointer;

  & + & {
    border-top: 1px solid ${theme.colors.borderLight};
  }

  ${(props) =>
    props.active === true &&
    css`
      color: ${theme.colors.primary};
    `}
`;

const PeriodPopupTabWrap = styled.div`
  margin: 60px 0;
`;

const PopUpButtonGray = styled(PopUpButton)`
  color: ${theme.colors.borderLight};
`;

const RegionPopupWrap = styled.div``;

const RegionPopupTop = styled.div`
  height: 60px;
  background-color: #d0d2e8;
  padding: 0;
  border-radius: 15px 15px 0 0;
  display: flex;
`;

const RegionPopupTopBox = styled.div`
  width: 50%;
  text-align: center;
  font-size: 20px;
  font-family: 'nexonBold';
  padding: 18px 0 0 0;
`;

const RegionPopupMiddle = styled.div`
  padding: 10px 40px;
  display: flex;
  justify-content: space-between;
`;

const CityWrap = styled.div`
  width: 40%;
  height: 460px;
  overflow: hidden;
  overflow-y: scroll;
`;

const CityItem = styled.div`
  font-size: 20px;
  padding: 15px 0;
  cursor: pointer;

  ${(props) =>
    props.active === true &&
    css`
      color: ${theme.colors.primary};
    `}
`;

const CityEng = styled.div`
  color: ${theme.colors.borderLight};
  font-size: 14px;
  margin: 3px 0 0 0;
`;

const PCTitle = styled.div`
  font-size: 18px;
  color: #1f2422;
  font-family: 'nexonMedium';
  letter-spacing: -0.46px;
  margin: 30px 0 0 0;
`;

const ModifyResumeWrap = styled.div`
  background-color: #eef1f5;
  padding: 17px;
  width: 100%;
  margin: 11px 0 0 0;
`;

const PCContentWrap = styled.div`
  background-color: white;
  padding: 35px 49px;
  border-radius: 10px;
`;

const PCPhotoWrap = styled.div`
  margin: 0 0 30px 0;
`;

const PCPhoto = styled.div`
  width: 230px;
  height: 252px;
  background-color: #e6e4f2;
  border: 1px solid #e6e4f2;

  ${(props) =>
    props.active === true &&
    css`
      border: 1px solid ${theme.colors.primary};
    `}
`;

const PCImgIcon = styled(BsFillPersonFill)`
  font-size: 190px;
  color: white;
  padding: 20px;
  cursor: pointer;
`;

const PCLabel = styled(Label)`
  font-size: 19px;
  color: #1f2422;
  width: 100px;
  letter-spacing: -1px;
`;

const InputFlexWrap = styled.div`
  display: flex;
  align-items: center;

  @media ${theme.mobile} {
    display: block;
  }
`;

const CareerInput = styled.input`
  font-family: 'nexonRegular';
  width: 620px;
  height: 44px;
  border: none;
  border-bottom: 1px solid ${theme.colors.borderLight};
  font-size: 17px;
  &::placeholder {
    color: #d5d5e4;
  }
`;

const TextAreaWrap = styled.div`
  margin: 150px 0 0 0;
`;

const PCButton = styled(Button)`
  width: 320px;
  height: 60px;
  font-size: 20px;
  font-family: 'nexonMedium';
  margin: 63px auto 0;
  display: block;
`;

export default ModifyResume;
