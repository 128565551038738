import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import MainNav from 'components/templates/MainNav';
import Checkbox from 'components/atoms/Checkbox';
import ResumeInput from 'components/modules/ResumeInput';
import { BsFillPersonFill } from 'react-icons/bs';
import { observer } from 'mobx-react';
import stores from 'stores';
import { toJS } from 'mobx';
import PopUp from 'components/atoms/PopUp';
import { Mobile, Others } from 'styles/MediaQuery';

const expectedJobCategory = [
  { text: '공장', value: 'FACTORY' },
  { text: '농장', value: 'FARM' },
  { text: '식당', value: 'RESTAURANT' },
  { text: '건설', value: 'CONSTRUCTION' },
  { text: '번역', value: 'TRANSLATION' },
  { text: '사무직', value: 'OFFICE' },
];

const pcExpectedJobCategory = [
  { text: '공장', value: 'FACTORY' },
  { text: '농장', value: 'FARM' },
  { text: '식당', value: 'RESTAURANT' },
  { text: '건설', value: 'CONSTRUCTION' },
  { text: '번역', value: 'TRANSLATION' },
  { text: '사무직', value: 'OFFICE' },
  { text: '기타', value: 'ETC' },
];

const payTypeArray = [
  { text: '시급', value: 'HOURLY' },
  { text: '일급', value: 'DAILY' },
  { text: '주급', value: 'WEEKLY' },
  { text: '월급', value: 'MONTHLY' },
];

const pcPayTypeArray = [
  { text: '시급', value: 'HOURLY' },
  { text: '일급', value: 'DAILY' },
  { text: '주급', value: 'WEEKLY' },
  { text: '월급', value: 'MONTHLY' },
  { text: '연봉', value: 'YEARLY' },
];

const koreanSkillArray = [
  {
    text: 'very bad',
    value: 'VERY_BAD',
  },
  {
    text: 'bad',
    value: 'BAD',
  },
  {
    text: 'normal',
    value: 'NORMAL',
  },
  {
    text: 'good',
    value: 'GOOD',
  },
  {
    text: 'very good',
    value: 'VERY_GOOD',
  },
];

const careerArray = [
  {
    text: 'Factory',
    value: 'FACTORY',
  },
  {
    text: 'Farm',
    value: 'FARM',
  },
  {
    text: 'Restaurant',
    value: 'RESTAURANT',
  },
  {
    text: 'Construction',
    value: 'CONSTRUCTION',
  },
  {
    text: 'Translation',
    value: 'TRANSLATION',
  },
  {
    text: 'etc',
    value: 'ETC',
  },
];

const JobPeriodType = [
  {
    text: '일주일 이하',
    value: 'UNDER_WEEK',
  },
  {
    text: '한 달 이하',
    value: 'UNDER_MONTH',
  },
  {
    text: '3개월 이하',
    value: 'UNDER_3MONTH',
  },
  {
    text: '6개월 이하',
    value: 'UNDER_6MONTH',
  },
  {
    text: '1년 미만',
    value: 'UNDER_1YEAR',
  },
  {
    text: '1년 이상',
    value: 'ABOVE_1YEAR',
  },
];

const workType = [
  {
    koreanText: '유학생',
    engText: 'foreign student',
    value: 'FOREIGN_STUDENT',
  },
  {
    koreanText: '근로자',
    engText: 'foreign worker',
    value: 'FOREIGN_WORKER',
  },
  {
    koreanText: '구직준비',
    engText: 'job seeker',
    value: 'JOB_SEEKER',
  },
  {
    koreanText: '이주민',
    engText: 'immigrant',
    value: 'IMMIGRANT',
  },
  {
    koreanText: '기타',
    engText: 'etc',
    value: 'ETC',
  },
];

const WriteResume = observer(() => {
  const { fileStore, resumeStore, certificationStore, countryStore, regionsStore } = stores;

  useEffect(() => {
    countryStore.getCountries();
  }, [countryStore]);

  useEffect(() => {
    regionsStore.getRegions();
  }, [regionsStore]);

  const regionData = regionsStore.regionData.slice(0, 17);
  const regionDataByParentId = regionsStore.regionDataByParentId;

  const countryData = toJS(countryStore.countryData);

  const [tab, setTab] = useState('RESUME_FIRST');

  const [sectors, setSectors] = useState('');

  const handleClickSectors = (tab) => {
    setSectors(tab);
  };

  const [noSectors, setNoSectors] = useState(false);

  const handleNoSectors = () => {
    setNoSectors(!noSectors);
    setSectors('');
  };

  useEffect(() => {
    if (sectors !== '') {
      setNoSectors(false);
    }
  }, [sectors]);

  //   useEffect(() => {
  //     if (noSectors === true) {
  //       setSectors('ETC');
  //     }
  //   }, [noSectors]);

  const [noRegion, setNoRegion] = useState(false);

  const handleNoRegion = () => {
    setNoRegion(!noSectors);
  };

  const [payType, setPayType] = useState('');

  const handleClickPayType = (tab) => {
    setPayType(tab);
  };

  const [noPayAmount, setNoPayAmount] = useState(false);

  const handleNoPayAmount = (e) => {
    setNoPayAmount(!noPayAmount);
  };

  useEffect(() => {
    if (noPayAmount === true) {
      setPayInputs({
        lowest: '',
        highest: '',
      });
    }
  }, [noPayAmount]);

  const [payInputs, setPayInputs] = useState({
    lowest: '',
    highest: '',
  });

  const { lowest, highest } = payInputs;

  const onChangePayInput = (e) => {
    const { name, value } = e.target;
    let payFormatted = `${parseInt(value.replace(/\D/g, '')).toLocaleString()}`;
    if (isNaN(parseInt(value))) {
      setPayInputs({
        ...payInputs,
        [name]: '',
      });
    } else {
      setPayInputs({
        ...payInputs,
        [name]: payFormatted,
      });
      setNoPayAmount(false);
    }
  };

  const [inputs, setInputs] = useState({
    name: '',
    birth: '',
    cellphone: '',
    certificationNumber: '',
    careerExplanation: '',
    introduction: '',
    countryEtc: '',
  });

  const {
    name,
    birth,
    cellphone,
    certificationNumber,
    careerExplanation,
    introduction,
    countryEtc,
  } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onChangeBirth = (e) => {
    const { value, name } = e.target;
    // console.log(value, name);
    let birthFormatted = '';
    let onlyNumber = value.replaceAll('-', '');
    if (onlyNumber.length > 6) {
      birthFormatted = `${onlyNumber.substring(0, 4)}-${onlyNumber.substring(
        4,
        6,
      )}-${onlyNumber.substring(6, 8)}`;
    } else if (onlyNumber.length > 4) {
      birthFormatted = `${onlyNumber.substring(0, 4)}-${onlyNumber.substring(4, 6)}`;
    } else {
      birthFormatted = onlyNumber.substring(0, 4);
    }
    setInputs({
      ...inputs,
      [name]: birthFormatted,
    });
  };

  const [image, setImage] = useState('');
  const fileInput = useRef(null);
  const [profileImgUrl, setProfileImageUrl] = useState('');

  const handleChangeImage = (e) => {
    const uploadImage = e.target.files[0];
    if (uploadImage) {
      const formData = new FormData();
      formData.append('file', uploadImage);
      formData.append('type', 'resume');
      fileStore
        .updateFile(formData)
        .then((res) => {
          setProfileImageUrl(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
      setImage(uploadImage);
    } else {
      //업로드 취소할 시
      setImage('');
      return;
    }
    //화면에 프로필 사진 표시
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const [gender, setGender] = useState('NONE');

  const handleClickGender = (tab) => {
    setGender(tab);
  };

  const [koreanSkill, setKoreanSkill] = useState('');

  const handleClickKoreanSkill = (tab) => {
    setKoreanSkill(tab);
  };

  const [residenceType, setResidenceType] = useState('');

  const handleClickResidenceType = (tab) => {
    setResidenceType(tab);
  };

  const handleTabResumeSecond = () => {
    setTab('RESUME_SECOND');
  };

  const handleSendCertificationCode = () => {
    certificationStore.postCertificationsCode({ cellphone: cellphone });
  };

  const handleCheckCertificationCode = () => {
    certificationStore.postCertifications({
      cellphone: cellphone,
      code: certificationNumber,
      onlyForCertification: true,
    });
  };

  const [countryPopupVisible, setCountryPopupVisible] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCountryName, setSelectedCountryName] = useState('');

  const handleCheckCountryPopup = () => {
    if (!selectedCountry) {
      return;
    }
    setCountryPopupVisible(!countryPopupVisible);
  };

  const handleSelectCountry = (countryIsoCode, langCode, nameInOrigin) => {
    setSelectedCountry(countryIsoCode);
    setSelectedCountryName(nameInOrigin);
    localStorage.setItem('countryIsoCode', countryIsoCode);
    localStorage.setItem('languageCode', langCode);
  };

  const handleCloseCountryPopup = () => {
    setCountryPopupVisible(false);
    setSelectedCountry('');
    setSelectedCountryName('');
  };

  const [career, setCareer] = useState('');

  const handleClickCareer = (value) => {
    setCareer(value);
    setJobCategoryPeriodPopupVisible(true);
  };

  const [jobPeriod, setJobPeriod] = useState('');

  const [jobCategoryPeriodPopupVisible, setJobCategoryPeriodPopupVisible] = useState(false);

  const handleSelectJobCategoryPeriod = (value) => {
    setJobPeriod(value);
  };

  const [jobPeriodTypeObject, setJobPeriodTypeObject] = useState({});

  const handleCheckPeriodPopup = () => {
    if (!jobPeriod) {
      return;
    }
    setJobPeriodTypeObject({ ...jobPeriodTypeObject, [career]: jobPeriod });
    setJobPeriod('');
    setCareer('');
    setJobCategoryPeriodPopupVisible(false);
  };

  const handleCancelPeriodPopup = () => {
    setJobPeriod('');
    setCareer('');
    setJobCategoryPeriodPopupVisible(false);
  };

  const [regionsPopupVisible, setRegionsPopupVisible] = useState(false);

  const [selectedSido, setSelectedSido] = useState(0);
  const [selectedSidoCode, setSelectedSidoCode] = useState(0);
  const [selectedSidoName, setSelectedSidoName] = useState('');

  const handleSelectSido = (id, name, code) => {
    setSelectedSido(id);
    setSelectedSidoName(name);
    setSelectedSidoCode(code);
    setSelectGungu('');
    setSelectGunguName('');
    regionsStore.getRegionsByParentId(id);
  };

  const [selectGungu, setSelectGungu] = useState('');
  const [selectGunguName, setSelectGunguName] = useState('');

  const handleSelectGungu = (id, name) => {
    setSelectGungu(id);
    setSelectGunguName(name);
  };

  const handleCheckRegionPopup = () => {
    if (!selectGungu) {
      return;
    }
    setRegionsPopupVisible(false);
  };

  const handleCancelRegionPopup = () => {
    setSelectedSido('');
    setSelectGungu('');
    setRegionsPopupVisible(false);
  };

  useEffect(() => {
    if (cellphone?.length === 11) {
      setInputs({
        cellphone: cellphone.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'),
      });
    }
  }, [cellphone]);

  const handleSubmitResume = () => {
    const data = {
      birthDate: birth,
      cellphone: cellphone,
      countryEtcText: countryEtc,
      countyIsoCode: selectedCountry,
      details: careerExplanation,
      expectedJobCategory: sectors,
      expectedMaxPayAmount: parseFloat(lowest.replace(/\D/g, '')),
      expectedMinPayAmount: parseFloat(highest.replace(/\D/g, '')),
      expectedPayType: payType,
      expectedRegionId: parseInt(selectGungu),
      gender: gender,
      introduction: introduction,
      jobCategoryPeriodTypeMap: jobPeriodTypeObject,
      jobId: null, //job id
      koreanCapability: koreanSkill,
      name: name,
      noSelectExpectedJobCategory: noSectors,
      noSelectExpectedPayAmount: noPayAmount,
      noSelectExpectedRegion: null,
      profileImageData: profileImgUrl,
      resumeId: 0, //resumeId?
      workType: residenceType,
    };
    console.log(data);
    const expectedData = {
      expectedRegionId: parseInt(selectGungu),
      expectedJobCategory: sectors,
      noSelectExpectedJobCategory: noSectors,
      expectedMaxPayAmount: parseFloat(lowest.replace(/\D/g, '')),
      expectedMinPayAmount: parseFloat(highest.replace(/\D/g, '')),
      expectedPayType: payType,
      noSelectExpectedPayAmount: noPayAmount,
    };
    resumeStore
      .postResume(data)
      .then((res) => {
        resumeStore.postResumeExpectedJob(expectedData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <PageLayout>
      <MainNav />
      {tab === 'RESUME_SECOND' ? (
        <ContentWrap>
          <Others>
            <PCTitle>희망직무 작성</PCTitle>
          </Others>
          <ResumeSecondWrap>
            <Title>희망직무</Title>
            <InputItemWrap>
              <Label>지역</Label>
              <Mobile>
                <SelectBox onClick={() => setRegionsPopupVisible(true)}>
                  {selectGunguName
                    ? `${selectedSidoName}/${selectGunguName}`
                    : selectedSidoName
                    ? selectedSidoName
                    : '선택'}
                </SelectBox>
              </Mobile>
              <Others>
                <LocationWrap>
                  <PCCityWrap>
                    {regionData.map((item) => (
                      <PCCityItem
                        key={item.id}
                        onClick={() => handleSelectSido(item.id, item.sigunguCode)}
                        active={selectedSido === item.id}
                      >
                        {item.sidoInSummary}
                      </PCCityItem>
                    ))}
                  </PCCityWrap>
                  <CountryWrap>
                    {regionDataByParentId.map((item) => (
                      <CountryItem
                        key={item.id}
                        onClick={() => handleSelectGungu(item.id)}
                        active={String(selectGungu).includes(item.id)}
                      >
                        {item.sigungu}
                      </CountryItem>
                    ))}
                  </CountryWrap>
                </LocationWrap>
                <Checkbox
                  key="지역선택"
                  defaultValue={true}
                  type="checkbox"
                  name="regions"
                  handleClick={handleNoRegion}
                  isChecked={noRegion === true}
                >
                  지역 선택 안함
                </Checkbox>
              </Others>
            </InputItemWrap>
            <InputItemWrap>
              <Label>업종</Label>
              <SectorsWrap>
                <Mobile>
                  {expectedJobCategory.map((item) => (
                    <SectorsTab
                      onClick={() => handleClickSectors(item.value)}
                      active={sectors === item.value}
                      key={item.value}
                    >
                      {item.text}
                    </SectorsTab>
                  ))}
                </Mobile>
                <Others>
                  {pcExpectedJobCategory.map((item) => (
                    <PCItemTab
                      onClick={() => handleClickSectors(item.value)}
                      active={sectors === item.value}
                      key={item.value}
                    >
                      {item.text}
                    </PCItemTab>
                  ))}
                </Others>
              </SectorsWrap>
              <Checkbox
                key="업종선택"
                defaultValue={true}
                type="checkbox"
                name="sectors"
                handleClick={handleNoSectors}
                isChecked={noSectors === true}
              >
                업종 선택 안함
              </Checkbox>
            </InputItemWrap>
            <InputItemWrap>
              <Label>급여</Label>
              <PayFlexWrap>
                <PayTypeWrap>
                  <Mobile>
                    {payTypeArray.map((item) => (
                      <PayTypeTab
                        onClick={() => handleClickPayType(item.value)}
                        active={payType === item.value}
                        key={item.value}
                      >
                        {item.text}
                      </PayTypeTab>
                    ))}
                  </Mobile>
                  <Others>
                    {pcPayTypeArray.map((item) => (
                      <PCItemTab
                        onClick={() => handleClickPayType(item.value)}
                        active={payType === item.value}
                        key={item.value}
                      >
                        {item.text}
                      </PCItemTab>
                    ))}
                  </Others>
                </PayTypeWrap>
                <PayAmountInputWrap>
                  <PayAmountInput
                    name="lowest"
                    value={lowest}
                    type="text"
                    onChange={onChangePayInput}
                  />{' '}
                  <Others>
                    <MoneySign>₩</MoneySign>
                  </Others>
                  <WaveSign>~</WaveSign>
                  <PayAmountInput
                    name="highest"
                    value={highest}
                    type="text"
                    onChange={onChangePayInput}
                  />
                  <Others>
                    <MoneySign>₩</MoneySign>
                  </Others>
                </PayAmountInputWrap>
              </PayFlexWrap>
              <Checkbox
                key="급여선택"
                defaultValue={true}
                type="checkbox"
                name="payAmount"
                handleClick={handleNoPayAmount}
                isChecked={noPayAmount === true}
              >
                급여 선택 안함
              </Checkbox>
            </InputItemWrap>
          </ResumeSecondWrap>
          <Button type="button" onClick={handleSubmitResume}>
            저장하기
          </Button>
        </ContentWrap>
      ) : (
        <>
          <ContentWrap>
            <FlexWrap>
              <Mobile>
                <Photo>
                  {image ? (
                    <Img
                      src={image}
                      onClick={() => {
                        fileInput.current.click();
                      }}
                    />
                  ) : (
                    <ImgIcon
                      onClick={() => {
                        fileInput.current.click();
                      }}
                    />
                  )}
                  <ImgInput
                    type="file"
                    accept="image/jpg,image/png,image/jpeg"
                    onChange={handleChangeImage}
                    ref={fileInput}
                  />
                </Photo>
              </Mobile>
              <LeftSectionWrap>
                <InputItemWrap>
                  <ResumeInput
                    label="이름"
                    name="name"
                    value={name}
                    placeholder="홍길동"
                    onChange={onChange}
                  />
                </InputItemWrap>
                <InputItemWrap>
                  <InputFlexWrap>
                    <Label>국적</Label>
                    <NationalWrap>
                      <NationalButton
                        active={selectedCountryName?.length > 0}
                        onClick={() => setCountryPopupVisible(true)}
                      >
                        {selectedCountryName ? selectedCountryName : '선택'}
                      </NationalButton>
                      {selectedCountryName === 'ETC' && (
                        <>
                          <NationalIcon>:</NationalIcon>
                          <NationalInput name="countryEtc" value={countryEtc} onChange={onChange} />
                        </>
                      )}
                    </NationalWrap>
                  </InputFlexWrap>
                </InputItemWrap>
                <InputItemWrap>
                  <InputFlexWrap>
                    <Label>성별</Label>
                    <GenderTabWrap>
                      <GenderTab onClick={() => handleClickGender('MAN')} active={gender === 'MAN'}>
                        남자
                      </GenderTab>
                      <GenderTab
                        onClick={() => handleClickGender('WOMAN')}
                        active={gender === 'WOMAN'}
                      >
                        여자
                      </GenderTab>
                    </GenderTabWrap>
                  </InputFlexWrap>
                </InputItemWrap>
                <InputItemWrap>
                  <ResumeInput
                    label="생년월일"
                    name="birth"
                    value={birth}
                    placeholder="1900 . 00 . 00"
                    onChange={onChangeBirth}
                  />
                </InputItemWrap>
                <InputItemWrap>
                  <ResumeInput
                    label="연락처"
                    name="cellphone"
                    value={cellphone}
                    placeholder="000-0000-0000"
                    onChange={onChange}
                  />
                  <CertificationButtonPostion
                    active={cellphone?.length > 8}
                    onClick={handleSendCertificationCode}
                  >
                    인증번호 전송
                  </CertificationButtonPostion>
                </InputItemWrap>
                <InputItemWrap>
                  <InputFlexWrap>
                    <Label>인증번호</Label>
                    <CertificationWrap>
                      <CertificationInput
                        name="certificationNumber"
                        value={certificationNumber}
                        onChange={onChange}
                      />
                      <CertificationButton
                        active={certificationNumber?.length > 3}
                        onClick={handleCheckCertificationCode}
                      >
                        확인
                      </CertificationButton>
                    </CertificationWrap>
                  </InputFlexWrap>
                </InputItemWrap>
              </LeftSectionWrap>
              <Others>
                <PCPhotoWrap>
                  <Label>증명사진</Label>
                  <PCPhoto active={image !== ''}>
                    {image ? (
                      <Img
                        src={image}
                        onClick={() => {
                          fileInput.current.click();
                        }}
                      />
                    ) : (
                      <PCImgIcon
                        onClick={() => {
                          fileInput.current.click();
                        }}
                      />
                    )}
                    <ImgInput
                      type="file"
                      accept="image/jpg,image/png,image/jpeg"
                      onChange={handleChangeImage}
                      ref={fileInput}
                    />
                  </PCPhoto>
                </PCPhotoWrap>
              </Others>
            </FlexWrap>
          </ContentWrap>
          <DivideBorder></DivideBorder>
          <ContentWrap>
            <FlexWrap>
              <LeftSectionWrap>
                <ContentTitle>
                  이력사항 <span>*선택 입력 사항입니다.</span>
                </ContentTitle>
                <InputItemWrap>
                  <InputFlexWrap>
                    <Label>한국어 능력</Label>
                    <KoreanSkillWrap>
                      {koreanSkillArray.map((item) => (
                        <KoreanSkillTab
                          onClick={() => handleClickKoreanSkill(item.value)}
                          active={koreanSkill === item.value}
                          key={item.value}
                        >
                          {item.text}
                        </KoreanSkillTab>
                      ))}
                    </KoreanSkillWrap>
                  </InputFlexWrap>
                </InputItemWrap>
                <InputItemWrap>
                  <InputFlexWrap>
                    <Label>경력</Label>
                    <CareerWrap>
                      {careerArray.map((item) => (
                        <CareerTabBox active={item.value in jobPeriodTypeObject} key={item.value}>
                          <CareerTab
                            onClick={() => handleClickCareer(item.value)}
                            active={item.value in jobPeriodTypeObject}
                          >
                            <Mobile>
                              <CareerIcon></CareerIcon>
                            </Mobile>
                            {item.text}
                          </CareerTab>
                          {item.value in jobPeriodTypeObject ? (
                            <JobPeriodText>
                              {jobPeriodTypeObject[item.value] === 'UNDER_WEEK'
                                ? '1week↓'
                                : jobPeriodTypeObject[item.value] === 'UNDER_MONTH'
                                ? '1month↓'
                                : jobPeriodTypeObject[item.value] === 'UNDER_3MONTH'
                                ? '3month↓'
                                : jobPeriodTypeObject[item.value] === 'UNDER_6MONTH'
                                ? '6month↓'
                                : jobPeriodTypeObject[item.value] === 'UNDER_1YEAR'
                                ? '1year↓'
                                : jobPeriodTypeObject[item.value] === 'ABOVE_1YEAR'
                                ? '1year↑'
                                : ''}
                            </JobPeriodText>
                          ) : (
                            <></>
                          )}
                        </CareerTabBox>
                      ))}
                    </CareerWrap>
                  </InputFlexWrap>
                </InputItemWrap>
                <Mobile>
                  <InputItemWrap>
                    <ResumeInput
                      label="경력 업무"
                      name="careerExplanation"
                      value={careerExplanation}
                      placeholder="경력에 대해 작성해주세요."
                      onChange={onChange}
                    />
                    <HelpIcon>?</HelpIcon>
                    <TextLength>0/30</TextLength>
                  </InputItemWrap>
                  <InputItemWrap>
                    <Label>거주 유형</Label>
                    <ResidenceTypeWrap>
                      {workType.map((item) => (
                        <ResidenceTypeTab
                          onClick={() => handleClickResidenceType(item.value)}
                          active={residenceType === item.value}
                          key={item.value}
                        >
                          {item.koreanText}
                          <span>{item.engText}</span>
                        </ResidenceTypeTab>
                      ))}
                    </ResidenceTypeWrap>
                  </InputItemWrap>
                </Mobile>
                <Others>
                  <InputItemWrap>
                    <InputFlexWrap>
                      <Label>거주 유형</Label>
                      <ResidenceTypeWrap>
                        {workType.map((item) => (
                          <ResidenceTypeTab
                            onClick={() => handleClickResidenceType(item.value)}
                            active={residenceType === item.value}
                            key={item.value}
                          >
                            {item.koreanText}
                          </ResidenceTypeTab>
                        ))}
                      </ResidenceTypeWrap>
                    </InputFlexWrap>
                  </InputItemWrap>
                  <InputItemWrap>
                    <InputFlexWrap>
                      <Label>경력 업무</Label>
                      <CareerInput
                        name="careerExplanation"
                        value={careerExplanation}
                        placeholder="경력에 대해 작성해주세요."
                        onChange={onChange}
                      />
                      <TextLength>0/30</TextLength>
                    </InputFlexWrap>
                  </InputItemWrap>
                </Others>
              </LeftSectionWrap>
              <Others>
                <TipTool>
                  <TipToolTitle>
                    경력 업무 작성 <span>TIP</span>
                  </TipToolTitle>
                  <TipToolText>
                    <TipToolError>*되도록 한국어로 작성해주세요!</TipToolError>
                    <br />
                    <br />
                    경력 사항은 기간을 포함하여 최대한 간결하게 작성해주세요!
                    <br />
                    <br />
                    <TipToolActive>예 1</TipToolActive>: [ ]공장 생산직 업무 6개월
                    <br />
                    <TipToolActive>예 2</TipToolActive>: [ ]식당 주방/서빙 업무 3개월
                    <br />
                    <br />
                    만약 한국에서 경력사항이 없다면 본국에서의 경력을 작성해도 괜찮아요!
                  </TipToolText>
                </TipTool>
              </Others>
            </FlexWrap>
            <Others>
              <DivideBorder></DivideBorder>
            </Others>
            <FlexWrap>
              <LeftSectionWrap>
                <InputItemWrap>
                  <TextAreaLabel>자기소개</TextAreaLabel>
                  <TextArea
                    name="introduction"
                    value={introduction}
                    placeholder="자기소개를 적어주세요"
                    onChange={onChange}
                  />
                  <Mobile>
                    <HelpIcon>?</HelpIcon>
                  </Mobile>
                  <TextLength>0/500</TextLength>
                </InputItemWrap>
              </LeftSectionWrap>
              <Others>
                <TipTool>
                  <TipToolTitle>
                    자기소개 작성 <span>TIP</span>
                  </TipToolTitle>
                  <TipToolText>
                    <TipToolError>*되도록 한국어로 작성해주세요!</TipToolError>
                    <br />
                    <br />
                    담당자들은 자기소개를 굉장히 중요하게 생각해요. 최대한 자세하게 여러분을
                    소개해주세요.
                    <br />
                    <br />
                    <TipToolActive>-자기소개에서 작성하면 좋은 것들</TipToolActive>
                    <br />
                    경력, 한국어, 자격증, 본인만의 장점, 거주 기간 등
                    <br />
                    <br />
                    <TipToolActive>예</TipToolActive> : 한국에서 3년 거주한 [ ]이라고 합니다. [
                    ]대학교의 [ ]학과를 졸업하였으며, TOPIK 5급을 취득했습니다. 식당 주방 보조로
                    6개월, 통번역 업무도 1년 했습니다. 저는 누구에게나 친절하다는 장점을 가지고
                    있습니다.
                    <br />
                    <br />
                    <TipToolError>-자기소개에 작성하면 안좋은 예</TipToolError>
                    <br />
                    <TipToolError>예 1</TipToolError>: 저는 성실한 사람입니다. <br />
                    <TipToolError>예 2</TipToolError>: 저는 000 (국가) 에서 온 000(이름)입니다.{' '}
                    <br />
                    <TipToolError>예 3</TipToolError>: 한국어 이외의 언어로 작성
                  </TipToolText>
                </TipTool>
              </Others>
            </FlexWrap>
            <Button onClick={handleTabResumeSecond}>다음</Button>
          </ContentWrap>
        </>
      )}
      <PopUp visible={countryPopupVisible}>
        <PopUpTitle>국적 선택</PopUpTitle>
        <PopUpTab>
          {countryData.map((item) => (
            <PopUpLanguageItemWrap
              onClick={() =>
                handleSelectCountry(item.countryIsoCode, item.langCode, item.nameInOrigin)
              }
              key={item.countryIsoCode}
            >
              <PopUpLanguageItem tab={selectedCountry === item.countryIsoCode}>
                <Flag>
                  <img src={item.flagImgUrl} alt="flag" />
                </Flag>
                <FlagText langCode={item.langCode}>{item.nameInOrigin}</FlagText>
              </PopUpLanguageItem>
            </PopUpLanguageItemWrap>
          ))}
        </PopUpTab>
        <PopupButtonWrap>
          <PopUpButtonGray onClick={handleCloseCountryPopup}>취소</PopUpButtonGray>
          <PopUpButton onClick={handleCheckCountryPopup}>확인</PopUpButton>
        </PopupButtonWrap>
      </PopUp>
      <PopUp visible={jobCategoryPeriodPopupVisible}>
        <PeriodPopupTabWrap>
          {JobPeriodType.map((item) => (
            <PeriodPopupTab
              onClick={() => handleSelectJobCategoryPeriod(item.value)}
              key={item.value}
              active={jobPeriod === item.value}
            >
              {item.text}
            </PeriodPopupTab>
          ))}
        </PeriodPopupTabWrap>
        <PopupButtonWrap>
          <PopUpButtonGray onClick={handleCancelPeriodPopup}>취소</PopUpButtonGray>
          <PopUpButton onClick={handleCheckPeriodPopup} type="button">
            확인
          </PopUpButton>
        </PopupButtonWrap>
      </PopUp>
      <PopUp visible={regionsPopupVisible}>
        <RegionPopupWrap>
          <RegionPopupTop>
            <RegionPopupTopBox>시/도</RegionPopupTopBox>
            <RegionPopupTopBox>시/군/구</RegionPopupTopBox>
          </RegionPopupTop>
          <RegionPopupMiddle>
            <CityWrap>
              {regionData.map((item) => (
                <CityItem
                  key={item.id}
                  onClick={() => handleSelectSido(item.id, item.sidoInSummary, item.sigunguCode)}
                  active={selectedSido === item.id}
                >
                  {item.sidoInSummary}
                  <CityEng>{item.sidoInEnglish}</CityEng>
                </CityItem>
              ))}
            </CityWrap>
            <CityWrap>
              {regionDataByParentId.map((item) => (
                <CityItem
                  key={item.id}
                  onClick={() => handleSelectGungu(item.id, item.sigungu)}
                  active={selectGungu === item.id}
                >
                  {item.sigungu}
                  <CityEng>{item.sigunguInEnglish}</CityEng>
                </CityItem>
              ))}
            </CityWrap>
          </RegionPopupMiddle>
        </RegionPopupWrap>
        <PopupButtonWrap>
          <PopUpButtonGray onClick={handleCancelRegionPopup} type="button">
            취소
          </PopUpButtonGray>
          <PopUpButton onClick={handleCheckRegionPopup} type="button">
            확인
          </PopUpButton>
        </PopupButtonWrap>
      </PopUp>
    </PageLayout>
  );
});

const ContentWrap = styled.div`
  width: 1100px;
  margin: 0 auto;

  @media ${theme.mobile} {
    padding: 40px 30px;
    width: 100%;
  }
`;

const Title = styled.p`
  font-size: 20px;
  color: #313131;
  letter-spacing: -1.13px;
  font-family: 'nexonMedium';
  margin: 0 0 56px 0;

  @media ${theme.mobile} {
    font-size: 22px;
  }
`;

const InputItemWrap = styled.div`
  margin: 10px 0 70px 0;
  position: relative;
`;

const Label = styled.p`
  font-family: 'nexonMedium';
  color: #80878e;
  font-size: 20px;
  letter-spacing: -1px;
  color: #1f2422;
  width: 100px;
  line-height: 44px;

  @media ${theme.mobile} {
    margin: 10px 0 15px 0;
    line-height: 20px;
    color: #80878e;
  }
`;

const SelectBox = styled.div`
  width: 50%;
  height: 53px;
  border: 1px solid ${theme.colors.borderLight};
  color: #80878e;
  font-family: 'nexonMedium';
  font-size: 20px;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
`;

const SectorsWrap = styled.div`
  display: flex;
  width: 570px;
  border: 1px solid ${theme.colors.border};
  border-radius: 10px;
  padding: 22px;
  margin: 0 0 17px 0;

  @media ${theme.mobile} {
    flex-wrap: wrap;
    margin: 0 0 32px 0;
    width: 100%;
    border: none;
    padding: 0;
  }
`;

const SectorsTab = styled(SelectBox)`
  position: relative;

  &:nth-child(2n) {
    margin-left: -1px;
  }

  &:nth-child(n + 1) {
    margin-top: -1px;
  }

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonMedium';
      border: 1px solid ${theme.colors.primary};
      z-index: 1;
      color: ${theme.colors.primary};
    `}
`;

const PCItemTab = styled.div`
  font-size: 17px;
  color: #313131;
  border: 1px solid ${theme.colors.lightPrimary};
  border-radius: 20px;
  padding: 5px 15px;
  cursor: pointer;

  ${(props) =>
    props.active === true &&
    css`
      border: 1px solid ${theme.colors.primary};
      color: ${theme.colors.primary};
    `}

  & + & {
    margin-left: 10px;
  }
`;

const PayFlexWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 18px 0;

  @media ${theme.mobile} {
    display: block;
    margin: 0 0 0 0;
  }
`;

const PayTypeWrap = styled.div`
  display: flex;
  width: 425px;
  border: 1px solid ${theme.colors.border};
  border-radius: 10px;
  padding: 22px 25px;

  @media ${theme.mobile} {
    flex-wrap: wrap;
    margin: 0 0 32px 0;
    width: 100%;
    border: none;
    padding: 0;
    margin: 0 0 17px 0;
  }
`;

const PayTypeTab = styled.div`
  width: 25%;
  height: 53px;
  border: 1px solid ${theme.colors.borderLight};
  color: #80878e;
  font-family: 'nexonMedium';
  font-size: 20px;
  text-align: center;
  padding: 15px 0;
  position: relative;
  cursor: pointer;

  & + & {
    margin-left: -1px;
  }

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonMedium';
      border: 1px solid ${theme.colors.primary};
      z-index: 1;
      color: ${theme.colors.primary};
    `}
`;

const PayAmountInputWrap = styled.div`
  font-family: 'nexonRegular';
  display: flex;
  justify-content: space-between;
  font-size: 21px;
  margin: 0 0 0 32px;

  @media ${theme.mobile} {
    margin: 55px 0 50px 0;
  }
`;

const PayAmountInput = styled.input`
  border: none;
  border: 1px solid ${theme.colors.borderLight};
  border-radius: 10px;
  padding: 6px 3px;
  width: 180px;
  font-size: 17px;
  font-family: 'nexonRegular';
  color: #313131;
  height: 46px;

  @media ${theme.mobile} {
    height: 40px;
    border: none;
    border-radius: 0px;
    width: 205px;
    font-size: 21px;
    border-bottom: 1px solid ${theme.colors.borderLight};
  }
`;

const Button = styled.button`
  font-family: 'nexonMedium';
  width: 320px;
  height: 60px;
  background-color: ${theme.colors.primary};
  color: white;
  font-size: 20px;
  letter-spacing: -1.25px;
  margin: 120px auto;
  display: block;

  @media ${theme.mobile} {
    width: 100%;
    margin: 63px 0 0 0;
    height: 67px;
    font-family: 'nexonBold';
    font-size: 25px;
  }
`;

const Photo = styled.div`
  width: 170px;
  height: 170px;
  border-radius: 100px;
  background-color: #e6e4f2;
  margin: 35px auto 35px;
  overflow: hidden;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;

const ImgIcon = styled(BsFillPersonFill)`
  font-size: 130px;
  color: white;
  padding: 20px;
  cursor: pointer;
`;

const ImgInput = styled.input`
  display: none;
`;

const NationalWrap = styled.div`
  display: flex;
`;

const NationalButton = styled.button`
  font-family: 'nexonMedium';
  width: 224px;
  height: 54px;
  border: 1px solid ${theme.colors.borderLight};
  background-color: transparent;
  color: #80878e;
  font-size: 17px;

  ${(props) =>
    props.active === true &&
    css`
      border: 1.5px solid ${theme.colors.primary};
    `}
  @media ${theme.mobile} {
    font-size: 20px;
  }
`;

const NationalIcon = styled.p`
  font-family: 'nexonRegular';
  font-size: 25px;
  line-height: 36px;
  padding: 7px 0 0 0;
  margin: 0 10px;
`;

const NationalInput = styled.input`
  width: 224px;
  border: none;
  border-bottom: 1px solid ${theme.colors.borderLight};
  height: 51px;
  font-family: 'nexonRegular';
  font-size: 21px;
  line-height: 34px;
  color: #313131;
`;

const GenderTabWrap = styled.div`
  display: flex;
`;

const GenderTab = styled.button`
  font-family: 'nexonMedium';
  width: 224px;
  height: 54px;
  border: 1px solid ${theme.colors.borderLight};
  background-color: transparent;
  color: #80878e;
  font-size: 17px;

  & + & {
    margin-left: -1px;
  }

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonBold';
      border: 1px solid ${theme.colors.primary};
      z-index: 1;
      color: ${theme.colors.primary};
    `}

  @media ${theme.mobile} {
    font-size: 20px;
  }
`;

const CertificationWrap = styled.div`
  display: flex;
`;

const CertificationInput = styled.input`
  font-family: 'nexonRegular';
  width: 500px;
  height: 44px;
  border: none;
  border-bottom: 1px solid ${theme.colors.borderLight};
  padding: 0 0 0 15px;
  font-size: 22px;

  @media ${theme.mobile} {
    width: 326px;
    height: 44px;
    border: 1px solid ${theme.colors.borderLight};
  }
`;

const CertificationButton = styled.button`
  font-family: 'nexonMedium';
  width: 110px;
  height: 40px;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid ${theme.colors.primary};
  color: #595959;
  font-size: 15px;
  margin: 0 0 0 11px;

  @media ${theme.mobile} {
    width: 147px;
    height: 43px;
    border-radius: 30px;
    background-color: #d4cae3;
    border: none;
    color: white;
    font-size: 20px;
    margin: 0 0 0 17px;
    ${(props) =>
      props.active === true &&
      css`
        background-color: ${theme.colors.primary};
      `}
  }
`;

const CertificationButtonPostion = styled(CertificationButton)`
  position: absolute;
  right: 10px;
  bottom: 5px;
`;

const DivideBorder = styled.div`
  height: 14px;
  width: 1100px;
  background-color: #eef1f5;
  margin: 0 auto;

  @media ${theme.mobile} {
    height: 1px;
    width: 100%;
    background-color: ${theme.colors.purpleGray};
    margin: 72px 0 46px 0;
  }
`;

const ContentTitle = styled.p`
  font-family: 'nexonMedium';
  font-size: 20px;
  margin: 0 0 65px 0;

  & > span {
    font-family: 'nexonRegular';
    font-size: 15px;
    color: #9495a3;
    margin: 0 0 0 15px;
  }
`;

const KoreanSkillWrap = styled.div`
  display: flex;
  width: 620px;

  @media ${theme.mobile} {
    width: 100%;
  }
`;

const KoreanSkillTab = styled.div`
  font-family: 'nexonRegular';
  cursor: pointer;
  width: 20%;
  height: 66px;
  border: 1px solid ${theme.colors.borderLight};
  font-size: 12px;
  text-align: center;
  color: #595959;
  padding: 25px 0;

  & + & {
    margin-left: -1px;
  }

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonMedium';
      border: 1px solid ${theme.colors.primary};
      z-index: 1;
      color: ${theme.colors.primary};
    `}

  @media ${theme.mobile} {
    height: 61px;
    font-size: 18px;
    color: ${theme.colors.borderLight};
    padding: 20px 0;
  }
`;

const CareerWrap = styled.div`
  display: flex;
  width: 620px;

  @media ${theme.mobile} {
    width: 100%;
  }
`;

const CareerTabBox = styled.div`
  width: 16.666%;
  border: 1px solid ${theme.colors.borderLight};
  height: 66px;
  & + & {
    margin-left: -1px;
  }
  ${(props) =>
    props.active === true &&
    css`
      z-index: 1;
    `}

  @media ${theme.mobile} {
    height: 85px;
  }
`;

const CareerTab = styled.div`
  font-family: 'nexonRegular';
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  color: #595959;
  margin: 25px 0 0 0;

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonMedium';
      border: 1px solid ${theme.colors.primary};
      color: ${theme.colors.primary};
    `}

  @media ${theme.mobile} {
    color: ${theme.colors.borderLight};
    margin: 0;
  }
`;

const CareerIcon = styled.div`
  width: 40px;
  height: 40px;
  background-color: #eee;
  margin: 13px 22px 8px 22px;
`;

const JobPeriodText = styled.div`
  font-size: 17px;
  text-align: center;
  margin: 7px 0 0 0;
`;

const HelpIcon = styled.div`
  width: 19px;
  height: 19px;
  background-color: ${theme.colors.primary};
  border-radius: 10px;
  color: white;
  font-family: 'nexonBold';
  font-size: 16px;
  padding: 1px 5px;
  position: absolute;
  top: 0;
  right: 0;
`;

const TextLength = styled.p`
  text-align: right;
  color: #838392;
  font-size: 17px;
  font-family: 'nexonRegular';
  margin: 6px 0 0 0;

  position: absolute;
  right: 10px;
  bottom: -30px;
`;

const ResidenceTypeWrap = styled.div`
  position: relative;
  display: flex;
  width: 620px;

  @media ${theme.mobile} {
    display: block;
    width: 100%;
  }
`;

const ResidenceTypeTab = styled.div`
  position: relative;
  width: 20%;
  height: 66px;
  border: 1px solid ${theme.colors.borderLight};
  text-align: center;
  font-size: 12px;
  color: #595959;
  font-family: 'nexonMedium';
  padding: 25px 0 0 0;
  cursor: pointer;
  & + & {
    margin-left: -1px;
  }

  & > span {
    font-size: 17px;
    font-family: 'nexonRegular';
    margin: 0 0 0 18px;
  }

  ${(props) =>
    props.active === true &&
    css`
      border: 1px solid ${theme.colors.primary};
      z-index: 2;
      color: ${theme.colors.primary};
    `}

  @media ${theme.mobile} {
    width: 100%;
    height: 53px;
    font-size: 20px;
    color: #80878e;
    padding: 16px 0 0 0;
    & + & {
      margin-top: -1px;
      margin-left: 0px;
    }
  }
`;

const TextAreaLabel = styled.p`
  font-family: 'nexonMedium';
  font-size: 20px;
  margin: 0 0 24px 0;
`;

const TextArea = styled.textarea`
  font-family: 'nexonRegular';
  border: 1px solid #d0d2e8;
  height: 320px;
  width: 100%;
  font-size: 17px;
  padding: 23px;
  box-sizing: border-box;
  resize: none;
  letter-spacing: -1px;

  &::placeholder {
    color: #d5d5e4;
  }

  &:focus {
    outline: none;
  }

  @media ${theme.mobile} {
    font-size: 20px;
    height: 198px;
    padding: 15px;
  }
`;

const WaveSign = styled.p`
  padding: 10px 0 0 0;
  margin: 0 20px;

  @media ${theme.mobile} {
    margin: 0;
  }
`;

const MoneySign = styled.p`
  padding: 10px 0 0 0;
  margin: 0 0 0 5px;
`;

const PopUpTitle = styled.p`
  text-align: center;
  font-family: 'nexonRegular';
  font-size: 25px;
  margin: 40px 0 0 0;
`;

const PopUpTab = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 20px 0 0 0;
  height: 420px;
  overflow: scroll;
`;

const PopUpLanguageItemWrap = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const PopUpLanguageItem = styled.div`
  text-align: center;
  width: 80px;
  height: 100px;
  padding: 13px 0 0 0;

  ${(props) =>
    props.tab === true &&
    css`
      background-color: rgba(7, 0, 133, 0.2);
      border-radius: 5px;
    `}
`;

const Flag = styled.div`
  width: 30px;
  height: 25px;
  margin: 0 auto 25px;

  & > img {
    width: 30px;
    object-fit: cover;
  }
`;

const PopupButtonWrap = styled.div`
  display: flex;
  position: absolute;
  bottom: 0px;
  width: 100%;
`;

const PopUpButton = styled.button`
  width: 50%;
  height: 60px;
  border-top: 1px solid ${theme.colors.borderLight};
  color: ${theme.colors.primary};
  background-color: transparent;
  font-size: 20px;
  display: block;

  & + & {
    border-left: 1px solid ${theme.colors.borderLight};
  }
`;

const FlagText = styled.p`
  font-size: 15px;
  line-height: 20px;
  font-family: 'nexonRegular';
  ${(props) =>
    props.langCode === 'th' &&
    css`
      font-family: 'PromptR';
    `}
  ${(props) =>
    props.langCode === 'vi' &&
    css`
      font-family: 'ArchivoR';
    `}
  ${(props) =>
    ['ru', 'mn', 'km'].includes(props.langCode) &&
    css`
      font-family: 'NotoSansR';
    `}
  ${(props) =>
    ['zh-TW', 'zh-CN'].includes(props.langCode) &&
    css`
      font-family: 'NotoSansCJKTCR';
    `}
`;

const PeriodPopupTab = styled.div`
  font-size: 20px;
  font-family: 'nexonRegular';
  text-align: center;
  padding: 22px 0;
  margin: 0 40px;
  cursor: pointer;

  & + & {
    border-top: 1px solid ${theme.colors.borderLight};
  }

  ${(props) =>
    props.active === true &&
    css`
      color: ${theme.colors.primary};
    `}
`;

const PeriodPopupTabWrap = styled.div`
  margin: 60px 0;
`;

const PopUpButtonGray = styled(PopUpButton)`
  color: ${theme.colors.borderLight};
`;

const RegionPopupWrap = styled.div``;

const RegionPopupTop = styled.div`
  height: 60px;
  background-color: #d0d2e8;
  padding: 0;
  border-radius: 15px 15px 0 0;
  display: flex;
`;

const RegionPopupTopBox = styled.div`
  width: 50%;
  text-align: center;
  font-size: 20px;
  font-family: 'nexonBold';
  padding: 18px 0 0 0;
`;

const RegionPopupMiddle = styled.div`
  padding: 10px 40px;
  display: flex;
  justify-content: space-between;
`;

const CityWrap = styled.div`
  width: 40%;
  height: 460px;
  overflow: hidden;
  overflow-y: scroll;
`;

const CityItem = styled.div`
  font-size: 20px;
  padding: 15px 0;
  cursor: pointer;

  ${(props) =>
    props.active === true &&
    css`
      color: ${theme.colors.primary};
    `}
`;

const CityEng = styled.div`
  color: ${theme.colors.borderLight};
  font-size: 14px;
  margin: 3px 0 0 0;
`;

const LeftSectionWrap = styled.div`
  width: 730px;
  margin: 100px 0 0 0;

  @media ${theme.mobile} {
    width: 100%;
    margin: 0;
  }
`;

const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;

  @media ${theme.mobile} {
    display: block;
    padding: 0;
  }
`;

const InputFlexWrap = styled.div`
  display: flex;
  align-items: center;

  @media ${theme.mobile} {
    display: block;
  }
`;

const PCPhotoWrap = styled.div``;

const PCPhoto = styled.div`
  width: 230px;
  height: 252px;
  background-color: #e6e4f2;
  border: 1px solid #e6e4f2;

  ${(props) =>
    props.active === true &&
    css`
      border: 1px solid ${theme.colors.primary};
    `}
`;

const PCImgIcon = styled(BsFillPersonFill)`
  font-size: 190px;
  color: white;
  padding: 20px;
  cursor: pointer;
`;

const CareerInput = styled.input`
  font-family: 'nexonRegular';
  width: 620px;
  height: 44px;
  border: none;
  border-bottom: 1px solid ${theme.colors.borderLight};
  font-size: 17px;
  &::placeholder {
    color: #d5d5e4;
  }
`;

const TipTool = styled.div`
  width: 230px;
  border-radius: 10px;
  background-color: #eef1f5;
  padding: 22px 14px 28px;
`;

const TipToolTitle = styled.p`
  font-size: 15px;
  font-family: 'nexonBold';
  margin: 0 0 36px 0;

  & > span {
    color: ${theme.colors.primary};
  }
`;

const TipToolText = styled.p`
  font-size: 11px;
  font-family: 'nexonMedium';
  line-height: 20px;
  letter-spacing: -0.28px;
`;

const TipToolError = styled.span`
  color: #d82b2b;
`;

const TipToolActive = styled.span`
  color: ${theme.colors.primary};
`;

const ResumeSecondWrap = styled.div`
  width: 100%;
  border: 1px solid ${theme.colors.border};
  border-radius: 10px;
  padding: 38px 42px;

  @media ${theme.mobile} {
    border: none;
    padding: 0;
  }
`;

const PCTitle = styled.p`
  font-size: 17px;
  font-family: 'nexonBold';
  color: #434343;
  letter-spacing: -0.44px;
  margin: 25px 0 6px 9px;
`;

const LocationWrap = styled.div`
  border: 1px solid ${theme.colors.border};
  width: 850px;
  height: 202px;
  display: flex;
  border-radius: 10px;
  margin: 0 0 15px 0;
`;
const PCCityWrap = styled.div`
  width: 310px;
  border-right: 1px solid ${theme.colors.borderLight};
  display: flex;
  flex-wrap: wrap;
  height: 202px;
  padding: 10px 20px 0;
  overflow-y: scroll;
`;

const PCCityItem = styled.div`
  font-family: 'nexonRegular';
  font-size: 17px;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
  width: 33%;

  ${(props) =>
    props.active === true &&
    css`
      color: ${theme.colors.primary};
    `}
`;

const CountryWrap = styled.div`
  width: 560px;
  height: 202px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 30px 0;
  justify-content: space-between;
  align-content: flex-start;
`;

const CountryItem = styled.div`
  font-family: 'nexonRegular';
  font-size: 17px;
  text-align: center;
  margin: 15px 0;
  width: 25%;
  height: 19px;
  cursor: pointer;

  ${(props) =>
    props.active === true &&
    css`
      color: ${theme.colors.primary};
    `}
`;

export default WriteResume;
