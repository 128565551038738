import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import { observer } from 'mobx-react';
import stores from 'stores';
import KakaoTalkLogo from 'resources/image/Kakaotalk_logo.png';
import FacebookLogo from 'resources/image/Facebook_logo.png';
import GoogleLogo from 'resources/image/Google_logo.png';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useGoogleLogin } from '@react-oauth/google';

const SignInEmployee = observer(() => {
  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`;
  const { userStore } = stores;
  const [inputs, setInputs] = useState({
    id: '',
    password: '',
    cellphone: '',
    certificationNumber: '',
  });

  const { id, password } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const data = {
      authType: 'EMAIL',
      email: id,
      name: '',
      password: password,
      role: 'EMPLOYEE',
      thirdPartyAccessToken: '',
      thirdPartyUserId: '',
    };
    console.log(data);
    userStore.signInUsers(data);
  };

  const handleKaKaoLogin = () => {
    window.location.href = KAKAO_AUTH_URL;
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (res) => {
      // const params = new URLSearchParams();
      // params.append('idToken', res.tokenObj.id_token);
      console.log(res);
    },
    flow: 'auth-code',

    // const googleLogin = async () => {
    //   const res = await axios.post('요청 주소', params, {
    //     headers: {
    //       'Content-Type': 'application/x-www-form-urlencoded',
    //     },
    //   });
    //   localStorage.setItem('accessToken', res.data.token.access);
    //   localStorage.setItem('refreshToken', res.data.token.refresh);
    // };

    // googleLogin()
  });

  return (
    <PageLayout>
      <ContentWrap>
        <Title>구직자 회원 로그인</Title>
        <LoginBoxWrap>
          <LoginBox>
            <SmallTitle>아이디/비밀번호로 로그인 하기</SmallTitle>
            <TopButtonWrap>
              <TextButton>회원가입하기</TextButton>
            </TopButtonWrap>
            <LoginInputWrap>
              <LeftInputBox>
                <Input name="id" value={id} placeholder="아이디" onChange={onChange} />
                <Input
                  name="password"
                  value={password}
                  placeholder="비밀번호"
                  onChange={onChange}
                />
              </LeftInputBox>
              <LoginButton onClick={handleLogin}>로그인</LoginButton>
            </LoginInputWrap>
            <BottomButtonWrap>
              <TextButton>로그인 상태 유지</TextButton>
              <TextButton>비밀번호 찾기</TextButton>
            </BottomButtonWrap>
          </LoginBox>
          <ColumnBorder></ColumnBorder>
          <LoginBox>
            <SmallTitle>SNS로 로그인 하기</SmallTitle>
            <SNSLoginWrap>
              <SNSItem onClick={handleKaKaoLogin}>
                <SNSImageBox>
                  <SNSImg src={KakaoTalkLogo} alt="kakaoTalk-logo" />
                </SNSImageBox>
                <SNSButtonText>
                  카카오톡
                  <br />
                  로그인
                </SNSButtonText>
              </SNSItem>
              <FacebookLogin
                appId="3339139296323940"
                onSuccess={(response) => {
                  console.log('Login Success!');
                  console.log('id: ', response.id);
                }}
                onFail={(error) => {
                  console.log('Login Failed!');
                  console.log('status: ', error.status);
                }}
                onProfileSuccess={(response) => {
                  console.log('Get Profile Success!');
                  console.log('name: ', response.name);
                }}
                render={(renderProps) => (
                  <SNSItem onClick={renderProps.onClick}>
                    <SNSImageBox>
                      <SNSImg src={FacebookLogo} alt="facebook-logo" />
                    </SNSImageBox>
                    <SNSButtonText>
                      페이스북
                      <br />
                      로그인
                    </SNSButtonText>
                  </SNSItem>
                )}
              />
              <SNSItem onClick={() => handleGoogleLogin()}>
                <SNSImageBoxGoogle>
                  <SNSImg src={GoogleLogo} alt="google-logo" />
                </SNSImageBoxGoogle>
                <SNSButtonText>
                  구글
                  <br />
                  로그인
                </SNSButtonText>
              </SNSItem>
              {/* <GoogleOAuthProvider clientId="719063081930-3fgcvfbsq1edm0p1n62datkbthu44jom.apps.googleusercontent.com">
            <GoogleLogin
              onSuccess={onGoogleSignInSuccess}
              onFailure={onFailure}
              render={(renderProps) => (
              )}
            />
          </GoogleOAuthProvider> */}
            </SNSLoginWrap>
          </LoginBox>
        </LoginBoxWrap>
      </ContentWrap>
    </PageLayout>
  );
});

const ContentWrap = styled.div`
  width: 1080px;
  margin: 140px auto 0;
`;
const Title = styled.p`
  font-size: 28px;
  font-family: 'nexonBold';
  margin: 0 0 0 10px;
`;

const LoginBoxWrap = styled.div`
  border: 1px solid ${theme.colors.border};
  border-radius: 10px;
  display: flex;
  margin: 28px 0 0 0;
`;

const LoginBox = styled.div`
  width: 420px;
  margin: 60px 60px 120px;
`;

const SmallTitle = styled.p`
  font-family: 'nexonMedium';
  font-size: 20px;
  color: #242d24;
  letter-spacing: -0.54px;
`;

const TopButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 70px 0 10px 0;
`;

const TextButton = styled.button`
  font-family: 'nexonRegular';
  font-size: 15px;
  background: transparent;
  padding: 0;
`;

const LoginInputWrap = styled.div`
  display: flex;
`;

const LeftInputBox = styled.div`
  width: calc(100% - 128px);
`;

const Input = styled.input`
  width: 100%;
  height: 56px;
  border: 1px solid ${theme.colors.borderLight};
  font-size: 17px;
  padding: 0 0 0 22px;
  font-family: 'nexonRegular';

  &::placeholder {
    color: ${theme.colors.borderLight};
  }

  & + & {
    border-top: none;
  }
`;

const LoginButton = styled.button`
  width: 128px;
  height: 112px;
  background-color: ${theme.colors.primary};
  color: white;
  font-size: 18px;
`;

const BottomButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0 0;
`;

const ColumnBorder = styled.div`
  width: 1px;
  height: 420px;
  margin: 10px 0 0 0;
  background-color: ${theme.colors.border};
`;

const SNSLoginWrap = styled.div`
  margin: 80px 0 0 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SNSItem = styled.button`
  width: 92px;
  background: transparent;
  padding: 0;
`;

const SNSImageBox = styled.div`
  width: 92px;
  height: 92px;
  border-radius: 50px;
  overflow: hidden;
`;

const SNSImageBoxGoogle = styled(SNSImageBox)`
  padding: 30px 0;
  border: 1px solid #eee;
`;

const SNSImg = styled.img`
  width: 100%;
`;

const SNSButtonText = styled.p`
  font-size: 15px;
  font-family: 'nexonRegular';
  text-align: center;
  margin: 16px 0 0 0;
`;

export default SignInEmployee;
