import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import RecruitCard, { PCRecruitCard } from 'components/modules/RecruitCard';
import { observer } from 'mobx-react';
import stores from 'stores';
import { formatPayType } from 'components/utils/useful-function';
import Pagination from 'components/atoms/Pagination';
import { Mobile, Others } from 'styles/MediaQuery';
import MyPageLayout from 'components/templates/MypageLayout';

const Favorites = observer(() => {
  const { favoriteStore } = stores;
  const [page, setPage] = useState(1);
  const listPerPage = 10;

  useEffect(() => {
    favoriteStore.getFavorites('', page - 1, listPerPage);
  }, [favoriteStore, page]);

  const favoritesData = favoriteStore.favoritesData;

  return (
    <PageLayout>
      <Mobile>
        {favoritesData.map((item) => (
          <RecruitCardWrap key={item.id}>
            <RecruitCard
              id={item.id}
              companyName={item.companyName}
              title={item.title}
              address={item.address}
              payType={formatPayType(item.payType)}
              payAmount={item.payAmount}
              apply={item.apply}
              bookmark={item.favorited}
              style={{ padding: '23px 34px 35px' }}
              link={`/recruitment/detail/${item.id}`}
            />
          </RecruitCardWrap>
        ))}
        <PaginationWrap>
          <Pagination
            totalPage={parseInt(favoriteStore?.totalFavorites / 10)}
            limit={5}
            page={page}
            setPage={setPage}
          ></Pagination>
        </PaginationWrap>
      </Mobile>
      <Others>
        <MyPageLayout>
          <PCTitle>즐겨찾기</PCTitle>
          <FavoritesWrap>
            {favoritesData.map((item) => (
              <RecruitCardWrap key={item.id}>
                <PCRecruitCard
                  id={item.id}
                  companyName={item.companyName}
                  title={item.title}
                  address={item.address}
                  payType={formatPayType(item.payType)}
                  payAmount={item.payAmount}
                  apply={item.apply}
                  bookmark={item.favorited}
                  style={{
                    backgroundColor: '#ffffff',
                    borderRadius: 5,
                    padding: '17px',
                    height: '166px',
                  }}
                  createdDate={item.createdDate}
                  link={`/recruitment/detail/${item.id}`}
                />
              </RecruitCardWrap>
            ))}
            <PaginationWrap>
              <Pagination
                totalPage={parseInt(favoriteStore?.totalFavorites / 10)}
                limit={9}
                page={page}
                setPage={setPage}
              ></Pagination>
            </PaginationWrap>
          </FavoritesWrap>
        </MyPageLayout>
      </Others>
    </PageLayout>
  );
});

const RecruitCardWrap = styled.div`
  margin: 0 0 7px 0;
  @media ${theme.mobile} {
    border-bottom: 1px solid ${theme.colors.purpleGray};
    margin: 0;
  }
`;
const PaginationWrap = styled.div`
  margin: 40px 0 0 0;
`;

const PCTitle = styled.div`
  font-size: 18px;
  color: #1f2422;
  font-family: 'nexonMedium';
  letter-spacing: -0.46px;
  margin: 30px 0 0 0;
`;

const FavoritesWrap = styled.div`
  background-color: #eef1f5;
  padding: 17px;
  width: 100%;
  margin: 11px 0 0 0;
`;

export default Favorites;
