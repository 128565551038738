import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import { sliceArrayByLimit } from 'components/utils/useful-function';

import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';

const Pagination = ({ totalPage, limit, page, setPage, small }) => {
  const [currentPageArray, setCurrentPageArray] = useState([]);
  const [totalPageArray, setTotalPageArray] = useState([]);

  useEffect(() => {
    if (page % limit === 1) {
      setCurrentPageArray(totalPageArray[Math.floor(page / limit)]);
    } else if (page % limit === 0) {
      setCurrentPageArray(totalPageArray[Math.floor(page / limit) - 1]);
    }
  }, [page]);

  console.log(totalPage, 'totalPage');

  useEffect(() => {
    const slicedPageArray = sliceArrayByLimit(totalPage, limit);
    console.log(slicedPageArray, '호잇 ');
    setTotalPageArray(slicedPageArray);
    setCurrentPageArray(slicedPageArray[0]);
  }, [totalPage]);

  return (
    <div>
      <nav>
        <PageUl>
          <Prev onClick={() => setPage(page - 1)} disabled={page === 1} small={small}>
            <Icon small={small}>
              <MdOutlineKeyboardArrowLeft />
            </Icon>
            이전
          </Prev>
          {totalPage === 0 ? (
            <PageLi active={true} small={small}>
              1
            </PageLi>
          ) : (
            currentPageArray?.map((i) => (
              <PageLi
                key={i + 1}
                onClick={() => setPage(i + 1)}
                active={page === i + 1}
                small={small}
              >
                {i + 1}
              </PageLi>
            ))
          )}
          <Next onClick={() => setPage(page + 1)} disabled={page === totalPage} small={small}>
            다음
            <Icon small={small}>
              <MdOutlineKeyboardArrowRight />
            </Icon>
          </Next>
        </PageUl>
      </nav>
    </div>
  );
};

const PageUl = styled.ul`
  list-style: none;
  text-align: center;
  border-radius: 3px;
  font-family: 'nexonBold';
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
`;

const PageLi = styled.li`
  font-size: 25px;
  cursor: pointer;
  margin: 0 10px;
  color: #4d4d4d;

  ${(props) =>
    props.active === true &&
    css`
      color: ${theme.colors.primary};
    `}

  ${(props) =>
    props.small === true &&
    css`
      font-size: 12px;
      margin: 0 5px;
    `}
`;

const PageSpan = styled.span``;

const Prev = styled.button`
  font-family: 'nexonBold';
  font-size: 20px;
  color: #9c9eb9;
  margin: 0 38px 0 0;
  display: flex;
  cursor: pointer;
  background-color: transparent;

  ${(props) =>
    props.small === true &&
    css`
      font-size: 9px;
      margin: 0 18px 0 0;
    `}
`;

const Next = styled(Prev)`
  margin: 0 0 0 38px;

  ${(props) =>
    props.small === true &&
    css`
      font-size: 9px;
      margin: 0 0 0 10px;
    `}
`;

const Icon = styled.div`
  font-size: 30px;
  margin-top: -5px;

  ${(props) =>
    props.small === true &&
    css`
      font-size: 20px;
    `}
`;

export default Pagination;
