import {
  signUpUsersApi,
  signInUsersApi,
  putLangCodeApi,
  getUsersMeApi,
  putUsersMeApi,
} from 'api/API';
import { makeAutoObservable } from 'mobx';

class UserStore {
  alarmOn = false;
  alarmExpectedJobOn = false;
  alarmNewsOn = false;

  constructor() {
    makeAutoObservable(this);
  }

  setAlarms = (param) => {
    console.log(param.alarmOn);
    this.alarmOn = param.alarmOn;
    this.alarmExpectedJobOn = param.alarmExpectedJobOn;
    this.alarmNewsOn = param.alarmNewsOn;
  };

  signUpUsers = async (data) => {
    await signUpUsersApi(data)
      .then((res) => {
        console.log(res);
        if (res.data.responseCode === 'SUCCESS') {
          alert('성공적으로 요청되었습니다.');
          window.location.href = '/signIn';
          return;
        }
        alert('요청에 실패했습니다.');
      })
      .catch((error) => {
        console.log(error);
        alert('요청에 실패했습니다.');
      });
  };

  signInUsers = async (data) => {
    await signInUsersApi(data)
      .then((res) => {
        console.log(res);
        if ('SUCCESS' !== res.data.responseCode) {
          alert('요청에 실패했습니다.');
        } else {
          localStorage.setItem('userType', res.data.data.role);
          localStorage.setItem('token', res.data.data.accessToken);
          //   alert('요청에 성공했습니다.');
          window.location.href = '/';
        }
      })
      .catch((error) => {
        console.log({ ...error });
        if (error.response.status === 401) {
          alert('아이디 비밀번호가 일치하지 않습니다.');
        } else {
          alert('요청에 실패했습니다.');
        }
      });
  };

  putLangCode = async (data) => {
    await putLangCodeApi(data)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getUsersMe = async () => {
    await getUsersMeApi()
      .then((res) => {
        this.setAlarms(res.data.data);
        console.log(res.data.data.alarmOn);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  putUsersMe = async (alarmExpectedJobOn, alarmNewsOn, alarmOn) => {
    await putUsersMeApi(alarmExpectedJobOn, alarmNewsOn, alarmOn)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const userStore = new UserStore();

export default userStore;
