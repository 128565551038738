import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react';

const { kakao } = window;

const KakaoMap = observer(({ latitude, longitude }) => {
  useEffect(() => {
    const container = document.getElementById('map');
    const options = {
      center: new kakao.maps.LatLng(latitude, longitude),
      level: 2,
    };
    const map = new kakao.maps.Map(container, options);
    const markerPosition = new kakao.maps.LatLng(latitude, longitude);
    const marker = new kakao.maps.Marker({
      position: markerPosition,
    });
    marker.setMap(map);
  }, [latitude, longitude]);

  return <div id="map" style={{ width: '100%', height: '400px' }}></div>;
});

export default KakaoMap;
