import axios from 'axios';

export const instance = axios.create({
  baseURL: 'https://api.ko-life.co.kr',
});

instance.interceptors.request.use(
  function (config) {
    config.headers['Content-Type'] = 'application/json; charset=utf-8';
    if (localStorage.getItem('token')) {
      config.headers['X-KOLIFE-AUTH-TOKEN'] = localStorage.getItem('token');
    }
    return config;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  function (response) {
    console.log(response);

    return response;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  },
);

/* 파일 */
export const updateFilesApi = (body) => instance.post(`/files`, body);

/* 국가 */
export const getCountriesApi = () => instance.get(`/countries`);

/* 유저 */
export const signUpUsersApi = (body) => instance.post(`/users`, body);
export const signInUsersApi = (body) => instance.post(`/users/authentication`, body);
export const putLangCodeApi = (body) => instance.put(`/users/me/lang-code`, body);
export const getUsersMeApi = () => instance.get(`/users/me`);
export const putUsersMeApi = (alarmExpectedJobOn, alarmNewsOn, alarmOn) =>
  instance.put(
    `/users/me?alarmExpectedJobOn=${alarmExpectedJobOn}&alarmNewsOn=${alarmNewsOn}&alarmOn=${alarmOn}`,
  );

/* news-controller */
export const getNewsBannerApi = () => instance.get(`/new-banners`);
export const postNewsBannersClicksApi = (id) => instance.post(`/new-banners/${id}/clicks`);
export const getNewsMainsApi = () => instance.get(`/news-mains`);
export const getNewsApi = (pageNumber, pageSize) =>
  instance.get(`/news?pageNumber=${pageNumber}&pageSize=${pageSize}`);
export const getNewsDetailApi = (id) => instance.get(`/news/${id}`);

/* 지역 */
export const getRegionsApi = () => instance.get(`/regions`);

export const getRegionsByParentIdApi = (parentId) => instance.get(`/regions?parentId=${parentId}`);

/* 이력서 */
export const postResumeApi = (body) => instance.post(`/resumes`, body);
export const getResumeMeApi = () => instance.get(`/users/me/resume`);
export const postResumeExpectedJobApi = (body) => instance.post(`/resumes/expected-job`, body);
export const getResumesByIdApi = (id) => instance.get(`/resumes/${id}`);

/* 인증 */
export const postCertificationsApi = (body) => instance.post(`/certifications`, body);
export const postCertificationsCodeApi = (body) => instance.post(`/certifications/code`, body);

/* 공고 */
export const getEmployeeJobsApi = (
  productType,
  jobCategories,
  keywordType,
  langCode,
  pageNumber,
  pageSize,
  regionIds,
) =>
  instance.get(
    `/employee/jobs?productType=${productType}&jobCategories=${jobCategories}&keywordType=${keywordType}&langCode=${langCode}&pageNumber=${pageNumber}&pageSize=${pageSize}&regionIds=${regionIds}`,
  );

export const postEmployerJobsApi = (body) => instance.post(`/employers/jobs`, body);

export const getJobsDetailApi = (id) => instance.get(`/jobs/${id}`);

export const getEmployerJobsProgressApi = (pageNumber, pageSize, progress) =>
  instance.get(
    `/employers/jobs?pageNumber=${pageNumber}&pageSize=${pageSize}&progress=${progress}`,
  );

export const postEmployerJobsEndApi = (id) => instance.post(`/employers/jobs/${id}/end`);

export const getJobsDetailsTranslationApi = (id, langCode) =>
  instance.get(`/jobs/${id}/details/translation?langCode=${langCode}`);

export const postJobsReports = (id, body) => instance.post(`/jobs/${id}/reports`, body);

/* 지원 */
export const postJobsApplicationsApi = (jobId) => instance.post(`/jobs/${jobId}/applications`);

export const getUsersApplicationsApi = (langCode, pageNumber, pageSize) =>
  instance.get(
    `/users/applications?langCode=${langCode}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
  );

/* 즐겨찾기 */
export const postFavoritesApi = (jobId) => instance.post(`/jobs/${jobId}/favorites`);
export const deleteFavoritesApi = (jobId) => instance.delete(`/jobs/${jobId}/favorites`);
export const getFavoritesApi = (langCode, pageNumber, pageSize) =>
  instance.get(
    `/jobs/favorites?langCode=${langCode}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
  );

/* 메인 */
export const getMainJobsApi = (langCode) =>
  instance.get(`/main/recommended-jobs?langCode=${langCode}`);

export const getAdvertisementsForMainApi = () => instance.get(`/advertisements-for-main`);
