import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import MainNav from 'components/templates/MainNav';
import { observer } from 'mobx-react';
import stores from 'stores';
import SelectOptions from 'components/atoms/SelectOptions';
import PopUp from 'components/atoms/PopUp';
import { FaMapMarkerAlt } from 'react-icons/fa';

const cardOptions = [
  {
    text: '삼성카드',
  },
  {
    text: '현대카드',
  },
  {
    text: '신한카드',
  },
];

const monthOptions = [
  {
    text: '일시불',
  },
  {
    text: '3개월',
  },
  {
    text: '6개월',
  },
];

const PaymentLocalJob = observer(() => {
  const { regionsStore } = stores;

  useEffect(() => {
    regionsStore.getRegions();
  }, [regionsStore]);

  const regionData = regionsStore.regionData.slice(0, 17);
  const regionDataByParentId = regionsStore.regionDataByParentId;

  const [ratePlan, setRatePlan] = useState('');

  const handleRatePlan = (value) => {
    setRatePlan(value);
  };

  const [couponNum, setCouponNum] = useState('');

  const onChange = (e) => {
    setCouponNum(e.target.value);
  };

  const [payMethod, setPaymethod] = useState('');

  const handlePayMethod = (value) => {
    setPaymethod(value);
  };

  const [card, setCard] = useState({ text: '카드를 선택해주세요' });
  const [installment, setInstallment] = useState({ text: '일시불' });

  const [regionsPopupVisible, setRegionsPopupVisible] = useState(false);

  const [clickedLocationBox, setClickedLocationBox] = useState(null);

  const handleClickLocationBox = (id) => {
    setRegionsPopupVisible(true);
    setClickedLocationBox(id);
  };

  const [selectedSido, setSelectedSido] = useState(0);
  const [selectedSidoCode, setSelectedSidoCode] = useState(0);
  const [selectedSidoName, setSelectedSidoName] = useState('');

  const [advertisementRegions, setAdvertisementRegions] = useState([
    { id: 0, sidoId: 0, sidoName: '', gunguId: 0, gunguName: '' },
    { id: 1, sidoId: 0, sidoName: '', gunguId: 0, gunguName: '' },
    { id: 2, sidoId: 0, sidoName: '', gunguId: 0, gunguName: '' },
  ]);

  const handleSelectSido = (id, name, code) => {
    setSelectedSido(id);
    setSelectedSidoName(name);
    setSelectedSidoCode(code);
    setSelectGungu('');
    setSelectGunguName('');
    regionsStore.getRegionsByParentId(id);
  };

  const [selectGungu, setSelectGungu] = useState('');
  const [selectGunguName, setSelectGunguName] = useState('');

  const handleSelectGungu = (id, name) => {
    setSelectGungu(id);
    setSelectGunguName(name);
  };

  const handleCheckRegionPopup = () => {
    if (!selectGungu) {
      return;
    }
    setRegionsPopupVisible(false);
    setAdvertisementRegions(
      advertisementRegions.map((item) =>
        item.id === clickedLocationBox
          ? {
              sidoId: selectedSido,
              sidoName: selectedSidoName,
              gunguId: selectGungu,
              gunguName: selectGunguName,
            }
          : item,
      ),
    );
  };
  console.log(clickedLocationBox);
  console.log(advertisementRegions);

  const handleCancelRegionPopup = () => {
    setSelectedSido('');
    setSelectGungu('');
    setRegionsPopupVisible(false);
  };

  return (
    <PageLayout>
      <MainNav />
      <Wrap>
        <Title>결제하기</Title>
        <FlexWrap>
          <LeftSection>
            <ContentBox>
              <Label>요금제선택</Label>
              <RatePlanWrap>
                <RatePlanLabel>
                  로컬잡
                  <br />
                  Local Jobs
                </RatePlanLabel>
                <FlexWrapGrid>
                  <RatePlanBox
                    onClick={() => handleRatePlan('3일권')}
                    active={ratePlan === '3일권'}
                  >
                    <PointText>3일권</PointText>
                    <PayText>15,000 원</PayText>
                  </RatePlanBox>
                  <RatePlanBox
                    onClick={() => handleRatePlan('7일권')}
                    active={ratePlan === '7일권'}
                  >
                    <PointText>7일권</PointText>
                    <PayText>26,000 원</PayText>
                  </RatePlanBox>
                  <RatePlanBox
                    onClick={() => handleRatePlan('15일권')}
                    active={ratePlan === '15일권'}
                  >
                    <PointText>15일권</PointText>
                    <PayText>36,000 원</PayText>
                  </RatePlanBox>
                  <RatePlanBox
                    onClick={() => handleRatePlan('30일권')}
                    active={ratePlan === '30일권'}
                  >
                    <PointText>30일권</PointText>
                    <PayText>56,000 원</PayText>
                  </RatePlanBox>
                </FlexWrapGrid>
              </RatePlanWrap>
            </ContentBox>
            <ContentBox>
              <Label>광고 지역 선택</Label>
              <LocationBoxWrap>
                <LocationBox
                  onClick={() => handleClickLocationBox(0)}
                  active={advertisementRegions[0].sidoName !== ''}
                >
                  <MapIcon />
                  <LocationText active={advertisementRegions[0].sidoName !== ''}>
                    {advertisementRegions[0].sidoName !== ''
                      ? `${advertisementRegions[0].sidoName}/${advertisementRegions[0].gunguName}`
                      : '지역 선택'}
                  </LocationText>
                </LocationBox>
                <LocationBox
                  onClick={() => handleClickLocationBox(1)}
                  active={advertisementRegions[1].sidoName !== ''}
                >
                  <MapIcon />
                  <LocationText active={advertisementRegions[1].sidoName !== ''}>
                    {advertisementRegions[1].sidoName !== ''
                      ? `${advertisementRegions[1].sidoName}/${advertisementRegions[1].gunguName}`
                      : '지역 선택'}
                  </LocationText>
                </LocationBox>
                <LocationBox
                  onClick={() => handleClickLocationBox(2)}
                  active={advertisementRegions[2].sidoName !== ''}
                >
                  <MapIcon />
                  <LocationText active={advertisementRegions[2].sidoName !== ''}>
                    {advertisementRegions[2].sidoName !== ''
                      ? `${advertisementRegions[2].sidoName}/${advertisementRegions[2].gunguName}`
                      : '지역 선택'}
                  </LocationText>
                </LocationBox>
              </LocationBoxWrap>
            </ContentBox>
            <ContentBox>
              <Label>쿠폰등록</Label>
              <CouponWrap>
                <CouponInput
                  value={couponNum}
                  placeholder="쿠폰 번호를 입력하세요"
                  onChange={onChange}
                />
                <CouponButton>등록하기</CouponButton>
              </CouponWrap>
            </ContentBox>
            <ContentBox>
              <Label>결제 방법 선택</Label>
              <PayMethodWrap>
                <PayMethodItem
                  onClick={() => handlePayMethod('CreditCard')}
                  active={payMethod === 'CreditCard'}
                >
                  카드결제
                </PayMethodItem>
                <PayMethodItem
                  onClick={() => handlePayMethod('AccountTransfer')}
                  active={payMethod === 'AccountTransfer'}
                >
                  계좌이체
                </PayMethodItem>
                <PayMethodItem
                  onClick={() => handlePayMethod('DepositWithoutBankbook')}
                  active={payMethod === 'DepositWithoutBankbook'}
                >
                  무통장입금
                </PayMethodItem>
                <PayMethodItem
                  onClick={() => handlePayMethod('NaverPay')}
                  active={payMethod === 'NaverPay'}
                >
                  네이버페이
                </PayMethodItem>
                <PayMethodItem
                  onClick={() => handlePayMethod('KakaoPay')}
                  active={payMethod === 'KakaoPay'}
                >
                  카카오페이
                </PayMethodItem>
                <PayMethodItem
                  onClick={() => handlePayMethod('SamsungPay')}
                  active={payMethod === 'SamsungPay'}
                >
                  삼성페이
                </PayMethodItem>
              </PayMethodWrap>
              <SelectOptionWrap>
                <SelectOptions
                  value={card}
                  onSelect={(val) => setCard(val)}
                  options={cardOptions}
                  style={{ height: '40px' }}
                  border={true}
                />
              </SelectOptionWrap>
              <SelectOptionWrap>
                <SelectOptions
                  value={installment}
                  onSelect={(val) => setInstallment(val)}
                  options={monthOptions}
                  style={{ height: '40px' }}
                  border={true}
                />
              </SelectOptionWrap>
            </ContentBox>
          </LeftSection>
          <RightSection>
            <Label>최종 결제 금액</Label>
            <AmountBox>
              <AmountLabel>상품 금액</AmountLabel>
              <Amount>90,000원</Amount>
            </AmountBox>
            <AmountBox>
              <AmountLabel>지역 추구</AmountLabel>
              <Amount active={true}>+ 9,900원</Amount>
            </AmountBox>
            <Border></Border>
            <AmountBox>
              <AmountLabel>총 결제 금액</AmountLabel>
              <Amount>99,900원</Amount>
            </AmountBox>
            <PayButton>결제 하기</PayButton>
          </RightSection>
        </FlexWrap>
      </Wrap>
      <PopUp visible={regionsPopupVisible}>
        <RegionPopupWrap>
          <RegionPopupTop>
            <RegionPopupTopBox>시/도</RegionPopupTopBox>
            <RegionPopupTopBox>시/군/구</RegionPopupTopBox>
          </RegionPopupTop>
          <RegionPopupMiddle>
            <CityWrap>
              {regionData.map((item) => (
                <CityItem
                  key={item.id}
                  onClick={() => handleSelectSido(item.id, item.sidoInSummary, item.sigunguCode)}
                  active={selectedSido === item.id}
                >
                  {item.sidoInSummary}
                  <CityEng>{item.sidoInEnglish}</CityEng>
                </CityItem>
              ))}
            </CityWrap>
            <CityWrap>
              {regionDataByParentId.map((item) => (
                <CityItem
                  key={item.id}
                  onClick={() => handleSelectGungu(item.id, item.sigungu)}
                  active={selectGungu === item.id}
                >
                  {item.sigungu}
                  <CityEng>{item.sigunguInEnglish}</CityEng>
                </CityItem>
              ))}
            </CityWrap>
          </RegionPopupMiddle>
        </RegionPopupWrap>
        <PopupButtonWrap>
          <PopUpButtonGray onClick={handleCancelRegionPopup} type="button">
            취소
          </PopUpButtonGray>
          <PopUpButton onClick={handleCheckRegionPopup} type="button">
            확인
          </PopUpButton>
        </PopupButtonWrap>
      </PopUp>
    </PageLayout>
  );
});

const Wrap = styled.div`
  width: 1100px;
  margin: 0 auto;
  background-color: #eef1f5;
  padding: 26px 24px;
`;

const Title = styled.p`
  font-size: 17px;
  color: #434343;
  font-family: 'nexonBold';
  letter-spacing: -0.4px;
`;

const FlexWrap = styled.div`
  display: flex;
  margin: 17px 0 0 0;
`;

const LeftSection = styled.div`
  width: 672px;
`;

const ContentBox = styled.div`
  width: 100%;
  background-color: white;
  padding: 13px 52px 37px 14px;

  & + & {
    margin: 11px 0 0 0;
  }
`;

const Label = styled.p`
  font-size: 13px;
  color: #434343;
  font-family: 'nexonMedium';
  letter-spacing: -0.34px;
  margin: 0 0 30px 0;
`;

const RatePlanLabel = styled(Label)`
  margin: 0 0 0 13px;
  line-height: 25px;
`;

const RatePlanWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const RatePlanBox = styled.div`
  width: 226px;
  height: 59px;
  border: 3px solid #9c9eb9;
  display: flex;
  align-items: center;
  margin: 5px 3.5px;
  cursor: pointer;

  ${(props) =>
    props.active === true &&
    css`
      border: 3px solid ${theme.colors.primary};
    `}
`;

const PointText = styled.p`
  font-size: 15px;
  color: #434343;
  font-family: 'nexonMedium';
  letter-spacing: -0.34px;
  margin: 0 0 0 34px;
  width: 80px;
`;

const PayText = styled(PointText)`
  font-family: 'nexonBold';
  margin: 0 0 0 0;
`;

const FlexWrapGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 486px;
`;

const CouponWrap = styled.div`
  display: flex;
  align-items: center;
`;

const CouponInput = styled.input`
  border: 3px solid #9c9eb9;
  width: 272px;
  height: 46px;
  font-size: 15px;
  color: #434343;
  font-family: 'nexonRegular';
  letter-spacing: -0.34px;
  padding: 0 0 0 17px;
  &::placeholder {
    color: #d0d2e8;
  }
`;

const CouponButton = styled.button`
  width: 125px;
  height: 46px;
  background-color: #d4cae3;
  border: none;
  font-size: 13px;
  color: #ffffff;
  font-family: 'nexonBold';
  letter-spacing: -0.33px;
  margin: 0 0 0 29px;
`;

const PayMethodWrap = styled.div`
  display: flex;
  margin: 0 0 17px 0;
`;

const PayMethodItem = styled.div`
  width: 94px;
  height: 51px;
  border: 2px solid #9c9eb9;
  font-size: 13px;
  color: #434343;
  font-family: 'nexonMedium';
  letter-spacing: -0.33px;
  text-align: center;
  padding: 16px 0 0 0;
  position: relative;
  cursor: pointer;

  & + & {
    margin-left: -2px;
  }

  ${(props) =>
    props.active === true &&
    css`
      border: 2px solid ${theme.colors.primary};
      z-index: 1;
    `}
`;

const SelectOptionWrap = styled.div`
  width: 554px;
  margin: 0 0 6px 0;
`;

const RightSection = styled.div`
  width: 340px;
  padding: 19px 17px;
  background-color: white;
  margin: 0 0 0 7px;
  height: 336px;
`;

const AmountBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 17px;

  & + & {
    margin: 25px 0 0 0;
  }
`;

const AmountLabel = styled.p`
  font-size: 16px;
  color: #434343;
  font-family: 'nexonMedium';
  letter-spacing: -0.41px;
`;

const Amount = styled(AmountLabel)`
  text-align: right;

  ${(props) =>
    props.active === true &&
    css`
      color: ${theme.colors.primary};
    `}
`;

const Border = styled.div`
  height: 8px;
  background-color: ${theme.colors.primary};
  width: 100%;
  margin: 25px 0 10px 0;
`;

const PayButton = styled.button`
  width: 100%;
  height: 54px;
  background-color: ${theme.colors.primary};
  border: none;
  font-size: 17px;
  color: #ffffff;
  font-family: 'nexonMedium';
  letter-spacing: -0.33px;
  margin: 70px 0 0 0;
`;

const LocationBoxWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 610px;
`;

const LocationBox = styled.div`
  width: 272px;
  height: 38px;
  border: 3px solid #9c9eb9;
  font-size: 15px;
  color: #d0d2e8;
  font-family: 'nexonRegular';
  letter-spacing: -0.34px;
  padding: 8px 15px;
  margin: 7px 16px;
  ${(props) =>
    props.active === true &&
    css`
      border: 3px solid ${theme.colors.primary};
      color: ${theme.colors.primary};
    `}
`;

const LocationText = styled.p`
  font-size: 15px;
  color: #d0d2e8;
  font-family: 'nexonRegular';
  letter-spacing: -0.34px;
  margin: 0 0 0 20px;

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonMedium';
      color: #434343;
    `}
`;

const RegionPopupWrap = styled.div``;

const RegionPopupTop = styled.div`
  height: 60px;
  background-color: #d0d2e8;
  padding: 0;
  border-radius: 15px 15px 0 0;
  display: flex;
`;

const RegionPopupTopBox = styled.div`
  width: 50%;
  text-align: center;
  font-size: 20px;
  font-family: 'nexonBold';
  padding: 18px 0 0 0;
`;

const RegionPopupMiddle = styled.div`
  padding: 10px 40px;
  display: flex;
  justify-content: space-between;
`;

const CityWrap = styled.div`
  width: 40%;
  height: 460px;
  overflow: hidden;
  overflow-y: scroll;
`;

const CityItem = styled.div`
  font-size: 20px;
  padding: 15px 0;
  cursor: pointer;

  ${(props) =>
    props.active === true &&
    css`
      color: ${theme.colors.primary};
    `}
`;

const CityEng = styled.div`
  color: ${theme.colors.borderLight};
  font-size: 14px;
  margin: 3px 0 0 0;
`;

const PopupButtonWrap = styled.div`
  display: flex;
  position: absolute;
  bottom: 0px;
  width: 100%;
`;

const PopUpButton = styled.button`
  width: 50%;
  height: 60px;
  border-top: 1px solid ${theme.colors.borderLight};
  color: ${theme.colors.primary};
  background-color: transparent;
  font-size: 20px;
  display: block;

  & + & {
    border-left: 1px solid ${theme.colors.borderLight};
  }
`;

const PopUpButtonGray = styled(PopUpButton)`
  color: ${theme.colors.borderLight};
`;

const MapIcon = styled(FaMapMarkerAlt)`
  font-size: 17px;
  position: absolute;
`;

export default PaymentLocalJob;
