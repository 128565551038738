import * as localeKo from './ko';
import * as localeEn from './en';

export const ko = { ...localeKo };
export const en = { ...localeEn };

const locales = {
  ko,
  en,
};
export default locales;
