export const sliceArrayByLimit = (totalPage, limit) => {
  const totalPageArray = Array(totalPage)
    .fill()
    .map((_, i) => i);
  return Array(Math.ceil(totalPage / limit))
    .fill()
    .map(() => totalPageArray?.splice(0, limit));
};

export const formatPayType = (type) => {
  if (type === 'MONTHLY') {
    return 'MONTH';
  } else if (type === 'WEEKLY') {
    return 'WEEK';
  } else if (type === 'DAILY') {
    return 'DAY';
  } else if (type === 'HOURLY') {
    return 'HOUR';
  } else if (type === 'YEARLY') {
    return 'YEAR';
  }
};

export const formatPhoneNumber = (value) => {
  value?.replace(/[^0-9]/g, '');
  value?.replace(/^\d{3}-\d{3,4}-\d{4}$/);
};
