import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';

import { GrFormClose } from 'react-icons/gr';

const PopUp = ({ children, visible, onClose }) => {
  if (!visible) return null;
  return (
    <>
      <DarkBackground />
      <BasicPopUp>
        <ContentsWrap>{children}</ContentsWrap>
      </BasicPopUp>
    </>
  );
};
export default PopUp;

const DarkBackground = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.3;
  z-index: 99;
`;

const PopupContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -200px;
  margin-top: -300px;
  width: 400px;
  height: 600px;
  padding: 0;
  background-color: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 15px;
  z-index: 100;
  ${(props) =>
    props.type === 'success' &&
    css`
      text-align: center;
    `}
`;
const BasicPopUp = styled(PopupContainer)`
  @media ${theme.mobile} {
    padding-bottom: 36px;
  }
`;

const ContentsWrap = styled.div`
  overflow-y: hidden;
  position: relative;
  height: 600px;
`;
