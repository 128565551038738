import userStore from './UserStore';
import countryStore from './CountryStore';
import newsStore from './NewsStore';
import regionsStore from './RegionsStore';
import fileStore from './FileStore';
import resumeStore from './ResumeStore';
import certificationStore from './CertificationStore';
import jobStore from './JobStore';
import favoriteStore from './FavoriteStore';
import advertisementsStore from './AdvertisementsStore';

const stores = {
  userStore,
  countryStore,
  newsStore,
  regionsStore,
  fileStore,
  resumeStore,
  certificationStore,
  jobStore,
  favoriteStore,
  advertisementsStore,
};

export default stores;
