import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import MyPageLayout from 'components/templates/MypageLayout';
import { observer } from 'mobx-react';
import stores from 'stores';
import HiringCard from 'components/modules/HiringCard';
import Pagination from 'components/atoms/Pagination';

const dummy = [
  {
    id: 1,
    jobType: 'Life Job',
    period: '2021.08.01 ~ 2021.10.29',
    companyName: '업체 이름',
    title: '중국인, 베트남 투자자 대상 통역 인력 모집',
    payType: 'MONTHLY',
    pay: 2000000,
  },
  {
    id: 2,
    jobType: 'Life Job',
    period: '2021.08.01 ~ 2021.10.29',
    companyName: '업체 이름',
    title: '중국인, 베트남 투자자 대상 통역 인력 모집',
    payType: 'MONTHLY',
    pay: 2000000,
  },
  {
    id: 3,
    jobType: 'Life Job',
    period: '2021.08.01 ~ 2021.10.29',
    companyName: '업체 이름',
    title: '중국인, 베트남 투자자 대상 통역 인력 모집',
    payType: 'MONTHLY',
    pay: 2000000,
  },
];

const HiringAnnouncement = observer(() => {
  const [focusedId, setFocusedId] = useState(null);
  const handleClickCard = (id) => {
    if (focusedId === id) {
      setFocusedId(null);
    } else {
      setFocusedId(id);
    }
  };
  const [page, setPage] = useState(1);

  return (
    <PageLayout>
      <MyPageLayout>
        <Title>진행중인 공고</Title>
        <Wrap>
          {dummy.map((item) => (
            <HiringCardWrap key={item.id}>
              <HiringCard
                id={item.id}
                jobType={item.jobType}
                period={item.period}
                companyName={item.companyName}
                title={item.title}
                payType={item.payType}
                pay={item.pay}
                focused={focusedId === item.id}
                handleClick={() => handleClickCard(item.id)}
              />
            </HiringCardWrap>
          ))}
          <PaginationWrap>
            <Pagination totalPage={10} limit={5} page={page} setPage={setPage}></Pagination>
          </PaginationWrap>
        </Wrap>
      </MyPageLayout>
    </PageLayout>
  );
});

const Title = styled.div`
  font-size: 18px;
  color: #1f2422;
  font-family: 'nexonMedium';
  letter-spacing: -0.46px;
  margin: 30px 0 0 0;
`;

const Wrap = styled.div`
  background-color: #eef1f5;
  padding: 17px;
  width: 100%;
  margin: 11px 0 0 0;
`;

const HiringCardWrap = styled.div`
  & + & {
    margin: 10px 0 0 0;
  }
`;

const PaginationWrap = styled.div`
  margin: 60px 0 0 0;
`;

export default HiringAnnouncement;
