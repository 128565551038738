import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import { Link } from 'react-router-dom';
import { BiMenu } from 'react-icons/bi';
import { Mobile, Others } from 'styles/MediaQuery';

const MainNav = () => {
  const location = useLocation();

  const [splitPathName, setSplitPathName] = useState([]);
  useEffect(() => {
    setSplitPathName(location.pathname.split('/'));
  }, [location.pathname]);

  return (
    <MainNavWrap>
      <MainNavUl borderNone={location.pathname === '/'}>
        <Others>
          <MenuIcon>
            <BiMenu />
          </MenuIcon>
        </Others>
        <MainNavLi
          active={
            location.pathname === '/recruitinfo' || location.pathname === '/recruitment/detail'
          }
        >
          <Link to="/recruitinfo">채용정보</Link>
        </MainNavLi>
        <MainNavLi active={location.pathname === '/writeResume'}>
          <Link to="/writeResume">이력서작성</Link>
        </MainNavLi>
        <MainNavLi>
          <Link to="/">맞춤인재</Link>
        </MainNavLi>
        <MainNavLi>
          <Link to="/writeAnnouncement">공고작성</Link>
        </MainNavLi>
        <MainNavLi
          active={
            location.pathname === '/news' ||
            `/${splitPathName[1]}/${splitPathName[2]}` === '/news/detail'
          }
        >
          <Link to="/news">뉴스페이지</Link>
        </MainNavLi>
      </MainNavUl>
    </MainNavWrap>
  );
};

const MainNavWrap = styled.header`
  padding: 0 25px;
`;

const MainNavUl = styled.ul`
  width: 1100px;
  padding: 0;
  margin: 28px auto 0;
  display: flex;
  border-bottom: 1px solid #8d8a9b;
  box-sizing: border-box;

  @media ${theme.mobile} {
    width: 100%;
    margin: 28px 0 0 0;
  }

  ${(props) =>
    props.borderNone === true &&
    css`
      border-bottom: none;
    `}
`;

const MenuIcon = styled.div`
  font-size: 30px;
  padding: 8px 20px 0 10px;
`;

const MainNavLi = styled.li`
  font-family: 'nexonMedium';
  width: 100px;
  font-size: 18px;
  text-align: center;
  padding: 13px 0;
  margin: 0 10px;
  cursor: pointer;
  position: relative;
  & > a {
    color: #1f1f1f;
  }

  @media ${theme.mobile} {
    width: 20%;
    margin: 0;
  }

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonMedium';
      border-bottom: 2px solid ${theme.colors.primary};
      z-index: 1;
      color: ${theme.colors.primary};
      & > a {
        color: ${theme.colors.primary};
      }
    `}
`;

export default MainNav;
