import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import Checkbox from 'components/atoms/Checkbox';
import { observer } from 'mobx-react';
import stores from 'stores';
import PopUp from 'components/atoms/PopUp';
import { Mobile, Others } from 'styles/MediaQuery';
import MyPageLayout from 'components/templates/MypageLayout';

const expectedJobCategory = [
  { text: '공장', value: 'FACTORY' },
  { text: '농장', value: 'FARM' },
  { text: '식당', value: 'RESTAURANT' },
  { text: '건설', value: 'CONSTRUCTION' },
  { text: '번역', value: 'TRANSLATION' },
  { text: '사무직', value: 'OFFICE' },
];

const pcExpectedJobCategory = [
  { text: '공장', value: 'FACTORY' },
  { text: '농장', value: 'FARM' },
  { text: '식당', value: 'RESTAURANT' },
  { text: '건설', value: 'CONSTRUCTION' },
  { text: '번역', value: 'TRANSLATION' },
  { text: '사무직', value: 'OFFICE' },
  { text: '기타', value: 'ETC' },
];

const payTypeArray = [
  { text: '시급', value: 'HOURLY' },
  { text: '일급', value: 'DAILY' },
  { text: '주급', value: 'WEEKLY' },
  { text: '월급', value: 'MONTHLY' },
];

const pcPayTypeArray = [
  { text: '시급', value: 'HOURLY' },
  { text: '일급', value: 'DAILY' },
  { text: '주급', value: 'WEEKLY' },
  { text: '월급', value: 'MONTHLY' },
  { text: '연봉', value: 'YEARLY' },
];

const Alarm = observer(() => {
  const { userStore, resumeStore, regionsStore } = stores;

  useEffect(() => {
    userStore.getUsersMe();
    resumeStore.getResumeMe();
  }, [userStore, resumeStore]);

  useEffect(() => {
    regionsStore.getRegions();
  }, [regionsStore]);

  const regionData = regionsStore.regionData.slice(0, 17);
  const regionDataByParentId = regionsStore.regionDataByParentId;

  const [alarmAll, setAlarmAll] = useState(userStore.alarmOn);
  const [newsAlarm, setNewsAlarm] = useState(userStore.alarmNewsOn);
  const [hopeWorkAlarm, setHopeWorkAlarm] = useState(userStore.alarmExpectedJobOn);

  const myResume = resumeStore.myResume;

  useEffect(() => {
    setAlarmAll(userStore.alarmOn);
    setNewsAlarm(userStore.alarmNewsOn);
    setHopeWorkAlarm(userStore.alarmExpectedJobOn);
  }, [userStore.alarmOn, userStore.alarmNewsOn, userStore.alarmExpectedJobOn]);

  const clickedAlarmAll = () => {
    setAlarmAll(!alarmAll);
    userStore.putUsersMe(hopeWorkAlarm, newsAlarm, !alarmAll);
  };

  const clickedAlarmAllTrue = () => {
    setAlarmAll(true);
    userStore.putUsersMe(hopeWorkAlarm, newsAlarm, true);
  };

  const clickedAlarmAllFalse = () => {
    setAlarmAll(false);
    userStore.putUsersMe(hopeWorkAlarm, newsAlarm, false);
  };

  const clickedNewsAlarm = () => {
    setNewsAlarm(!newsAlarm);
    userStore.putUsersMe(hopeWorkAlarm, !newsAlarm, alarmAll);
  };

  const clickedNewsAlarmTrue = () => {
    setNewsAlarm(true);
    userStore.putUsersMe(hopeWorkAlarm, true, alarmAll);
  };

  const clickedNewsAlarmFalse = () => {
    setNewsAlarm(false);
    userStore.putUsersMe(hopeWorkAlarm, false, alarmAll);
  };

  const clicekdHopeWorkAlarm = () => {
    setHopeWorkAlarm(!hopeWorkAlarm);
    userStore.putUsersMe(!hopeWorkAlarm, newsAlarm, alarmAll);
  };

  const clickedHopeWorkAlarmTrue = () => {
    setHopeWorkAlarm(true);
    userStore.putUsersMe(true, newsAlarm, alarmAll);
  };

  const clickedHopeWorkAlarmFalse = () => {
    setHopeWorkAlarm(false);
    userStore.putUsersMe(false, newsAlarm, alarmAll);
  };

  const [noRegion, setNoRegion] = useState(false);

  const handleNoRegion = () => {
    setNoRegion(!noSectors);
  };

  const [sectors, setSectors] = useState('');

  const handleClickSectors = (tab) => {
    setSectors(tab);
  };

  const [noSectors, setNoSectors] = useState(false);

  const handleNoSectors = (e) => {
    setNoSectors(!noSectors);
  };

  const [payType, setPayType] = useState('');

  const handleClickPayType = (tab) => {
    setPayType(tab);
  };

  const [noPayAmount, setNoPayAmount] = useState(false);

  const handleNoPayAmount = (e) => {
    setNoPayAmount(!noPayAmount);
  };

  const [payInputs, setPayInputs] = useState({
    lowest: '',
    highest: '',
  });

  const { lowest, highest } = payInputs;

  const onChangePayInput = (e) => {
    const { name, value } = e.target;
    let payFormatted = `${parseInt(value.replace(/\D/g, '')).toLocaleString()}`;
    if (isNaN(parseInt(value))) {
      setPayInputs({
        ...payInputs,
        [name]: '',
      });
    } else {
      setPayInputs({
        ...payInputs,
        [name]: payFormatted,
      });
      setNoPayAmount(false);
    }
  };

  const [regionsPopupVisible, setRegionsPopupVisible] = useState(false);

  const [selectedSido, setSelectedSido] = useState(myResume?.expectedRegion?.parent?.id);
  const [selectedSidoName, setSelectedSidoName] = useState(myResume?.expectedRegion?.sidoInSummary);

  const handleSelectSido = (id, name, code) => {
    setSelectedSido(id);
    setSelectedSidoName(name);
    setSelectGungu('');
    setSelectGunguName('');
    regionsStore.getRegionsByParentId(id);
  };

  const [selectGungu, setSelectGungu] = useState(myResume?.expectedRegion?.id);
  const [selectGunguName, setSelectGunguName] = useState(myResume?.expectedRegion?.sigungu);

  const handleSelectGungu = (id, name) => {
    setSelectGungu(id);
    setSelectGunguName(name);
  };

  useEffect(() => {
    setSelectedSidoName(myResume?.expectedRegion?.sidoInSummary);
    setSelectedSido(myResume?.expectedRegion?.parent?.id);
    setSelectGungu(myResume?.expectedRegion?.id);
    setSelectGunguName(myResume?.expectedRegion?.sigungu);
    setSectors(myResume?.expectedJobCategory);
    setNoSectors(myResume?.noSelectExpectedJobCategory);
    setPayType(myResume?.expectedPayType);
    setPayInputs({
      lowest: myResume?.expectedMinPayAmount?.toLocaleString(),
      highest: myResume?.expectedMaxPayAmount?.toLocaleString(),
    });
    setNoPayAmount(myResume?.noSelectExpectedPayAmount);
  }, [
    myResume?.expectedRegion?.sidoInSummary,
    myResume?.expectedRegion?.parent?.id,
    myResume?.expectedRegion?.id,
    myResume?.expectedRegion?.sigungu,
    myResume?.expectedJobCategory,
    myResume?.noSelectExpectedJobCategory,
    myResume?.expectedPayType,
    myResume?.expectedMinPayAmount,
    myResume?.expectedMaxPayAmount,
    myResume?.noSelectExpectedPayAmount,
  ]);

  const handleCheckRegionPopup = () => {
    if (!selectGungu) {
      return;
    }
    setRegionsPopupVisible(false);
  };

  const handleCancelRegionPopup = () => {
    setSelectedSido('');
    setSelectGungu('');
    setRegionsPopupVisible(false);
  };

  const handleSubmitExpectedResume = () => {
    const data = {
      expectedRegionId: parseInt(selectGungu),
      expectedJobCategory: sectors,
      noSelectExpectedJobCategory: noSectors,
      expectedMaxPayAmount: parseFloat(lowest.replace(/\D/g, '')),
      expectedMinPayAmount: parseFloat(highest.replace(/\D/g, '')),
      expectedPayType: payType,
      noSelectExpectedPayAmount: noPayAmount,
    };
    resumeStore.postResumeExpectedJob(data);
  };

  return (
    <PageLayout>
      <Mobile>
        <AlarmWrap>
          <AlarmLabel>전체 알림</AlarmLabel>
          <ToggleBtn onClick={clickedAlarmAll} toggle={alarmAll}>
            <Circle toggle={alarmAll} />
          </ToggleBtn>
        </AlarmWrap>
        <Border></Border>
        <AlarmWrap>
          <AlarmLabel>뉴스 알림</AlarmLabel>
          <ToggleBtn onClick={clickedNewsAlarm} toggle={newsAlarm}>
            <Circle toggle={newsAlarm} />
          </ToggleBtn>
        </AlarmWrap>
        <Border></Border>
        <AlarmWrap>
          <AlarmLabel>희망직무 알림</AlarmLabel>{' '}
          <ToggleBtn onClick={clicekdHopeWorkAlarm} toggle={hopeWorkAlarm}>
            <Circle toggle={hopeWorkAlarm} />
          </ToggleBtn>
        </AlarmWrap>
        <ContentWrap>
          <InputItemWrap>
            <Label>지역</Label>
            <SelectBox onClick={() => setRegionsPopupVisible(true)}>
              {selectGunguName
                ? `${selectedSidoName}/${selectGunguName}`
                : selectedSidoName
                ? selectedSidoName
                : '선택'}
            </SelectBox>
          </InputItemWrap>
          <InputItemWrap>
            <Label>업종</Label>
            <SectorsWrap>
              {expectedJobCategory.map((item) => (
                <SectorsTab
                  onClick={() => handleClickSectors(item.value)}
                  active={sectors === item.value}
                  key={item.value}
                >
                  {item.text}
                </SectorsTab>
              ))}
            </SectorsWrap>
            <Checkbox
              key="업종선택"
              defaultValue={true}
              type="checkbox"
              name="sectors"
              handleClick={handleNoSectors}
              isChecked={noSectors === true}
            >
              업종 선택 안함
            </Checkbox>
          </InputItemWrap>
          <InputItemWrap>
            <Label>급여</Label>
            <PayTypeWrap>
              {payTypeArray.map((item) => (
                <PayTypeTab
                  onClick={() => handleClickPayType(item.value)}
                  active={payType === item.value}
                  key={item.value}
                >
                  {item.text}
                </PayTypeTab>
              ))}
            </PayTypeWrap>
            <PayAmountInputWrap>
              <PayAmountInput name="lowest" value={lowest} onChange={onChangePayInput} /> ~
              <PayAmountInput name="highest" value={highest} onChange={onChangePayInput} />
            </PayAmountInputWrap>
          </InputItemWrap>
          <Checkbox
            key="급여선택"
            defaultValue={true}
            type="checkbox"
            name="payAmount"
            handleClick={handleNoPayAmount}
            isChecked={noPayAmount === true}
          >
            급여 선택 안함
          </Checkbox>
          <Button type="button" onClick={handleSubmitExpectedResume}>
            저장하기
          </Button>
        </ContentWrap>
        <PopUp visible={regionsPopupVisible}>
          <RegionPopupWrap>
            <RegionPopupTop>
              <RegionPopupTopBox>시/도</RegionPopupTopBox>
              <RegionPopupTopBox>시/군/구</RegionPopupTopBox>
            </RegionPopupTop>
            <RegionPopupMiddle>
              <CityWrap>
                {regionData.map((item) => (
                  <CityItem
                    key={item.id}
                    onClick={() => handleSelectSido(item.id, item.sidoInSummary, item.sigunguCode)}
                    active={selectedSido === item.id}
                  >
                    {item.sidoInSummary}
                    <CityEng>{item.sidoInEnglish}</CityEng>
                  </CityItem>
                ))}
              </CityWrap>
              <CityWrap>
                {regionDataByParentId.map((item) => (
                  <CityItem
                    key={item.id}
                    onClick={() => handleSelectGungu(item.id, item.sigungu)}
                    active={selectGungu === item.id}
                  >
                    {item.sigungu}
                    <CityEng>{item.sigunguInEnglish}</CityEng>
                  </CityItem>
                ))}
              </CityWrap>
            </RegionPopupMiddle>
          </RegionPopupWrap>
          <PopupButtonWrap>
            <PopUpButtonGray onClick={handleCancelRegionPopup} type="button">
              취소
            </PopUpButtonGray>
            <PopUpButton onClick={handleCheckRegionPopup} type="button">
              확인
            </PopUpButton>
          </PopupButtonWrap>
        </PopUp>
      </Mobile>
      <Others>
        <MyPageLayout>
          <PCTitle>알림 설정</PCTitle>
          <PCAlarmWrap>
            <PCAlarmBox>
              <PCAlarmTitle>전체 알림</PCAlarmTitle>
              <PCToggleAlarm>
                <On active={alarmAll === true} onClick={clickedAlarmAllTrue}>
                  ON
                </On>
                <Off active={alarmAll === false} onClick={clickedAlarmAllFalse}>
                  OFF
                </Off>
              </PCToggleAlarm>
              <PCAlarmDesc>
                희망 채용 정보, 지원현황, 이력서 열람, 뉴스 등의 알림을 표시합니다.
              </PCAlarmDesc>
            </PCAlarmBox>
            <PCAlarmBox>
              <PCAlarmTitle>뉴스 알림</PCAlarmTitle>
              <PCToggleAlarm>
                <On active={newsAlarm === true} onClick={clickedNewsAlarmTrue}>
                  ON
                </On>
                <Off active={newsAlarm === false} onClick={clickedNewsAlarmFalse}>
                  OFF
                </Off>
              </PCToggleAlarm>
              <PCAlarmDesc>새로운 뉴스가 등록될 때 마다 알림을 표시합니다.</PCAlarmDesc>
            </PCAlarmBox>
            <PCAlarmBox>
              <PCAlarmTitle>희망직무 알림</PCAlarmTitle>
              <PCToggleAlarm>
                <On active={hopeWorkAlarm === true} onClick={clickedHopeWorkAlarmTrue}>
                  ON
                </On>
                <Off active={hopeWorkAlarm === false} onClick={clickedHopeWorkAlarmFalse}>
                  OFF
                </Off>
              </PCToggleAlarm>
              <PCAlarmDesc>
                희망직무에 해당하는 채용정보가 등록될 때 마다 알림을 표시합니다.
              </PCAlarmDesc>
            </PCAlarmBox>
          </PCAlarmWrap>
          <PCSmallTitle>희망직무 설정</PCSmallTitle>
          <InputItemWrap>
            <Label>지역</Label>
            <LocationWrap>
              <PCCityWrap>
                {regionData.map((item) => (
                  <PCCityItem
                    key={item.id}
                    onClick={() => handleSelectSido(item.id, item.sigunguCode)}
                    active={selectedSido === item.id}
                  >
                    {item.sidoInSummary}
                  </PCCityItem>
                ))}
              </PCCityWrap>
              <CountryWrap>
                {regionDataByParentId.map((item) => (
                  <CountryItem
                    key={item.id}
                    onClick={() => handleSelectGungu(item.id)}
                    active={String(selectGungu).includes(item.id)}
                  >
                    {item.sigungu}
                  </CountryItem>
                ))}
              </CountryWrap>
            </LocationWrap>
            <Checkbox
              key="지역선택"
              defaultValue={true}
              type="checkbox"
              name="regions"
              handleClick={handleNoRegion}
              isChecked={noRegion === true}
            >
              지역 선택 안함
            </Checkbox>
          </InputItemWrap>
          <InputItemWrap>
            <Label>업종</Label>
            <PCSectorsWrap>
              {pcExpectedJobCategory.map((item) => (
                <PCItemTab
                  onClick={() => handleClickSectors(item.value)}
                  active={sectors === item.value}
                  key={item.value}
                >
                  {item.text}
                </PCItemTab>
              ))}
            </PCSectorsWrap>
            <Checkbox
              key="업종선택"
              defaultValue={true}
              type="checkbox"
              name="sectors"
              handleClick={handleNoSectors}
              isChecked={noSectors === true}
            >
              업종 선택 안함
            </Checkbox>
          </InputItemWrap>
          <InputItemWrap>
            <Label>급여</Label>
            <PayTypeWrap>
              {pcPayTypeArray.map((item) => (
                <PCItemTab
                  onClick={() => handleClickPayType(item.value)}
                  active={payType === item.value}
                  key={item.value}
                >
                  {item.text}
                </PCItemTab>
              ))}
            </PayTypeWrap>
            <PayAmountInputWrap>
              <PayAmountInput
                name="lowest"
                value={lowest}
                type="text"
                onChange={onChangePayInput}
              />{' '}
              <Others>
                <MoneySign>₩</MoneySign>
              </Others>
              <WaveSign>~</WaveSign>
              <PayAmountInput
                name="highest"
                value={highest}
                type="text"
                onChange={onChangePayInput}
              />
              <Others>
                <MoneySign>₩</MoneySign>
              </Others>
            </PayAmountInputWrap>
            <Checkbox
              key="급여선택"
              defaultValue={true}
              type="checkbox"
              name="payAmount"
              handleClick={handleNoPayAmount}
              isChecked={noPayAmount === true}
            >
              급여 선택 안함
            </Checkbox>
          </InputItemWrap>
          <BorderButton type="button" onClick={handleSubmitExpectedResume}>
            저장하기
          </BorderButton>
        </MyPageLayout>
      </Others>
    </PageLayout>
  );
});

const AlarmWrap = styled.div`
  display: flex;
  padding: 32px 42px 37px;
  justify-content: space-between;
`;

const AlarmLabel = styled.p`
  font-family: 'nexonMedium';
  font-size: 22px;
  letter-spacing: -1px;
  color: #313131;
`;

const ToggleBtn = styled.button`
  width: 58px;
  height: 27px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  background-color: ${(props) => (!props.toggle ? 'none' : theme.colors.primary)};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
`;

const Circle = styled.div`
  background-color: white;
  width: 21px;
  height: 21px;
  border-radius: 15px;
  position: absolute;
  left: 5%;
  transition: all 0.5s ease-in-out;
  ${(props) =>
    props.toggle &&
    css`
      transform: translate(30px, 0);
      transition: all 0.5s ease-in-out;
    `}
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.purpleGray};
`;

const ContentWrap = styled.div`
  padding: 0 42px;
`;

const InputItemWrap = styled.div`
  margin: 10px 0 70px 30px;

  @media ${theme.mobile} {
    margin: 10px 0 70px 0;
  }
`;

const Label = styled.p`
  font-family: 'nexonMedium';
  color: #80878e;
  font-size: 20px;
  margin: 10px 0 15px 0;
`;

const SelectBox = styled.div`
  width: 50%;
  height: 53px;
  border: 1px solid ${theme.colors.borderLight};
  color: #80878e;
  font-family: 'nexonMedium';
  font-size: 20px;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
`;

const SectorsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 32px 0;
`;

const SectorsTab = styled(SelectBox)`
  position: relative;

  &:nth-child(2n) {
    margin-left: -1px;
  }

  &:nth-child(n + 1) {
    margin-top: -1px;
  }

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonMedium';
      border: 1px solid ${theme.colors.primary};
      z-index: 1;
      color: ${theme.colors.primary};
    `}
`;

const PayTypeWrap = styled.div`
  display: flex;
  width: 425px;
  border: 1px solid ${theme.colors.border};
  border-radius: 10px;
  padding: 22px 25px;

  @media ${theme.mobile} {
    flex-wrap: wrap;
    margin: 0 0 32px 0;
    width: 100%;
    border: none;
    padding: 0;
    margin: 0 0 17px 0;
  }
`;

const PayTypeTab = styled.div`
  width: 25%;
  height: 53px;
  border: 1px solid ${theme.colors.borderLight};
  color: #80878e;
  font-family: 'nexonMedium';
  font-size: 20px;
  text-align: center;
  padding: 15px 0;
  position: relative;
  cursor: pointer;

  & + & {
    margin-left: -1px;
  }

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonMedium';
      border: 1px solid ${theme.colors.primary};
      z-index: 1;
      color: ${theme.colors.primary};
    `}
`;

const PayAmountInputWrap = styled.div`
  font-family: 'nexonRegular';
  display: flex;
  justify-content: space-between;
  font-size: 21px;
  margin: 0 0 0 0;
  width: 450px;
  margin: 22px 0 20px 0;

  @media ${theme.mobile} {
    width: 100%;
    margin: 55px 0 50px 0;
  }
`;

const PayAmountInput = styled.input`
  border: none;
  border: 1px solid ${theme.colors.borderLight};
  border-radius: 10px;
  padding: 6px 3px;
  width: 180px;
  font-size: 17px;
  font-family: 'nexonRegular';
  color: #313131;
  height: 46px;

  @media ${theme.mobile} {
    height: 40px;
    border: none;
    border-radius: 0px;
    width: 205px;
    font-size: 21px;
    border-bottom: 1px solid ${theme.colors.borderLight};
  }
`;

const Button = styled.button`
  font-family: 'nexonBold';
  width: 100%;
  height: 67px;
  background-color: ${theme.colors.primary};
  color: white;
  font-size: 25px;
  letter-spacing: -1.25px;
  margin: 63px 0 0 0;
`;

const RegionPopupWrap = styled.div``;

const RegionPopupTop = styled.div`
  height: 60px;
  background-color: #d0d2e8;
  padding: 0;
  border-radius: 15px 15px 0 0;
  display: flex;
`;

const RegionPopupTopBox = styled.div`
  width: 50%;
  text-align: center;
  font-size: 20px;
  font-family: 'nexonBold';
  padding: 18px 0 0 0;
`;

const RegionPopupMiddle = styled.div`
  padding: 10px 40px;
  display: flex;
  justify-content: space-between;
`;

const CityWrap = styled.div`
  width: 40%;
  height: 460px;
  overflow: hidden;
  overflow-y: scroll;
`;

const CityItem = styled.div`
  font-size: 20px;
  padding: 15px 0;
  cursor: pointer;

  ${(props) =>
    props.active === true &&
    css`
      color: ${theme.colors.primary};
    `}
`;

const CityEng = styled.div`
  color: ${theme.colors.borderLight};
  font-size: 14px;
  margin: 3px 0 0 0;
`;

const PopupButtonWrap = styled.div`
  display: flex;
  position: absolute;
  bottom: 0px;
  width: 100%;
`;

const PopUpButton = styled.button`
  width: 50%;
  height: 60px;
  border-top: 1px solid ${theme.colors.borderLight};
  color: ${theme.colors.primary};
  background-color: transparent;
  font-size: 20px;
  display: block;

  & + & {
    border-left: 1px solid ${theme.colors.borderLight};
  }
`;

const PopUpButtonGray = styled(PopUpButton)`
  color: ${theme.colors.borderLight};
`;

const PCTitle = styled.div`
  font-size: 18px;
  color: #1f2422;
  font-family: 'nexonMedium';
  letter-spacing: -0.46px;
  margin: 30px 0 0 0;
`;

const PCAlarmWrap = styled.div`
  width: 100%;
  background-color: #eef1f5;
  padding: 30px;
  margin: 9px 0 0 0;
`;

const PCAlarmBox = styled.div`
  display: flex;
  align-items: center;

  & + & {
    margin: 38px 0 0 0;
  }
`;

const PCAlarmTitle = styled.p`
  font-size: 16px;
  color: #313131;
  font-family: 'nexonMedium';
  letter-spacing: -0.4px;
  width: 105px;
`;

const PCToggleAlarm = styled.div`
  width: 128px;
  height: 38px;
  border: 1px solid #9292a1;
  border-radius: 5px;
  display: flex;
  margin: 0 24px 0 0;
`;

const On = styled.button`
  font-size: 15px;
  color: #313131;
  font-family: 'nexonMedium';
  letter-spacing: -0.38px;
  width: 50%;
  text-align: center;
  background-color: #e8e8f6;
  border-right: 1px solid #9292a1;
  border-radius: 5px 0 0 5px;

  ${(props) =>
    props.active &&
    css`
      background-color: #ffffff;
      color: ${theme.colors.primary};
    `}
`;

const Off = styled(On)`
  border-right: none;
  border-radius: 0 5px 5px 0;
`;

const PCAlarmDesc = styled.p`
  font-size: 14px;
  color: #2d3130;
  font-family: 'nexonRegular';
  letter-spacing: -0.35px;
`;

const PCSmallTitle = styled.div`
  font-size: 16px;
  color: #1f2422;
  font-family: 'nexonMedium';
  letter-spacing: -0.46px;
  margin: 38px 0 30px 10px;
`;

const LocationWrap = styled.div`
  border: 1px solid ${theme.colors.border};
  width: 730px;
  height: 202px;
  display: flex;
  border-radius: 10px;
  margin: 0 0 15px 0;
`;
const PCCityWrap = styled.div`
  width: 310px;
  border-right: 1px solid ${theme.colors.borderLight};
  display: flex;
  flex-wrap: wrap;
  height: 202px;
  padding: 10px 20px 0;
  overflow-y: scroll;
`;

const PCCityItem = styled.div`
  font-family: 'nexonRegular';
  font-size: 17px;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
  width: 33%;

  ${(props) =>
    props.active === true &&
    css`
      color: ${theme.colors.primary};
    `}
`;

const CountryWrap = styled.div`
  width: 440px;
  height: 202px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 30px 0;
  justify-content: space-between;
  align-content: flex-start;
`;

const CountryItem = styled.div`
  font-family: 'nexonRegular';
  font-size: 17px;
  text-align: center;
  margin: 15px 0;
  width: 33.33%;
  height: 19px;
  cursor: pointer;

  ${(props) =>
    props.active === true &&
    css`
      color: ${theme.colors.primary};
    `}
`;

const PCSectorsWrap = styled.div`
  display: flex;
  width: 570px;
  border: 1px solid ${theme.colors.border};
  border-radius: 10px;
  padding: 22px;
  margin: 0 0 17px 0;
`;

const PCItemTab = styled.div`
  font-size: 17px;
  color: #313131;
  border: 1px solid ${theme.colors.lightPrimary};
  border-radius: 20px;
  padding: 5px 15px;
  cursor: pointer;

  ${(props) =>
    props.active === true &&
    css`
      border: 1px solid ${theme.colors.primary};
      color: ${theme.colors.primary};
    `}

  & + & {
    margin-left: 10px;
  }
`;

const MoneySign = styled.p`
  padding: 10px 0 0 0;
  margin: 0 0 0 5px;
`;

const WaveSign = styled.p`
  padding: 10px 0 0 0;
  margin: 0 20px;

  @media ${theme.mobile} {
    margin: 0;
  }
`;

const BorderButton = styled.button`
  font-family: 'nexonMedium';
  width: 236px;
  height: 60px;
  background-color: white;
  border: 2px solid ${theme.colors.primary};
  color: #2d3130;
  font-size: 19px;
  letter-spacing: -0.48px;
  border-radius: 5px;
  display: block;
  margin: 0 auto;
`;

export default Alarm;
