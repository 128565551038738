import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import theme from 'styles/theme';

const Checkbox = ({
  defaultValue,
  id,
  type,
  name,
  handleClick,
  isChecked,
  children,
  warning,
  ...rest
}) => {
  return (
    <CheckboxWrapper>
      <CheckBoxLabel>
        <CheckBoxInput
          defaultValue={defaultValue}
          id={id}
          name={name}
          type={type}
          onChange={handleClick}
          checked={isChecked}
        />
        <CheckBoxIcon checked={isChecked} warning={warning}>
          <div></div>
        </CheckBoxIcon>
        {children ? <CheckBoxText>{children}</CheckBoxText> : <></>}
      </CheckBoxLabel>
    </CheckboxWrapper>
  );
};

const wave = keyframes`
  50% {
    transform: scale(0.9);
  }
`;

const CheckBoxSpan = styled.span`
  display: inline-block;
  transform: translate3d(0, 0, 0);
`;

const CheckBoxIcon = styled(CheckBoxSpan)`
  position: relative;
  width: 22px;
  height: 22px;
  margin: 2px 0 0 0;
  border: 2px solid #80878e;
  border-radius: 20px;
  vertical-align: middle;
  transition: all 0.2s ease;
  & > div {
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${theme.colors.primary};
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
  }
  ${(props) =>
    props.checked === true &&
    css`
      border: 2px solid ${theme.colors.primary};
      animation: ${wave} 0.4s ease;
      & > div {
        position: absolute;
        top: 4px;
        left: 4px;
        width: 14px;
        height: 14px;
        border-radius: 15px;
        background-color: ${theme.colors.primary};
      }
      &:before {
        transform: scale(2);
        opacity: 0;
        transition: all 0.6s ease;
      }
    `}
`;

const CheckBoxLabel = styled.label`
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  font-weight: 400;
  position: relative;
  display: flex;
`;

const CheckBoxText = styled(CheckBoxSpan)`
  display: flex;
  align-items: center;
  margin: 3px 0 0 8px;
  color: #80878e;
  font-size: 19px;
  letter-spacing: -0.9px;
  font-family: 'nexonMedium';
`;

const CheckBoxInput = styled.input`
  display: none;
`;

const CheckboxWrapper = styled.div`
  & + & {
    margin-top: 30px;
  }
`;

export default Checkbox;
