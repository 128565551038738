import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import { formatPayType } from 'components/utils/useful-function';

const PremiumJobCard = ({ image, company, title, address, pay, payType, logoImg }) => {
  return (
    <Wrap>
      <Photo>
        <img src={image} alt="프리미엄-잡-이미지" />
      </Photo>
      <CompanyInfoWrap>
        <CompanyIcon>
          <img src={logoImg} alt="로고-이미지" />
        </CompanyIcon>
        <CompanyName>{company}</CompanyName>
      </CompanyInfoWrap>
      <Title>{title}</Title>
      <EngAddress>{address}</EngAddress>
      <PayWrap>
        <PayBadge>{formatPayType(payType)}</PayBadge>
        <Pay>{pay.toLocaleString()}₩</Pay>
      </PayWrap>
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 500px;
  @media ${theme.mobile} {
    width: 252px;
    margin: 0 0 10px 0;
  }
`;

const Photo = styled.div`
  width: 100%;
  height: 260px;
  background-color: #eee;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media ${theme.mobile} {
    height: 130px;
  }
`;

const CompanyInfoWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0 10px 0;
`;

const CompanyIcon = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 30px;
  background-color: #ffffff;
  overflow: hidden;
  @media ${theme.mobile} {
    width: 33px;
    height: 33px;
    border-radius: 20px;
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CompanyName = styled.p`
  font-family: 'nexonBold';
  color: #555666;
  font-size: 30px;
  letter-spacing: -1.1px;
  margin: 0 0 0 8px;
  @media ${theme.mobile} {
    font-size: 22px;
    letter-spacing: -1.1px;
  }
`;

const Title = styled.p`
  font-family: 'nexonMedium';
  color: #1f2422;
  font-size: 20px;
  letter-spacing: -0.7px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media ${theme.mobile} {
    font-size: 14px;
  }
`;

const EngAddress = styled.p`
  font-family: 'nexonMedium';
  color: #80878e;
  font-size: 20px;
  letter-spacing: -0.7px;
  margin: 12px 0;
  @media ${theme.mobile} {
    font-size: 14px;
    margin: 9px 0;
  }
`;

const PayWrap = styled.div`
  display: flex;
`;

const PayBadge = styled.div`
  font-family: 'nexonMedium';
  color: #ff419f;
  font-size: 16px;
  letter-spacing: -0.5px;
  border: 2px solid #ff419f;
  padding: 2px 7px;
  border-radius: 20px;
  @media ${theme.mobile} {
    font-size: 11px;
  }
`;

const Pay = styled.p`
  font-family: 'nexonBold';
  color: #1c1c1c;
  font-size: 24px;
  letter-spacing: -0.7px;
  margin: 0 0 0 6px;
  @media ${theme.mobile} {
    font-size: 15px;
  }
`;

export default PremiumJobCard;
