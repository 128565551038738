import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import MainNav from 'components/templates/MainNav';
import SmallNewsCard from 'components/modules/SmallNewsCard';
import Pagination from 'components/atoms/Pagination';
import { observer } from 'mobx-react';
import stores from 'stores';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import 'resources/css/slick.css';
import 'resources/css/slick-theme.css';
import { Mobile, Others } from 'styles/MediaQuery';

const News = observer(() => {
  const { newsStore } = stores;

  const [page, setPage] = useState(1);
  const listPerPage = 9;

  useEffect(() => {
    newsStore.getNewsBanner();
    newsStore.getNewsMain();
    newsStore.getNews(page - 1, listPerPage);
  }, [newsStore, page]);

  const newsBanners = newsStore.newsBanners;
  const newsMain = newsStore.newsMain;
  const newsData = newsStore.newsData;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handlePostClicks = (id) => {
    newsStore.postNewsBannersClicks(id);
  };

  return (
    <PageLayout>
      <MainNav />
      <NewsMainWrap>
        <Slider {...settings}>
          {newsBanners.map((item) => (
            <Link
              to={{ pathname: `/news/detail/${item.id}` }}
              onClick={() => handlePostClicks(item.id)}
            >
              <MainImgWrap>
                <img src={item.imageUrl} alt="기사 사진" />
                <MainImgTitle>{item.title}</MainImgTitle>
              </MainImgWrap>
            </Link>
          ))}
        </Slider>
        <NewsBoxWrap>
          <Title>오늘의 소식</Title>
          <BigNewsCard>
            <Photo>
              <img src={newsMain.imageUrl} alt="오늘의소식이미지" />
            </Photo>
            <BigNewsCardText>
              <BigNewsCardTitle>{newsMain.title}</BigNewsCardTitle>
              <BigNewsCardInfo>{newsMain.company} ㅣ 정회성 기자</BigNewsCardInfo>
              <BigNewsCardDesc>{newsMain.contents}</BigNewsCardDesc>
            </BigNewsCardText>
          </BigNewsCard>
        </NewsBoxWrap>
        <NewsBoxWrap>
          <Title>뉴스 더보기</Title>
          <SmallNewsCardWrap>
            {newsData.map((item) => (
              <Link to={{ pathname: `/news/detail/${item.id}` }}>
                <SmallNewsCard
                  image={item.imageUrl}
                  title={item.title}
                  days={item.pastDays}
                  view={item.hitCount}
                  key={item.id}
                />
              </Link>
            ))}
          </SmallNewsCardWrap>
        </NewsBoxWrap>
        <Mobile>
          <Pagination
            totalPage={newsStore.totalNews / 5}
            limit={5}
            page={page}
            setPage={setPage}
          ></Pagination>
        </Mobile>
        <Others>
          <Pagination
            totalPage={newsStore.totalNews / 5}
            limit={10}
            page={page}
            setPage={setPage}
          ></Pagination>
        </Others>
      </NewsMainWrap>
    </PageLayout>
  );
});

const NewsMainWrap = styled.div`
  width: 1040px;
  margin: 0 auto;
  @media ${theme.mobile} {
    width: 100%;
  }
`;

const MainImgWrap = styled.div`
  width: 100%;
  height: 340px;
  background-color: #eee;
  margin: 13px 0 0 0;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media ${theme.mobile} {
    border-radius: 0px;
  }
`;

const MainImgTitle = styled.p`
  font-family: 'nexonBold';
  font-size: 28px;
  color: #fff;
  padding: 33px 35px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
`;

const NewsBoxWrap = styled.div`
  margin: 60px 0 80px;

  @media ${theme.mobile} {
    margin: 28px 20px 70px;
  }
`;

const Title = styled.p`
  font-family: 'nexonMedium';
  font-size: 20px;
  border-bottom: 2px solid ${theme.colors.primary};
  width: 180px;
  padding: 7px 7px;
  display: inline-block;

  @media ${theme.mobile} {
    border-radius: 0px;
    width: auto;
    border-bottom: 4px solid ${theme.colors.primary};
  }
`;

const BigNewsCard = styled.div`
  margin: 33px 10px 0;
  display: flex;
`;

const Photo = styled.div`
  width: 445px;
  height: 350px;
  background-color: #eee;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media ${theme.mobile} {
    width: 255px;
    height: 200px;
  }
`;

const BigNewsCardText = styled.div`
  width: 560px;
  margin: 0 0 0 15px;

  @media ${theme.mobile} {
    width: 240px;
  }
`;

const BigNewsCardTitle = styled.p`
  font-family: 'nexonMedium';
  font-size: 22px;
  letter-spacing: -1px;
  color: #424547;
  margin: 0 0 24px 0;
`;

const BigNewsCardInfo = styled.p`
  font-family: 'nexonRegular';
  font-size: 16px;
  letter-spacing: -0.4px;
  color: #80878e;
  margin: 0 0 40px 0;

  @media ${theme.mobile} {
    margin: 0 0 10px 0;
  }
`;

const BigNewsCardDesc = styled.p`
  font-family: 'nexonRegular';
  font-size: 16px;
  letter-spacing: -0.4px;
  color: #000000;
  line-height: 30px;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media ${theme.mobile} {
    -webkit-line-clamp: 3;
    color: #80878e;
    line-height: 24px;
  }
`;

const SmallNewsCardWrap = styled.div`
  margin: 25px 0 0 0;
  display: flex;
  flex-wrap: wrap;

  @media ${theme.mobile} {
    -webkit-line-clamp: 3;
    color: #80878e;
    line-height: 24px;
  }

  @media ${theme.mobile} {
    display: block;
  }
`;

export default News;
