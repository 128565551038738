import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import { formatPayType } from 'components/utils/useful-function';

const GrandJobCard = ({ company, title, address, pay, payType, logoImg }) => {
  return (
    <Wrap>
      <CompanyInfoWrap>
        <CompanyIcon>
          <img src={logoImg} alt="로고-이미지" />
        </CompanyIcon>
        <CompanyName>{company}</CompanyName>
      </CompanyInfoWrap>
      <Title>{title}</Title>
      <EngAddress>{address}</EngAddress>
      <PayWrap>
        <PayBadge>{formatPayType(payType)}</PayBadge>
        <Pay>{pay.toLocaleString()}₩</Pay>
      </PayWrap>
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 330px;
  border: 1px solid ${theme.colors.border};
  border-radius: 5px;
  padding: 14px 18px 20px;
  @media ${theme.mobile} {
    width: 252px;
    margin: 0 0 10px 0;
    padding: 10px;
  }
`;

const CompanyInfoWrap = styled.div`
  margin: 0 auto;
  width: 100px;

  @media ${theme.mobile} {
    width: 74px;
  }
`;

const CompanyIcon = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  background-color: #ffffff;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media ${theme.mobile} {
    width: 74px;
    height: 74px;
  }
`;

const CompanyName = styled.p`
  font-family: 'nexonBold';
  color: #555666;
  font-size: 20px;
  letter-spacing: -1.1px;
  text-align: center;
  margin: 5px 0 0 0;

  @media ${theme.mobile} {
    font-size: 15px;
  }
`;

const Title = styled.p`
  font-family: 'nexonMedium';
  color: #1f2422;
  font-size: 18px;
  letter-spacing: -0.7px;
  margin: 22px 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media ${theme.mobile} {
    font-size: 14px;
    margin: 17px 0 0 0;
  }
`;

const EngAddress = styled.p`
  font-family: 'nexonMedium';
  color: #80878e;
  font-size: 18px;
  letter-spacing: -0.7px;
  margin: 11px 0;
  @media ${theme.mobile} {
    font-size: 14px;
    margin: 9px 0;
  }
`;

const PayWrap = styled.div`
  display: flex;
`;

const PayBadge = styled.div`
  font-family: 'nexonMedium';
  color: #ff419f;
  font-size: 18px;
  letter-spacing: -0.5px;
  border: 2px solid #ff419f;
  padding: 4px 7px;
  border-radius: 18px;
  @media ${theme.mobile} {
    font-size: 13px;
    padding: 2px 7px;
    border-radius: 19px;
  }
`;

const Pay = styled.p`
  font-family: 'nexonBold';
  color: #1c1c1c;
  font-size: 24px;
  letter-spacing: -0.7px;
  margin: 2px 0 0 6px;
  @media ${theme.mobile} {
    font-size: 17px;
    margin: 2px 0 0 6px;
  }
`;

export default GrandJobCard;
