import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import stores from 'stores';

const KakaoLogin = observer(() => {
  // https://data-jj.tistory.com/53
  const { userStore } = stores;
  const PARAMS = new URL(document.URL).searchParams;
  const KAKAO_CODE = PARAMS.get('code');
  const navigate = useNavigate();

  const getKakaoProfile = (token) => {
    window.Kakao.API.request({
      url: '/v2/user/me',
    })
      .then(function (response) {
        console.log(response);
        const body = {
          authType: 'KAKAO',
          email: '',
          name: '',
          role: 'EMPLOYEE',
          thirdPartyAccessToken: token,
          thirdPartyUserId: response.id,
        };
        userStore.signInUsers(body);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getKakaoToken = () => {
    fetch(`https://kauth.kakao.com/oauth/token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `grant_type=authorization_code&client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&code=${KAKAO_CODE}`,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.access_token) {
          localStorage.setItem('kakaoToken', data.access_token);
          // Kakao Javascript SDK 초기화
          window.Kakao.init(process.env.REACT_APP_KAKAO_REST_API_KEY);
          // access token 설정
          window.Kakao.Auth.setAccessToken(data.access_token);
          getKakaoProfile(data.access_token);
        } else {
          //   navigate('/main');
        }
      });
  };

  useEffect(() => {
    // if (!PARAMS) return;
    getKakaoToken();
  }, []);

  return <div>{KAKAO_CODE}</div>;
});

export default KakaoLogin;
