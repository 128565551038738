import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import { Mobile, Others } from 'styles/MediaQuery';

const Footer = () => {
  return (
    <Wrap>
      <ContentWrap>
        <Logo>KO-LIFE</Logo>
        <Text>(주) 아웃랜더 OUTLANDER | 사업자 등록 번호: 492-87-02358</Text>
        <Text>대표자: 윤미혜 | 통신판매업신고번호: 제 2022-충북충주-0314호</Text>
        <Text>대표전화: 1660-1656 | 직업정보제공사업신고번호: J1712020220001</Text>
        <Text>홈페이지: www.ko-life.co.kr | 개인정보보호책임자: 김준규 | 이메일: seoul@naver.com</Text>
        <Text>본점: 충청북도 충주시 충원대로 268 건국대학교 창업보육센터 407호</Text>
        <Text>지점: 서울특별시 금천구 가산디지털1로 120 G밸리 창업규브 813호</Text>
      </ContentWrap>
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 100%;
  background-color: #d1d5da;
  height: 320px;
  /* position: absolute; */
  /* bottom: 0; */
  margin: 200px 0 0 0;
  @media ${theme.mobile} {
    height: 235px;
  }
`;

const ContentWrap = styled.div`
  width: 1100px;
  margin: 0 auto;
  padding: 40px 0 0 0;
  @media ${theme.mobile} {
    padding: 20px 0 0 50px;
  }
`;

const Logo = styled.h1`
  color: white;
  font-size: 22px;
  margin: 0 0 32px 0;
  @media ${theme.mobile} {
    font-size: 15px;
  }
`;

const Text = styled.p`
  color: #6a697a;
  font-size: 17px;
  margin: 0 0 10px 0;
  @media ${theme.mobile} {
    font-size: 9px;
  }
`;

export default Footer;
