import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import { BsFillPersonFill } from 'react-icons/bs';

const ApplyCard = () => {
  return (
    <Wrap>
      <Reading>미열람</Reading>
      <Photo>
        <PersonIcon />
      </Photo>
      <National>베트남</National>
      <Border></Border>
      <Name>찐 티프엉</Name>
      <Border></Border>
      <Gender>여</Gender>
      <Border></Border>
      <Birth>93년생</Birth>
      <Border></Border>
      <Date>지원 날짜 : 2021.08.17</Date>
      <Button>이력서 확인</Button>
    </Wrap>
  );
};

export default ApplyCard;

const Wrap = styled.div`
  padding: 5px 10px;
  height: 63px;
  display: flex;
  position: relative;
  align-items: center;
  border-top: 1px solid ${theme.colors.purpleGray};
`;

const Reading = styled.p`
  color: ${theme.colors.primary};
  font-size: 12px;
  font-family: 'nexonMedium';
  letter-spacing: -0.31px;
  border: 2px solid ${theme.colors.primary};
  padding: 3px 6px;
  border-radius: 20px;
  margin: 0 26px 0 10px;
`;

const Photo = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 30px;
  background-color: #d4cae3;
  overflow: hidden;
`;

const PersonIcon = styled(BsFillPersonFill)`
  color: white;
  font-size: 40px;
  padding: 5px 0 0 5px;
`;

const National = styled.p`
  font-size: 13px;
  font-family: 'nexonRegular';
  letter-spacing: -0.34px;
  margin: 0 22px 0 26px;
`;

const Border = styled.div`
  width: 1px;
  background-color: ${theme.colors.purpleGray};
  height: 53px;
`;

const Name = styled.p`
  font-size: 13px;
  font-family: 'nexonRegular';
  letter-spacing: -0.34px;
  margin: 0 10px 0 30px;
  width: 100px;
`;

const Gender = styled.p`
  font-size: 13px;
  font-family: 'nexonRegular';
  letter-spacing: -0.34px;
  margin: 0 28px;
`;

const Birth = styled.p`
  font-size: 13px;
  font-family: 'nexonRegular';
  letter-spacing: -0.34px;
  margin: 0 18px;
`;
const Date = styled.p`
  font-size: 13px;
  font-family: 'nexonRegular';
  letter-spacing: -0.34px;
  margin: 0 27px 0 17px; ;
`;

const Button = styled.button`
  font-family: 'nexonBold';
  font-size: 14px;
  border: 1px solid black;
  width: 97px;
  height: 33px;
  background-color: transparent;
  border-radius: 30px;
`;
