import FindPassword from 'components/pages/Sign/FindPassword';
import SignUp from 'components/pages/Sign/SignUp';
import SignIn from 'components/pages/Sign/SignIn';
import Language from 'components/pages/MyPage/Language';
import Favorites from 'components/pages/MyPage/Favorites';
import ApplyStatus from 'components/pages/MyPage/ApplyStatus';
import ModifyResume from 'components/pages/MyPage/ModifyResume';
import Alarm from 'components/pages/MyPage/Alarm';
import News from 'components/pages/Main/News';
import NewsDetail from 'components/pages/Main/NewsDetail';
import RecruitInfo from 'components/pages/Main/RecruitInfo';
import WriteResume from 'components/pages/Main/WriteResume';
import RecruitmentDetail from 'components/pages/Main/RecruitmentDetail';
import Main from 'components/pages/Main/Main';
import KakaoLogin from 'components/pages/Sign/KakaoLogin';
import SignInEmployer from 'components/pages/Sign/SignInEmployer';
import SignInEmployee from 'components/pages/Sign/SignInEmployee';
import WriteAnnouncement from 'components/pages/Main/WriteAnnouncement';
import ProposalEmployment from 'components/pages/Main/ProposalEmployment';
import ShowResume from 'components/pages/Main/ShowResume';
import HiringAnnouncement from 'components/pages/EmployementPage/HiringAnnouncement';
import CloseAnnouncement from 'components/pages/EmployementPage/CloseAnnouncement';
import PaymentHistory from 'components/pages/EmployementPage/PaymentHistory';
import Payment from 'components/pages/EmployementPage/Payment';
import PaymentLocalJob from 'components/pages/EmployementPage/PaymentLocalJob';
import AnnouncementType from 'components/pages/Main/AnnouncementType';
import Landing from 'components/pages/Landing/Landing';

export const routes = [
  { path: '/findPassword', element: <FindPassword /> },
  { path: '/signUp', element: <SignUp /> },
  { path: '/signIn', element: <SignIn /> },
  { path: '/signIn/employer', element: <SignInEmployer /> },
  { path: '/signIn/employee', element: <SignInEmployee /> },
  { path: '/mypage/language', element: <Language /> },
  { path: '/mypage/favorites', element: <Favorites /> },
  { path: '/mypage/applyStatus', element: <ApplyStatus /> },
  { path: '/mypage/modifyResume', element: <ModifyResume /> },
  { path: '/mypage/alarm', element: <Alarm /> },
  { path: '/news', element: <News /> },
  { path: '/news/detail/:id', element: <NewsDetail /> },
  { path: '/recruitInfo', element: <RecruitInfo /> },
  { path: '/writeResume', element: <WriteResume /> },
  { path: '/recruitment/detail/:id', element: <RecruitmentDetail /> },
  // { path: '/', element: <Main /> },
  { path: '/', element: <Landing /> },
  { path: '/kakaoLogin', element: <KakaoLogin /> },
  { path: '/writeAnnouncement', element: <WriteAnnouncement /> },
  { path: '/proposalEmployment', element: <ProposalEmployment /> },
  { path: '/showResume', element: <ShowResume /> },
  { path: '/employmentPage/hiringAnnouncement', element: <HiringAnnouncement /> },
  { path: '/employmentPage/closeAnnouncement', element: <CloseAnnouncement /> },
  { path: '/employmentPage/paymenthistory', element: <PaymentHistory /> },
  { path: '/payment', element: <Payment /> },
  { path: '/payment/localJob', element: <PaymentLocalJob /> },
  { path: '/anoouncementType', element: <AnnouncementType /> },
];
