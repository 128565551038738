import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import MainNav from 'components/templates/MainNav';
import { FaMapMarkerAlt, FaHashtag } from 'react-icons/fa';
import { BiSearch } from 'react-icons/bi';
import { MdOutlineChevronRight, MdOutlineChevronLeft } from 'react-icons/md';
import PremiumJobCard from 'components/modules/PremiumJobCard';
import GrandJobCard from 'components/modules/GrandJobCard';
import LocalJobCard from 'components/modules/LocalJobCard';
import { observer } from 'mobx-react';
import stores from 'stores';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Mobile, Others } from 'styles/MediaQuery';

const Main = observer(() => {
  const { advertisementsStore, jobStore } = stores;
  const [page, setPage] = useState(1);
  const listPerPage = 10;

  useEffect(() => {
    jobStore.getEmployeeLocalJobs('LOCAL', '', '', '', 0, 10, '');
    jobStore.getEmployeeGrandJobs('GRAND', '', '', '', 0, 10, '');
    jobStore.getEmployeePremiumJobs('PREMIUM', '', '', '', 0, 10, '');
  }, [jobStore]);

  useEffect(() => {
    advertisementsStore.getAdvertisementsForMain('');
  }, [advertisementsStore]);

  const localJobData = jobStore.localJobData;
  const grandJobData = jobStore.grandJobData;
  const premiunJobData = jobStore.premiunJobData;
  const advertisementsData = advertisementsStore.advertisementsData;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const jobSliderSetting = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    nextArrow: (
      <div>
        <MdOutlineChevronRight />
      </div>
    ),
    prevArrow: (
      <div>
        <MdOutlineChevronLeft />
      </div>
    ),
  };

  return (
    <PageLayout>
      <MainNav />
      <ContentWrap>
        <SearchWrap>
          <SearchTitle>JOB SEARCH</SearchTitle>
          <SearchRight>
            <SearchInputWrap>
              <SearchInput />
              <SearchIcon />
            </SearchInputWrap>
            <TagWrap>
              <Tag>
                <TagIcon>
                  <FaMapMarkerAlt />
                </TagIcon>
                <TagText>Location</TagText>
              </Tag>
              <Tag>
                <TagIcon>
                  <FaHashtag />
                </TagIcon>
                <TagText>Keyword</TagText>
              </Tag>
            </TagWrap>
          </SearchRight>
        </SearchWrap>
        <Mobile>
          <Slider {...settings}>
            {advertisementsData.map((item) => (
              <a href={item.linkUrl} key={item.id}>
                <MobileMainPhoto>
                  <img src={item.imageUrl} alt="광고 사진" />
                </MobileMainPhoto>
              </a>
            ))}
          </Slider>
          <LinkWrap>
            <LinkButton>공고 등록 하기</LinkButton>
            <LinkButton>맞춤 인재 찾기</LinkButton>
            <LinkButton>해외 인재 찾기</LinkButton>
          </LinkWrap>
        </Mobile>
        <Others>
          <MainWrap>
            <MainLeft>
              <Slider {...settings}>
                {advertisementsData.map((item) => (
                  <a href={item.linkUrl} key={item.id}>
                    <MainPhoto>
                      <img src={item.imageUrl} alt="광고 사진" />
                    </MainPhoto>
                  </a>
                ))}
              </Slider>
              <LinkWrap>
                <LinkButton>공고 등록 하기</LinkButton>
                <LinkButton>맞춤 인재 찾기</LinkButton>
                <LinkButton>해외 인재 찾기</LinkButton>
              </LinkWrap>
            </MainLeft>
            <MainRight>
              <LoginBox>
                <LoginTitle>기업 회원</LoginTitle>
                <LoginButton>로그인 하기</LoginButton>
                <TextButtonWrap>
                  <TextButton>회원가입 하기</TextButton>
                  <TextButton>아이디 · 비번 찾기</TextButton>
                </TextButtonWrap>
              </LoginBox>
              <LoginBox>
                <LoginTitle>구직자 회원</LoginTitle>
                <LoginButton>로그인 하기</LoginButton>
                <TextButtonWrap>
                  <TextButton>회원가입 하기</TextButton>
                  <TextButton>아이디 · 비번 찾기</TextButton>
                </TextButtonWrap>
              </LoginBox>
            </MainRight>
          </MainWrap>
        </Others>
        <JobRecruitTitle>현재 진행 중인 공고</JobRecruitTitle>
        <SmallTitle>Premium Job</SmallTitle>
        <Mobile>
          <PremiumJobWrap>
            {premiunJobData.map((item) => (
              <Link to={{ pathname: `/recruitment/detail/${item.id}` }} key={item.id}>
                <PremiumJobCard
                  company={item.companyName}
                  title={item.title}
                  address={item.address}
                  pay={item.payAmount}
                  payType={item.payType}
                  image={item.imageUrl}
                  logoImg={item.logoImageUrl}
                />
              </Link>
            ))}
          </PremiumJobWrap>
        </Mobile>
        <Others>
          <JobCardSliderWrap>
            <JobCardSlider {...jobSliderSetting}>
              {premiunJobData.map((item) => (
                <Premiumjob>
                  <Link to={{ pathname: `/recruitment/detail/${item.id}` }} key={item.id}>
                    <PremiumJobCard
                      company={item.companyName}
                      title={item.title}
                      address={item.address}
                      pay={item.payAmount}
                      payType={item.payType}
                      image={item.imageUrl}
                      logoImg={item.logoImageUrl}
                    />
                  </Link>
                </Premiumjob>
              ))}
            </JobCardSlider>
          </JobCardSliderWrap>
        </Others>
        <SmallTitle>Grand Job</SmallTitle>
        <Mobile>
          <MobileGrandJobWrap>
            {grandJobData.map((item) => (
              <Link to={{ pathname: `/recruitment/detail/${item.id}` }} key={item.id}>
                <GrandJobCard
                  company={item.companyName}
                  title={item.title}
                  address={item.address}
                  pay={item.payAmount}
                  payType={item.payType}
                  logoImg={item.logoImageUrl}
                />
              </Link>
            ))}
          </MobileGrandJobWrap>
        </Mobile>
        <Others>
          <GrandJobWrap>
            {grandJobData.map((item) => (
              <GrandJob>
                <Link to={{ pathname: `/recruitment/detail/${item.id}` }} key={item.id}>
                  <GrandJobCard
                    company={item.companyName}
                    title={item.title}
                    address={item.address}
                    pay={item.payAmount}
                    payType={item.payType}
                    logoImg={item.logoImageUrl}
                  />
                </Link>
              </GrandJob>
            ))}
          </GrandJobWrap>
        </Others>
        <SmallTitle>Local Job</SmallTitle>
        <Mobile>
          <MobileLocalJobWrap>
            {localJobData.map((item) => (
              <Link to={{ pathname: `/recruitment/detail/${item.id}` }} key={item.id}>
                <LocalJobCard
                  company={item.companyName}
                  title={item.title}
                  address={item.address}
                  pay={item.payAmount}
                  payType={item.payType}
                  useSecurePayment={item.useSecurePayment}
                  visaAvailable={item.visaAvailable}
                />
              </Link>
            ))}
          </MobileLocalJobWrap>
        </Mobile>
        <Others>
          <LocalJobWrap>
            {localJobData.map((item) => (
              <LocalJob>
                <Link to={{ pathname: `/recruitment/detail/${item.id}` }} key={item.id}>
                  <LocalJobCard
                    company={item.companyName}
                    title={item.title}
                    address={item.address}
                    pay={item.payAmount}
                    payType={item.payType}
                    useSecurePayment={item.useSecurePayment}
                    visaAvailable={item.visaAvailable}
                  />
                </Link>
              </LocalJob>
            ))}
            <LocalJob>
              <ShowMoreJob>
                공고
                <br />
                더보기
              </ShowMoreJob>
            </LocalJob>
          </LocalJobWrap>
        </Others>
      </ContentWrap>
    </PageLayout>
  );
});

const ContentWrap = styled.div`
  width: 1100px;
  margin: 0 auto;
  @media ${theme.mobile} {
    padding: 0 23px 0;
    width: 100%;
  }
`;

const SearchWrap = styled.div`
  border: 1px solid ${theme.colors.border};
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0 0;
  padding: 30px 60px 15px 30px;

  @media ${theme.mobile} {
    margin: 0;
    padding: 13px 16px;
    border-radius: 5px;
  }
`;

const SearchTitle = styled.p`
  font-family: 'nexonBold';
  color: ${theme.colors.primary};
  font-size: 30px;
  letter-spacing: -1.2px;
  margin: 3px 0 0 0;
  @media ${theme.mobile} {
    font-size: 25px;
  }
`;

const SearchRight = styled.div`
  width: 800px;
  @media ${theme.mobile} {
    width: 315px;
  }
`;

const SearchInputWrap = styled.div`
  position: relative;
`;

const SearchInput = styled.input`
  width: 100%;
  border: 2.5px solid ${theme.colors.primary};
  height: 40px;
  @media ${theme.mobile} {
    height: 34px;
  }
`;

const SearchIcon = styled(BiSearch)`
  position: absolute;
  color: ${theme.colors.primary};
  font-size: 26px;
  right: 8px;
  top: 7px;
  @media ${theme.mobile} {
    right: 4px;
    top: 4px;
  }
`;

const TagWrap = styled.div`
  margin: 20px 0 0 0;
  display: flex;
  @media ${theme.mobile} {
    margin: 11px 0 0 0;
  }
`;

const Tag = styled.div`
  padding: 5px 8px;
  background-color: #e8e8f6;
  border-radius: 20px;
  display: flex;

  & + & {
    margin: 0 0 0 10px;
  }
`;

const TagIcon = styled.div`
  color: ${theme.colors.primary};
  font-size: 16px;
  margin: 1px 3px 0 0;
`;

const TagText = styled.p`
  font-family: 'nexonBold';
  color: #313131;
  font-size: 16px;
  letter-spacing: -0.8px;
`;

const MainWrap = styled.div`
  margin: 20px 0 0 0;
  display: flex;
  justify-content: space-between;
`;

const MainLeft = styled.div`
  width: 740px;
`;

const MainRight = styled.div`
  width: 340px;
  border: 1px solid ${theme.colors.border};
  border-radius: 10px;
`;

const MainPhoto = styled.div`
  width: 100%;
  height: 284px;
  border-radius: 10px;
  background-color: #eee;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const MobileMainPhoto = styled.div`
  width: 100%;
  height: 227px;
  border-radius: 5px;
  background-color: #eee;
  margin: 7px 0 0 0;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const LinkWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0 0;

  @media ${theme.mobile} {
    margin: 5px 0 0 0;
  }
`;

const LinkButton = styled.button`
  width: 232px;
  height: 74px;
  border-radius: 5px;
  background-color: #555666;
  font-family: 'nexonBold';
  color: #fff;
  font-size: 18px;
  letter-spacing: -0.9px;

  @media ${theme.mobile} {
    width: 163px;
    height: 51px;
    font-size: 20px;
  }
`;

const LoginBox = styled.div`
  padding: 24px 0 26px;
  & + & {
    border-top: 1px solid ${theme.colors.border};
  }
`;

const LoginTitle = styled.p`
  font-size: 30px;
  color: #555666;
  font-family: 'nexonBold';
  text-align: center;
`;

const LoginButton = styled.button`
  background-color: transparent;
  border: 2px solid ${theme.colors.primary};
  border-radius: 10px;
  width: 224px;
  height: 54px;
  font-size: 18px;
  color: #403f49;
  font-family: 'nexonBold';
  display: block;
  margin: 24px auto 10px;
`;

const TextButtonWrap = styled.div`
  display: flex;
  padding: 0 0 0 10px;
`;

const TextButton = styled.button`
  font-family: 'nexonRegular';
  font-size: 15px;
  color: #403f49;
  background-color: transparent;
  padding: 0 30px;

  & + & {
    border-left: 1px solid ${theme.colors.border};
  }
`;

const JobRecruitTitle = styled.p`
  font-family: 'nexonBold';
  color: #1f2422;
  font-size: 26px;
  letter-spacing: -1px;
  border-bottom: 1px solid ${theme.colors.border};
  padding: 7px 0;
  text-align: center;
  margin: 100px 0 20px 0;

  @media ${theme.mobile} {
    margin: 40px 0 20px 0;
    font-size: 20px;
  }
`;

const SmallTitle = styled.p`
  font-family: 'nexonRegular';
  font-size: 18px;
  color: #1f2422;
  padding: 0 0 10px 38px;
  @media ${theme.mobile} {
    font-size: 15px;
    padding: 0px;
  }
`;

const PremiumJobWrap = styled.div`
  margin: 8px 0 75px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${theme.mobile} {
    margin: 8px 0 20px 0;
    font-size: 20px;
  }
`;

const Premiumjob = styled.div`
  width: 500px;
  height: 500px;
`;

const MobileGrandJobWrap = styled.div`
  margin: 8px 0 75px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const GrandJob = styled.div`
  & + & {
    margin: 0 0 0 18px;
  }
`;

const GrandJobWrap = styled.div`
  margin: 8px 37px 75px 37px;
  display: flex;
  flex-wrap: wrap;
`;

const MobileLocalJobWrap = styled.div`
  margin: 8px 0 75px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const LocalJob = styled.div`
  & + & {
    margin: 0 0 0 18px;
  }
`;

const LocalJobWrap = styled.div`
  margin: 8px 37px 75px 37px;
  display: flex;
  flex-wrap: wrap;
`;

const ShowMoreJob = styled.div`
  width: 330px;
  height: 204px;
  border: 1px solid ${theme.colors.border};
  border-radius: 5px;
  text-align: center;
  padding: 55px 0 0 0;
  font-family: 'nexonBold';
  font-size: 40px;
  color: #555666;
  cursor: pointer;
`;

const JobCardSliderWrap = styled.div`
  margin: 0 0 0 25px;
`;

const JobCardSlider = styled(Slider)`
  width: 1050px;
  margin: 0 auto;
  .slick-arrow {
    font-size: 30px;
    color: #000;
    top: 120px;
  }

  .slick-prev {
    cursor: pointer;
    left: -35px;
    &::before {
      content: '';
    }
  }

  .slick-next {
    cursor: pointer;
    right: 0px;
    &::before {
      content: '';
    }
  }
`;

export default Main;
