import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import { formatPayType } from 'components/utils/useful-function';
import ApplyCard from './ApplyCard';
import Pagination from 'components/atoms/Pagination';

const HiringCard = ({
  id,
  jobType,
  period,
  companyName,
  title,
  payType,
  pay,
  handleClick,
  focused,
}) => {
  const [page, setPage] = useState(1);

  return (
    <Wrap>
      <CardWrap>
        <LeftSide>
          <JobType>{jobType}</JobType>
          <ExpirationPeriod>유효기간 : {period}</ExpirationPeriod>
        </LeftSide>
        <RightSide>
          <CompanyName>{companyName}</CompanyName>
          <Title>{title}</Title>
          <PayWrap>
            <PayBadge>{formatPayType(payType)}</PayBadge>
            <Pay>{pay.toLocaleString()}₩</Pay>
          </PayWrap>
        </RightSide>
        <ButtonWrap>
          <TextButtonWrap>
            <TextButton>마감</TextButton>
            <TextButton>수정</TextButton>
          </TextButtonWrap>
          <Button onClick={() => handleClick(id)} active={focused}>
            지원자 확인
          </Button>
        </ButtonWrap>
      </CardWrap>
      {focused && (
        <IsOpenWrap>
          <TopSection>
            <TopSectionText>
              총 <span>5명</span>이 지원하였습니다.
            </TopSectionText>
            <TopSectionText>
              미열람 이력서 : <span>4</span>
            </TopSectionText>
          </TopSection>
          <ApplyCardWrap>
            <ApplyCard />
          </ApplyCardWrap>
          <PaginationWrap>
            <Pagination
              totalPage={10}
              limit={5}
              page={page}
              setPage={setPage}
              small={true}
            ></Pagination>
          </PaginationWrap>
        </IsOpenWrap>
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 0 13px;
`;

const CardWrap = styled.div`
  height: 103px;
  display: flex;
  position: relative;
  padding: 11px 0;
`;

const LeftSide = styled.div`
  border-right: 1px solid ${theme.colors.purpleGray};
  padding: 0 22px 0 0;
`;

const JobType = styled.p`
  font-size: 15px;
  color: #424547;
  font-family: 'nexonBold';
  letter-spacing: -0.36px;
  margin: 0 0 26px 0;
`;

const ExpirationPeriod = styled.p`
  font-size: 15px;
  color: #424547;
  font-family: 'nexonMedium';
  letter-spacing: -0.73px;

  & > span {
    color: #ff419f;
  }
`;

const RightSide = styled.div`
  padding: 5px 0 0 26px;
`;

const CompanyName = styled.p`
  font-size: 12px;
  color: #9493a4;
  font-family: 'nexonMedium';
  letter-spacing: -0.3px;
`;

const Title = styled.p`
  font-size: 16px;
  color: #424547;
  font-family: 'nexonMedium';
  letter-spacing: -0.4px;
  margin: 9px 0;
`;

const PayWrap = styled.div`
  display: flex;
  align-items: center;
`;

const PayBadge = styled.div`
  font-family: 'nexonMedium';
  color: #ff419f;
  font-size: 12px;
  letter-spacing: -0.3px;
  border: 2px solid #ff419f;
  padding: 2px 5px;
  height: auto;
  border-radius: 19px;
`;

const Pay = styled.p`
  font-family: 'nexonBold';
  color: #424547;
  font-size: 17px;
  letter-spacing: -0.44px;
  margin: 0 0 0 10px;
`;

const ButtonWrap = styled.div`
  position: absolute;
  right: 25px;
`;

const Button = styled.button`
  border: 2px solid ${theme.colors.primary};
  background-color: transparent;
  border-radius: 5px;
  width: 120px;
  height: 45px;
  color: #434343;
  font-size: 20px;
  font-family: 'nexonBold';
  letter-spacing: -0.46px;
  margin: 30px 0 0 0;

  ${(props) =>
    props.active === true &&
    css`
      color: ${theme.colors.primary};
    `}
`;

const TextButtonWrap = styled.div`
  position: absolute;
  right: 0;
`;

const TextButton = styled.button`
  border: none;
  background-color: transparent;
  color: #434343;
  font-size: 16px;
  font-family: 'nexonMedium';
  letter-spacing: -0.4px;
  padding: 0 14px;

  & + & {
    padding: 0 0 0 14px;
    border-left: 1px solid #d0d2e8;
  }
`;

const IsOpenWrap = styled.div`
  margin: 40px 0 0 0;
  padding: 0 0 22px 0;
`;

const TopSection = styled.div`
  display: flex;
  margin: 0 0 5px 0;
  justify-content: space-between;
`;

const TopSectionText = styled.p`
  color: #424547;
  font-size: 14px;
  font-family: 'nexonMedium';
  letter-spacing: -0.35px;

  & > span {
    color: ${theme.colors.primary};
  }
`;

const ApplyCardWrap = styled.div`
  border-bottom: 1px solid ${theme.colors.purpleGray};
`;

const PaginationWrap = styled.div`
  margin: 20px 0 0 0;
`;

export default HiringCard;
