import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import LabeldInput from 'components/atoms/LabeledInput';

const FindPassword = () => {
  const [inputs, setInputs] = useState({ email: '', name: '' });

  const { email, name } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  return (
    <PageLayout>
      <ContentWrap>
        <Title>비밀번호찾기</Title>
        <Form>
          <LabeldInput
            label="이메일 입력"
            name="email"
            value={email}
            placeholder="사용하시는 이메일을 입력해주세요."
            onChange={onChange}
          />
          <LabeldInput
            label="이름"
            name="name"
            value={name}
            placeholder="성함을 입력해주세요."
            onChange={onChange}
          />
          <Button>비밀번호 찾기</Button>
        </Form>
      </ContentWrap>
    </PageLayout>
  );
};

const ContentWrap = styled.div`
  padding: 0 30px;
`;

const Title = styled.div`
  font-size: 35px;
  font-family: 'nexonBold';
  text-align: center;
  margin: 140px 0 65px 0;
  @media ${theme.mobile} {
    font-size: 25px;
    margin: 60px 0 65px 0;
  }
`;

const Form = styled.form`
  border: 1px solid ${theme.colors.border};
  width: 900px;
  margin: 0 auto;
  padding: 90px 80px 100px;
  border-radius: 10px;
  @media ${theme.mobile} {
    border: 0;
    width: 100%;
    padding: 0;
  }
`;

const Button = styled.button`
  font-family: 'nexonMedium';
  border: none;
  width: 372px;
  height: 68px;
  background-color: ${theme.colors.primary};
  margin: 120px auto 0;
  display: block;
  color: white;
  font-size: 22px;
  @media ${theme.mobile} {
    margin: 80px auto 0;
    width: 250px;
    height: 58px;
  }
`;

export default FindPassword;
