import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import MainNav from 'components/templates/MainNav';
import { observer } from 'mobx-react';
import stores from 'stores';
import { useParams } from 'react-router-dom';
import { Mobile, Others } from 'styles/MediaQuery';

const NewsDetail = observer(() => {
  const { newsStore } = stores;
  const { id } = useParams();

  useEffect(() => {
    newsStore.getNewsDetail(id);
  }, [newsStore, id]);

  const newsDetail = newsStore.newsDetailData;

  return (
    <PageLayout>
      <MainNav />
      <NewsDetailWrap>
        <TitleInfo>
          <Date>{newsDetail.createdDate} 등록</Date>
          <Title>{newsDetail.title}</Title>
          <Info>
            {newsDetail.company} ㅣ {/*{newsDetail.pastDays}일전*/}
          </Info>
          <Others>
            <ShareButton>공유하기</ShareButton>
          </Others>
        </TitleInfo>
        <ArticleBox>
          {newsDetail.imageUrl && (
            <>
              <Photo>
                <img src={newsDetail.imageUrl} alt="뉴스이미지" />
              </Photo>
              <PhotoDesc>{newsDetail.imageCaption}</PhotoDesc>
            </>
          )}
          <ArticleText>{newsDetail.contents}</ArticleText>
          <ArticleSource> 원본 기사 : {newsDetail.linkUrl}</ArticleSource>
        </ArticleBox>
        <Mobile>
          <Button>공유하기</Button>
        </Mobile>
      </NewsDetailWrap>
    </PageLayout>
  );
});

const NewsDetailWrap = styled.div`
  width: 1040px;
  margin: 0 auto;
  @media ${theme.mobile} {
    width: 100%;
  }
`;

const TitleInfo = styled.div`
  padding: 30px 0 28px;
  position: relative;
  @media ${theme.mobile} {
    border-bottom: 1px solid ${theme.colors.purpleGray};
    padding: 30px 45px 28px;
  }
`;

const Date = styled.p`
  font-family: 'nexonMedium';
  font-size: 14px;
  letter-spacing: -0.7px;
  color: #9493a4;
`;

const Title = styled.p`
  margin: 16px 0 19px 0;
  font-family: 'nexonMedium';
  font-size: 27px;
  letter-spacing: -1.4px;
  color: #424547;
`;

const Info = styled.p`
  font-family: 'nexonMedium';
  font-size: 17px;
  letter-spacing: -0.8px;
  color: #80878e;
`;

const ArticleBox = styled.div`
  padding: 28px 0;

  @media ${theme.mobile} {
    padding: 28px 30px;
  }
`;

const Photo = styled.div`
  width: 100%;
  height: 327px;
  background-color: #eee;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const PhotoDesc = styled.p`
  font-family: 'nexonMedium';
  font-size: 17px;
  letter-spacing: -0.4px;
  color: #80878e;
  margin: 10px 0 70px 0;
`;

const ArticleText = styled.p`
  font-family: 'nexonLight';
  font-size: 20px;
  letter-spacing: -0.5px;
  line-height: 35px;
  color: #000000;
`;

const ArticleSource = styled(ArticleText)`
  font-size: 17px;
  margin: 40px 0 0 0;
`;

const Button = styled.button`
  font-family: 'nexonRegular';
  width: 175px;
  height: 60px;
  border: 2px solid #6e6a6b;
  border-radius: 5px;
  font-size: 24px;
  color: #595959;
  background-color: transparent;
  display: block;
  margin: 70px auto 0;
`;

const ShareButton = styled(Button)`
  width: 112px;
  height: 40px;
  border-radius: 5px;
  font-size: 15px;
  position: absolute;
  right: 0;
  top: -10px;
`;

export default NewsDetail;
