import React from 'react';
import styled from 'styled-components';
import Navbar from 'components/templates/Navbar';
import Footer from 'components/templates/Footer';
import { Mobile, Others } from 'styles/MediaQuery';

const PageLayout = ({ children }) => {
  return (
    <>
      <Mobile>
        <MobileWrap>
          <Navbar />
          {children}
          <Footer />
        </MobileWrap>
      </Mobile>
      <Others>
        <Wrap>
          <Navbar />
          {children}
          <Footer />
        </Wrap>
      </Others>
    </>
  );
};

const MobileWrap = styled.div`
  width: 562px;
  height: 100vh;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white;
  position: relative;
`;

const Wrap = styled.div`
  width: 100%;
`;

export default PageLayout;
