import { createGlobalStyle } from 'styled-components';
import nexonR3 from './NEXON-Lv2-Gothic.ttf';
import nexonM3 from './NEXON-Lv2-Gothic-Medium.ttf';
import nexonB3 from './NEXON-Lv2-Gothic-Bold.ttf';
import nexonL3 from './NEXON-Lv2-Gothic-Light.ttf';
import archivoR from './Archivo-Regular.ttf';
import NotoSansR from './Noto-Sans.ttf';
import PromptR from './Prompt-Regular.ttf';
import NotoSansCJKTCR from './Noto-Sans-CJK-TC-Regular.otf';
import PretendardBold from './Pretendard-Bold.ttf';
import PretendardRegular from './Pretendard-Regular.ttf';
import PretendardMedium from './Pretendard-Medium.ttf';
import PretendardSemiBold from './Pretendard-SemiBold.ttf';

export default createGlobalStyle`
 @font-face {
        font-family: 'nexonRegular';
        src: local('nexonR3'), local('nexonR3');
        font-style: normal;
        src: url(${nexonR3}) format('truetype');
  }
   @font-face {
        font-family: 'nexonMedium';
        src: local('nexonM3'), local('nexonM3');
        font-style: normal;
        src: url(${nexonM3}) format('truetype');
  }
  @font-face {
        font-family: 'nexonBold';
        src: local('nexonB3'), local('nexonB3');
        font-style: normal;
        src: url(${nexonB3}) format('truetype');
  }
    @font-face {
        font-family: 'nexonLight';
        src: local('nexonL3'), local('nexonL3');
        font-style: normal;
        src: url(${nexonL3}) format('truetype');
    }
    @font-face {
         font-family: 'ArchivoR';
         src: local('archivoR'), local('archivoR');
         font-style: normal;
         src: url(${archivoR}) format('truetype');
   }
   @font-face {
        font-family: 'NotoSansCJKTCR';
        src: local('NotoSansR'), local('NotoSansR');
        font-style: normal;
        src: url(${NotoSansCJKTCR}) format('opentype');
      }
   @font-face {
        font-family: 'NotoSansR';
        src: local('NotoSansR'), local('NotoSansR');
        font-style: normal;
        src: url(${NotoSansR}) format('truetype');
      }
      @font-face {
           font-family: 'PromptR';
           src: local('PromptR'), local('PromptR');
           font-style: normal;
           src: url(${PromptR}) format('truetype');
         }
      @font-face {
            font-family: 'PretendardBold';
            src: local('PretendardBold'), local('PretendardBold');
            font-style: normal;
            src: url(${PretendardBold}) format('truetype');
      }
      @font-face {
            font-family: 'PretendardRegular';
            src: local('PretendardRegular'), local('PretendardRegular');
            font-style: normal;
            src: url(${PretendardRegular}) format('truetype');
      }
      @font-face {
            font-family: 'PretendardMedium';
            src: local('PretendardMedium'), local('PretendardMedium');
            font-style: normal;
            src: url(${PretendardMedium}) format('truetype');
      }
      @font-face {
            font-family: 'PretendardSemiBold';
            src: local('PretendardSemiBold'), local('PretendardSemiBold');
            font-style: normal;
            src: url(${PretendardSemiBold}) format('truetype');
      }
`;
