import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';

const SmallNewsCard = ({ image, title, days, view }) => {
  return (
    <Wrap>
      <Photo>
        <img src={image} alt="기사이미지" />
      </Photo>
      <TextBox>
        <Title>{title}</Title>
        <Info>
          {days}일전 <Border></Border> <ViewText>View</ViewText> {view}
        </Info>
      </TextBox>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: flex;
  padding: 15px 6px;

  @media ${theme.mobile} {
    padding: 15px 10px;
    border-bottom: 1px solid ${theme.colors.purpleGray};
  }
`;

const Photo = styled.div`
  width: 173px;
  height: 100px;
  background-color: #eee;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TextBox = styled.div`
  width: 320px;
  margin: 10px 0 0 15px;
`;

const Title = styled.p`
  font-family: 'nexonMedium';
  font-size: 19px;
  letter-spacing: -0.4px;
  color: #424547;
  height: 40px;
`;

const Info = styled.p`
  margin: 20px 0 0 0;
  font-family: 'nexonMedium';
  font-size: 17px;
  letter-spacing: -0.8px;
  color: #80878e;
  display: flex;
  align-items: center;
`;

const Border = styled.span`
  width: 2px;
  background-color: #80878e;
  height: 15px;
  display: block;
  margin: 0 14px;
`;

const ViewText = styled.span`
  color: ${theme.colors.primary};
  margin: 0 5px 0 0;
`;

export default SmallNewsCard;
