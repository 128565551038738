import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import MainNav from 'components/templates/MainNav';
import { observer } from 'mobx-react';
import stores from 'stores';
import { toJS } from 'mobx';
import { AnnouncementInput } from 'components/modules/ResumeInput';
import Checkbox from 'components/atoms/Checkbox';
import PopUp from 'components/atoms/PopUp';
import SelectOptions from 'components/atoms/SelectOptions';
import DaumPostcode from 'react-daum-postcode';
import { BiSearch } from 'react-icons/bi';
import axios from 'axios';

const jobCategory = [
  { text: '공장', value: 'FACTORY' },
  { text: '농장', value: 'FARM' },
  { text: '식당', value: 'RESTAURANT' },
  { text: '건설', value: 'CONSTRUCTION' },
  { text: '번역', value: 'TRANSLATION' },
  { text: '사무직', value: 'OFFICE' },
];

const payTypeOptions = [
  { value: 'HOURLY', text: '시급' },
  { value: 'DAILY', text: '일급' },
  { value: 'WEEKLY', text: '주급' },
  { value: 'MONTHLY', text: '월급' },
  { value: 'YEARLY', text: '연봉' },
];

const timeOption = [
  { value: 'MORNING', text: '오전' },
  { value: 'AFTERNOON', text: '오후' },
];

const hourOption = [
  { value: '01:00', text: '01:00' },
  { value: '02:00', text: '02:00' },
  { value: '03:00', text: '03:00' },
  { value: '04:00', text: '04:00' },
  { value: '05:00', text: '05:00' },
  { value: '06:00', text: '06:00' },
  { value: '07:00', text: '07:00' },
  { value: '08:00', text: '08:00' },
  { value: '09:00', text: '09:00' },
  { value: '10:00', text: '10:00' },
  { value: '11:00', text: '11:00' },
  { value: '12:00', text: '12:00' },
];

const JobPeriodTypeOptions = [
  {
    text: '일주일 이하',
    value: 'UNDER_WEEK',
  },
  {
    text: '한 달 이하',
    value: 'UNDER_MONTH',
  },
  {
    text: '3개월 이하',
    value: 'UNDER_3MONTH',
  },
  {
    text: '6개월 이하',
    value: 'UNDER_6MONTH',
  },
  {
    text: '1년 미만',
    value: 'UNDER_1YEAR',
  },
  {
    text: '1년 이상',
    value: 'ABOVE_1YEAR',
  },
];

const workDayOptions = [
  {
    text: '주 1일',
    value: 1,
  },
  {
    text: '주 2일',
    value: 2,
  },
  {
    text: '주 3일',
    value: 3,
  },
  {
    text: '주 4일',
    value: 4,
  },
  {
    text: '주 5일',
    value: 5,
  },
  {
    text: '주 6일',
    value: 6,
  },
  {
    text: '주 7일',
    value: 7,
  },
];

const keywordOptions = [
  {
    value: 'HIGH_PAYMENT',
    text: '높은 급여',
  },
  {
    value: 'PROVIDE_RESIDENCE',
    text: '숙소 제공',
  },
  {
    value: 'EASY_WORK',
    text: '쉬운 업무',
  },
  {
    value: 'PROVIDE_MEAL',
    text: '식사 제공',
  },
  {
    value: 'VISA_NOT_CARE',
    text: '비자 불필요',
  },
  {
    value: 'KOREAN_NOT_CARE',
    text: '한국어 불필요',
  },
];

const WriteAnnouncement = observer(() => {
  const { countryStore, fileStore, jobStore } = stores;

  useEffect(() => {
    countryStore.getCountries();
  }, [countryStore]);

  const countryData = toJS(countryStore.countryData);

  const [inputs, setInputs] = useState({
    title: '',
    companyName: '',
    cellphone: '',
    numOfPerson: 0,
    payAmount: 0,
    address: '',
    zipcode: '',
    detailAddress: '',
    detailInformation: '',
    businessName: '',
    idNumFront: '',
    idNumBack: '',
    representative: '',
    categoryEtc: '',
    bcode: '',
    latitude: 0,
    longitude: 0,
    addressEng: '',
    sido: '',
    sigungu: '',
    sigunguCode: '',
  });

  const {
    title,
    companyName,
    cellphone,
    numOfPerson,
    payAmount,
    address,
    zipcode,
    detailAddress,
    detailInformation,
    businessName,
    idNumFront,
    idNumBack,
    representative,
    categoryEtc,
    bcode,
    latitude,
    longitude,
    addressEng,
    sido,
    sigungu,
    sigunguCode,
  } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onBirthChange = (e) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value.length > 6 ? value.substring(0, 6) : value,
    });
  };

  const [sectors, setSectors] = useState([]);

  const handleClickSectors = (tab) => {
    if (sectors.includes(tab)) {
      setSectors(sectors.filter((item) => item !== tab));
    } else {
      setSectors([...sectors, tab]);
    }
  };

  const [sectorEtc, setSectorEtc] = useState(false);

  const handleSectorEtc = () => {
    setSectorEtc(!sectorEtc);
    setSectors('');
  };

  useEffect(() => {
    if (sectors !== '') {
      setSectorEtc(false);
    }
  }, [sectors]);

  const [gender, setGender] = useState('NONE');

  const handleClickGender = (tab) => {
    setGender(tab);
  };

  const [countryPopupVisible, setCountryPopupVisible] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCountryName, setSelectedCountryName] = useState('');
  const handleSelectCountry = (countryIsoCode, langCode, nameInOrigin) => {
    setSelectedCountry(countryIsoCode);
    setSelectedCountryName(nameInOrigin);
    localStorage.setItem('countryIsoCode', countryIsoCode);
    localStorage.setItem('languageCode', langCode);
  };

  const handleCloseCountryPopup = () => {
    setCountryPopupVisible(false);
    setSelectedCountry('');
    setSelectedCountryName('');
  };

  const handleCheckCountryPopup = () => {
    if (!selectedCountry) {
      return;
    }
    setCountryPopupVisible(!countryPopupVisible);
  };

  const handleClickNoCountry = () => {
    setSelectedCountry('');
  };

  const [payType, setPayType] = useState({ value: '', text: '선택' });

  const [showPostCode, setShowPostCode] = useState(false);

  const handleAddressComplete = (data) => {
    console.log(data);
    const searchTxt = data.address;
    const config = {};
    axios.defaults.headers.common['Authorization'] =
      'KakaoAK ' + 'd0312c5353b3af7e1c0006d79cb0c21c';
    const url = 'https://dapi.kakao.com/v2/local/search/address.json?query=' + searchTxt;
    axios.get(url, config).then(function (result) {
      // API호출
      if (result.data !== undefined || result.data !== null) {
        if (result.data.documents[0].x && result.data.documents[0].y) {
          setInputs({
            ...inputs,
            latitude: result.data.documents[0].x,
            longitude: result.data.documents[0].y,
          });
        }
      }
    });
    setInputs({
      ...inputs,
      addressEng: data.addressEnglish,
      address: data.address,
      zipcode: data.zonecode,
      bcode: data.bcode,
      sido: data.sido,
      sigungu: data.sigungun,
      sigunguCode: data.sigunguCode,
    });
    setShowPostCode(false);
  };

  const postCodeStyle = {
    display: 'block',
    position: 'fixed',
    top: 'calc(50% - 200px)',
    left: 'calc(50% - 350px)',
    width: '700px',
    height: '400px',
    zIndex: 100,
    border: '2px solid #bcbcbc',
  };

  const [startTime, setStartTime] = useState({ value: 'AFTERNOON', text: '오후' });
  const [startHour, setStartHour] = useState({ value: '01:00', text: '01:00' });
  const [endTime, setEndTime] = useState({ value: 'MORNING', text: '오전' });
  const [endHour, setEndHour] = useState({ value: '01:00', text: '01:00' });
  const [jobPeriodType, setJobPeriodType] = useState({ value: '', text: '선택' });
  const [workDay, setWorkDay] = useState({ text: '선택', value: '' });

  const [keywordPopupVisible, setKeywordPopupVisible] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState('');
  const handleClickNoKeyword = () => {
    setSelectedKeyword('');
  };
  const handleSelectKeyword = (value) => {
    setSelectedKeyword(value);
  };
  const handleCloseKeywordPopup = () => {
    setKeywordPopupVisible(false);
    setSelectedKeyword('');
  };

  const handleCheckKeywordPopup = () => {
    if (!selectedKeyword) {
      return;
    }
    setKeywordPopupVisible(!keywordPopupVisible);
  };

  const [visaAvailable, setVisaAvailable] = useState(false);

  const handleVisaAvailable = () => {
    setVisaAvailable(!visaAvailable);
  };

  const fileInput = useRef(null);
  const [Image, setImage] = useState('');
  const [imageName, setImageName] = useState('');

  const handleChangeImage = (e) => {
    const uploadImage = e.target.files[0];
    if (uploadImage) {
      const formData = new FormData();
      formData.append('file', uploadImage);
      formData.append('type', 'announcement');
      fileStore
        .updateFile(formData)
        .then((res) => {
          console.log(res);

          setImage(e.target.files[0]);
          setImageName(e.target.files[0].name);
        })
        .catch((error) => {
          alert('파일 업로드에 실패했습니다.');
          console.log(error);
        });
    } else {
      //업로드 취소할 시
      setImage('');
      return;
    }
  };

  const businessFileInput = useRef(null);
  const [businessImage, setbusinessImage] = useState('');
  const [businessImageName, setBusinessImageName] = useState('');

  const handleChangeBusinessImage = (e) => {
    const uploadImage = e.target.files[0];
    if (uploadImage) {
      const formData = new FormData();
      formData.append('file', uploadImage);
      formData.append('type', 'announcement/business');
      fileStore
        .updateFile(formData)
        .then((res) => {
          console.log(res);

          setbusinessImage(e.target.files[0]);
          setBusinessImageName(e.target.files[0].name);
        })
        .catch((error) => {
          alert('파일 업로드에 실패했습니다.');
          console.log(error);
        });
    } else {
      //업로드 취소할 시
      setImage('');
      return;
    }
  };

  const [safeAnnouncement, setSafeAnnouncement] = useState(false);

  const handleSafeAnnouncement = () => {
    setSafeAnnouncement(!safeAnnouncement);
  };

  const handleSubmitAnnouncement = () => {
    const data = {
      address: address,
      addressCode: zipcode,
      addressDetails: detailAddress,
      bcode: bcode,
      // 통째로 체크 필요
      businessDivisionEnd: '',
      businessDivisionStart: '',
      businessHourEnd: '',
      businessHourNegotiable: '',
      businessHourStart: '',
      businessName: '',
      businessType: '',
      // 통째로 체크 필요
      categories: sectors,
      categoryEtc: categoryEtc,
      certificateFileDataId: '',
      companyName: companyName,
      contact: cellphone,
      countryCodes: [], // 국적 여러개 체크 가능한지
      details: detailInformation,
      gender: gender,
      id: 0, // 수정시의 아이디인지 체크
      imageData: '', // ??
      imageFileDataId: '', // ??
      imageUrl: Image,
      keywordType: selectedKeyword,
      latitude: latitude,
      localJobRegionIds: [], // ??
      longitude: longitude,
      nationalityUnconcerned: selectedCountry === '' ? true : false,
      numberOfDaysNegotiable: true, //?? 선택 상태가 타협 가능 상태인지 체크
      numberOfPersons: parseFloat(numOfPerson),
      numberOfWorkDays: parseFloat(workDay.value),
      onTheWay: '', // 뭔지
      payAmount: parseFloat(payAmount),
      payType: payType.value,
      periodNegotiable: true, //?? 선택 상태가 타협 가능 상태인지 체크
      periodType: jobPeriodType.value,
      personName: representative,
      personRegistrationNumber: idNumFront + idNumBack,
      roadAddressEnglish: addressEng,
      securePayment: safeAnnouncement,
      sido: sido,
      sigungu: sigungu,
      sigunguCode: sigunguCode,
      title: title,
      visaAvailable: visaAvailable,
    };
    jobStore.postEmployerJobs(data);
  };

  return (
    <PageLayout>
      <MainNav />
      <ContentWrap>
        <Title>공고 등록 절차</Title>
        <FlexWrap>
          <LeftSectionWrap>
            <InputItemWrap>
              <AnnouncementInput
                label="공고 제목"
                name="title"
                value={title}
                placeholder="오늘의 맛집"
                onChange={onChange}
              />
            </InputItemWrap>
            <InputItemWrap>
              <AnnouncementInput
                label="업체명"
                name="companyName"
                value={companyName}
                placeholder="아웃랜더코트립"
                onChange={onChange}
              />
            </InputItemWrap>
            <InputItemWrap>
              <AnnouncementInput
                label="연락처"
                name="cellphone"
                value={cellphone}
                placeholder="010-5123-1234"
                onChange={onChange}
              />
            </InputItemWrap>
            <InputItemWrap>
              <InputFlexWrap>
                <Label>모집 분야</Label>
                <SectorsWrap>
                  <SectorsTabWrap>
                    {jobCategory.map((item) => (
                      <SectorsTab
                        onClick={() => handleClickSectors(item.value)}
                        active={sectors.includes(item.value)}
                        key={item.value}
                      >
                        {item.text}
                      </SectorsTab>
                    ))}
                  </SectorsTabWrap>
                  <CheckboxFlexWrap>
                    <Checkbox
                      key="sectorEtc"
                      defaultValue={true}
                      type="checkbox"
                      name="sectorEtc"
                      handleClick={handleSectorEtc}
                      isChecked={sectorEtc === true}
                    >
                      기타 :
                    </Checkbox>
                    <CheckboxInput name="categoryEtc" value={categoryEtc} onChange={onChange} />
                  </CheckboxFlexWrap>
                </SectorsWrap>
              </InputFlexWrap>
            </InputItemWrap>
            <InputItemWrap>
              <InputFlexWrap>
                <Label>모집 성별</Label>
                <GenderTabWrap>
                  <GenderTab onClick={() => handleClickGender('NONE')} active={gender === 'NONE'}>
                    성별 무관
                  </GenderTab>
                  <GenderTab onClick={() => handleClickGender('MAN')} active={gender === 'MAN'}>
                    남자
                  </GenderTab>
                  <GenderTab onClick={() => handleClickGender('WOMAN')} active={gender === 'WOMAN'}>
                    여자
                  </GenderTab>
                </GenderTabWrap>
              </InputFlexWrap>
            </InputItemWrap>
            <InputItemWrap>
              <InputFlexWrap>
                <Label>근로자 희망 국적</Label>
                <NationalWrap>
                  <NationalButton
                    onClick={() => handleClickNoCountry}
                    active={selectedCountry === ''}
                  >
                    희망 국적 없음
                  </NationalButton>
                  <NationalButton
                    active={selectedCountryName?.length > 0}
                    onClick={() => setCountryPopupVisible(true)}
                  >
                    {selectedCountryName ? selectedCountryName : '선택'}
                  </NationalButton>
                </NationalWrap>
              </InputFlexWrap>
            </InputItemWrap>
          </LeftSectionWrap>
          <TipTool>
            <TipToolTitle>
              공고 제목 작성 요령 <span>TIP</span>
            </TipToolTitle>
            <TipToolText>
              제목은 지역이나 구체적인 업무 내용 중심으로 작성해주세요!
              <br />
              <br />
              <TipToolActive>예 1</TipToolActive>: 강남역/주말/야간/코라이프 식당에서 함께 일할 직원
              모집합니다.
              <br />
              <TipToolActive>예 2</TipToolActive>: 단기5일/경기도 평택/당일지급/코라이프 공장에서
              생산인력 모집
              <br />
              <br />
              <TipToolError>※이런 제목은 안돼요!</TipToolError>
              <br />
              <br />- 주방 보조 모집 / 배달 기사 모집
              <br />
              <TipToolActive>
                단순한 제목은 타 공고와 중복되는 경우가 많아서 지원률이 떨어져요.
              </TipToolActive>
              <br />- 외국인 알바 모집
              <br />
              <TipToolActive>제목만으로는 무슨 업무인지 알 수 없어요.</TipToolActive>
            </TipToolText>
          </TipTool>
        </FlexWrap>
      </ContentWrap>
      <DivideBorder></DivideBorder>
      <ContentWrap>
        <FlexWrap>
          <SecondHalfSection>
            <InputItemWrap>
              <Label>모집 인원</Label>
              <SmallInputMarginWrap>
                <SmallInputWrap>
                  <SmallInput
                    name="numOfPerson"
                    value={numOfPerson}
                    placeholder="0"
                    onChange={onChange}
                    type="number"
                  />
                  명
                </SmallInputWrap>
              </SmallInputMarginWrap>
            </InputItemWrap>
            <InputItemWrap>
              <LabelFlexWrap>
                <FlexLabel>급여</FlexLabel>
                <LabelAdd>
                  *2021년 최저시급은 <span>9,160원</span> 입니다.
                </LabelAdd>
              </LabelFlexWrap>
              <PayTypeFlexWrap>
                <SelectOptionWrap>
                  <SelectOptions
                    value={payType}
                    onSelect={(val) => setPayType(val)}
                    options={payTypeOptions}
                    style={{ height: '40px' }}
                    line={true}
                  />
                </SelectOptionWrap>
                <SmallInputWrap>
                  <SmallInput
                    name="payAmount"
                    value={payAmount}
                    placeholder="0"
                    onChange={onChange}
                    type="number"
                  />
                  원
                </SmallInputWrap>
              </PayTypeFlexWrap>
            </InputItemWrap>
            <InputItemWrap>
              <Label>근무 장소</Label>
              <AddressInputWrap>
                <AddressInputBtnWrap>
                  <AddressInput
                    placeholder="주소"
                    defaultValue={inputs.address}
                    onClick={() => setShowPostCode(!showPostCode)}
                  />
                  <SearchIcon />
                </AddressInputBtnWrap>
                <DetailAddressInput
                  name="detailAddress"
                  value={detailAddress}
                  placeholder="상세주소를 입력해주세요"
                  onChange={onChange}
                />
              </AddressInputWrap>
              {showPostCode ? (
                <PopupBackground onClick={() => setShowPostCode(false)}>
                  <DaumPostcode onComplete={handleAddressComplete} style={postCodeStyle} />
                </PopupBackground>
              ) : (
                <></>
              )}
            </InputItemWrap>
          </SecondHalfSection>
          <SecondHalfSection>
            <InputItemWrap>
              <Label>근무 시간</Label>
              <TimeWrap>
                <StartTime>
                  <TimeSelectWrap>
                    <SelectOptions
                      value={startTime}
                      onSelect={(val) => setStartTime(val)}
                      options={timeOption}
                      style={{ height: '40px' }}
                      line={true}
                    />
                  </TimeSelectWrap>
                  <HourSelectWrap>
                    <SelectOptions
                      value={startHour}
                      onSelect={(val) => setStartHour(val)}
                      options={hourOption}
                      style={{ height: '40px' }}
                      line={true}
                    />
                  </HourSelectWrap>
                </StartTime>
                <InputSubText>~</InputSubText>
                <EndTime>
                  <TimeSelectWrap>
                    <SelectOptions
                      value={endTime}
                      onSelect={(val) => setEndTime(val)}
                      options={timeOption}
                      style={{ height: '40px' }}
                      line={true}
                    />
                  </TimeSelectWrap>
                  <HourSelectWrap>
                    <SelectOptions
                      value={endHour}
                      onSelect={(val) => setEndHour(val)}
                      options={hourOption}
                      style={{ height: '40px' }}
                      line={true}
                    />
                  </HourSelectWrap>
                </EndTime>
              </TimeWrap>
            </InputItemWrap>
            <InputItemWrap>
              <InputFlexWrap>
                <InputFlexItem>
                  <Label>근무 기간</Label>
                  <JobPeriodSelectWrap>
                    <SelectOptions
                      value={jobPeriodType}
                      onSelect={(val) => setJobPeriodType(val)}
                      options={JobPeriodTypeOptions}
                      style={{ height: '40px' }}
                      line={true}
                    />
                  </JobPeriodSelectWrap>
                </InputFlexItem>
                <InputFlexItem>
                  <Label>근무 요일</Label>
                  <JobPeriodSelectWrap>
                    <SelectOptions
                      value={workDay}
                      onSelect={(val) => setWorkDay(val)}
                      options={workDayOptions}
                      style={{ height: '40px' }}
                      line={true}
                    />
                  </JobPeriodSelectWrap>
                </InputFlexItem>
              </InputFlexWrap>
            </InputItemWrap>
            <InputItemWrap>
              <Label>키워드</Label>
              <KeywordWrap>
                <KeywordButton
                  active={selectedKeyword?.length > 0}
                  onClick={() => setKeywordPopupVisible(true)}
                >
                  {selectedKeyword ? selectedKeyword : '선택'}
                </KeywordButton>
                <KeywordButton onClick={handleClickNoKeyword} active={selectedKeyword === ''}>
                  키워드 없음
                </KeywordButton>
              </KeywordWrap>
              <KeywordCheckboxWrap>
                <Checkbox
                  key="visaAvailable"
                  defaultValue={true}
                  type="checkbox"
                  name="visaAvailable"
                  handleClick={handleVisaAvailable}
                  isChecked={visaAvailable === true}
                >
                  비자 제공 가능{' '}
                  <CheckboxActiveText>
                    *지원자에게 비자 지원이 가능하다면, 체크해주세요
                  </CheckboxActiveText>
                </Checkbox>
              </KeywordCheckboxWrap>
            </InputItemWrap>
          </SecondHalfSection>
        </FlexWrap>
      </ContentWrap>
      <DivideBorder></DivideBorder>
      <ContentWrap>
        <FlexWrap>
          <LeftSectionWrap>
            <InputItemWrap>
              <Label>상세 정보</Label>
              <TextArea
                name="detailInformation"
                value={detailInformation}
                placeholder="상세정보를 적어주세요"
                onChange={onChange}
              />
            </InputItemWrap>
            <InputItemWrap>
              <Label>사진등록</Label>
              <PhotoUploadWrap>
                <PhotoUploadBtn
                  type="button"
                  onClick={() => {
                    fileInput.current.click();
                  }}
                >
                  <PlusIcon>+</PlusIcon>
                  <p>사진 올리기</p>
                </PhotoUploadBtn>
                <FileName>{imageName}</FileName>
              </PhotoUploadWrap>
              <ImgInput
                type="file"
                accept="image/jpg,image/png,image/jpeg"
                name="profile_img"
                onChange={handleChangeImage}
                ref={fileInput}
              />
            </InputItemWrap>
            <InputItemWrap>
              <HelpLabel>안심 공고란?</HelpLabel>
              <Checkbox
                key="safeAnnouncement"
                defaultValue={true}
                type="checkbox"
                name="safeAnnouncement"
                handleClick={handleSafeAnnouncement}
                isChecked={safeAnnouncement === true}
              >
                안심공고 신청하기
              </Checkbox>
            </InputItemWrap>
          </LeftSectionWrap>
          <TipTool>
            <TipToolTitle>
              채용 공고 상세 설명 <span>TIP</span>
            </TipToolTitle>
            <TipToolText>
              외국인 근로자들은 본인이 어떤 일을 해야 하는지 알고 싶어 합니다.
              <br />
              최대한 자세하게 업무에 대해 설명해주세요.
              <br />
              <br />
              <TipToolActive>- 설명란에 작성하면 좋은 것들</TipToolActive>
              <br /> 주요 업무, 필요 능력, 휴게 시간, 근무 환경 및 복지, 우대 사항 등
              <br />
              <TipToolActive>예 </TipToolActive>: 담당 업무 : 00부품 수리 및 생산
              <br />
              자격 요건 : 고졸 이상, 한국어 중급, 빠른 손놀림
              <br />
              우대 사항 : 인근 거주자, 해당 직무 근무 경험, 교대 근무 가능자
              <br />
              근무 형태 : 정규직 / 아르바이트 등<br />
              휴게 시간 : 15:00 ~ 16:00
              <br />
              채용 절차 : 서류 - 면접
              <br />
              접수 방법 : 코라이프 앱 지원, 이메일 이력서 지원
            </TipToolText>
          </TipTool>
        </FlexWrap>
      </ContentWrap>
      <DivideBorder></DivideBorder>
      <ContentWrap>
        <Title>사업자 인증</Title>
        <FlexWrap>
          <SecondHalfSection>
            <InputItemWrap>
              <LabelFlexWrap>
                <FlexLabel>회사명</FlexLabel>
                <LabelAdd>
                  <span>*사업자등록증 사진과 동일하게 작성해주세요</span>
                </LabelAdd>
              </LabelFlexWrap>
              <InputBox
                placeholder="회사명을 입력하세요."
                value={businessName}
                onChange={onChange}
                name="businessName"
              />
            </InputItemWrap>
            <InputItemWrap>
              <Label>대표자 생년월일</Label>
              <IdNumWrap>
                <IdNumInputBox
                  type={'number'}
                  maxLength="8"
                  value={idNumFront}
                  onChange={onBirthChange}
                  name="idNumFront"
                />{' '}
                <Dash></Dash>
                <IdNumInputBoxBack
                  value={idNumBack}
                  onChange={onChange}
                  name="idNumBack"
                  maxLength="1"
                />{' '}
                <IdNumBackEncryption>******</IdNumBackEncryption>
              </IdNumWrap>
            </InputItemWrap>
            <InputItemWrap>
              <Label>사업자등록증 사진</Label>
              <PhotoUploadWrap>
                <PhotoUploadBtn
                  type="button"
                  onClick={() => {
                    businessFileInput.current.click();
                  }}
                >
                  <PlusIcon>+</PlusIcon>
                  <p>사진 올리기</p>
                </PhotoUploadBtn>
                <FileName>{businessImageName}</FileName>
              </PhotoUploadWrap>
              <ImgInput
                type="file"
                accept="image/jpg,image/png,image/jpeg"
                name="profile_img"
                onChange={handleChangeBusinessImage}
                ref={businessFileInput}
              />
            </InputItemWrap>
          </SecondHalfSection>
          <SecondHalfSection>
            <InputItemWrap>
              <LabelFlexWrap>
                <FlexLabel>대표자 성함</FlexLabel>
                <LabelAdd>
                  <span>*사업자등록증 사진과 동일하게 작성해주세요</span>
                </LabelAdd>
              </LabelFlexWrap>
              <InputBox
                placeholder="회사명을 입력하세요."
                value={representative}
                onChange={onChange}
                name="representative"
              />
            </InputItemWrap>
          </SecondHalfSection>
        </FlexWrap>
        <Button type="button" onClick={handleSubmitAnnouncement}>
          공고 등록
        </Button>
      </ContentWrap>
      <PopUp visible={countryPopupVisible}>
        <PopUpTitle>국적 선택</PopUpTitle>
        <PopUpTab>
          {countryData.map((item) => (
            <PopUpLanguageItemWrap
              onClick={() =>
                handleSelectCountry(item.countryIsoCode, item.langCode, item.nameInOrigin)
              }
              key={item.countryIsoCode}
            >
              <PopUpLanguageItem tab={selectedCountry === item.countryIsoCode}>
                <Flag>
                  <img src={item.flagImgUrl} alt="flag" />
                </Flag>
                <FlagText langCode={item.langCode}>{item.nameInOrigin}</FlagText>
              </PopUpLanguageItem>
            </PopUpLanguageItemWrap>
          ))}
        </PopUpTab>
        <PopupButtonWrap>
          <PopUpButtonGray onClick={handleCloseCountryPopup}>취소</PopUpButtonGray>
          <PopUpButton onClick={handleCheckCountryPopup}>확인</PopUpButton>
        </PopupButtonWrap>
      </PopUp>
      <PopUp visible={keywordPopupVisible}>
        <PopUpTitle>키워드 선택</PopUpTitle>
        <PopupKeywordWrap>
          {keywordOptions.map((item) => (
            <PopupKeywordItemWrap onClick={() => handleSelectKeyword(item.value)} key={item.value}>
              <PopUpKeywordItem tab={selectedKeyword === item.value}>
                <PopupKeywordText>{item.text}</PopupKeywordText>
              </PopUpKeywordItem>
            </PopupKeywordItemWrap>
          ))}
        </PopupKeywordWrap>
        <PopupButtonWrap>
          <PopUpButtonGray onClick={handleCloseKeywordPopup}>취소</PopUpButtonGray>
          <PopUpButton onClick={handleCheckKeywordPopup}>확인</PopUpButton>
        </PopupButtonWrap>
      </PopUp>
    </PageLayout>
  );
});

const ContentWrap = styled.div`
  width: 1100px;
  margin: 0 auto;
`;

const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
`;

const LeftSectionWrap = styled.div`
  width: 730px;
  margin: 40px 0 0 0;
`;

const Title = styled.p`
  font-size: 17px;
  font-family: 'nexonBold';
  letter-spacing: -0.44px;
  color: #434343;
  margin: 27px 0 0 10px;
`;

const InputItemWrap = styled.div`
  margin: 10px 0 70px 0;
  position: relative;
`;

const Label = styled.p`
  font-family: 'nexonMedium';
  color: #80878e;
  font-size: 17px;
  letter-spacing: -1px;
  color: #1f2422;
  width: 150px;
`;

const SectorsWrap = styled.div``;

const SectorsTabWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 32px 0;
  width: 385px;
  border: none;
  padding: 0;
`;

const SelectBox = styled.div`
  border: 1px solid ${theme.colors.borderLight};
  color: #6d6f82;
  font-family: 'nexonMedium';
  font-size: 15px;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
`;

const SectorsTab = styled(SelectBox)`
  position: relative;
  width: 50%;
  height: 40px;

  &:nth-child(2n) {
    margin-left: -1px;
  }

  &:nth-child(n + 1) {
    margin-top: -1px;
  }

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonMedium';
      border: 1px solid ${theme.colors.primary};
      z-index: 1;
      color: ${theme.colors.primary};
    `}
`;

const InputFlexWrap = styled.div`
  display: flex;
`;

const CheckboxFlexWrap = styled.div`
  display: flex;
`;

const CheckboxInput = styled.input`
  width: 300px;
  border: none;
  border-bottom: 1px solid ${theme.colors.borderLight};
  font-family: 'nexonRegular';
  font-size: 15px;
  padding: 6px 0;
  margin: 0 0 0 10px;

  &::placeholder {
    color: #d0d2e8;
  }
`;

const GenderTabWrap = styled.div`
  display: flex;
  width: 385px;
`;

const GenderTab = styled.button`
  font-family: 'nexonMedium';
  width: 33.33%;
  height: 45px;
  border: 1px solid ${theme.colors.borderLight};
  background-color: transparent;
  color: #80878e;
  font-size: 17px;

  & + & {
    margin-left: -1px;
  }

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonBold';
      border: 1px solid ${theme.colors.primary};
      z-index: 1;
      color: ${theme.colors.primary};
    `}

  @media ${theme.mobile} {
    font-size: 20px;
  }
`;

const NationalWrap = styled.div`
  display: flex;
  width: 385px;
`;

const NationalButton = styled.button`
  font-family: 'nexonMedium';
  width: 50%;
  height: 45px;
  border: 1px solid ${theme.colors.borderLight};
  background-color: transparent;
  color: #80878e;
  font-size: 17px;

  ${(props) =>
    props.active === true &&
    css`
      border: 1.5px solid ${theme.colors.primary};
      color: ${theme.colors.primary};
    `}

  & + & {
    margin-left: -1px;
  }
`;

const PopUpTitle = styled.p`
  text-align: center;
  font-family: 'nexonRegular';
  font-size: 25px;
  margin: 40px 0 0 0;
`;

const PopUpTab = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 20px 0 0 0;
  height: 420px;
  overflow: scroll;
`;

const PopUpLanguageItemWrap = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const PopUpLanguageItem = styled.div`
  text-align: center;
  width: 80px;
  height: 100px;
  padding: 13px 0 0 0;

  ${(props) =>
    props.tab === true &&
    css`
      background-color: rgba(7, 0, 133, 0.2);
      border-radius: 5px;
    `}
`;

const Flag = styled.div`
  width: 30px;
  height: 25px;
  margin: 0 auto 25px;

  & > img {
    width: 30px;
    object-fit: cover;
  }
`;

const PopupButtonWrap = styled.div`
  display: flex;
  position: absolute;
  bottom: 0px;
  width: 100%;
`;

const PopUpButton = styled.button`
  width: 50%;
  height: 60px;
  border-top: 1px solid ${theme.colors.borderLight};
  color: ${theme.colors.primary};
  background-color: transparent;
  font-size: 20px;
  display: block;

  & + & {
    border-left: 1px solid ${theme.colors.borderLight};
  }
`;

const FlagText = styled.p`
  font-size: 15px;
  line-height: 20px;
  font-family: 'nexonRegular';
  ${(props) =>
    props.langCode === 'th' &&
    css`
      font-family: 'PromptR';
    `}
  ${(props) =>
    props.langCode === 'vi' &&
    css`
      font-family: 'ArchivoR';
    `}
  ${(props) =>
    ['ru', 'mn', 'km'].includes(props.langCode) &&
    css`
      font-family: 'NotoSansR';
    `}
  ${(props) =>
    ['zh-TW', 'zh-CN'].includes(props.langCode) &&
    css`
      font-family: 'NotoSansCJKTCR';
    `}
`;

const PopUpButtonGray = styled(PopUpButton)`
  color: ${theme.colors.borderLight};
`;

const TipTool = styled.div`
  width: 230px;
  height: 420px;
  border-radius: 10px;
  background-color: #eef1f5;
  padding: 22px 14px 28px;
  margin: 40px 0 0 0;
`;

const TipToolTitle = styled.p`
  font-size: 15px;
  font-family: 'nexonBold';
  margin: 0 0 36px 0;

  & > span {
    color: ${theme.colors.primary};
  }
`;

const TipToolText = styled.p`
  font-size: 11px;
  font-family: 'nexonMedium';
  line-height: 20px;
  letter-spacing: -0.28px;
`;

const TipToolError = styled.span`
  color: #d82b2b;
`;

const TipToolActive = styled.span`
  color: ${theme.colors.primary};
`;

const DivideBorder = styled.div`
  height: 14px;
  width: 1100px;
  background-color: #eef1f5;
  margin: 0 auto;
`;

const SecondHalfSection = styled.div`
  margin: 38px 0 0 0;
`;

const SmallInputMarginWrap = styled.div`
  margin: 28px 0 0 0;
`;

const SmallInputWrap = styled.div`
  display: flex;
  font-size: 19px;
  color: #434343;
  letter-spacing: -0.43px;
  font-family: 'nexonMedium';
  align-items: center;
`;

const SmallInput = styled.input`
  width: 180px;
  border: none;
  border-bottom: 1px solid ${theme.colors.borderLight};
  font-family: 'nexonRegular';
  font-size: 19px;
  margin: 0 8px 0 0;
  height: 40px;

  &::placeholder {
    color: #d0d2e8;
  }
`;

const LabelFlexWrap = styled.div`
  display: flex;
`;

const FlexLabel = styled(Label)`
  width: auto;
`;

const LabelAdd = styled(Label)`
  font-size: 14px;
  width: auto;
  margin: 0 0 0 25px;

  & > span {
    color: ${theme.colors.primary};
  }
`;

const SelectOptionWrap = styled.div`
  width: 80px;
  margin: 0 28px 0 0;
`;

const PayTypeFlexWrap = styled.div`
  display: flex;
  margin: 20px 0 0 0;
`;

const AddressInputWrap = styled.div`
  margin: 14px 0 0 0;
  width: 440px;
`;

const AddressInput = styled.input`
  border: 1px solid #d0d2e8;
  width: 440px;
  height: 54px;
  padding: 0 0 0 17px;
  font-size: 17px;
  font-family: 'nexonRegular';
  cursor: pointer;
`;

const AddressInputBtnWrap = styled.div`
  position: relative;
`;

const DetailAddressInput = styled.input`
  border: 1px solid #d0d2e8;
  width: 440px;
  height: 54px;
  padding: 0 0 0 17px;
  font-size: 17px;
  font-family: 'nexonRegular';
  margin: 7px 0 0 0;
`;

const PopupBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
`;

const SearchIcon = styled(BiSearch)`
  position: absolute;
  color: #313131;
  font-size: 26px;
  right: 8px;
  top: 14px;
`;

const TimeWrap = styled.div`
  margin: 20px 0 0 0;
  display: flex;
`;

const StartTime = styled.div`
  display: flex;
  margin: 0 20px 0 0;
`;

const EndTime = styled.div`
  display: flex;
  margin: 0 0 0 20px;
`;

const SelectWrap = styled.div`
  width: 80px;
  position: relative;
`;

const TimeSelectWrap = styled(SelectWrap)`
  width: 70px;
`;

const HourSelectWrap = styled(SelectWrap)`
  width: 90px;
  margin: 0 0 0 17px;
`;

const InputSubText = styled.p`
  font-size: 22px;
  color: ${theme.colors.bodyText};
  margin: 7px 0 0 0;
`;

const InputFlexItem = styled.div`
  & + & {
    margin: 0 0 0 60px;
  }
`;

const JobPeriodSelectWrap = styled.div`
  width: 160px;
  margin: 20px 0 0 0;
`;

const KeywordWrap = styled.div`
  display: flex;
  width: 385px;
  margin: 10px 0 0 0;
`;

const KeywordButton = styled.button`
  font-family: 'nexonMedium';
  width: 50%;
  height: 45px;
  border: 1px solid ${theme.colors.borderLight};
  background-color: transparent;
  color: #80878e;
  font-size: 17px;

  ${(props) =>
    props.active === true &&
    css`
      border: 1.5px solid ${theme.colors.primary};
      color: ${theme.colors.primary};
    `}

  & + & {
    margin-left: -1px;
  }
`;

const PopupKeywordWrap = styled.div`
  margin: 40px 0 0 0;
`;

const PopupKeywordText = styled.div`
  font-size: 17px;
  color: #313131;
  font-family: 'nexonMedium';
`;

const PopupKeywordItemWrap = styled.div`
  margin: 5px 0 0 0;
`;

const PopUpKeywordItem = styled.div`
  text-align: center;
  width: 100%;
  height: 60px;
  padding: 20px 0 0 0;

  ${(props) =>
    props.tab === true &&
    css`
      background-color: #eee;
      border-radius: 5px;
    `}
`;

const KeywordCheckboxWrap = styled.div`
  margin: 26px 0 0 0;
`;

const CheckboxActiveText = styled.span`
  color: ${theme.colors.primary};
  font-size: 14px;
  margin: 0 0 0 8px;
`;

const TextArea = styled.textarea`
  font-family: 'nexonRegular';
  border: 1px solid #d0d2e8;
  height: 320px;
  width: 100%;
  font-size: 17px;
  padding: 23px;
  box-sizing: border-box;
  resize: none;
  letter-spacing: -1px;
  margin: 20px 0 0 0;

  &::placeholder {
    color: #d5d5e4;
  }

  &:focus {
    outline: none;
  }
`;

const PhotoUploadWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0 0 0;
`;

const PhotoUploadBtn = styled.button`
  width: 154px;
  height: 42px;
  border-radius: 20px;
  border: 3px solid #d0d2e8;
  background-color: transparent;
  font-size: 20px;
  font-family: 'nexonRegular';
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    margin: 2px 0 0 0;
  }
`;

const PlusIcon = styled.div`
  font-size: 30px;
  font-family: 'nexonBold';
  color: white;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  background: ${theme.colors.primary};
  display: inline-block;
  line-height: 28px;
  margin: 0 5px 0 0;
`;

const ImgInput = styled.input`
  display: none;
`;

const FileName = styled.p`
  font-size: 18px;
  font-family: 'nexonRegular';
  margin: 0 0 0 8px;
`;

const HelpLabel = styled(Label)`
  font-size: 18px;
  background-color: ${theme.colors.primary};
  border-radius: 20px;
  color: white;
  width: 110px;
  text-align: center;
  padding: 5px 0;
  margin: 0 0 13px 0;
`;

const InputBox = styled.input`
  width: 424px;
  height: 52px;
  border: 1px solid #555666;
  margin: 10px 0 0 0;
  font-size: 18px;
  padding: 0 60px 0 19px;
  display: block;

  &::placeholder {
    color: #d0d2e8;
  }
`;

const IdNumWrap = styled.div`
  display: flex;
  align-items: center;
`;

const Dash = styled.div`
  height: 2px;
  width: 10px;
  background: #555666;
  margin: 0 15px;
`;

const IdNumInputBox = styled(InputBox)`
  width: 160px;
  padding: 0 19px 0 19px;
`;
const IdNumInputBoxBack = styled(InputBox)`
  width: 35px;
  padding: 0 5px 0 9px;
`;

const IdNumBackEncryption = styled.p`
  font-size: 26px;
  color: #555666;
  margin: 10px 0 0 10px;
`;

const Button = styled.button`
  font-family: 'nexonMedium';
  width: 320px;
  height: 60px;
  background-color: ${theme.colors.primary};
  color: white;
  font-size: 20px;
  letter-spacing: -1.25px;
  margin: 120px auto;
  display: block;
`;

export default WriteAnnouncement;
