import { getCountriesApi } from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class CountryStore {
  countryData = [];

  constructor() {
    makeAutoObservable(this);
  }

  setCountryData = (param) => {
    this.countryData = param;
  };

  getCountries = async () => {
    await getCountriesApi()
      .then((res) => {
        this.setCountryData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const countryStore = new CountryStore();

export default countryStore;
