import {
  getNewsBannerApi,
  getNewsMainsApi,
  getNewsApi,
  getNewsDetailApi,
  postNewsBannersClicksApi,
} from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class NewsStore {
  newsBanners = [];
  newsMain = {};
  newsData = [];
  totalNews = 0;
  totalPages = 0;
  newsDetailData = {};

  constructor() {
    makeAutoObservable(this);
  }

  setNewsBanners = (param) => {
    this.newsBanners = param;
  };

  setNewsMain = (param) => {
    this.newsMain = param;
  };

  setTotalNews = (param) => {
    this.totalNews = param;
  };

  setTotalPages = (param) => {
    this.totalPages = param;
  };

  setNewsData = (param) => {
    this.newsData = param;
  };

  setNewsDetailData = (param) => {
    this.newsDetailData = param;
  };

  getNewsBanner = async () => {
    await getNewsBannerApi()
      .then((res) => {
        this.setNewsBanners(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getNewsMain = async () => {
    await getNewsMainsApi()
      .then((res) => {
        this.setNewsMain(res.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getNews = async (pageNumber, pageSize) => {
    await getNewsApi(pageNumber, pageSize)
      .then((res) => {
        console.log(res.data, 'get news!');
        this.setTotalNews(res.data.totalCount);
        this.setNewsData(res.data.data);
        this.setTotalPages(res.data.totalPages);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getNewsDetail = async (id) => {
    await getNewsDetailApi(id)
      .then((res) => {
        console.log(res.data.data);
        this.setNewsDetailData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postNewsBannersClicks = async (id) => {
    await postNewsBannersClicksApi(id)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const newsStore = new NewsStore();

export default newsStore;
