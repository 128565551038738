import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import { BsFillBookmarkFill, BsBookmark } from 'react-icons/bs';
import { observer } from 'mobx-react';
import stores from 'stores';
import { Link } from 'react-router-dom';
import { Mobile, Others } from 'styles/MediaQuery';

const RecruitCard = observer(
  ({
    id,
    companyName,
    title,
    address,
    payType,
    payAmount,
    apply,
    bookmark,
    style,
    link,
    visaAvailable,
    useSecurePayment,
  }) => {
    const { favoriteStore } = stores;
    const formatPrice = (number) => {
      return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const [favorites, setFavorites] = useState(bookmark);

    const handleClickFavorites = (jobId) => {
      if (!bookmark) {
        favoriteStore.postFavorites(jobId);
        setFavorites(true);
      } else {
        favoriteStore.deleteFavorites(jobId);
        setFavorites(false);
      }
    };

    useEffect(() => {
      setFavorites(bookmark);
    }, [bookmark]);

    return (
      <>
        <Mobile>
          <Wrap style={style}>
            <Link to={{ pathname: link }}>
              <CompanyName>{companyName}</CompanyName>
              <Title>{title}</Title>
              <Address>{address}</Address>
              <PayWrap>
                <PayType>{payType}</PayType>
                <PayAmount>{formatPrice(payAmount)} ￦</PayAmount>
              </PayWrap>
            </Link>
            <Icon onClick={() => handleClickFavorites(id)}>
              {favorites ? <BookmarkTrue /> : <BookmarkFalse />}
            </Icon>
            {apply ? <></> : <Button>지원하기</Button>}
          </Wrap>
        </Mobile>
        <Others>
          <Wrap style={style}>
            <Link to={{ pathname: link }}>
              <FlexWrap>
                <FlexCompanyName>{companyName}</FlexCompanyName>
                <FlexPayWrap style={{ height: '166px', paddingTop: '50px' }}>
                  <FlexPayType>{payType}</FlexPayType>
                  <PayAmount>{formatPrice(payAmount)} ￦</PayAmount>
                </FlexPayWrap>
                <FlexInfo border={true} padding={true} style={{ height: '166px' }}>
                  <FlexTitle>{title}</FlexTitle>
                  <Address>{address}</Address>
                  <TagAllWrap>
                    <TagWrap>
                      {useSecurePayment && <Tag color="purple">#Pay Guaranteed</Tag>}
                    </TagWrap>
                    <TagWrap>{visaAvailable && <Tag color="green">#VISA</Tag>}</TagWrap>
                  </TagAllWrap>
                </FlexInfo>
              </FlexWrap>
            </Link>
            <Icon onClick={() => handleClickFavorites(id)}>
              {favorites ? <BookmarkTrue /> : <BookmarkFalse />}
            </Icon>
            {apply ? <></> : <Button>지원하기</Button>}
          </Wrap>
        </Others>
      </>
    );
  },
);

export const PCRecruitCard = observer(
  ({
    id,
    companyName,
    title,
    address,
    payType,
    payAmount,
    apply,
    bookmark,
    style,
    link,
    visaAvailable,
    useSecurePayment,
    createdDate,
  }) => {
    const { favoriteStore } = stores;
    const formatPrice = (number) => {
      return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const [favorites, setFavorites] = useState(bookmark);

    const handleClickFavorites = (jobId) => {
      if (!bookmark) {
        favoriteStore.postFavorites(jobId);
        setFavorites(true);
      } else {
        favoriteStore.deleteFavorites(jobId);
        setFavorites(false);
      }
    };

    useEffect(() => {
      setFavorites(bookmark);
    }, [bookmark]);

    return (
      <Others>
        <Wrap style={style}>
          <Link to={{ pathname: link }}>
            <PCRecruitCardFlexWrap>
              <FlexInfo style={{ height: '128px' }}>
                <FlexCreatedDate>{createdDate} 등록</FlexCreatedDate>
                <FlexTitle>{title}</FlexTitle>
                <PCRecruitCompanyName>{companyName}</PCRecruitCompanyName>
                <Address>{address}</Address>
              </FlexInfo>
              <PayApplyWrap>
                <FlexPayWrap style={{ height: '128px', paddingTop: '35px' }}>
                  <FlexPayType>{payType}</FlexPayType>
                  <PayAmount>{formatPrice(payAmount)} ￦</PayAmount>
                </FlexPayWrap>
                {apply ? <></> : <PCRecruitButton>지원하기</PCRecruitButton>}
              </PayApplyWrap>
            </PCRecruitCardFlexWrap>
          </Link>
          <Icon onClick={() => handleClickFavorites(id)}>
            {favorites ? <BookmarkTrue /> : <BookmarkFalse />}
          </Icon>
        </Wrap>
      </Others>
    );
  },
);

const Wrap = styled.div`
  width: 100%;
  position: relative;
`;

const CompanyName = styled.p`
  color: #9493a4;
  font-size: 14px;
  font-family: 'nexonMedium';
`;

const Title = styled.p`
  font-family: 'nexonMedium';
  margin: 20px 0 0 0;
  color: #424547;
  font-size: 20px;
  letter-spacing: -1px;
`;

const Address = styled.p`
  font-family: 'nexonMedium';
  font-size: 17px;
  color: #80878e;
  margin: 10px 0 0 0;
`;

const PayWrap = styled.div`
  display: flex;
  margin: 10px 0 0 0;
`;

const PayType = styled.div`
  font-family: 'nexonRegular';
  font-size: 15px;
  height: 24px;
  color: #ff419f;
  border: 2px solid #ff419f;
  border-radius: 12px;
  padding: 2px 7px;
  margin: 0 12px 0 0;
`;

const PayAmount = styled.p`
  font-family: 'nexonBold';
  font-size: 21px;
  color: #1c1c1c;
`;

const Icon = styled.div`
  position: absolute;
  top: 23px;
  right: 30px;
  font-size: 20px;
  z-index: 1000;
  cursor: pointer;
`;

const BookmarkTrue = styled(BsFillBookmarkFill)`
  color: ${theme.colors.primary};
`;

const BookmarkFalse = styled(BsBookmark)`
  color: #9292a1;
`;

const Button = styled.button`
  font-family: 'nexonMedium';
  width: 135px;
  height: 45px;
  border: 2px solid ${theme.colors.purpleGray};
  border-radius: 30px;
  font-size: 20px;
  color: #424547;
  background-color: transparent;
  position: absolute;
  bottom: 15px;
  right: 30px;
`;

const FlexWrap = styled.div`
  display: flex;
  align-items: center;
  height: 168px;
`;

const PCRecruitCardFlexWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;s
`;

const FlexCompanyName = styled.p`
  color: #1f2422;
  font-size: 20px;
  font-family: 'nexonMedium';
  width: 160px;
  text-align: center;
  padding: 60px 20px 0 0;
  height: 166px;
  box-sizing: border-box;
`;

const FlexTitle = styled.p`
  font-family: 'nexonMedium';
  margin: 0 0 16px 0;
  color: #424547;
  font-size: 20px;
  letter-spacing: -1px;
`;

const FlexPayWrap = styled.div`
  display: block;
  width: 200px;
  border-left: 1px solid ${theme.colors.border};
  box-sizing: border-box;
  text-align: center;
`;

const FlexPayType = styled(PayType)`
  display: inline-block;
  text-align: center;
  margin: 0 0 12px 0;
`;

const FlexInfo = styled.div`
  box-sizing: border-box;

  ${(props) =>
    props.border &&
    css`
      border-left: 1px solid ${theme.colors.border};
    `}

  ${(props) =>
    props.padding &&
    css`
      padding: 20px 0 0 20px;
    `}
`;

const TagAllWrap = styled.div`
  display: flex;
  margin: 40px 0 0 0;
`;

const TagWrap = styled.div`
  margin: 0 0 2px 0;

  & + & {
    margin-left: 15px;
  }
`;

const Tag = styled.div`
  font-family: 'nexonMedium';
  padding: 5px;
  background-color: #f1f3f6;
  border-radius: 14px;
  font-size: 15px;
  letter-spacing: -0.6px;
  display: inline-block;

  ${(props) =>
    props.color === 'purple' &&
    css`
      color: #712cef;
    `}

  ${(props) =>
    props.color === 'green' &&
    css`
      color: #00bf87;
    `}
`;

const FlexCreatedDate = styled.p`
  font-size: 13px;
  letter-spacing: -0.33px;
  font-family: 'nexonRegular';
  color: #9292a1;
  margin: 0 0 25px 0;
`;

const PCRecruitCompanyName = styled.p`
  color: #342f2f;
  font-size: 16px;
  font-family: 'nexonMedium';
  margin: 17px 0 0 0;
`;

const PayApplyWrap = styled.div`
  display: flex;
  align-items: center;
`;

const PCRecruitButton = styled.button`
  background-color: transparent;
  border: 2px solid #9292a1;
  height: 40px;
  width: 110px;
  border-radius: 5px;
  font-family: 'nexonRegular';
  font-size: 15px;
  color: #595959;
`;

export default RecruitCard;
