import { getAdvertisementsForMainApi } from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class AdvertisementsStore {
  advertisementsData = [];

  constructor() {
    makeAutoObservable(this);
  }

  setAdvertisementsData = (param) => {
    this.advertisementsData = param;
  };

  getAdvertisementsForMain = async () => {
    await getAdvertisementsForMainApi()
      .then((res) => {
        console.log(res.data.data, 'adver');
        this.setAdvertisementsData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const advertisementsStore = new AdvertisementsStore();

export default advertisementsStore;
