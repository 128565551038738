import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';

const LabeldInput = ({ label, name, value, placeholder, type, ...rest }) => {
  return (
    <InputWrap>
      <Label>{label}</Label>
      <Input name={name} value={value} type={type} placeholder={placeholder} {...rest} />
    </InputWrap>
  );
};

const InputWrap = styled.div`
  & + & {
    margin: 53px 0 0 0;
  }
  @media ${theme.mobile} {
    & + & {
      margin: 45px 0 0 0;
    }
  }
`;
const Label = styled.p`
  font-family: 'nexonMedium';
  font-size: 20px;
  letter-spacing: -1px;
  margin: 0 0 10px 0;
  color: #242d24;
`;
const Input = styled.input`
  width: 100%;
  height: 72px;
  font-size: 22px;
  border: 1px solid ${theme.colors.borderLight};
  font-family: 'nexonRegular';
  padding: 0 0 0 30px;

  &::placeholder {
    color: ${theme.colors.borderLight};
  }

  @media ${theme.mobile} {
    height: 50px;
    font-size: 18px;
    padding: 0 0 0 15px;
  }
`;

export default LabeldInput;
