import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import EmployeeServiceNav, { EmployerServiceNav } from 'components/templates/EmployeeServiceNav';

const MyPageLayout = ({ children }) => {
  return (
    <Wrap>
      {/* <EmployeeServiceNav /> */}
      <EmployerServiceNav />
      <RightSide>{children}</RightSide>
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 1100px;
  display: flex;
  margin: 60px auto 0;
  justify-content: space-between;
`;

const RightSide = styled.div`
  width: 860px;
`;

export default MyPageLayout;
