import { observer } from 'mobx-react';
import React, { useState, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import LogoImg from 'resources/image/landing/logo.png';
import ArrowDownImg from 'resources/image/landing/arrow_down.png';
import GooglePlayImg from 'resources/image/landing/btn_google_play.png';
import AppStoreImg from 'resources/image/landing/btn_app_store.png';
import Main11Img from 'resources/image/landing/main_1_1.png';
import Main11EnImg from 'resources/image/landing/main_1_1_en.png';
import Main21Img from 'resources/image/landing/main_2_1.png';
import Main22Img from 'resources/image/landing/main_2_2.png';
import Main23Img from 'resources/image/landing/main_2_3.png';
import Main31Img from 'resources/image/landing/main_3_1.png';
import Main4Img from 'resources/image/landing/main_4.png';
import Main41Img from 'resources/image/landing/main_employer_4_1.png';
import Main411Img from 'resources/image/landing/main_employer_4_1_1.png';
import Main42Img from 'resources/image/landing/main_employer_4_2.png';
import Main43Img from 'resources/image/landing/main_employer_4_3.png';
import Main44Img from 'resources/image/landing/main_employer_4_4.png';
import Main441Img from 'resources/image/landing/main_employer_4_4_1.png';
import Main45Img from 'resources/image/landing/main_employer_4_5.png';
import Main451Img from 'resources/image/landing/main_employer_4_5_1.png';
import MainE41Img from 'resources/image/landing/main_employee_4_1.png';
import MainE42Img from 'resources/image/landing/main_employee_4_2.png';
import MainE43Img from 'resources/image/landing/main_employee_4_3.png';
import LogoGrey from 'resources/image/landing/logo_grey.png';

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const Landing = observer(() => {
  const { t } = useTranslation('common');

  const [currentLang, setCurrentLang] = useState('ko');
  const [langPopupOpened, setLangPopupOpened] = useState(false);
  const [userType, setUserType] = useState('EMPLOYER');
  const [featureNumber, setFeatureNumber] = useState(1);

  const currentLangTxt = useMemo(() => {
    if (currentLang === 'ko') {
      return '한국어';
    } else if (currentLang === 'en') {
      return 'English';
    }
    return '한국어';
  }, [currentLang]);

  const Main11ImageSource = useMemo(() => {
    if (currentLang === 'ko') {
      return Main11Img;
    } else if (currentLang === 'en') {
      return Main11EnImg;
    }
    return Main11Img;
  }, [currentLang]);

  const handleClickLogo = () => {
    window.location.reload();
  };

  const toggleLangPopup = () => {
    setLangPopupOpened(!langPopupOpened);
  };

  const selectLang = (lang) => {
    setCurrentLang(lang);
    i18n.changeLanguage(lang);
    setLangPopupOpened(false);
  };

  const handleClickEmail = () => {
    window.location.href = 'mailto:seoul@ko-life.co.kr';
  };

  const handleClickKakaoChannel = () => {
    window.open('http://pf.kakao.com/_HxgcxbT');
  };

  const handleClickGooglePlay = () => {
    window.open('https://play.google.com/store/apps/details?id=com.kotrip&hl=ko&gl=US');
  };

  const handleClickAppStore = () => {
    window.open(
      'https://apps.apple.com/kr/app/ko-life-%EC%99%B8%EA%B5%AD%EC%9D%B8-%EC%B1%84%EC%9A%A9%EC%9D%80-%EC%BD%94%EB%9D%BC%EC%9D%B4%ED%94%84/id1547216428',
    );
  };

  const moveToIntro = () => {
    const rect = document.getElementById('section1').getBoundingClientRect();
    window.scrollTo(0, rect.y + window.scrollY);
  };

  const moveToMethod = () => {
    const rect = document.getElementById('sectionSelector').getBoundingClientRect();
    window.scrollTo(0, rect.y + window.scrollY);
  };

  const handleClickFeature1 = () => {
    setFeatureNumber(1);
    // const rect = document.getElementById('feature1').getBoundingClientRect();
    // window.scrollTo(0, rect.y + window.scrollY);
  };

  const handleClickFeature2 = () => {
    setFeatureNumber(2);
    // const rect = document.getElementById('feature2').getBoundingClientRect();
    // window.scrollTo(0, rect.y + window.scrollY);
  };

  const handleClickFeature3 = () => {
    setFeatureNumber(3);
    // const rect = document.getElementById('feature3').getBoundingClientRect();
    // window.scrollTo(0, rect.y + window.scrollY);
  };

  return (
    <Layout>
      <TopNav>
        <SectionInner nav={true}>
          <TopNavLeft>
            <TopLogo onClick={handleClickLogo} src={LogoImg} />
            <MenuContainer>
              <Menu onClick={moveToIntro}>기능 소개</Menu>
              <Menu onClick={moveToMethod}>채용 방법</Menu>
            </MenuContainer>
          </TopNavLeft>
          <LanguageSelector onClick={toggleLangPopup}>
            {currentLangTxt} <ArrowDown src={ArrowDownImg} />
            {langPopupOpened && (
              <LanguageSelectPopup>
                <CurLanguageItem>
                  {currentLangTxt} <ArrowDown src={ArrowDownImg} />
                </CurLanguageItem>
                <LanguageItem
                  onClick={() => selectLang('ko')}
                  selected={currentLang === 'ko'}
                  top={true}
                >
                  한국어
                </LanguageItem>
                <LanguageItem onClick={() => selectLang('en')} selected={currentLang === 'en'}>
                  English
                </LanguageItem>
                {/* <LanguageItem>简体中⽂</LanguageItem>
              <LanguageItem>Tiêng Viêt</LanguageItem>
              <LanguageItem>Русский Язык</LanguageItem> */}
              </LanguageSelectPopup>
            )}
          </LanguageSelector>
        </SectionInner>
      </TopNav>
      <ContentContainer>
        <Section1 id="section1">
          <Section1Inner mobileVertical={true}>
            <Section1Left>
              <Section1Title>{t('main_1')}</Section1Title>
              <Section1Subtitle>
                {t('main_2_1')} <Section1SubtitleStrong>{t('main_2_2')}</Section1SubtitleStrong>
              </Section1Subtitle>
              <StoreButtonContainer>
                <StoreButton src={GooglePlayImg} onClick={handleClickGooglePlay} />
                <StoreButton src={AppStoreImg} onClick={handleClickAppStore} />
              </StoreButtonContainer>
            </Section1Left>
            <Section1Right>
              <Section1Img src={Main11ImageSource} />
            </Section1Right>
          </Section1Inner>
        </Section1>
        <Section2>
          <SectionInner vertical={true}>
            <Section2Title>{t('section_1_1')}</Section2Title>
            <Section2ContentContainer>
              <Section2ContentItem>
                <Section2Img src={Main21Img} />
                <Section2ContentText>
                  {t('section_1_2_1')}
                  <br />
                  <Section2ContentTextStrong>100,000</Section2ContentTextStrong>
                  {t('section_1_2_2')}
                </Section2ContentText>
              </Section2ContentItem>
              <Section2ContentItem>
                <Section2Img src={Main22Img} />
                <Section2ContentText>
                  {t('section_1_3_1')}
                  <br />
                  <Section2ContentTextStrong>2,000</Section2ContentTextStrong>
                  {t('section_1_3_2')}
                </Section2ContentText>
              </Section2ContentItem>
              <Section2ContentItem>
                <Section2Img src={Main23Img} />
                <Section2ContentText>
                  {t('section_1_4_1')}
                  <br />
                  <Section2ContentTextStrong>85%</Section2ContentTextStrong> {t('section_1_4_2')}
                </Section2ContentText>
              </Section2ContentItem>
            </Section2ContentContainer>
          </SectionInner>
        </Section2>
        <Section3>
          <SectionInner vertical={true}>
            <Section3Title>왜 외국인 근로자를 뽑을까요?</Section3Title>
            <Section3Img src={Main31Img} />
            <Section3Info>*외국인 채용기업 1,000개사 대상</Section3Info>
          </SectionInner>
        </Section3>
        <Section4>
          <SectionInner vertical={true}>
            <Section4Title>{t('section_2_1')}</Section4Title>
            <Section4Img src={Main4Img} />
          </SectionInner>
        </Section4>
        <Section5>
          <SectionSelector id="sectionSelector">
            <SectionInner vertical={true}>
              <SelectorButtonContainer>
                <SelectButton
                  left={true}
                  onClick={() => setUserType('EMPLOYER')}
                  selected={userType === 'EMPLOYER'}
                >
                  {t('section_3_1')}
                </SelectButton>
                <SelectButton
                  right={true}
                  onClick={() => setUserType('EMPLOYEE')}
                  selected={userType === 'EMPLOYEE'}
                >
                  {t('section_3_2')}
                </SelectButton>
              </SelectorButtonContainer>
              {userType === 'EMPLOYER' && (
                <SelectorEmployerContainer>
                  <SelectorTitle>{t('section_3_3')}</SelectorTitle>
                  <SelectorItemButtonContainer>
                    <SelectorItemButton
                      onClick={handleClickFeature1}
                      selected={featureNumber === 1}
                    >
                      {t('section_3_4')}
                    </SelectorItemButton>
                    <SelectorItemButton
                      onClick={handleClickFeature2}
                      selected={featureNumber === 2}
                    >
                      {t('section_3_5')}
                    </SelectorItemButton>
                    <SelectorItemButton
                      onClick={handleClickFeature3}
                      selected={featureNumber === 3}
                    >
                      {t('section_3_6')}
                    </SelectorItemButton>
                  </SelectorItemButtonContainer>
                </SelectorEmployerContainer>
              )}
              {userType === 'EMPLOYEE' && (
                <SelectorEmployerContainer>
                  <SelectorTitle>간편하게 일자리를 찾아보세요</SelectorTitle>
                </SelectorEmployerContainer>
              )}
            </SectionInner>
          </SectionSelector>
          {userType === 'EMPLOYER' && (
            <EmployerSection5 id="section5">
              <SectionInner vertical={true}>
                {featureNumber === 1 && (
                  <FeatureItemContainer id="feature1" mobileVertical={true}>
                    <FeatureText>
                      <FeatureTag>{t('section_3_4')}</FeatureTag>
                      <FeatureTitle>{t('f_recruit_1')}</FeatureTitle>
                      <FeatureSubTitle>{t('f_recruit_2')}</FeatureSubTitle>
                      <Feature4Img src={Main411Img} />
                    </FeatureText>
                    <FeatureImg src={Main41Img} />
                  </FeatureItemContainer>
                )}
                {featureNumber === 2 && (
                  <FeatureItemContainer id="feature2" mobileVertical={true}>
                    <FeatureText>
                      <FeatureTag>{t('section_3_5')}</FeatureTag>
                      <FeatureTitle>{t('f_resume_1')}</FeatureTitle>
                      <FeatureSubTitle>{t('f_resume_2')}</FeatureSubTitle>
                    </FeatureText>
                    <FeatureImg src={Main42Img} />
                  </FeatureItemContainer>
                )}
                {featureNumber === 3 && (
                  <FeatureItemContainer id="feature3" mobileVertical={true}>
                    <FeatureText>
                      <FeatureTag>{t('section_3_6')}</FeatureTag>
                      <FeatureTitle>{t('f_chat_1')}</FeatureTitle>
                      <FeatureSubTitle>{t('f_chat_2')}</FeatureSubTitle>
                    </FeatureText>
                    <FeatureImg src={Main43Img} />
                  </FeatureItemContainer>
                )}
              </SectionInner>
            </EmployerSection5>
          )}
          {userType === 'EMPLOYEE' && (
            <EmployeeSection5>
              <FeatureItemContainer verticalReverse={true}>
                <FeatureImg src={MainE41Img} />
                <FeatureText>
                  <FeatureTag>No. 01</FeatureTag>
                  <FeatureTitle>{t('employee_1_1')}</FeatureTitle>
                  <FeatureSubTitle>{t('employee_1_2')}</FeatureSubTitle>
                </FeatureText>
              </FeatureItemContainer>
            </EmployeeSection5>
          )}
        </Section5>
        {userType === 'EMPLOYER' && (
          <>
            <EmployerSection6>
              <SectionInner verticalReverse={true}>
                <EmployerSectionImg src={Main44Img} />
                <EmployerSectionContent>
                  <FeatureTitle>{t('employer_1_1')}</FeatureTitle>
                  <FeatureSubTitle>{t('f_chat_2')}</FeatureSubTitle>
                  <EmployerSection6HorizontalImageContainer>
                    <EmployerSection6HorizontalImage src={Main441Img} />
                  </EmployerSection6HorizontalImageContainer>
                </EmployerSectionContent>
              </SectionInner>
            </EmployerSection6>
            <EmployerSection7>
              <SectionInner>
                <FeatureItemContainer mobileVertical={true}>
                  <FeatureText>
                    <FeatureTitle>{t('employer_2_1')}</FeatureTitle>
                    <FeatureSubTitle>{t('employer_2_2')}</FeatureSubTitle>
                    <EmployerSection7SubImg src={Main451Img} />
                  </FeatureText>
                  <FeatureImg src={Main45Img} />
                </FeatureItemContainer>
              </SectionInner>
            </EmployerSection7>
          </>
        )}
        {userType === 'EMPLOYEE' && (
          <>
            <EmployeeSection6>
              <FeatureItemContainer mobileVertical={true}>
                <FeatureText>
                  <FeatureTag>No. 02</FeatureTag>
                  <FeatureTitle>{t('employee_2_1')}</FeatureTitle>
                  <FeatureSubTitle>{t('employee_2_2')}</FeatureSubTitle>
                </FeatureText>
                <FeatureImg src={MainE42Img} />
              </FeatureItemContainer>
            </EmployeeSection6>
            <EmployeeSection7>
              <FeatureItemContainer mobileVertical={true}>
                <FeatureText>
                  <FeatureTag>No. 03</FeatureTag>
                  <FeatureTitle>{t('employee_3_1')}</FeatureTitle>
                  <FeatureSubTitle>{t('employee_3_2')}</FeatureSubTitle>
                </FeatureText>
                <FeatureImg src={MainE43Img} />
              </FeatureItemContainer>
            </EmployeeSection7>
          </>
        )}
        <ContactFooterSection>
          <ContactSection>
            <SectionInner vertical={true}>
              <StoreButtonContainer>
                <StoreButton src={GooglePlayImg} onClick={handleClickGooglePlay} />
                <StoreButton src={AppStoreImg} onClick={handleClickAppStore} />
              </StoreButtonContainer>
              <ContactTitle>CONTACT</ContactTitle>
              <ContactCircle>
                <ContactSubTitle>{t('contact_1')}</ContactSubTitle>
                <ContactSubValue onClick={handleClickKakaoChannel}>
                  {t('contact_2')}
                </ContactSubValue>
              </ContactCircle>
              <ContactCircle>
                <ContactSubTitle>{t('contact_3')}</ContactSubTitle>
                <ContactSubValue onClick={handleClickEmail}>seoul@ko-life.co.kr</ContactSubValue>
              </ContactCircle>
            </SectionInner>
          </ContactSection>
          <Footer>
            <SectionInner vertical={true}>
              <FooterLogo onClick={handleClickLogo} src={LogoGrey} />
              <FooterItem>(주) 아웃랜더 Outlander</FooterItem>
              <FooterItem>대표자 : 윤미혜 | 대표전화 : 1660-1656</FooterItem>
              <FooterItem>
                홈페이지 : www.ko-life.co.kr | 이메일 :{' '}
                <FooterAchor href="mailto:seoul@ko-life.co.kr">seoul@ko-life.co.kr</FooterAchor>
              </FooterItem>
              <FooterItem>
                사업자 등록 번호 : 492-87-02358 | 통신판매업신고번호 : 제2022-충북충주-0314호 |
                직업정보제공사업신고번호 : J1712020220001 | 개인정보보호책임자 : 김준규
              </FooterItem>
              <FooterItem>
                본점 : 충청북도 충주시 충원대로 268 건국대학교 창업보육센터 407호 | 지점 :
                서울특별시 금천구 가산디지털1로 120 G밸리 창업큐브 813호
              </FooterItem>
            </SectionInner>
          </Footer>
        </ContactFooterSection>
      </ContentContainer>
    </Layout>
  );
});

const Layout = styled.div`
  margin: 0 auto;
  @media ${theme.laptop} {
    width: 100%;
  }
`;

const TopNav = styled.div`
  width: 100%;
  position: fixed;
  background: white;
  margin: 0 auto;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${theme.laptop} {
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
    margin: 0 auto;
    height: 52px;
  }
`;

const TopNavLeft = styled.div`
  display: flex;
`;

const TopLogo = styled.img`
  cursor: pointer;
  width: 120px;
  height: 23px;
  margin-right: 100px;

  @media ${theme.laptop} {
    width: 78px;
    height: 15px;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;

  @media ${theme.laptop} {
    display: none;
  }
`;

const Menu = styled.div`
  cursor: pointer;
  font-family: 'PretendardBold';
  font-size: 16px;
  margin-left: 60px;
  margin-right: 60px;
`;

const LanguageSelector = styled.div`
  cursor: pointer;
  font-family: 'PretendardMedium';
  font-size: 14px;
  position: relative;
  @media ${theme.laptop} {
    font-size: 12px;
  }
`;

const ArrowDown = styled.img``;

const LanguageSelectPopup = styled.div`
  top: -12px;
  right: -12px;
  position: absolute;
  width: 135px;
  padding: 22px;
  border-radius: 20px;
  box-shadow: 0px 0px 20px #ccc;
  background: white;
  text-align: right;
`;

const CurLanguageItem = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const LanguageItem = styled.div`
  cursor: pointer;
  font-family: 'PretendardMedium';
  font-size: 12px;
  color: black;
  margin-top: 12px;
  margin-right: 8px;
  text-align: right;

  ${(props) =>
    props.selected === true &&
    css`
      color: #9827cc;
    `}

  ${(props) =>
    props.top === true &&
    css`
      margin-top: 20px;
    `}

  @media ${theme.laptop} {
  }
`;

const SectionInner = styled.div`
  width: 1280px;
  margin: 0 auto;
  display: flex;

  @media ${theme.laptop} {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }
  ${(props) =>
    props.vertical === true &&
    css`
      flex-direction: column;
    `}

  ${(props) =>
    props.verticalReverse === true &&
    css`
      justify-content: center;
      align-items: center;
      @media ${theme.laptop} {
        flex-direction: column-reverse;
      }
    `}

  ${(props) =>
    props.mobileVertical === true &&
    css`
      @media ${theme.laptop} {
        flex-direction: column;
      }
    `}

  ${(props) =>
    props.nav === true &&
    css`
      justify-content: space-between;
    `}
`;

const ContentContainer = styled.div``;

const Section1 = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: space-between;
  min-height: 480px;

  @media ${theme.laptop} {
    height: auto;
  }

  @media ${theme.mobile} {
    height: 100vh;
  }
`;

const Section1Inner = styled(SectionInner)`
  margin: 64px auto 0;
`;

const Section1Left = styled.div`
  flex: 1;
`;

const Section1Title = styled.h1`
  font-size: 52px;
  font-family: 'PretendardBold';
  white-space: pre-line;

  @media ${theme.laptop} {
    font-size: 32px;
  }
`;

const Section1Subtitle = styled.h2`
  font-size: 18px;
  font-family: 'PretendardMedium';
  margin-top: 8px;

  @media ${theme.laptop} {
    font-size: 14px;
  }
`;

const Section1SubtitleStrong = styled.span`
  font-family: 'PretendardBold';
`;

const StoreButtonContainer = styled.div`
  display: flex;
  margin: 0 auto;

  @media ${theme.laptop} {
    justify-content: center;
  }
`;

const StoreButton = styled.img`
  cursor: pointer;

  @media ${theme.laptop} {
    width: 160px;
  }

  @media ${theme.sMobile} {
    width: 130px;
  }
`;

const Section1Right = styled.div`
  flex: 1;
`;

const Section1Img = styled.img`
  width: 100%;
`;

const Section2 = styled.div`
  background: #fbf7fd;
  display: flex;
  height: calc(100vh - 64px);
  align-items: center;
  min-height: 530px;

  @media ${theme.laptop} {
    height: calc(100vh - 52px);
  }
`;

const Section2Title = styled.div`
  font-family: 'PretendardBold';
  font-size: 40px;
  color: black;
  text-align: center;
  white-space: pre-line;

  @media ${theme.laptop} {
    font-size: 30px;
  }
`;

const Section2ContentContainer = styled.div`
  display: flex;
  padding-top: 60px;
`;

const Section2ContentItem = styled.div`
  flex: 1;
  background: #fefefe;
  border-radius: 40px;
  height: 330px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  padding-top: 35px;
  box-shadow: 0px 0px 20px #ccc;

  @media ${theme.laptop} {
    height: max-content;
    border-radius: 25px;
    padding-top: 0px;
    padding-bottom: 20px;
    margin-left: 6px;
    margin-right: 6px;
  }

  @media ${theme.sMobile} {
    height: 120px;
    border-radius: 18px;
  }
`;

const Section2Img = styled.img`
  @media ${theme.laptop} {
    width: 60%;
  }
`;

const Section2ContentText = styled.div`
  font-family: 'PretendardBold';
  font-size: 32px;
  color: black;
  margin-top: 20px;

  @media ${theme.laptop} {
    font-size: 14px;
    margin-top: 5px;
  }

  @media ${theme.sMobile} {
    font-size: 10px;
    margin-top: 2px;
  }
`;

const Section2ContentTextStrong = styled.span`
  font-family: 'PretendardBold';
  font-size: 32px;
  color: #561d86;

  @media ${theme.laptop} {
    font-size: 14px;
  }
`;

const Section3 = styled.div`
  text-align: center;
  display: flex;
  height: calc(100vh - 64px);
  align-items: center;
  min-height: 530px;

  @media ${theme.laptop} {
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media ${theme.mobile} {
    height: calc(100vh - 52px);
  }
`;

const Section3Title = styled.h3`
  font-family: 'PretendardBold';
  font-size: 40px;
  color: black;

  @media ${theme.laptop} {
    font-size: 30px;
  }
`;

const Section3Img = styled.img`
  // width: 880px;
  height: 60vh;
  min-height: 315px;
  margin: 0 auto;
  margin-top: 30px;
  @media ${theme.laptop} {
    width: 100%;
    height: auto;
  }
`;

const Section3Info = styled.div`
  font-family: 'PretendardRegular';
  font-size: 18px;
  color: black;
  text-align: right;

  @media ${theme.laptop} {
    font-size: 10px;
    margin-top: 12px;
  }
`;

const Section4 = styled.div`
  background: #fbf7fd;
  text-align: center;
  display: flex;
  height: calc(100vh - 64px);
  align-items: center;
  min-height: 530px;

  @media ${theme.laptop} {
    height: auto;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  @media ${theme.mobile} {
    height: calc(100vh - 52px);
  }
`;

const Section4Title = styled.h3`
  font-family: 'PretendardBold';
  font-size: 40px;
  color: black;

  @media ${theme.laptop} {
    font-size: 28px;
  }
`;

const Section4Img = styled.img`
  // width: 670px;
  height: 60vh;
  margin: 0 auto;
  margin-top: 40px;
  @media ${theme.laptop} {
    width: 100%;
    height: auto;
  }
`;

const SectionSelector = styled.div`
  background: #fbf7fd;
  text-align: center;
  padding: 40px 0;
  width: 100%;

  @media ${theme.laptop} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const SelectorButtonContainer = styled.div`
  display: flex;
  width: 556px;
  height: 90px;
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: 0px 0px 10px #ccc;

  @media ${theme.laptop} {
    max-width: 330px;
    height: 50px;
    border-radius: 14px;
    margin: 0 auto;
  }

  @media ${theme.sMobile} {
    max-width: 95%;
  }
`;

const SelectButton = styled.div`
  cursor: pointer;
  flex: 1;
  font-family: 'PretendardBold';
  font-size: 24px;
  text-align: center;
  color: #561d86;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.selected === true &&
    css`
      background: #561d86;
      color: white;
    `}
  ${(props) =>
    props.left === true &&
    css`
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;

      @media ${theme.laptop} {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    `}
    ${(props) =>
    props.right === true &&
    css`
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;

      @media ${theme.laptop} {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
    `}

  
  @media ${theme.laptop} {
    font-size: 14px;
  }
`;

const SelectorTitle = styled.h3`
  font-family: 'PretendardBold';
  font-size: 40px;
  color: black;

  @media ${theme.laptop} {
    font-size: 30px;
  }
`;

const SelectorEmployerContainer = styled.div``;

const SelectorItemButtonContainer = styled.div`
  width: 700px;
  margin: 0 auto;
  display: flex;
  @media ${theme.laptop} {
    width: 90%;
    margin: 0 auto;
  }
`;

const SelectorItemButton = styled.div`
  cursor: pointer;
  display: flex;
  flex: 1;
  border-radius: 40px;
  margin-left: 12px;
  margin-right: 12px;
  height: 72px;
  text-align: center;
  font-size: 24px;
  font-family: 'PretendardBold';
  background: #f0dfff;
  align-items: center;
  justify-content: center;
  color: #561d86;

  ${(props) =>
    props.selected === true &&
    css`
      box-shadow: 0px 0px 10px #ccc;
      background: white;
    `}

  @media ${theme.laptop} {
    border-radius: 25px;
    margin-left: 8px;
    margin-right: 8px;
    height: 36px;
    text-align: center;
    font-size: 14px;
  }

  @media ${theme.sMobile} {
    border-radius: 18px;
    font-size: 12px;
    height: 30px;
    margin-left: 3px;
    margin-right: 3px;
  }
`;

const SelectorEmployeeContainer = styled.div``;

const FeatureItemContainer = styled.div`
  display: flex;
  padding: 10px 0;

  width: 900px;
  margin: 0 auto;
  @media ${theme.laptop} {
    width: 90%;
    text-align: center;
  }

  ${(props) =>
    props.mobileVertical === true &&
    css`
      @media ${theme.laptop} {
        flex-direction: column;
      }
    `}

  ${(props) =>
    props.verticalReverse === true &&
    css`
      @media ${theme.laptop} {
        flex-direction: column-reverse;
      }
    `}
`;

const FeatureTag = styled.span`
  width: fit-content;
  margin: 0 auto;
  display: inline-block;
  background: #561d86;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  text-align: center;
  color: white;
  font-size: 18px;
  font-family: 'PretendardBold';

  @media ${theme.laptop} {
    padding-left: 12px;
    padding-right: 12px;
    height: 25px;
    line-height: 25px;
    border-radius: 14px;
    font-size: 12px;
  }
`;

const FeatureTitle = styled.h4`
  font-family: 'PretendardBold';
  color: black;
  font-size: 40px;
  margin-bottom: 28px;
  margin-top: 25px;
  white-space: pre-line;

  @media ${theme.laptop} {
    font-size: 24px;
    margin-bottom: 18px;
    margin-top: 15px;
  }
`;

const FeatureSubTitle = styled.h5`
  font-family: 'PretendardRegular';
  color: black;
  font-size: 18px;
  line-height: 24px;
  margin-top: 10px;
  white-space: pre-line;

  @media ${theme.laptop} {
    font-size: 14px;
    line-height: 20px;
    margin-top: 6px;
  }
`;

const FeatureText = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FeatureImg = styled.img`
  /* width: 252px;
  height: 490px; */
  height: 45vh;
  min-height: 300px;

  @media ${theme.laptop} {
    margin: 0 auto;
    min-height: 250px;
    height: 250px;
  }

  @media ${theme.mobile} {
    min-height: 250px;
    height: 250px;
  }
`;

const Feature4Img = styled.img`
  width: 100%;

  @media ${theme.laptop} {
    width: 70%;
    margin: 0 auto;
  }
`;

const EmployerSection5 = styled.div`
  height: calc(100vh - 370px - 64px);
  display: flex;
  align-items: center;
  min-height: 400px;

  @media ${theme.laptop} {
    height: calc(100vh - 262px - 52px);
    min-height: 500px;
  }
`;

const EmployerSection6 = styled.div`
  background: #f0efff;
  display: flex;
  height: calc(100vh - 64px);
  align-items: center;
  min-height: 530px;

  @media ${theme.laptop} {
    padding-top: 35px;
    padding-bottom: 35px;
    height: auto;
  }

  @media ${theme.mobile} {
    height: calc(100vh - 52px);
  }
`;

const EmployerSectionImg = styled.img`
  height: 45vh;
  min-height: 300px;
`;

const EmployerSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const EmployerSection6HorizontalImageContainer = styled.div`
  overflow-x: hidden;
  @media ${theme.laptop} {
    width: 100%;
    overflow-x: hidden;
  }
`;

const EmployerSection6HorizontalImage = styled.img`
  height: 11.5vh;
  min-height: 70px;
  @media ${theme.laptop} {
    width: 100%;
    overflow-x: hidden;
    height: auto;
  }
`;

const EmployerSection7 = styled.div`
  background: #f9f9f9;
  display: flex;
  height: calc(100vh - 64px);
  align-items: center;
  min-height: 530px;

  @media ${theme.laptop} {
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media ${theme.mobile} {
    height: calc(100vh - 52px);
  }
`;

const EmployerSection7SubImg = styled.img`
  width: 320px;
  margin: 0 auto;

  @media ${theme.laptop} {
    width: 40%;
  }

  @media ${theme.mobile} {
    width: 80%;
  }
`;

const ContactSection = styled.div`
  background: white;
  height: calc(100vh - 64px - 295px);
  display: flex;
  align-items: center;
  min-height: 420px;

  @media ${theme.laptop} {
    padding-top: 40px;
    padding-bottom: 40px;
    height: calc(100vh - 52px - 215px);
  }
`;

const ContactTitle = styled.h3`
  font-family: 'PretendardSemiBold';
  color: black;
  font-size: 90px;
  margin: 15px auto 30px auto;

  @media ${theme.laptop} {
    font-size: 40px;
    margin: 8px auto 15px auto;
  }
`;

const ContactCircle = styled.div`
  width: 480px;
  margin: 10px auto;
  border: 2px solid #e3e3e3;
  padding: 18px 34px;
  font-family: 'PretendardSemiBold';
  font-size: 20px;
  border-radius: 32px;
  display: flex;
  justify-content: space-between;
  @media ${theme.laptop} {
    width: 80%;
    padding: 12px 18px;
    font-size: 14px;
    border-radius: 18px;
  }

  @media ${theme.sMobile} {
    width: 95%;
  }
`;

const ContactSubTitle = styled.div`
  font-family: 'PretendardSemiBold';
  font-size: 20px;
  color: #838388;
  flex: 1;

  @media ${theme.laptop} {
    font-size: 14px;
  }

  @media ${theme.sMobile} {
    font-size: 11px;
  }
`;

const ContactSubValue = styled.div`
  font-family: 'PretendardSemiBold';
  font-size: 20px;
  color: black;
  cursor: pointer;

  @media ${theme.laptop} {
    font-size: 14px;
  }

  @media ${theme.sMobile} {
    font-size: 11px;
  }
`;

const EmployeeSection5 = styled.div`
  height: calc(100vh - 298px - 64px);
  display: flex;
  align-items: center;
  min-height: 400px;

  @media ${theme.laptop} {
    height: calc(100vh - 226px - 52px);
    min-height: 480px;
  }
`;

const EmployeeSection6 = styled.div`
  background: #f0efff;
  display: flex;
  height: calc(100vh - 64px);
  align-items: center;
  min-height: 530px;

  @media ${theme.laptop} {
    padding-top: 35px;
    padding-bottom: 35px;
    height: auto;
  }

  @media ${theme.mobile} {
    height: calc(100vh - 52px);
  }
`;

const EmployeeSection7 = styled.div`
  background: #f9f9f9;
  display: flex;
  height: calc(100vh - 64px);
  align-items: center;
  min-height: 530px;

  @media ${theme.laptop} {
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media ${theme.mobile} {
    height: calc(100vh - 52px);
  }
`;

const EmployeeSection8 = styled.div`
  background: white;
`;

const Footer = styled.div`
  background: #f9f9f9;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  height: 295px;

  @media ${theme.laptop} {
    height: 215px;
    padding-top: 25px;
    padding-bottom: 40px;
  }
`;

const FooterLogo = styled.img`
  cursor: pointer;
  width: 180px;
  height: 35px;
  margin-bottom: 18px;

  @media ${theme.laptop} {
    width: 90px;
    height: 18px;
  }
`;

const FooterItem = styled.div`
  font-size: 14px;
  font-family: 'PretendardMedium';
  color: #838388;
  margin-top: 12px;

  @media ${theme.laptop} {
    font-size: 10px;
    margin-top: 6px;
  }
`;

const FooterAchor = styled.a`
  font-size: 14px;
  font-family: 'PretendardMedium';
  color: #838388;

  @media ${theme.laptop} {
    font-size: 10px;
  }
`;

const Section5 = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 64px);
  max-height: auto;
  align-items: center;

  @media ${theme.laptop} {
    min-height: calc(100vh - 52px);
    height: auto;
  }
`;

const ContactFooterSection = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  align-items: center;

  @media ${theme.laptop} {
    height: calc(100vh - 52px);
  }
`;

export default Landing;
