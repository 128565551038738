import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import { observer } from 'mobx-react';
import stores from 'stores';
import KakaoTalkLogo from 'resources/image/Kakaotalk_logo.png';
import FacebookLogo from 'resources/image/Facebook_logo.png';
import GoogleLogo from 'resources/image/Google_logo.png';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';

const SignIn = observer(() => {
  const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}&response_type=code`;

  const { userStore } = stores;

  const [inputs, setInputs] = useState({ id: '', password: '' });
  const { id, password } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const [role, setRole] = useState('EMPLOYEE');
  const handleTab = (role) => {
    setRole(role);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const data = {
      authType: 'EMAIL',
      email: id,
      name: '',
      password: password,
      role: role,
      thirdPartyAccessToken: 'string',
      thirdPartyUserId: 'string',
    };
    console.log(data);
    userStore.signInUsers(data);
  };

  const handleKaKaoLogin = () => {
    window.location.href = KAKAO_AUTH_URL;
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (res) => {
      // const params = new URLSearchParams();
      // params.append('idToken', res.tokenObj.id_token);
      console.log(res);
    },
    flow: 'auth-code',

    // const googleLogin = async () => {
    //   const res = await axios.post('요청 주소', params, {
    //     headers: {
    //       'Content-Type': 'application/x-www-form-urlencoded',
    //     },
    //   });
    //   localStorage.setItem('accessToken', res.data.token.access);
    //   localStorage.setItem('refreshToken', res.data.token.refresh);
    // };

    // googleLogin()
  });

  const onFailure = (error) => {
    console.log(error);
  };

  return (
    <PageLayout>
      <ContentWrap>
        <LoginBox>
          <TabWrap>
            <EmployeeTab onClick={() => handleTab('EMPLOYEE')} active={role}>
              구직자 회원
            </EmployeeTab>
            <EmployerTab onClick={() => handleTab('EMPLOYER')} active={role}>
              기업 회원
            </EmployerTab>
          </TabWrap>
          <TopButtonWrap>
            <TextButton>회원가입하기</TextButton>
          </TopButtonWrap>
          <LoginInputWrap>
            <LeftInputBox>
              <Input name="id" value={id} placeholder="아이디" onChange={onChange} />
              <Input name="password" value={password} placeholder="비밀번호" onChange={onChange} />
            </LeftInputBox>
            <LoginButton onClick={handleLogin}>로그인</LoginButton>
          </LoginInputWrap>
          <BottomButtonWrap>
            <TextButton>로그인 상태 유지</TextButton>
            <TextButton>비밀번호 찾기</TextButton>
          </BottomButtonWrap>
        </LoginBox>
        <SNSLoginWrap>
          <SNSItem onClick={handleKaKaoLogin}>
            <SNSImageBox>
              <SNSImg src={KakaoTalkLogo} alt="kakaoTalk-logo" />
            </SNSImageBox>
            <SNSButtonText>
              카카오톡
              <br />
              로그인
            </SNSButtonText>
          </SNSItem>
          <FacebookLogin
            appId="3339139296323940"
            onSuccess={(response) => {
              console.log('Login Success!');
              console.log('id: ', response.id);
            }}
            onFail={(error) => {
              console.log('Login Failed!');
              console.log('status: ', error.status);
            }}
            onProfileSuccess={(response) => {
              console.log('Get Profile Success!');
              console.log('name: ', response.name);
            }}
            render={(renderProps) => (
              <SNSItem onClick={renderProps.onClick}>
                <SNSImageBox>
                  <SNSImg src={FacebookLogo} alt="facebook-logo" />
                </SNSImageBox>
                <SNSButtonText>
                  페이스북
                  <br />
                  로그인
                </SNSButtonText>
              </SNSItem>
            )}
          />
          <SNSItem onClick={() => handleGoogleLogin()}>
            <SNSImageBoxGoogle>
              <SNSImg src={GoogleLogo} alt="google-logo" />
            </SNSImageBoxGoogle>
            <SNSButtonText>
              구글
              <br />
              로그인
            </SNSButtonText>
          </SNSItem>
          {/* <GoogleOAuthProvider clientId="719063081930-3fgcvfbsq1edm0p1n62datkbthu44jom.apps.googleusercontent.com">
            <GoogleLogin
              onSuccess={onGoogleSignInSuccess}
              onFailure={onFailure}
              render={(renderProps) => (
              )}
            />
          </GoogleOAuthProvider> */}
        </SNSLoginWrap>
      </ContentWrap>
    </PageLayout>
  );
});

const ContentWrap = styled.div`
  padding: 0 30px;
`;

const LoginBox = styled.div`
  border: 1px solid ${theme.colors.border};
  height: 340px;
  margin: 56px 0 0 0;
`;

const TabWrap = styled.div`
  display: flex;
`;

const EmployeeTab = styled.div`
  font-family: 'nexonMedium';
  width: 50%;
  height: 50px;
  text-align: center;
  font-size: 20px;
  padding: 15px 0 0 0;
  border-bottom: 1px solid ${theme.colors.border};
  border-right: 1px solid ${theme.colors.border};

  ${(props) =>
    props.active === 'EMPLOYEE' &&
    css`
      border-bottom: 1px solid white;
      border-right: 1px solid white;
    `}
`;

const EmployerTab = styled.div`
  font-family: 'nexonMedium';
  width: 50%;
  height: 50px;
  text-align: center;
  font-size: 20px;
  padding: 14px 0 0 0;
  border-bottom: 1px solid ${theme.colors.border};
  border-left: 1px solid ${theme.colors.border};

  ${(props) =>
    props.active === 'EMPLOYER' &&
    css`
      border-bottom: 1px solid white;
      border-left: 1px solid white;
    `}
`;

const TopButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 50px 50px 10px 50px;
`;

const TextButton = styled.button`
  font-family: 'nexonRegular';
  font-size: 15px;
  background: transparent;
`;

const LoginInputWrap = styled.div`
  margin: 0 50px;
  display: flex;
`;

const LeftInputBox = styled.div`
  width: 266px;
`;

const Input = styled.input`
  width: 100%;
  height: 56px;
  border: 1px solid ${theme.colors.borderLight};
  font-size: 17px;
  padding: 0 0 0 22px;
  font-family: 'nexonRegular';

  &::placeholder {
    color: ${theme.colors.borderLight};
  }

  & + & {
    border-top: none;
  }
`;

const LoginButton = styled.button`
  width: 128px;
  height: 112px;
  background-color: ${theme.colors.primary};
  color: white;
  font-size: 18px;
`;

const BottomButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 50px 0 50px;
`;

const SNSLoginWrap = styled.div`
  margin: 37px 85px 0;
  display: flex;
  justify-content: space-between;
`;

const SNSItem = styled.button`
  width: 92px;
  background: transparent;
  padding: 0;
`;

const SNSImageBox = styled.div`
  width: 92px;
  height: 92px;
  border-radius: 50px;
  overflow: hidden;
`;

const SNSImageBoxGoogle = styled(SNSImageBox)`
  padding: 30px 0;
  border: 1px solid #eee;
`;

const SNSImg = styled.img`
  width: 100%;
`;

const SNSButtonText = styled.p`
  font-size: 15px;
  font-family: 'nexonRegular';
  text-align: center;
  margin: 16px 0 0 0;
`;

export default SignIn;
