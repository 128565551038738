import { postFavoritesApi, deleteFavoritesApi, getFavoritesApi } from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class FavoriteStore {
  favoritesData = [];
  totalFavorites = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setFavoritesData = (param) => {
    this.favoritesData = param;
  };
  setTotalFavorites = (param) => {
    this.totalFavorites = param;
  };

  postFavorites = async (jobId) => {
    await postFavoritesApi(jobId)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteFavorites = async (jobId) => {
    await deleteFavoritesApi(jobId)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getFavorites = async (langCode, pageNumber, pageSize) => {
    await getFavoritesApi(langCode, pageNumber, pageSize)
      .then((res) => {
        console.log(res);
        this.setFavoritesData(res.data.data);
        this.setTotalFavorites(res.data.totalCount);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const favoriteStore = new FavoriteStore();

export default favoriteStore;
