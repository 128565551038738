import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import { observer } from 'mobx-react';

export const AnnouncementHistory = observer(({ date, title, leftPeriod, payAmount, jobType }) => {
  return (
    <Wrap>
      <Section>
        <FlexWrap>
          <LightText>{date} 게시</LightText>
          <Title>공고 제목 ㅣ {title}</Title>
        </FlexWrap>
        <BigText>{jobType}</BigText>
      </Section>
      <Section>
        <LightText>남은 기간</LightText>
        <BigText>{leftPeriod}</BigText>
      </Section>
      <Section>
        <LightText>결제 금액</LightText>
        <BigText>{payAmount.toLocaleString()}원</BigText>
      </Section>
      <Section>
        <Button>공고 보기</Button>
      </Section>
    </Wrap>
  );
});

export const DepositWait = observer(({ account, payAmount, jobType }) => {
  return (
    <Wrap>
      <Section>
        <LightText>가상 계좌</LightText>
        <FlexWrap>
          <BigText>신한 {account}</BigText>
          <Border></Border>
          <BigText>{jobType}</BigText>
        </FlexWrap>
      </Section>
      <Section>
        <LightText>입금 금액</LightText>
        <BigTextBold>{payAmount.toLocaleString()}원</BigTextBold>
      </Section>
    </Wrap>
  );
});

export const ReadingTicket = observer(({ reading, point, date }) => {
  return (
    <Wrap>
      <Section>
        <LightText>
          {date}
          {reading ? '' : ' 결제'}
        </LightText>
        <FlexWrap>
          <BigText>{reading ? '이력서 열람' : `열람권 ${point.toLocaleString()} 포인트`}</BigText>
        </FlexWrap>
      </Section>
      <Section>
        <LightText>결제 금액</LightText>
        <BigTextBold>
          {reading ? `-${point.toLocaleString()} 포인트` : `${point.toLocaleString()} 원`}
        </BigTextBold>
      </Section>
    </Wrap>
  );
});

const Wrap = styled.div`
  padding: 11px 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #8d8a9b;
`;

const Section = styled.div`
  min-width: 110px;
`;

const FlexWrap = styled.div`
  display: flex;
`;

const LightText = styled.p`
  font-size: 14px;
  color: ${theme.colors.purpleGray};
  font-family: 'nexonMedium';
  letter-spacing: -0.4px;
`;

const Title = styled(LightText)`
  font-family: 'nexonRegular';
  color: #434343;
  margin: 0 0 0 17px;
`;

const BigText = styled(LightText)`
  font-size: 18px;
  color: #434343;
  margin: 19px 0 0 0;
`;

const Border = styled.div`
  background-color: ${theme.colors.purpleGray};
  width: 2px;
  margin: 14px 36px 0;
  height: 26px;
`;

const BigTextBold = styled(BigText)`
  font-family: 'nexonBold';
`;

const Button = styled.button`
  width: 118px;
  height: 44px;
  border-radius: 5px;
  border: 2px solid ${theme.colors.primary};
  background-color: transparent;
  font-size: 18px;
  font-family: 'nexonBold';
  color: #434343;
`;
