import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo from 'resources/image/logo_global.png';
import { Mobile, Others } from 'styles/MediaQuery';

import { BiChevronDown } from 'react-icons/bi';

const Navbar = () => {
  const location = useLocation();

  const userType = localStorage.getItem('userType');

  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Mobile>
        <Wrap active={isOpen}>
          <NavItemWrap>
            <Logo>
              <img src={logo} alt="logo-img" />
            </Logo>
            <RightSide>
              {userType === 'EMPLOYEE' ? (
                <DropMenuButton onClick={handleDropdownMenu}>
                  <DropMenuButtonText>구직자 서비스</DropMenuButtonText>
                  <Icon />
                </DropMenuButton>
              ) : (
                <Link to="/signIn">
                  <LoginButton>로그인</LoginButton>
                </Link>
              )}
            </RightSide>
          </NavItemWrap>
          <DropDownMenu active={isOpen}>
            <ListUl active={isOpen}>
              <ListLi active={location.pathname === '/mypage/alarm'}>
                <Link to="/mypage/alarm">알림 설정</Link>
              </ListLi>
              <ListLi active={location.pathname === '/mypage/modifyResume'}>
                <Link to="/mypage/modifyResume">이력서 수정</Link>
              </ListLi>
              <ListLi active={location.pathname === '/mypage/applystatus'}>
                <Link to="/mypage/applystatus">지원 현황</Link>
              </ListLi>
              <ListLi active={location.pathname === '/mypage/favorites'}>
                <Link to="/mypage/favorites">즐겨찾기</Link>
              </ListLi>
              <ListLi active={location.pathname === '/mypage/language'}>
                <Link to="/mypage/language">언어 변경</Link>
              </ListLi>
              <ListLi>문의하기</ListLi>
            </ListUl>
          </DropDownMenu>
        </Wrap>
      </Mobile>
      <Others>
        <Wrap>
          <OthersNavBoxWrap>
            <OthersNavItemsWrap>
              <Logo>
                <img src={logo} alt="logo-img" />
              </Logo>
              <OthersRightSide>
                <OthersDropMenuButton>
                  구직자 서비스
                  <IconBlack />
                </OthersDropMenuButton>
                <OthersDropMenuButton>
                  기업 서비스
                  <IconBlack />
                </OthersDropMenuButton>
              </OthersRightSide>
            </OthersNavItemsWrap>
          </OthersNavBoxWrap>
        </Wrap>
      </Others>
    </>
  );
};

const Wrap = styled.div`
  width: 100%;

  ${(props) =>
    props.active === false &&
    css`
      height: 83px;
    `}
`;

const NavItemWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 83px;
  padding: 0 26px;
  box-shadow: 0 0 9.5px 1.1px rgba(38, 42, 47, 0.3);
  z-index: 1;
  position: relative;
  background-color: white;
`;

const Logo = styled.h1`
  width: 150px;
  margin: 5px 0 0 0;

  & > img {
    width: 100%;
  }
`;

const RightSide = styled.div``;

const LoginButton = styled.button`
  width: 84px;
  height: 34px;
  border-radius: 20px;
  border: 2px solid ${theme.colors.primary};
  background-color: transparent;
  font-family: 'nexonMedium';
  padding: 2px 0 0 0;
`;

const DropMenuButton = styled.button`
  width: 142px;
  height: 53px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DropMenuButtonText = styled.p`
  font-family: 'nexonMedium';
  font-size: 17px;
  color: #403f49;
  margin: 0 0 0 8px;
`;

const Icon = styled(BiChevronDown)`
  font-size: 25px;
  color: ${theme.colors.primary};
`;

const DropDownMenu = styled.div`
  background-color: #eef1f5;
  /* transition: 0.2s ease-in; */
  transform: translateY(-100%);

  ${(props) =>
    props.active === true &&
    css`
      transform: translateY(0%);
    `}
`;

const ListUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
`;

const ListLi = styled.li`
  width: 33.3%;
  margin: 0;
  font-size: 17px;
  text-align: center;
  height: 80px;
  padding: 30px 0 0 0;
  font-family: 'nexonRegular';
  cursor: pointer;
  display: inline;

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonBold';
    `}

  & > a {
    color: #1f1f1f;
  }
`;

const OthersNavBoxWrap = styled.div`
  height: 83px;
  box-shadow: 0 0 9.5px 1.1px rgba(38, 42, 47, 0.3);
  background-color: white;
`;

const OthersNavItemsWrap = styled.div`
  width: 1100px;
  margin: 0 auto;
  padding: 17px 0;
  display: flex;
  justify-content: space-between;
`;

const OthersDropMenuButton = styled.button`
  font-size: 19px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'nexonMedium';
  color: #403f49;
  padding: 10px 0;

  & + & {
    margin: 0 0 0 60px;
  }
`;

const IconBlack = styled(BiChevronDown)`
  font-size: 25px;
  margin: 0 0 3px 0;
`;

const OthersRightSide = styled.div`
  display: flex;
`;

export default Navbar;
