import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import MyPageLayout from 'components/templates/MypageLayout';
import { observer } from 'mobx-react';
import stores from 'stores';
import { AnnouncementHistory, DepositWait, ReadingTicket } from 'components/modules/PaymentItem';
import Pagination from 'components/atoms/Pagination';

const dummyAnnouncement = [
  {
    id: 0,
    date: '2021.08.17',
    title: '주방보조 모집합니다. (베트남 사람 환영)',
    leftPeriod: '200일',
    payAmount: 450000,
    jobType: '프리미엄잡 Premium Jobs',
  },
  {
    id: 1,
    date: '2021.08.17',
    title: '주방보조 모집합니다. (베트남 사람 환영)',
    leftPeriod: '20일',
    payAmount: 4500000,
    jobType: '프리미엄잡 Premium Jobs',
  },
  {
    id: 2,
    date: '2021.08.17',
    title: '주방보조 모집합니다. (베트남 사람 환영)',
    leftPeriod: '20일',
    payAmount: 450000,
    jobType: '프리미엄잡 Premium Jobs',
  },
  {
    id: 3,
    date: '2021.08.17',
    title: '주방보조 모집합니다. (베트남 사람 환영)',
    leftPeriod: '20일',
    payAmount: 450000,
    jobType: '프리미엄잡 Premium Jobs',
  },
];

const dummyDeposit = [
  { id: 0, account: '110-52525-636326', payAmount: 450000, jobType: '프리미엄잡 Premium Jobs' },
  { id: 1, account: '110-52525-636326', payAmount: 450000, jobType: '프리미엄잡 Premium Jobs' },
  { id: 2, account: '110-52525-636326', payAmount: 450000, jobType: '프리미엄잡 Premium Jobs' },
  { id: 3, account: '110-52525-636326', payAmount: 450000, jobType: '프리미엄잡 Premium Jobs' },
];

const dummyReading = [
  {
    id: 0,
    reading: false,
    point: 50000,
    date: '2021.08.17',
  },
  {
    id: 1,
    reading: true,
    point: 3000,
    date: '2021.08.17',
  },
  {
    id: 2,
    reading: true,
    point: 3000,
    date: '2021.08.17',
  },
  {
    id: 3,
    reading: false,
    point: 50000,
    date: '2021.08.17',
  },
  {
    id: 4,
    reading: false,
    point: 50000,
    date: '2021.08.17',
  },
];

const PaymentHistory = observer(() => {
  const [tab, setTab] = useState('Announcement');
  const [announcementPage, setAnnouncementPage] = useState(1);
  const [depositPage, setDepositPage] = useState(1);
  const [readingPage, setReadingPage] = useState(1);

  return (
    <PageLayout>
      <MyPageLayout>
        <Title>열람권 결제</Title>
        <Wrap>
          <MarginWrap>
            <DescText>열람권은 맞춤인재의 이력서를 열람하실 때 필요한 포인트입니다.</DescText>
            <PointWrap>
              <PointBox>
                <PointText>10,000 포인트</PointText>
                <PointTextBold>10,000원</PointTextBold>
              </PointBox>
              <PointBox>
                <PointText>50,000 포인트</PointText>
                <PointTextBold>50,000원</PointTextBold>
              </PointBox>
              <PointBox>
                <PointText>30,000 포인트</PointText>
                <PointTextBold>30,000원</PointTextBold>
              </PointBox>
              <PointBox>
                <PointText>100,000 포인트</PointText>
                <PointTextBold>100,000원</PointTextBold>
              </PointBox>
            </PointWrap>
          </MarginWrap>
        </Wrap>
        <NowPointWrap>
          현재 열람권 : <span>14,000</span>포인트
        </NowPointWrap>
        <HistoryWrap>
          <TabWrap>
            <Tab active={tab === 'Announcement'} onClick={() => setTab('Announcement')}>
              공고 결제 내역
            </Tab>
            <Tab active={tab === 'DeposiWait'} onClick={() => setTab('DeposiWait')}>
              입금 대기
            </Tab>
            <Tab active={tab === 'ReadingTicket'} onClick={() => setTab('ReadingTicket')}>
              열람권 결제 내역
            </Tab>
          </TabWrap>
          {tab === 'Announcement' ? (
            <ListWrap>
              {dummyAnnouncement.map((item) => (
                <AnnouncementHistory
                  date={item.date}
                  title={item.title}
                  leftPeriod={item.leftPeriod}
                  payAmount={item.payAmount}
                  jobType={item.jobType}
                  key={item.id}
                />
              ))}
              <PaginationWrap>
                <Pagination
                  totalPage={10}
                  limit={5}
                  page={announcementPage}
                  setPage={setAnnouncementPage}
                ></Pagination>
              </PaginationWrap>
            </ListWrap>
          ) : tab === 'DeposiWait' ? (
            <ListWrap>
              {dummyDeposit.map((item) => (
                <DepositWait
                  account={item.account}
                  payAmount={item.payAmount}
                  jobType={item.jobType}
                  key={item.id}
                />
              ))}
              <PaginationWrap>
                <Pagination
                  totalPage={10}
                  limit={5}
                  page={depositPage}
                  setPage={setDepositPage}
                ></Pagination>
              </PaginationWrap>
            </ListWrap>
          ) : (
            <ListWrap>
              {dummyReading.map((item) => (
                <ReadingTicket
                  reading={item.reading}
                  point={item.point}
                  date={item.date}
                  key={item.id}
                />
              ))}
              <PaginationWrap>
                <Pagination
                  totalPage={10}
                  limit={5}
                  page={readingPage}
                  setPage={setReadingPage}
                ></Pagination>
              </PaginationWrap>
            </ListWrap>
          )}
        </HistoryWrap>
      </MyPageLayout>
    </PageLayout>
  );
});

const Title = styled.div`
  font-size: 18px;
  color: #1f2422;
  font-family: 'nexonMedium';
  letter-spacing: -0.46px;
  margin: 30px 0 0 0;
`;

const Wrap = styled.div`
  background-color: #eef1f5;
  padding: 22px 0;
  width: 100%;
  margin: 11px 0 0 0;
`;

const MarginWrap = styled.div`
  width: 718px;
  margin: 0 auto;
`;

const DescText = styled.p`
  font-size: 16px;
  color: #434343;
  font-family: 'nexonRegular';
  letter-spacing: -0.4px;
  margin: 0 12px 22px;
`;

const PointWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PointBox = styled.div`
  width: 335px;
  height: 82px;
  background-color: white;
  border: 2px solid #9c9eb9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 7px 12px;
`;

const PointText = styled.p`
  font-size: 18px;
  color: #434343;
  font-family: 'nexonMedium';
  letter-spacing: -0.4px;
  margin: 0 44px 0 0;
`;

const PointTextBold = styled(PointText)`
  font-family: 'nexonBold';
  margin: 0 0 0 0;
`;

const NowPointWrap = styled.div`
  background-color: #e6e4f2;
  padding: 20px 30px;
  width: 100%;
  margin: 80px 0 0 0;
  font-size: 22px;
  color: #434343;
  font-family: 'nexonMedium';
  letter-spacing: -0.4px;

  & > span {
    color: ${theme.colors.primary};
  }
`;

const HistoryWrap = styled.div`
  margin: 90px 0 0 0;
`;

const TabWrap = styled.div`
  display: flex;
`;

const Tab = styled.p`
  font-size: 18px;
  color: #1f2422;
  font-family: 'nexonRegular';
  letter-spacing: -0.4px;
  padding: 3px 12px 3px 0;
  cursor: pointer;

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonBold';
    `}

  & + & {
    border-left: 1px solid #8d8a9b;
    padding: 3px 12px;
  }
`;

const ListWrap = styled.div`
  border-top: 3px solid #8d8a9b;
  margin: 10px 0 0 0;
`;

const PaginationWrap = styled.div`
  margin: 48px 0 0 0;
`;

export default PaymentHistory;
