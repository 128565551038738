import { useMediaQuery } from 'react-responsive';

export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({
    query: '(max-width:562px)',
  });
  return <>{isMobile && children}</>;
};

export const Others = ({ children }) => {
  const isOthers = useMediaQuery({
    query: '(min-width:563px)',
  });
  return <>{isOthers && children}</>;
};

export const Laptop = ({ children }) => {
  const isLaptop = useMediaQuery({
    query: '(max-width:1100px)',
  });
  return <>{isLaptop && children}</>;
};
