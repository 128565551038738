import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import MainNav from 'components/templates/MainNav';
import { FaMapMarkerAlt, FaHashtag } from 'react-icons/fa';
import RecruitCard from 'components/modules/RecruitCard';
import Pagination from 'components/atoms/Pagination';
import { observer } from 'mobx-react';
import stores from 'stores';
import { Link } from 'react-router-dom';
import { formatPayType } from 'components/utils/useful-function';
import { Mobile, Others } from 'styles/MediaQuery';

const jobCategories = [
  { value: 'FACTORY', text: '공장' },
  { value: 'FARM', text: '농장' },
  { value: 'RESTAURANT', text: '식당' },
  { value: 'CONSTRUCTION', text: '건설' },
  { value: 'TRANSLATION', text: '번역' },
  { value: 'OFFICE', text: '사무직' },
  { value: 'ETC', text: '기타' },
];

const keywordType = [
  { value: 'HIGH_PAYMENT', text: '높은급여' },
  { value: 'EASY_WORK', text: '쉬운업무' },
  { value: 'PROVIDE_MEAL', text: '식사제공' },
  { value: 'PROVIDE_RESIDENCE', text: '숙소제공' },
  { value: 'KOREAN_NOT_CARE', text: '한국어 불필요' },
  { value: 'VISA_NOT_CARE', text: '비자 종류 상관 없음' },
];

const RecruitInfo = observer(() => {
  const { regionsStore, jobStore } = stores;

  useEffect(() => {
    regionsStore.getRegions();
  }, [regionsStore]);

  const [page, setPage] = useState(1);
  const listPerPage = 10;

  const regionData = regionsStore.regionData;
  const regionDataByParentId = regionsStore.regionDataByParentId;

  const [selectedSido, setSelectedSido] = useState(0);
  const [selectedSidoCode, setSelectedSidoCode] = useState(null);

  const handleSelectSido = (id, code) => {
    setSelectedSido(id);
    setSelectedSidoCode(code);
    setSelectGungu([]);
    regionsStore.getRegionsByParentId(id);
  };

  const [selectGungu, setSelectGungu] = useState([]);

  const handleSelectGungu = (id) => {
    setSelectGungu([...selectGungu, id]);
  };

  const [job, setJob] = useState([]);

  const handleClickJob = (tab) => {
    if (job.includes(tab)) {
      setJob(job.filter((item) => item !== tab));
    } else {
      setJob([...job, tab]);
    }
  };

  const [keyword, setKeyword] = useState('');

  const handleClickKeyword = (tab) => {
    setKeyword(tab);
  };

  useEffect(() => {
    jobStore.getEmployeeLocalJobs('LOCAL', job, keyword, '', 0, 10, selectGungu);
  }, [jobStore, job, keyword, selectGungu]);

  useEffect(() => {
    jobStore.getEmployeeLifeJobs('LIFE', job, keyword, '', page - 1, listPerPage, selectGungu);
  }, [jobStore, page, job, keyword, selectGungu]);

  const localJobData = jobStore.localJobData;
  const lifeJobData = jobStore.lifeJobData;

  return (
    <PageLayout>
      <MainNav />
      <FilterWrap>
        <Title>채용정보 상세 검색</Title>
        <LocationFilterWrap>
          <FilterTitleWrap>
            <FilterTitle>
              <LocationIcon />
              Location
            </FilterTitle>
          </FilterTitleWrap>
          <CityWrap>
            {regionData.map((item) => (
              <CityItem
                key={item.id}
                onClick={() => handleSelectSido(item.id, item.sigunguCode)}
                active={selectedSido === item.id}
              >
                {item.sidoInSummary}
              </CityItem>
            ))}
          </CityWrap>
          <CountryWrap>
            {regionDataByParentId.map((item) => (
              <CountryItem
                key={item.id}
                onClick={() => handleSelectGungu(item.id)}
                active={String(selectGungu).includes(item.id)}
              >
                {item.sigungu}
              </CountryItem>
            ))}
          </CountryWrap>
        </LocationFilterWrap>
        <KeywordFilterWrap>
          <FilterTitleWrap>
            <FilterTitle>
              <KeywordIcon />
              Keyword
            </FilterTitle>
          </FilterTitleWrap>
          <KeywordWrap>
            <JobCategoriesWrap>
              {jobCategories.map((item) => (
                <JobItem
                  onClick={() => handleClickJob(item.value)}
                  active={job.includes(item.value)}
                  key={item.value}
                >
                  {item.text}
                </JobItem>
              ))}
            </JobCategoriesWrap>
            <JobCategoriesWrap>
              {keywordType.map((item) => (
                <JobItem
                  onClick={() => handleClickKeyword(item.value)}
                  active={keyword === item.value}
                  key={item.value}
                >
                  {item.text}
                </JobItem>
              ))}
            </JobCategoriesWrap>
          </KeywordWrap>
        </KeywordFilterWrap>
      </FilterWrap>
      <LocalJobTitle>Local Job</LocalJobTitle>
      <LocalJobBackground>
        <LocalJobWrap>
          {localJobData.map((item) => (
            <RecruitCardWrap key={item.id}>
              <RecruitCard
                id={item.id}
                companyName={item.companyName}
                title={item.title}
                address={item.address}
                payType={formatPayType(item.payType)}
                payAmount={item.payAmount}
                apply={true}
                bookmark={item.favorited}
                style={{ padding: '17px' }}
                link={`/recruitment/detail/${item.id}`}
                useSecurePayment={item.useSecurePayment}
                visaAvailable={item.visaAvailable}
              />
            </RecruitCardWrap>
          ))}
        </LocalJobWrap>
      </LocalJobBackground>
      <LifeJobWrap>
        <SmallTitle>Life Job</SmallTitle>
        {lifeJobData.map((item) => (
          <LifeRecruitCardWrap key={item.id}>
            <RecruitCard
              id={item.id}
              companyName={item.companyName}
              title={item.title}
              address={item.address}
              payType={formatPayType(item.payType)}
              payAmount={item.payAmount}
              apply={true}
              bookmark={item.favorited}
              style={{ padding: '17px' }}
              link={`/recruitment/detail/${item.id}`}
            />
          </LifeRecruitCardWrap>
        ))}
        <PaginationWrap>
          <Mobile>
            <Pagination
              totalPage={parseInt(jobStore?.totalLifeJob / 10)}
              limit={5}
              page={page}
              setPage={setPage}
            ></Pagination>
          </Mobile>
          <Others>
            <Pagination
              totalPage={parseInt(jobStore?.totalLifeJob / 10)}
              limit={9}
              page={page}
              setPage={setPage}
            ></Pagination>
          </Others>
        </PaginationWrap>
      </LifeJobWrap>
    </PageLayout>
  );
});

const FilterWrap = styled.div`
  width: 1100px;
  margin: 52px auto 60px;
  @media ${theme.mobile} {
    width: 100%;
    padding: 34px 25px 34px;
  }
`;

const Title = styled.p`
  font-family: 'nexonBold';
  font-size: 20px;
  color: #1f2422;
  letter-spacing: -1px;
  margin: 0 0 0 5px;
`;

const LocationFilterWrap = styled.div`
  width: 100%;
  border: 1px solid ${theme.colors.borderLight};
  border-radius: 5px;
  height: 255px;
  margin: 14px 0 0 0;
  display: flex;
`;

const FilterTitleWrap = styled.div`
  height: 100%;
  width: 158px;
  padding: 18px 11px 0 16px;
  background-color: #e8e8f6;
  border-radius: 5px 0 0 5px;
  @media ${theme.mobile} {
    width: 105px;
    padding: 12px 11px 0 10px;
  }
`;

const LocationIcon = styled(FaMapMarkerAlt)`
  color: ${theme.colors.primary};
  font-size: 22px;
  margin: 0px 4px 0 0;
  @media ${theme.mobile} {
    font-size: 18px;
    margin: -1px 4px 0 0;
  }
`;

const FilterTitle = styled.p`
  font-family: 'nexonBold';
  font-size: 22px;
  color: #313131;
  letter-spacing: -0.8px;
  display: flex;
  @media ${theme.mobile} {
    font-size: 16px;
  }
`;

const CityWrap = styled.div`
  width: 310px;
  border-right: 1px solid ${theme.colors.borderLight};
  display: flex;
  flex-wrap: wrap;
  height: 253px;
  padding: 10px 20px 0;
  overflow-y: scroll;
  @media ${theme.mobile} {
    width: 120px;
    display: block;
    padding: 0;
  }
`;

const CityItem = styled.div`
  font-family: 'nexonRegular';
  font-size: 17px;
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
  width: 33%;

  ${(props) =>
    props.active === true &&
    css`
      color: ${theme.colors.primary};
    `}

  @media ${theme.mobile} {
    width: 100%;
  }
`;

const CountryWrap = styled.div`
  width: 640px;
  height: 253px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 30px 0;
  justify-content: space-between;
  align-content: flex-start;

  @media ${theme.mobile} {
    width: 286px;
    padding: 0 30px;
  }
`;

const CountryItem = styled.div`
  font-family: 'nexonRegular';
  font-size: 17px;
  text-align: center;
  margin: 15px 0;
  width: 25%;
  height: 19px;
  cursor: pointer;

  ${(props) =>
    props.active === true &&
    css`
      color: ${theme.colors.primary};
    `}

  @media ${theme.mobile} {
    width: 50%;
  }
`;

const KeywordFilterWrap = styled.div`
  width: 100%;
  height: 170px;
  border: 1px solid ${theme.colors.borderLight};
  border-radius: 5px;
  margin: 14px 0 0 0;
  display: flex;
  @media ${theme.mobile} {
    height: 230px;
  }
`;

const KeywordIcon = styled(FaHashtag)`
  color: ${theme.colors.primary};
  font-size: 22px;
  margin: 1px 4px 0 0;
  @media ${theme.mobile} {
    font-size: 18px;
    margin: -1px 4px 0 0;
  }
`;

const KeywordWrap = styled.div``;

const JobCategoriesWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 17px 20px;

  @media ${theme.mobile} {
    height: 114px;
    padding: 17px 5px;

    & + & {
      border-top: 1px solid ${theme.colors.borderLight};
    }
  }
`;

const JobItem = styled.div`
  font-family: 'nexonRegular';
  height: 36px;
  border: 1.5px solid #e8e8f6;
  border-radius: 40px;
  font-size: 17px;
  text-align: center;
  padding: 7px 20px;
  margin: 5px 5px;

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonMedium';
      border: 1.5px solid ${theme.colors.primary};
      color: ${theme.colors.primary};
    `}

  @media ${theme.mobile} {
    padding: 5px 13px;
  }
`;

const LocalJobBackground = styled.div`
  background-color: #eef1f5;
  margin: 14px 0 0 0;
  padding: 15px 0 40px 0;
`;

const LocalJobWrap = styled.div`
  width: 1100px;
  margin: 0 auto;

  @media ${theme.mobile} {
    width: 100%;
    padding: 16px 16px 26px;
  }
`;

const SmallTitle = styled.p`
  font-family: 'nexonRegular';
  font-size: 18px;
  color: #1f2422;
  margin: 9px;

  @media ${theme.mobile} {
    font-size: 15px;
  }
`;
const LocalJobTitle = styled(SmallTitle)`
  width: 1100px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 9px;

  @media ${theme.mobile} {
    font-size: 15px;
    margin: 0 0 0px 20px;
    padding: 0;
  }
`;

const RecruitCardWrap = styled.div`
  background-color: #fff;

  & + & {
    margin-top: 7px;
  }
`;

const LifeJobWrap = styled.div`
  width: 1100px;
  margin: 60px auto 0;

  @media ${theme.mobile} {
    width: 100%;
    padding: 39px 16px 0;
    margin: 0;
  }
`;

const PaginationWrap = styled.div`
  margin: 40px 0 0 0;
`;

const LifeRecruitCardWrap = styled.div`
  border: 1px solid ${theme.colors.borderLight};
  border-radius: 5px;

  & + & {
    margin-top: 7px;
  }
`;

export default RecruitInfo;
