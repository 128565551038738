import { getRegionsApi, getRegionsByParentIdApi } from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class RegionsStore {
  regionData = [];
  regionDataByParentId = [];

  constructor() {
    makeAutoObservable(this);
  }

  setRegionData = (param) => {
    this.regionData = param;
  };

  setRegionDataByParentId = (param) => {
    this.regionDataByParentId = param;
  };

  getRegions = async () => {
    await getRegionsApi()
      .then((res) => {
        console.log(res);
        this.setRegionData(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getRegionsByParentId = async (parentId) => {
    await getRegionsByParentIdApi(parentId)
      .then((res) => {
        console.log(res);
        this.setRegionDataByParentId(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const regionsStore = new RegionsStore();

export default regionsStore;
