import { postCertificationsApi, postCertificationsCodeApi } from 'api/API';
import { makeAutoObservable, runInAction } from 'mobx';

class CertificationStore {
  constructor() {
    makeAutoObservable(this);
  }

  postCertifications = async (data) => {
    await postCertificationsApi(data)
      .then((res) => {
        if (data.onlyForCertification === false) {
          if ('SUCCESS' !== res.data.responseCode) {
            alert('요청에 실패했습니다.');
          } else {
            localStorage.setItem('userType', res.data.data.role);
            localStorage.setItem('token', res.data.data.accessToken);
            //   alert('요청에 성공했습니다.');
            window.location.href = '/';
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postCertificationsCode = async (data) => {
    await postCertificationsCodeApi(data)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const certificationStore = new CertificationStore();

export default certificationStore;
