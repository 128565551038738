import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const EmployeeServiceNav = () => {
  const location = useLocation();
  return (
    <Wrap>
      <Title>구직자 서비스</Title>
      <BoxWrap>
        <Li active={location.pathname === '/mypage/alarm'}>
          <Link to="/mypage/alarm">알림 설정</Link>
        </Li>
        <Li active={location.pathname === '/mypage/modifyResume'}>
          <Link to="/mypage/modifyResume">이력서 수정</Link>
        </Li>
        <Li active={location.pathname === '/mypage/applystatus'}>
          <Link to="/mypage/applystatus">지원 현황</Link>
        </Li>
        <Li active={location.pathname === '/mypage/favorites'}>
          <Link to="/mypage/favorites">즐겨찾기</Link>
        </Li>
        <Li active={location.pathname === '/mypage/language'}>
          <Link to="/mypage/language">언어 변경</Link>
        </Li>
        <Li>문의하기</Li>
      </BoxWrap>
    </Wrap>
  );
};

export const EmployerServiceNav = () => {
  const location = useLocation();
  return (
    <Wrap>
      <Title>기업 서비스</Title>
      <BoxWrap>
        <Li active={location.pathname === '/mypage/alarm'}>
          <Link to="/mypage/alarm">진행중인 공고</Link>
        </Li>
        <Li active={location.pathname === '/mypage/modifyResume'}>
          <Link to="/mypage/modifyResume">마감된 공고</Link>
        </Li>
        <Li active={location.pathname === '/mypage/applystatus'}>
          <Link to="/mypage/applystatus">비자 확인</Link>
        </Li>
        <Li active={location.pathname === '/mypage/favorites'}>
          <Link to="/mypage/favorites">결제 내역</Link>
        </Li>
        <Li active={location.pathname === '/mypage/language'}>
          <Link to="/mypage/language">문의하기</Link>
        </Li>
      </BoxWrap>
    </Wrap>
  );
};

const Wrap = styled.div``;

const Title = styled.p`
  font-size: 20px;
  color: #1f2422;
  font-family: 'nexonMedium';
  padding: 0 0 0 10px;
  margin: 0 0 9px 0;
`;

const BoxWrap = styled.ul`
  width: 200px;
  border: 1px solid ${theme.colors.border};
  border-radius: 5px;
  padding: 0 7px;
`;

const Li = styled.li`
  font-size: 14px;
  color: #1f2422;
  font-family: 'nexonRegular';
  padding: 20px 0;
  text-align: center;

  & + & {
    border-top: 1px solid ${theme.colors.border};
  }

  & > a {
    color: #1f1f1f;
  }

  ${(props) =>
    props.active === true &&
    css`
      font-family: 'nexonBold';
    `}
`;

export default EmployeeServiceNav;
