import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import PageLayout from 'components/templates/PageLayout';
import { observer } from 'mobx-react';
import stores from 'stores';
import { toJS } from 'mobx';
import MyPageLayout from 'components/templates/MypageLayout';
import { Mobile, Others } from 'styles/MediaQuery';

const Language = observer(() => {
  const { countryStore, userStore } = stores;

  useEffect(() => {
    countryStore.getCountries();
  }, [countryStore]);

  const [languageData, setLanguageData] = useState([]);

  useEffect(() => {
    let filteredLanguageData = countryStore.countryData
      .filter((item) => item.countryIsoCode !== 'etc')
      .filter((item) => item.countryIsoCode !== 'kr')
      .filter((item) => item.countryIsoCode !== 'us')
      .filter((item) => item.countryIsoCode !== 'gb')
      .filter((item) => item.countryIsoCode !== 'ca')
      .filter((item) => item.countryIsoCode !== 'au');
    filteredLanguageData = [
      ...filteredLanguageData,
      {
        countryIsoCode: 'en',
        nameInNative: 'English',
        flagImgUrl: 'https://kotrip.s3.amazonaws.com/country/flag_glob_2.png',
      },
    ];
    setLanguageData(toJS(filteredLanguageData));
  }, [countryStore.countryData]);

  const [language, setLanguage] = useState('');

  const handleTabLanguage = (tab) => {
    setLanguage(tab);
  };

  const handleChangeLangCode = () => {
    userStore.putLangCode({ langCode: language });
  };

  return (
    <PageLayout>
      <Mobile>
        <ContentWrap>
          <LanguageWrap>
            {languageData.map((item) => (
              <LanguageItem
                onClick={() => handleTabLanguage(item.countryIsoCode)}
                active={language === item.countryIsoCode}
                key={item.countryIsoCode}
              >
                <Flag langCode={item.countryIsoCode}>
                  <img src={item.flagImgUrl} alt="flag" />
                </Flag>
                <FlagText>{item.nameInNative}</FlagText>
              </LanguageItem>
            ))}
          </LanguageWrap>
          <Button onClick={handleChangeLangCode}>언어 변경하기</Button>
        </ContentWrap>
      </Mobile>
      <Others>
        <MyPageLayout>
          <PCTitle>언어 변경</PCTitle>
          <PCLanguageWrap>
            {languageData.map((item) => (
              <PCLanguageItem
                onClick={() => handleTabLanguage(item.countryIsoCode)}
                active={language === item.countryIsoCode}
                key={item.countryIsoCode}
              >
                <Flag langCode={item.countryIsoCode}>
                  <img src={item.flagImgUrl} alt="flag" />
                </Flag>
                <FlagText>{item.nameInNative}</FlagText>
              </PCLanguageItem>
            ))}
          </PCLanguageWrap>
          <Button onClick={handleChangeLangCode}>언어 변경하기</Button>
        </MyPageLayout>
      </Others>
    </PageLayout>
  );
});

const ContentWrap = styled.div`
  padding: 0 30px;
`;

const LanguageWrap = styled.div`
  background-color: #f2f4f7;
  height: 400px;
  margin: 100px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 17px;
`;

const LanguageItem = styled.div`
  width: 100px;
  height: 110px;
  border-radius: 3px;
  margin: 5px 8px;
  padding: 13px 10px;
  cursor: pointer;

  ${(props) =>
    props.active === true &&
    css`
      background-color: #e4e9f0;
    `}
`;

const Flag = styled.div`
  width: 53px;
  height: 35px;
  margin: 0 auto;

  & > img {
    width: 100%;
    margin-top: -10px;
  }

  ${(props) =>
    props.langCode === 'np' &&
    css`
      & > img {
        height: 100%;
        object-fit: contain;
        margin-top: 0px;
      }
    `}

  ${(props) =>
    props.langCode === 'en' &&
    css`
      & > img {
        height: 100%;
        object-fit: contain;
        margin-top: 0px;
      }
    `}
`;

const FlagText = styled.p`
  font-size: 14px;
  text-align: center;
  margin: 15px 0 0 0;
`;

const Button = styled.button`
  font-family: 'nexonMedium';
  width: 253px;
  height: 62px;
  border-radius: 5px;
  border: 2px solid ${theme.colors.primary};
  font-size: 24px;
  margin: 65px auto 0;
  display: block;
  background-color: transparent;
`;

const PCTitle = styled.div`
  font-size: 18px;
  color: #1f2422;
  font-family: 'nexonMedium';
  letter-spacing: -0.46px;
  margin: 30px 0 0 0;
`;

const PCLanguageWrap = styled.div`
  background-color: #eef1f5;
  padding: 30px 40px;
  width: 100%;
  margin: 11px 0 0 0;
  display: flex;
  flex-wrap: wrap;
`;

const PCLanguageItem = styled(LanguageItem)`
  margin: 5px 14px;
`;

export default Language;
