const media = {
  sMobile: '360px',
  mobile: '562px',
  laptop: '1280px',
};

const theme = {
  sMobile: `(max-width: ${media.sMobile})`,
  mobile: `(max-width: ${media.mobile})`,
  laptop: `(max-width: ${media.laptop})`,
  colors: {
    primary: '#9937ff',
    bodyText: '#403f49',
    border: '#8d8a9b',
    lightPrimary: '#e8e8f6',
    borderLight: '#b2b3c7',
    purpleGray: '#a2a5d2',
    error: '#b85565',
  },
};

export default theme;
